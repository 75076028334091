import React, { useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import CheapUTrader from './CheapUTraderList/CheapUTrader'
import { Container } from 'react-bootstrap'

function CheapUTraderList(props){
	const MainContext = useContext(props.context)
	const {id: pageID} = useParams()

	const rowKeys = useMemo(()=>{
		let row = []
		for (let i = 0; i < props.item.rows; i++)
			row.push(pageID + "-C" + props.item["key"] + "-" +(i+1))
		return row
	}, [props.item.rows])

	const fontSize = useMemo(()=>{
		return MainContext.clientSetting.setting.fontSize
	}, [MainContext.clientSetting.setting.fontSize])

	const color = useMemo(()=>{
		const {buyColor, sellColor, AsColor, callColor, putColor} = MainContext.clientSetting.setting
		return {buyColor, sellColor, AsColor, callColor, putColor}
	}, [
		MainContext.clientSetting.setting.buyColor,
		MainContext.clientSetting.setting.sellColor,
		MainContext.clientSetting.setting.AsColor,
		MainContext.clientSetting.setting.callColor,
		MainContext.clientSetting.setting.putColor
	])

	const volumeSetting = useMemo(()=>{
		const {maxVolume, defaultVolume} = MainContext.clientSetting.setting
		return {maxVolume, defaultVolume}
	}, [MainContext.clientSetting.setting.maxVolume, MainContext.clientSetting.setting.defaultVolume])

	const shortcutKeySetting = useMemo(()=>{
		const {capAllKey, capBidKey, capAskKey, addKey, minusKey, triggerBuyKey, triggerSellKey, triggerAsKey} = MainContext.clientSetting.setting
		return {capAllKey, capBidKey, capAskKey, addKey, minusKey, triggerBuyKey, triggerSellKey, triggerAsKey}
	}, [MainContext.clientSetting.setting.shortcutKeyChange])

    return useMemo(()=>{
        return (
			<>
			<span className="react-close-handle react-draggable-cancel" onClick={()=>{
				rowKeys.forEach((x)=>{
					MainContext.ws.send(JSON.stringify({e: "trader", subE: "delRow", sessionID: MainContext.session, rowKey: x}))
				})
				props.onClose()
			}}>x</span>
			<Container className="noScrollbar" fluid style={{display:"flex", flexDirection: "column", overflowY: "auto", padding: 10, height: "100%"}}>
				{
					rowKeys.map((key)=>{
						return <CheapUTrader key={key} rowKey={key} fontSize={fontSize} color={color} volumeSetting={volumeSetting} shortcutKeySetting={shortcutKeySetting} context={props.context}/>
					})
				}
			</Container>
			</>
		)
	}, [fontSize, color, volumeSetting, shortcutKeySetting, MainContext.session])
}

export default CheapUTraderList