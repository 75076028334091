import { useState, useMemo } from 'react'

import { ComponentProps } from "../MainPage"

import { CloseButton, Tab, Tabs } from 'react-bootstrap'

import HistoryTable from './DetailPanel/History';
import RecordTable from './DetailPanel/Record';
import SummaryTable from './DetailPanel/Summary';

function DetailPanel({context, onClose}: ComponentProps){
    const [selectedKey, setSeletedKey] = useState<string>("History")

    return useMemo(()=>{
        return (
            <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
				<div style={{width: "100%", position: "sticky", display: "flex", backgroundColor: "#0d6efd"}}>
					<span className="text-white" style={{flex: 1, fontSize: 18, padding: 2, paddingLeft: 4}}>Detail Panel</span>
					<div style={{flex:0, backgroundColor: "#dc3545"}}>
						<CloseButton className="react-draggable-cancel" variant="white" style={{fontSize: 18, padding: 6}} onClick={onClose}/>
					</div>
				</div>
                <div className="react-draggable-cancel noScrollbar" style={{paddingLeft: 10, paddingRight: 10, height: "100%", overflow: "hidden", overflowY: "auto"}}>
                    <div style={{position:"sticky", top: 0, backgroundColor:"white", height: 10 , zIndex: 1}}></div>
                    <Tabs style={{position:"sticky", fontSize: 12, top: 10, backgroundColor: "white", zIndex: 1}} onSelect={(k)=>{k && setSeletedKey(k)}} activeKey={selectedKey}>
                        <Tab eventKey="History" title="History"/>
                        <Tab eventKey="Summary" title="Summary"/>
                        <Tab eventKey="Record" title="Record"/>
                    </Tabs>
                    {
                        selectedKey==="History"?<HistoryTable context={context}/>:
                        selectedKey==="Summary"?<SummaryTable context={context}/>:
                        selectedKey==="Record" ?<RecordTable context={context}/>:
                        null
                    }
                </div>
            </div>
        )
    }, [context, onClose, selectedKey]);
}

export default DetailPanel