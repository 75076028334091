import { useState, useEffect, useMemo } from 'react'

import { Button } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'

import { ReturnNextDownPrice, ReturnNextUpPrice } from '../../../../functions/StockHandle'

function BuyControl({
	buyLight, setBuyLight, UBid, UAsk, DBid, DAsk, volume,
	tbu, setTBU, maxB, setMaxB, callPut,
	br, setBR, bv, setBV,
	buyColor, buyStyle, fontSize, shortcutKeySetting
}){
	const [BVInput, setBVInput] = useState(null)
	const [BRInput, setBRInput] = useState(null)

	useEffect(() => {
		if (BVInput){
			BVInput.value = bv
		}
	}, [bv, BVInput])
	useEffect(() => {
		if (BRInput){
			BRInput.value = br
		}
	}, [br, BRInput])

	return useMemo(()=>{
		return (
			<div style={{flex:4, margin:2, display: "flex"}}>
						<InputGroup style={{flex: 2, marginLeft: 4, fontSize: fontSize}}>
							<Form.Control ref={(r)=>{setBVInput(r)}} type="text" className={buyStyle.InputClass} style={buyStyle.InputStyle}
								onBlur={(e)=>{
										if (e.target.value !== ""){
											if (/^[\d]*[.]*(\d)*$/.test(e.target.value)){
												const value = parseInt(parseFloat(e.target.value)*1000)/1000
												if (value >= 0){
													e.target.value = value
													setBV(value)
													return
												}
											}
											e.target.value = bv
										}else{
											e.target.value = 0
											setBV(0)
										}
								}}
							/>
							<InputGroup.Text className={buyStyle.LabelClass} style={buyStyle.LabelStyle}>BV</InputGroup.Text>
						</InputGroup>
						<InputGroup style={{flex: 2, marginLeft: 4, fontSize: fontSize}}>
							<Form.Control type="text" ref={(r)=>{setBRInput(r)}} className={buyStyle.InputClass} style={buyStyle.InputStyle}
								onBlur={(e)=>{
										if (e.target.value !== ""){
											if (/^[\d]*[.]*(\d)*$/.test(e.target.value)){
												const value = parseFloat(e.target.value)
												if (value >= 0 && value < 100){
													e.target.value = parseInt(value)
													setBR(parseInt(value))
													return
												}
											}
											e.target.value = br
										}else{
											e.target.value = 0
											setBR(0)
										}
								}}
							/>
							<InputGroup.Text className={buyStyle.LabelClass} style={buyStyle.LabelStyle}>BR</InputGroup.Text>
						</InputGroup>
						
						<InputGroup style={{flex: 3, marginLeft: 4, fontSize: fontSize}}>
							<Button className="react-draggable-cancel" size="sm" variant={buyColor.indexOf("#")>-1?buyColor:"outline-"+buyColor}
								style={buyStyle.InputButtonStyle}
								onDoubleClick={()=>{setMaxB(DAsk)}}
								onKeyPress={(e)=>{
									if (e.code === shortcutKeySetting.capBidKey)
										setMaxB(DBid)
									else if (e.code === shortcutKeySetting.capAskKey)
										setMaxB(DAsk)
									else if (e.code === shortcutKeySetting.capAllKey){
										if (callPut === "Call" || callPut === "Bull")
											setTBU(UAsk)
										else
											setTBU(UBid)
										setMaxB(DAsk)
									}
									else if (e.code === shortcutKeySetting.addKey)
										setMaxB((last)=>{return ReturnNextUpPrice(last)})
									else if (e.code === shortcutKeySetting.minusKey)
										setMaxB((last)=>{return ReturnNextDownPrice(last)})
								}}
							>
								{maxB}
							</Button>
							<InputGroup.Text className={buyStyle.LabelClass} style={buyStyle.LabelStyle}>Max.B</InputGroup.Text>
						</InputGroup>

						<InputGroup style={{flex: 3, marginLeft: 4, fontSize: fontSize}}>
							<Button className="react-draggable-cancel" size="sm" variant={buyColor.indexOf("#")>-1?buyColor:"outline-"+buyColor}
								style={buyStyle.InputButtonStyle}
								onDoubleClick={()=>{
									if (callPut === "Call" || callPut === "Bull")
										setTBU(UAsk)
									else
										setTBU(UBid)
								}}
								onKeyPress={(e)=>{
									if (e.code === shortcutKeySetting.capBidKey)
										setTBU(UBid)
									else if (e.code === shortcutKeySetting.capAskKey)
										setTBU(UAsk)
									else if (e.code === shortcutKeySetting.capAllKey){
										if (callPut === "Call" || callPut === "Bull")
											setTBU(UAsk)
										else
											setTBU(UBid)
										setMaxB(DAsk)
									}
									else if (e.code === shortcutKeySetting.addKey)
										setTBU((last)=>{return ReturnNextUpPrice(last)})
									else if (e.code === shortcutKeySetting.minusKey)
										setTBU((last)=>{return ReturnNextDownPrice(last)})
								}}
							>
								{tbu}
							</Button>
							<InputGroup.Text className={buyStyle.LabelClass} style={buyStyle.LabelStyle}>T.BU</InputGroup.Text>
						</InputGroup>
						<Button size="sm" className="react-draggable-cancel" variant={buyLight?buyColor:"outline-secondary"} disabled={tbu === 0 || maxB === 0 || volume === 0}
							style={{flex: 2, marginLeft: 4, padding: "0 4px", fontSize: fontSize, color: (buyLight)?"white":"" , borderColor: (buyLight)?buyColor:"", backgroundColor: (buyLight)?buyColor:""}}
							onClick={()=>{
								if (!buyLight) setBuyLight(true)
								else setBuyLight(false)
							}}
						>
							BUY
						</Button>
					</div>
		)
		// eslint-disable-next-line
	}, [
		UBid, UAsk, DBid, DAsk, buyLight, tbu, maxB, bv, br,
		setBuyLight, setTBU, setMaxB, setBV, setBR,
		fontSize, shortcutKeySetting, volume
	])
}

function SellControl({
	sellLight, setSellLight, UBid, UAsk, DBid, DAsk, volume,
	tsu, setTSU, minS, setMinS, callPut,
	sr, setSR, sv, setSV,
	sellColor, sellStyle, fontSize, shortcutKeySetting
}){
	const [SVInput, setSVInput] = useState(null)
	const [SRInput, setSRInput] = useState(null)

	useEffect(() => {
		if (SVInput){
			SVInput.value = sv
		}
	}, [sv, SVInput])
	useEffect(() => {
		if (SRInput){
			SRInput.value = sr
		}
	}, [sr, SRInput])

	return useMemo(()=>{
		return (
			<div style={{flex:4, margin:2, display: "flex"}}>
				<Button size="sm" className="react-draggable-cancel" variant={sellLight?sellColor:"outline-secondary"} disabled={tsu === 0 || minS === 0 || volume === 0}
					style={{flex: 2, marginLeft: 4, padding: "0 4px", fontSize: fontSize, color: (sellLight)?"white":"" , borderColor: (sellLight)?sellColor:"", backgroundColor: (sellLight)?sellColor:""}}
					onClick={()=>{
						if (!sellLight) setSellLight(true)
						else setSellLight(false)
					}}
				>
					SELL
				</Button>

				<InputGroup style={{flex: 3, marginLeft: 4, fontSize: fontSize}}>
					<InputGroup.Text className={sellStyle.LabelClass} style={sellStyle.LabelStyle}>T.SU</InputGroup.Text>
					<Button size="sm" className="react-draggable-cancel" variant={sellColor.indexOf("#")>-1?sellColor:"outline-"+sellColor}
						style={sellStyle.InputButtonStyle}
						onDoubleClick={()=>{
							if (callPut === "Call" || callPut === "Bull")
								setTSU(UBid)
							else
								setTSU(UAsk)
						}}
						onKeyPress={(e)=>{
							if (e.code === shortcutKeySetting.capBidKey)
								setTSU(UBid)
							else if (e.code === shortcutKeySetting.capAskKey)
								setTSU(UAsk)
							else if (e.code === shortcutKeySetting.capAllKey){
								if (callPut === "Call" || callPut === "Bull")
									setTSU(UBid)
								else
									setTSU(UAsk)
								setMinS(DBid)
							}
							else if (e.code === shortcutKeySetting.addKey)
								setTSU((last)=>{return ReturnNextUpPrice(last)})
							else if (e.code === shortcutKeySetting.minusKey)
								setTSU((last)=>{return ReturnNextDownPrice(last)})
						}}
					>
						{tsu}
					</Button>
				</InputGroup>

						<InputGroup style={{flex: 3, marginLeft: 4, fontSize: fontSize}}>
							<InputGroup.Text className={sellStyle.LabelClass} style={sellStyle.LabelStyle}>Min.S</InputGroup.Text>
							<Button size="sm" className="react-draggable-cancel" variant={sellColor.indexOf("#")>-1?sellColor:"outline-"+sellColor}
								style={sellStyle.InputButtonStyle}
								onDoubleClick={()=>{setMinS(DBid)}}
								onKeyPress={(e)=>{
									if (e.code === shortcutKeySetting.capBidKey)
										setMinS(DBid)
									else if (e.code === shortcutKeySetting.capAskKey)
										setMinS(DAsk)
									else if (e.code === shortcutKeySetting.capAllKey){
										if (callPut === "Call" || callPut === "Bull")
											setTSU(UBid)
										else
											setTSU(UAsk)
										setMinS(DBid)
									}
									else if (e.code === shortcutKeySetting.addKey)
										setMinS((last)=>{return ReturnNextUpPrice(last)})
									else if (e.code === shortcutKeySetting.minusKey)
										setMinS((last)=>{return ReturnNextDownPrice(last)})
								}}
							>
								{minS}
							</Button>
						</InputGroup>
				<InputGroup style={{flex: 2, marginLeft: 4, fontSize: fontSize}}>
					<InputGroup.Text className={sellStyle.LabelClass} style={sellStyle.LabelStyle}>SR</InputGroup.Text>
					<Form.Control ref={(r)=>{setSRInput(r)}} type="text" className={sellStyle.InputClass} style={sellStyle.InputStyle}
						onBlur={(e)=>{
								if (e.target.value !== ""){
									if (/^[\d]*[.]*(\d)*$/.test(e.target.value)){
										const value = parseFloat(e.target.value)
										if (value >= 0 && value < 100){
											e.target.value = parseInt(value)
											setSR(parseInt(value))
											return
										}
									}
									e.target.value = sr
								}else{
									e.target.value = 0
									setSR(0)
								}
						}}
					/>
				</InputGroup>
				<InputGroup style={{flex: 2, marginLeft: 4, fontSize: fontSize}}>
					<InputGroup.Text className={sellStyle.LabelClass} style={sellStyle.LabelStyle}>SV</InputGroup.Text>
					<Form.Control ref={(r)=>{setSVInput(r)}} className={sellStyle.InputClass} style={sellStyle.InputStyle}
						onBlur={(e)=>{
								if (e.target.value !== ""){
									if (/^[\d]*[.]*(\d)*$/.test(e.target.value)){
										const value = parseInt(parseFloat(e.target.value)*1000)/1000
										if (value >= 0){
											e.target.value = value
											setSV(value)
											return
										}
									}
									e.target.value = sv
								}else{
									e.target.value = 0
									setSV(0)
								}
						}}
					/>
				</InputGroup>
			</div>
		)
		// eslint-disable-next-line
	}, [
		UBid, UAsk, DBid, DAsk, sellLight, tsu, minS, sv, sr,
		setSellLight, setTSU, setMinS, setSV, setSR,
		fontSize, shortcutKeySetting, volume
	])
}

export { BuyControl, SellControl }