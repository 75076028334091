import { useEffect, useState, useRef, useMemo } from "react";

import { Table } from 'react-bootstrap'

function parseTime(time){
    return parseInt(time) * 1000
}

function shortTime(time){
    const date = new Date(parseTime(time))
    const hour = ("0"+date.getHours()).slice(-2)
    const minutes = ("0"+date.getMinutes()).slice(-2)
	const seconds = ("0"+date.getSeconds()).slice(-2)

    return hour+":"+minutes+":"+seconds
}

const RecordRows = 6

function TradeRecord(props){
	const [showRecord, setShowRecord] = useState([])
	const currentI = useRef(0)

	useEffect(()=>{
		setShowRecord(()=>{
			let rows = []
			for (let i = 0; i < RecordRows; i++){
				rows.push(
					<>
						<td>&nbsp;</td>
						<td></td>
						<td></td>
						<td></td>
					</>
				)
			}
			currentI.current = RecordRows + 1
			return rows
		})
	}, [props.symbol])
	
	useEffect(()=>{
		if (props.tradeData){
			(async()=>{
				setShowRecord((old)=>{
					const isTrade = parseInt(props.tradeData[3]) > 0
					if (!isTrade && currentI.current > RecordRows + 1) return old

					const isBid = parseInt(props.tradeData[7])===0
					const color = isTrade?isBid?"blue":"red":"black"
					const side = isTrade?isBid?"Bid":"Ask":"N/A"

					let newArr = old.slice(-5)

					newArr.push(
						<>
							<td style={{color: color}}>{shortTime(props.tradeData[6])}</td>
							<td style={{textAlign: "right", color: color}}>{props.tradeData[2]}</td>
							<td style={{textAlign: "right", color: color}}>{props.tradeData[3]}</td>
							<td style={{textAlign: "center",color: color}}>{side}</td>
						</>
					)
					currentI.current += 1
					return newArr
				})
			})()
		}
	}, [props.tradeData])

	return useMemo(()=>{
		return (
			<Table size="sm" bordered striped hover style={{marginBottom: 8}}>
				<thead>
					<tr style={{fontSize: 14, lineHeight: "1em"}}>
						<th >Time</th>
						<th style={{textAlign: "right"}}>Price</th>
						<th style={{textAlign: "right"}}>Amount</th>
						<th style={{textAlign: "center"}}>Side</th>
					</tr>
				</thead>
				<tbody>
					{
						showRecord.map((x, i) => {
							return <tr key={i} style={{fontSize: 14, lineHeight: "0.8em"}}>{x}</tr>
						})
					}
				</tbody>
			</Table>
		)
	}, [showRecord])
}

export default TradeRecord;