import { useState, useEffect, useMemo, useContext, useRef, useCallback } from "react";

import { GetSpreadTableType, ReturnNextDownPrice, ReturnNextUpPrice } from "../../functions/StockHandle";
import { ComponentProps } from "../MainPage"

import { InputGroup, Button, Table } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { GetQuoteRequest } from "../../api/request";

interface BidAskTableProps {
    Side: number
    Quotes: number[]
    Brokers: {[key:string]:string}
    BrokerData?: string[][][]
}
function BidAskTable({Side, Quotes, Brokers, BrokerData}: BidAskTableProps){
    const TableHeader = useMemo(()=>{
        return (
            <thead>
                <tr>
                    <td className={"text-white "+(Side===0?"bg-primary":"bg-danger")} style={{textAlign: "center", lineHeight: "0.8rem"}}>
                        {Side===0?"Bid":"Ask"}
                    </td>
                </tr>
            </thead>
        )
    }, [Side])

    const QuotesRef = useRef(Quotes)
    useEffect(()=>{
        QuotesRef.current = Quotes
    }, [Quotes])

    return useMemo(()=>{
        const PriceColor = "rgba(" + (Side===0?"13,110,253":"220,53,69") + ",0.25)"
        return (
            <Table size="sm" bordered striped hover style={{height: "fit-content"}}>
                {TableHeader}
                <tbody>
                    {
                        QuotesRef.current===undefined?<></>:QuotesRef.current.slice(0, 10).map((x, i)=>{
                            return (
                                <tr key={i} style={{lineHeight: "0.8rem"}}>
                                    <td style={{height: "fit-content", lineHeight: "0.8rem", margin: 0}}>
                                        <Table size="sm" style={{width: "100%", marginBottom: 0}} bordered>
                                            <thead><tr><td colSpan={2} style={{textAlign:"center", backgroundColor: PriceColor}}>{x}</td></tr></thead>
                                            <tbody>
                                                {
                                                    BrokerData===undefined?<></>:BrokerData[Side][i].map((x, j)=>{
                                                        const aqtech = x === "1247" || x === "1240" || x === "6305" || x === "6309" || x === "2068" || x === "2065"
                                                        const enemy = Brokers[x] === "雅利多證券" || Brokers[x] == "盈透證券"
                                                        const cmbi1292 = x === "1292"
                                                        const ffg = Brokers[x] === "富昌證券"
                                                        const hsbc8738 = x === "8738"

                                                        return (
                                                            <tr key={j} className={aqtech?"bg-success text-white":enemy?"bg-warning":cmbi1292||ffg||hsbc8738?"text-white":""} style={{lineHeight: "0.8rem", backgroundColor: cmbi1292?"#20c997":ffg?"#6f42c1":hsbc8738?"#0d6efd":""}}>
                                                                <td>{Brokers[x]}</td>
                                                                <td style={{textAlign: "right"}}>{x}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        )
    }, [Side, Brokers, BrokerData])
}

function BrokerPanel({onClose, context, item}: ComponentProps){
    const MainContext = useContext(context)

    const [symbol, setSymbol] = useState("")
    const [selectedSymbol, setSelectedSymbol] = useState<string|null>(null)

    const [bidPrice, setBidPrice] = useState(0)
    const [askPrice, setAskPrice] = useState(0)

    useEffect(()=>{
        if (selectedSymbol === null) return
        
        const quote = MainContext.bssQuote[selectedSymbol]
        if (typeof quote === "undefined") return

        setBidPrice(quote.bidP / 1000)
        setAskPrice(quote.askP / 1000)
    }, [MainContext.bssQuote])

    useEffect(()=>{
		if (MainContext.quoteData[1] === selectedSymbol){
			setBidPrice(parseFloat(MainContext.quoteData[2]))
			setAskPrice(parseFloat(MainContext.quoteData[3]))
		}
	}, [selectedSymbol, MainContext.quoteData])

    const quotePrices = useMemo(()=>{
        if (bidPrice === 0 || askPrice === 0 || selectedSymbol === null) return {
            bids: [], asks: []
        }
        
        let bids: number[] = [], asks: number[] = []
        for (let i = 0; i < 10; i++){
            const bp = bids.length===0 ? bidPrice : ReturnNextDownPrice(bids[bids.length-1], GetSpreadTableType(selectedSymbol))
            const ap = asks.length===0 ? askPrice : ReturnNextUpPrice(asks[asks.length-1], GetSpreadTableType(selectedSymbol))

            bids.push(bp)
            asks.push(ap)
        }

        return { bids, asks }
	}, [bidPrice, askPrice, selectedSymbol])

    const formHandle = useCallback((e)=>{
        e.preventDefault();
		e.stopPropagation();

		const cleanSpaceSymbol = symbol.replaceAll(" ", "").replaceAll(".", "").replaceAll("+","")

        if (MainContext.session && cleanSpaceSymbol !== "" && cleanSpaceSymbol !== selectedSymbol){
			MainContext.ws.send(new GetQuoteRequest(cleanSpaceSymbol))

            MainContext.ws.send(JSON.stringify({
                e: "trader",
                subE: "getQuote",
                sessionID: MainContext.session,
                s: cleanSpaceSymbol
            }))

			setSelectedSymbol(cleanSpaceSymbol)
            setBidPrice(0)
            setAskPrice(0)
		}
    },[MainContext.ws, MainContext.session, symbol])

    return useMemo(()=>{
        const selectedBrokerData = selectedSymbol === null ? void 0 : MainContext.brokerData[selectedSymbol]

        return (
            <>
            <span className="react-close-handle react-draggable-cancel" onClick={()=>{onClose()}}>x</span>
            <div className='noScrollbar' style={{padding: 10, height: "100%", overflowY: "scroll"}}>
                <Form className="mb-2" noValidate onSubmit={formHandle}>
                    <div className="react-draggable-cancel" style={{display: "flex"}}>
                        <div style={{flex: 9, paddingRight: 8}}>
                            <InputGroup size="sm">
                                <InputGroup.Text style={{fontSize: 12, fontWeight: "bold"}}>Symbol</InputGroup.Text>
                                <Form.Control type="text" style={{fontSize: 12}} onChange={(v)=>{setSymbol(v.target.value)}}/>
                            </InputGroup>
                        </div>
                        <div className="d-grid" style={{flex: 3, paddingLeft: 8}}>
                            <Button size="sm" style={{fontSize: 12}} type="submit">Search</Button>
                        </div>
                    </div>
                </Form>
                <div style={{display: "flex", gap: 4}}>
                    <BidAskTable Side={0} Quotes={quotePrices.bids} Brokers={MainContext.brokers} BrokerData={selectedBrokerData}/>
                    <BidAskTable Side={1} Quotes={quotePrices.asks} Brokers={MainContext.brokers} BrokerData={selectedBrokerData}/>
                </div>
            </div>
            </>
        )
    }, [symbol, quotePrices, MainContext.brokers, MainContext.brokerData, selectedSymbol])
}

export default BrokerPanel