import { useState, useCallback, useEffect } from "react"

import { Button } from 'react-bootstrap'
import Form from "react-bootstrap/Form"
import Modal from "react-bootstrap/Modal"

function CheapUTraderV2SetRows(props){
    const [rows, setRows] = useState(null)
    const [errMsg, setErrMsg] = useState("")

    const formHandle = useCallback((e)=>{
        const value = parseInt(rows)

        e.preventDefault();
        e.stopPropagation();

        if (/^[\d]*$/.test(rows) && value <= 99 && value > 0){
            props.setLayout((last)=>{
                let newArr = last.slice()
                let maxKey = 0
                let maxY = 0
                last.forEach((x)=>{
                    if (maxY < x["data-grid"].y + x["data-grid"].h)
                        maxY = x["data-grid"].y + x["data-grid"].h
                    if (maxKey < x["key"])
                        maxKey = x["key"]
                })

                newArr.push({"key": maxKey+1, "data-grid": {x: 0, y: maxY, w:8, h: 96, minW: 8, minH: 20}, "component": "CheapUTraderV2", "rows": value})
                return newArr
            })
            props.setShow(false)
        }else{
            if (/^[\d]*$/.test(rows) === false || value < 0)
                setErrMsg("It is Not a positive integer.")
            else if (value >= 100)
                setErrMsg("Rows number can't be more than 99.")
            else if (value < 1)
                setErrMsg("Rows number can't be less than 1.")
        }

    }, [rows, props.show, props.setLayout])

    useEffect(()=>{
        setErrMsg("")
    }, [props.show])

    return (
        <Modal show={props.show} onHide={()=>{props.setShow(false)}}>
            <Modal.Header>
                <Modal.Title>Setting How Many Rows</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={formHandle} noValidate>
                    <Form.Group className="mb-2">
                        <Form.Label>How many rows do you want?</Form.Label>
                        <Form.Control type="text" isInvalid={errMsg!==""} onChange={(v)=>{setRows(v.currentTarget.value)}}/>
                        <Form.Control.Feedback type="invalid">{errMsg}</Form.Control.Feedback>
                    </Form.Group>
                    <Button type="submit">Create Rows</Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default CheapUTraderV2SetRows