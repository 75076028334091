import { useMemo } from 'react'

import {InputGroup} from 'react-bootstrap'

function parseNumber(num){
    if (num >= 1000){
        return Math.round(num/10) /100 + "K"
    }
    return num
}

function UserStatus({Pts, AvaliablePts, Cost, DBidPrice, Turnover}){
    return useMemo(() => {
        let styleLabel = {padding: "0px 4px", fontSize: 12}
        let styleValue = {padding: "0px 4px", fontSize: 12, flex: 1}

        let FPnL = Math.round((DBidPrice-Cost)*Pts*100)/100

        return (
            <div style={{display: "flex", flexDirection: "row"}}>
                <InputGroup style={{flex: 4, display: "flex", marginRight: 2}}>
                    <InputGroup.Text style={styleLabel}><strong>Ava.Pts</strong></InputGroup.Text>
                    <InputGroup.Text style={styleValue}>{parseNumber(AvaliablePts)}</InputGroup.Text>
                </InputGroup>
                <InputGroup style={{flex: 3, display: "flex", marginLeft: 2, marginRight: 2}}>
                    <InputGroup.Text style={styleLabel}><strong>F.PnL</strong></InputGroup.Text>
                    <InputGroup.Text style={styleValue}>{FPnL}</InputGroup.Text>
                </InputGroup>
                <InputGroup style={{flex: 3, display: "flex", marginLeft: 2}}>
                    <InputGroup.Text style={styleLabel}><strong>TO</strong></InputGroup.Text>
                    <InputGroup.Text style={styleValue}>{parseNumber(Turnover)}</InputGroup.Text>
                </InputGroup>
            </div>
        )
    }, [Pts, AvaliablePts, DBidPrice, Turnover])
}

export default UserStatus