import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import './index.css';
import './react-grid-layout.css'
import './react-resizable.css'
import 'bootstrap/dist/css/bootstrap.min.css';

import App from './pages/MainPage';
import LoginPage from './pages/LoginPage';
import QRCodeLoginPage from './pages/QRCodeLoginPage'

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<Routes>
				<Route path="/qrcodelogin" element={<QRCodeLoginPage />}/>
				<Route path="/login" element={<LoginPage />}/>
				<Route path="/login/:reason" element={<LoginPage />}/>
				<Route path="/main/:id" element={<App />}/>
				<Route path="/main" element={<Navigate to="/main/0"/>}/>
				<Route path="*" element={<Navigate to="/login"/>}/>
			</Routes>
		</Router>
	</React.StrictMode>,
	document.getElementById('root')
);