import { useState, useEffect, useMemo } from 'react'

import Form from "react-bootstrap/Form"
import InputGroup from 'react-bootstrap/InputGroup'

import SwitchableButton from '../../global_component/SwitchableButton'

function Symbol({
	symbol, setSymbol,
	selectedSymbol, setSelectedSymbol,
	volume, setVolume,
	underlying, callPut,
	defaultVolume, maxVolume,
	AsColor, AslLight, setAslLight,
	buyLight, sellLight, fbuyLight, fsellLight,
	fontSize
}){
    const [VolumeInput, setVolumeInput] = useState(null)

    useEffect(()=>{
        if (VolumeInput){
            VolumeInput.value = volume
        }
    }, [volume, VolumeInput])

	return useMemo(()=>{
		return (
			<div style={{display: "flex"}}>
				<InputGroup style={{fontSize: fontSize, width: "fit-content"}}>
					<InputGroup.Text style={{padding: "0 4px", fontSize: fontSize}}>D</InputGroup.Text>
					<Form.Control disabled={buyLight||sellLight||fbuyLight||fsellLight} className="react-draggable-cancel" value={symbol} style={{padding: "0 4px", fontSize: fontSize, width: 50}} onChange={(v)=>{setSymbol(v.currentTarget.value)}}
						onBlur={(e)=>{e.target.value = selectedSymbol}}
						onKeyPress={(e)=>{
							if (e.code === "Enter" || e.code === "NumpadEnter"){
								if (symbol.length > 0){
									setSelectedSymbol(symbol)
								}
							}
						}}
					/>
				</InputGroup>
				<InputGroup style={{fontSize: fontSize, width: "fit-content", marginLeft: 4}}>
					<InputGroup.Text style={{padding: "0 4px", fontSize: fontSize}}>U</InputGroup.Text>
					<InputGroup.Text style={{padding: "0 4px", fontSize: fontSize}}>{("00000"+(underlying===null?"":underlying)).slice(-5)}</InputGroup.Text>
				</InputGroup>
				<InputGroup style={{fontSize: fontSize, width: "fit-content", marginLeft: 4}}>
					<InputGroup.Text style={{padding: "0 4px", fontSize: fontSize}}>{callPut}</InputGroup.Text>
				</InputGroup>
				<InputGroup style={{marginLeft: 4, fontSize: fontSize, width: "fit-content"}}>
					<InputGroup.Text style={{padding: "0 4px", fontSize: fontSize}}>Vol(K)</InputGroup.Text>
					<Form.Control className="react-draggable-cancel" ref={(r)=>{setVolumeInput(r)}} style={{padding: "0 4px", fontSize: fontSize, width: 50}}
						onBlur={(e)=>{ e.currentTarget.value = volume }}
						onKeyPress={(e) => {
							const value = e.currentTarget.value
							if (e.code === "Enter" || e.code === "NumpadEnter")
								setVolume((last)=>{
									if (value !== ""){
										if (/^[\d]*$/.test(value)){
											let volume = parseInt(value)
											if (volume > maxVolume || volume < 0){
												e.target.value = last
												return last
											}
										return volume
										}
										e.target.value = last
										return last
									}
									e.target.value = defaultVolume
									return defaultVolume
								})
						}}
					/>
				</InputGroup>
				<SwitchableButton className="react-draggable-cancel" value="AP" size="sm" style={{flex: 2, marginLeft: 4, padding: "0 8px", fontSize: fontSize}} color={AsColor} state={AslLight} setState={setAslLight}/>
			</div>
		)
	}, [
		fontSize, setSymbol, setSelectedSymbol, setVolume, setAslLight,
		symbol, selectedSymbol, volume, underlying, callPut, maxVolume, defaultVolume, AslLight, AsColor, buyLight, sellLight, fbuyLight, fsellLight
	])
}

export default Symbol