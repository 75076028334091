import { useState, useRef, useEffect, useContext, useMemo, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import Decimal from 'decimal.js'

import { StatusType } from '../../api/enum'
import { OrderSide, OrderStatus, RowModifyType } from '../../functions/Enum'
import { CurrentTimeString } from '../../functions/DateHandle'
import { ReturnNextDownPrice, ReturnNextUpPrice } from '../../functions/StockHandle'

import Symbol from './OXTrader/Symbol'
import Quote from './OXTrader/Quote'
import SwitchableButton from './global_component/SwitchableButton'
import UserStatus from './OXTrader/UserStatus'
import ControlPanel from './OXTrader/ControlPanel'

function MessageTable({messages}){
    return useMemo(()=>{
        return (
            <div className="react-draggable-cancel" style={{flex: 2, fontSize: 12, border: "1px solid #CCC", backgroundColor: "white", borderRadius: "5px", overflowY: "scroll"}}>
                {
                    messages.slice().reverse().map((x,i)=>{
                        return (
                            <div key={i} style={{display: "flex", flexDirection: "row"}}>
                                <span style={{flex: 3, marginLeft: 4}}>{x[0]}</span>
                                <span style={{flex: 9}}>{x[1]}</span>
                            </div>
                        )
                    })
                }
            </div>
        )
    }, [messages])
}

function OXTrader(props){
    const MainContext = useContext(props.context)
    const AllowAddRow = useRef(false)
    const AllowModify = useRef(false)

    const {id: pageID} = useParams()
    const rowKey = useMemo(()=>{
        return (pageID+"-O"+props.item["key"])
    }, [props.item["key"]])
    const [rowID, setRowID] = useState(null)

    const fontSize = useMemo(()=>{ return 12 }, [])

    const color = useMemo(()=>{
		const {buyColor, sellColor, AsColor, callColor, putColor} = MainContext.clientSetting.setting
		return {buyColor, sellColor, AsColor, callColor, putColor}
	}, [
        MainContext.clientSetting.setting.buyColor,
        MainContext.clientSetting.setting.sellColor,
        MainContext.clientSetting.setting.AsColor,
        MainContext.clientSetting.setting.callColor,
        MainContext.clientSetting.setting.putColor,
    ])

	const volumeSetting = useMemo(()=>{
		const {maxVolume, defaultVolume} = MainContext.clientSetting.setting
		return {maxVolume, defaultVolume}
	}, [MainContext.clientSetting.setting.maxVolume, MainContext.clientSetting.setting.defaultVolume])

	const shortcutKeySetting = useMemo(()=>{
		const {capAllKey, capBidKey,capAskKey, addKey, minusKey,
            triggerBuyKey, triggerSellKey, triggerAsKey} = MainContext.clientSetting.setting
		return {capAllKey, capBidKey, capAskKey, addKey, minusKey, triggerBuyKey, triggerSellKey, triggerAsKey}
	}, [MainContext.clientSetting.setting.shortcutKeyChange])

    const [symbol, setSymbol] = useState("")
    const [selectedSymbol, setSelectedSymbol] = useState(null)
    const [underlying, callPut, bgColor] = useMemo(()=>{
        let [underlying, callPut, bgColor] = [selectedSymbol, "Call", color.callColor]

        if (selectedSymbol){
            if (selectedSymbol.length === 5){
                let data = []
                if (selectedSymbol[0] === "1" || selectedSymbol[0] === "2")
                    data = MainContext.warrants.filter((x)=>{
                        if (x[1] === selectedSymbol)
                            return true
                        return false
                    })
                else if (selectedSymbol[0] === "5" || selectedSymbol[0] === "6")
                    data = MainContext.cbbcs.filter((x)=>{
                        if (x[1] === selectedSymbol)
                            return true
                        return false
                    })
                
                if (data.length === 1){
                    underlying = data[0][3]
                    callPut = data[0][5]
                    if (callPut === "Call" || callPut === "Bull")
                        bgColor = color.callColor
                    else if (callPut === "Put" || callPut === "Bear")
                        bgColor = color.putColor
                }
            }
        }

        return [underlying, callPut, bgColor]
    }, [selectedSymbol, MainContext.warrants, MainContext.cbbcs, MainContext.ws, color])

    const asset = useMemo(()=>{
		const result = MainContext.assets.filter((x)=>{
			if (x.symbol === selectedSymbol)
				return true
			return false
		})

		if (result.length !== 1)
			return {Pnl: 0, Pts: 0, Cost: 0}

		return result[0]
	}, [MainContext.assets, selectedSymbol])

    const [UBidPrice, setUBidPrice] = useState(0)
    const [UBidVolume, setUBidVolume] = useState(0)
    const [UAskPrice, setUAskPrice] = useState(0)
    const [UAskVolume, setUAskVolume] = useState(0)
    const [DBidPrice, setDBidPrice] = useState(0)
    const [DBidVolume, setDBidVolume] = useState(0)
    const [DAskPrice, setDAskPrice] = useState(0)
    const [DAskVolume, setDAskVolume] = useState(0)

    const [buyVolume, setBuyVolume] = useState(volumeSetting.defaultVolume)
    const [sellVolume, setSellVolume] = useState(volumeSetting.defaultVolume)

    const [tbu, setTBU] = useState(0)
    const [br, setBR] = useState(0)
    const [bv, setBV] = useState(0)
    const [tsu, setTSU] = useState(0)
    const [sr, setSR] = useState(0)
    const [sv, setSV] = useState(0)
    const [maxB, setMaxB] = useState(0)
    const [minS, setMinS] = useState(0)

    const [capBuyPriceLight , setCapBuyPriceLight] = useState(false)
    const [capSellPriceLight , setCapSellPriceLight] = useState(false)
    const [capAllPriceLight , setCapAllPriceLight] = useState(false)

    const [buyLight, setBuyLight] = useState(false)
    const [sellLight, setSellLight] = useState(false)
    const [BSLight, setBSLight] = useState(false)

    const [AsLight, setAsLight] = useState(false)
    const [ap, setAP] = useState(0)

    const [startLight, setStartLight] = useState(false)
    const [updateLight, setUpdateLight] = useState(false)
    const [forceLight, setForceLight] = useState(false)

    const [messages, setMessages] = useState([])

    //Guarding For Pending
	const [MaxAllowBuy, MinAllowSell] = useMemo(()=>{
		let maxAllowBuy = null
		let minAllowSell = null

        let maxPendBuy = null
        let minPendSell = null
		MainContext.pending.forEach((x)=>{
			if (x.symbol === selectedSymbol)
				if (x.side === OrderSide.Buy)
                    maxPendBuy = (maxPendBuy===null?x.price:Math.max(maxPendBuy, x.price))
				else if (x.side === OrderSide.Sell)
                    minPendSell = (minPendSell===null?x.price:Math.min(minPendSell, x.price))
		})

		MainContext.pendingRows.forEach((x)=>{
			if (x.symbol === selectedSymbol)
                minPendSell = (minPendSell===null?x.price:Math.min(minPendSell, x.price))
		})

        MainContext.marketTriggers.forEach((x)=>{
			if (x.symbol === selectedSymbol)
				if (x.side === OrderSide.Buy)
                    maxPendBuy = (maxPendBuy===null?x.price:Math.max(maxPendBuy, x.price))
				else if (x.side === OrderSide.Sell)
                    minPendSell = (minPendSell===null?x.price:Math.min(minPendSell, x.price))
		})

        maxAllowBuy = minPendSell===null?99999.0:ReturnNextDownPrice(new Decimal(minPendSell).div(1000).toNumber())
        minAllowSell = maxPendBuy===null?0.01:ReturnNextUpPrice(new Decimal(maxPendBuy).div(1000).toNumber())

		return [maxAllowBuy, minAllowSell]
	}, [selectedSymbol, MainContext.pending, MainContext.pendingRows, MainContext.marketTriggers])
	useEffect(()=>{
		if (MaxAllowBuy < maxB){
			if (startLight && (buyLight || BSLight)){
                setStartLight(false)
                setMessages((old)=>{
                    return [...old, [CurrentTimeString(), "Not Allowed to Buy when Pending Sell!"]]
                })
            }
		}

		if (MinAllowSell > minS || MinAllowSell > ap){
			if (startLight && sellLight){
                setStartLight(false)
                setMessages((old)=>{
                    return [...old, [CurrentTimeString(), "Not Allowed to Sell when Pending Buy!"]]
                })
            }
			setAsLight(false)
		}
	}, [MaxAllowBuy, MinAllowSell, maxB, minS, ap])

    const cleanUP = useCallback(() => {
        const setNull = [setRowID]
        setNull.forEach((e)=>{ e(null) });

        const setDefaultVolume = [setBuyVolume, setSellVolume]
        setDefaultVolume.forEach((e)=>{ e(volumeSetting.defaultVolume) });

        const setFalse = [setBuyLight, setSellLight, setBSLight, setAsLight, setStartLight, setUpdateLight, setForceLight]
        setFalse.forEach((e)=>{ e(false) });

        const setZero = [setAP, setTBU, setTSU, setMaxB, setMinS, setUBidPrice, setUBidVolume, setUAskPrice, setUAskVolume, setDBidPrice, setDAskPrice, setDBidVolume, setDAskVolume]
        setZero.forEach((e)=>{ e(0) });
    }, [volumeSetting])

    useEffect(()=>{
        if (MainContext.session){
            cleanUP()
            setSymbol("")
            setSelectedSymbol(null)

            AllowAddRow.current = false
            MainContext.ws.send(JSON.stringify({e: "trader", subE: "loadRow", rowKey: rowKey, sessionID: MainContext.session}))
        }
    }, [MainContext.session])

    useEffect(()=>{
        if (!MainContext.traderStream)
            return

        const traderStream = MainContext.traderStream
        if (traderStream.subE === "createRow"){
            if (traderStream.rowKey === rowKey && traderStream.status === StatusType.Success){
                setRowID(traderStream.rowID)
            }
            else if(traderStream.rowKey === rowKey && traderStream.status === StatusType.Error)
			{
                setMessages((old)=>{
                    return [...old, [CurrentTimeString(), traderStream.message]]
                })
				AllowAddRow.current = true
				AllowModify.current = true
				cleanUP()
                setSymbol("")
                setSelectedSymbol(null)
			}
        }
        else if (traderStream.subE === "loadRow"){
            if (traderStream.rowKey === rowKey){
                if (traderStream.status === StatusType.Success){
                    AllowAddRow.current = false
					AllowModify.current = false
                    setRowID(traderStream.row.rowID)
                    setSelectedSymbol(traderStream.row.symbol)
                    setSymbol(traderStream.row.symbol)

                    setBuyLight(traderStream.row.buyLight)
					setSellLight(traderStream.row.sellLight)
                    setBSLight(false)

                    if (traderStream.row.buyLight || traderStream.row.sellLight){
                        setStartLight(true)
                    }

					setBuyVolume(traderStream.row.buyVolume/1000)
                    setSellVolume(traderStream.row.sellVolume/1000)
					
					setBV(traderStream.row.bv*1000)
					setSV(traderStream.row.sv*1000)
					setBR(traderStream.row.br)
					setSR(traderStream.row.sr)
					setTBU(traderStream.row.tbu/1000)
					setTSU(traderStream.row.tsu/1000)
					setMaxB(traderStream.row.maxB/1000)
					setMinS(traderStream.row.minS/1000)

                    setAsLight(traderStream.row.AsLight)
                    setAP(traderStream.row.ap/1000)

                    setTimeout(()=>{
                        AllowAddRow.current = true
						AllowModify.current = true
                    }, 1)
                }else{
                    cleanUP()
                    AllowAddRow.current = true
                    AllowModify.current = true
                }
            }
        }
        else if (traderStream.subE === "serverRes_Order"){
			const serverRes_Order = traderStream.serverRes_Order
			if (serverRes_Order.RowID === rowID){
				if (serverRes_Order.Status === OrderStatus.Full || serverRes_Order.Status === OrderStatus.Partial || serverRes_Order.Status === OrderStatus.Reject){
					AllowModify.current = false
					if (serverRes_Order.OSide === OrderSide.Buy){
                        if (buyLight){
						    setBuyLight(false)
                            setStartLight(false)
                            setAP(serverRes_Order.Cost)
                            MainContext.ws.send(JSON.stringify({
                                e: "trader",
                                subE: "modifyRow",
                                sessionID: MainContext.session,
                                rowModifyType: RowModifyType.ap,
                                row: { rowID: rowID, ap: serverRes_Order.Cost*1000}
                            }))
                        }else if (BSLight){
                            setSellLight(true)
                            MainContext.ws.send(JSON.stringify({
                                e: "trader",
                                subE: "modifyRow",
                                sessionID: MainContext.session,
                                rowModifyType: RowModifyType.sellLight,
                                row: { rowID: rowID, sellLight: true}
                            }))
                        }
                    }
					else if (serverRes_Order.OSide === OrderSide.Sell){
                        setStartLight(false)
                        setSellLight(false)
                        setBSLight(false)
                        setAsLight(false)
                    }
					setTimeout(()=>{
						AllowModify.current = true
					}, 5)
				}

                if (serverRes_Order.Status === OrderStatus.Full || serverRes_Order.Status === OrderStatus.Partial){
                    if (forceLight){
                        setMessages((old)=>{
                            return [...old, [CurrentTimeString(), " Force: " + (serverRes_Order.OSide===OrderSide.Buy?"B":"S") + " Cost:" + serverRes_Order.Cost + " Vol:" + serverRes_Order.Shares]]
                        })
                    }else{
                        setMessages((old)=>{
                            return [...old, [CurrentTimeString(), " Trigger: " + (serverRes_Order.OSide===OrderSide.Buy?"B":"S") + " Cost:" + serverRes_Order.Cost + " Vol:" + serverRes_Order.Shares]]
                        })
                    }
                }
                else if (serverRes_Order.Status === OrderStatus.Reject){
                    setMessages((old)=>{
                        return [...old, [CurrentTimeString(), serverRes_Order.Reason]]
                    })
                }
			}
		}
        else if (traderStream.subE === "Quote"){
            if (traderStream.s === underlying && AllowModify.current){
                setUBidPrice(new Decimal(traderStream.quote.bidP).div(1000).toNumber())
                setUAskPrice(new Decimal(traderStream.quote.askP).div(1000).toNumber())
                setUBidVolume(traderStream.quote.bidS)
                setUAskVolume(traderStream.quote.askS)
            }
            if (traderStream.s === selectedSymbol && AllowModify.current){
                setDBidPrice(new Decimal(traderStream.quote.bidP).div(1000).toNumber())
                setDAskPrice(new Decimal(traderStream.quote.askP).div(1000).toNumber())
                setDBidVolume(traderStream.quote.bidS)
                setDAskVolume(traderStream.quote.askS)
            }
        }
    }, [MainContext.traderStream])

    useEffect(()=>{
        if (AllowModify.current){
            cleanUP()
        }

        if (AllowAddRow.current === true){
            if (!rowID && selectedSymbol){
                MainContext.ws.send(JSON.stringify({
					e: "trader",
					subE: "createRow",
					sessionID: MainContext.session,
					row: {
						rowKey: rowKey,
						symbol: selectedSymbol,
                        

						buyLight: (buyLight||BSLight)&&startLight,
						sellLight: sellLight||BSLight&&startLight,

                        buyVolume: buyVolume*1000,
                        sellVolume: sellVolume*1000,

						bv: bv*1000,
						sv: sv*1000,
						br: br,
						sr: sr,
						tbu: tbu*1000,
						tsu: tsu*1000,
						maxB: maxB*1000,
						minS: minS*1000,

                        AsLight: AsLight,
                        ap: ap*1000,
                        at: 0
					}
				}))
            }else{
                MainContext.ws.send(JSON.stringify({e: "trader", subE: "delRow", sessionID: MainContext.session, rowKey: rowKey}))
                if (selectedSymbol){
                    MainContext.ws.send(JSON.stringify({
                        e: "trader",
                        subE: "createRow",
                        sessionID: MainContext.session,
                        row: {
                            rowKey: rowKey,
                            symbol: selectedSymbol,

                            buyLight: (buyLight||BSLight)&&startLight,
                            sellLight: sellLight||BSLight&&startLight,

                            buyVolume: buyVolume*1000,
                            sellVolume: sellVolume*1000,

                            bv: bv*1000,
                            sv: sv*1000,
                            br: br,
                            sr: sr,
                            tbu: tbu*1000,
                            tsu: tsu*1000,
                            maxB: maxB*1000,
                            minS: minS*1000,

                            AsLight: AsLight,
                            ap: ap*1000,
                            at: 0
                        }
                    }))
                }
            }
        }
    }, [selectedSymbol])

    //Get Quote When getNewRowID
	useEffect(()=>{
		if (rowID && selectedSymbol){
            MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "subscribeRow",
				sessionID: MainContext.session,
				row: { rowID: rowID }
			}))
		}
	}, [selectedSymbol, rowID])

    useEffect(()=>{
        setUpdateLight(false)
        setForceLight(false)
        if (startLight){
            setCapBuyPriceLight(false)
            setCapSellPriceLight(false)
            setCapAllPriceLight(false)
        }
    }, [startLight])
    useEffect(()=>{
        if (rowID && AllowModify.current){
            if (startLight && !updateLight){
                setMessages((old)=>{
                    return [...old, [CurrentTimeString(), "Window Start..."]]
                })
                if (buyLight || BSLight){
                    if (!MaxAllowBuy){
                        setStartLight(false)
                        setMessages((old)=>{
                            return [...old, [CurrentTimeString(), "Not Allowed to Buy when Pending Sell!"]]
                        })
                        return
                    }
                    MainContext.ws.send(JSON.stringify({
                        e: "trader",
                        subE: "modifyRow",
                        sessionID: MainContext.session,
                        rowModifyType: RowModifyType.buyLight,
                        row: { rowID: rowID, buyLight: true}
                    }))
                }else if (sellLight){
                    if (!MinAllowSell){
                        setStartLight(false)
                        setMessages((old)=>{
                            return [...old, [CurrentTimeString(), "Not Allowed to Sell when Pending Buy!"]]
                        })
                        return
                    }
                    MainContext.ws.send(JSON.stringify({
                        e: "trader",
                        subE: "modifyRow",
                        sessionID: MainContext.session,
                        rowModifyType: RowModifyType.sellLight,
                        row: { rowID: rowID, sellLight: true }
                    }))
                }
            }else if ((!startLight || updateLight) && MainContext.ws){
                if (AllowModify.current && !updateLight){
                    setMessages((old)=>{
                        return [...old, [CurrentTimeString(), "Window Stop!"]]
                    })
                }else if (startLight && updateLight){
                    setMessages((old)=>{
                        return [...old, [CurrentTimeString(), "Window Stop until Update Light Off!"]]
                    })
                }
                MainContext.ws.send(JSON.stringify({
                    e: "trader",
                    subE: "modifyRow",
                    sessionID: MainContext.session,
                    rowModifyType: RowModifyType.buyLight,
                    row: { rowID: rowID, buyLight: false }
                }))
                MainContext.ws.send(JSON.stringify({
                    e: "trader",
                    subE: "modifyRow",
                    sessionID: MainContext.session,
                    rowModifyType: RowModifyType.sellLight,
                    row: { rowID: rowID, sellLight: false }
                }))
            }
        }
	}, [startLight, updateLight])
    useEffect(()=>{
        if (rowID && AllowModify.current){
            if (!MinAllowSell && AsLight){
                setAsLight(false)
                setMessages((old)=>{
                    return [...old, [CurrentTimeString(), "Not Allowed to Sell when Pending Buy!"]]
                })
                return
            }
            MainContext.ws.send(JSON.stringify({
                e: "trader",
                subE: "modifyRow",
                sessionID: MainContext.session,
                rowModifyType: RowModifyType.AsLight,
                row: { rowID: rowID, AsLight: AsLight }
            }))
        }
    }, [AsLight])
    useEffect(()=>{
		if (rowID && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.buyVolume,
				row: { rowID: rowID, buyVolume: buyVolume*1000 }
			}))
            if (buyVolume === 0){
                setStartLight(false)
                setUpdateLight(false)
                setAsLight(false)
            }
		}
	}, [buyVolume])
    useEffect(()=>{
		if (rowID && sellVolume !== -1 && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.sellVolume,
				row: { rowID: rowID, sellVolume: sellVolume*1000 }
			}))
            if (sellVolume === 0){
                setStartLight(false)
                setUpdateLight(false)
                setAsLight(false)
            }
		}
	}, [sellVolume])
    useEffect(()=>{
		if (rowID && tbu > 0){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.tbu,
				row: { rowID: rowID, tbu: tbu*1000 }
			}))
		}
	}, [tbu])
	useEffect(()=>{
		if (rowID && tsu > 0){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.tsu,
				row: { rowID: rowID, tsu: tsu*1000 }
			}))
		}
	}, [tsu])
    useEffect(()=>{
		if (rowID && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.bv,
				row: { rowID: rowID, bv: bv*1000 }
			}))
		}
	}, [bv])
	useEffect(()=>{
		if (rowID && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.sv,
				row: { rowID: rowID, sv: sv*1000 }
			}))
		}
	}, [sv])
    useEffect(()=>{
		if (rowID && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.br,
				row: { rowID: rowID, br: br }
			}))
		}
	}, [br])
	useEffect(()=>{
		if (rowID && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.sr,
				row: { rowID: rowID, sr: sr }
			}))
		}
	}, [sr])
    useEffect(()=>{
		if (rowID && maxB > 0 && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.maxB,
				row: { rowID: rowID, maxB: maxB*1000 }
			}))
		}
	}, [maxB])
	useEffect(()=>{
		if (rowID && minS > 0 && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.minS,
				row: { rowID: rowID, minS: minS*1000 }
			}))
		}
	}, [minS])
    useEffect(()=>{
        if (rowID && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.ap,
				row: { rowID: rowID, ap: ap*1000 }
			}))
		}
    }, [ap])

    useEffect(()=>{
        if (asset.Available_Pts <= 0 && !startLight && !sellLight){
            setAsLight(false)
        }
    }, [asset.Available_Pts])
    useEffect(()=>{
        if (AsLight){
            if (DBidPrice >= ap){
                MainContext.ws.send(JSON.stringify({
                    e: "trader",
                    subE: "forceRow",
                    sessionID: MainContext.session,
                    row: { rowID: rowID, buyLight: false, sellLight: true, minS: DBidPrice}
                }))
            }
        }
    }, [ap, AsLight, DBidPrice])

    // Init UAP && UBP and Cap Price
    useEffect(()=>{
        if (UBidPrice !== 0 && UAskPrice !== 0 && AllowModify.current){
            if (tsu === 0 || capSellPriceLight || capAllPriceLight)
                if (callPut === "Call" || callPut === "Bull")
                    setTSU(UBidPrice)
                else
                    setTSU(UAskPrice)
            if (tbu === 0 || capBuyPriceLight || capAllPriceLight)
                if (callPut === "Call" || callPut === "Bull")
                    setTBU(UAskPrice)
                else
                    setTBU(UBidPrice)
        }
    }, [UBidPrice, UAskPrice, capBuyPriceLight, capSellPriceLight, capAllPriceLight, tbu, tsu])
    useEffect(()=>{
        if (DBidPrice !== 0 && DAskPrice !== 0 && AllowModify.current){
            if (minS === 0 || capSellPriceLight || capAllPriceLight)
                setMinS(DBidPrice)
            if (maxB === 0 || capBuyPriceLight || capAllPriceLight)
                setMaxB(DAskPrice)
            if (ap === 0)
                setAP(DAskPrice)
        }
    }, [DBidPrice, DAskPrice, capBuyPriceLight, capSellPriceLight, capAllPriceLight, maxB, minS])

    useEffect(()=>{
        if (capBuyPriceLight){
            setCapSellPriceLight(false)
            setCapAllPriceLight(false)
        }
    }, [capBuyPriceLight])
    useEffect(()=>{
        if (capSellPriceLight){
            setCapBuyPriceLight(false)
            setCapAllPriceLight(false)
        }
    }, [capSellPriceLight])
    useEffect(()=>{
        if (capAllPriceLight){
            setCapBuyPriceLight(false)
            setCapSellPriceLight(false)
        }
    }, [capAllPriceLight])

    useEffect(()=>{
        if (buyLight){
            setSellLight(false)
            setBSLight(false)
        }
    }, [buyLight])
    useEffect(()=>{
        if (sellLight){
            setBuyLight(false)
            setBSLight(false)
        }
    }, [sellLight])
    useEffect(()=>{
        if (BSLight){
            setBuyLight(false)
            setSellLight(false)
        }
    }, [BSLight])
    useEffect(()=>{
		if (rowID && AllowModify.current && forceLight){
            if (buyLight){
                MainContext.ws.send(JSON.stringify({
                    e: "trader",
                    subE: "modifyRow",
                    sessionID: MainContext.session,
                    rowModifyType: RowModifyType.buyLight,
                    row: { rowID: rowID, buyLight: false }
                }))
                MainContext.ws.send(JSON.stringify({
                    e: "trader",
                    subE: "forceRow",
                    sessionID: MainContext.session,
                    row: { rowID: rowID, buyLight: true, sellLight: false}
                }))
            }else if (sellLight){
                MainContext.ws.send(JSON.stringify({
                    e: "trader",
                    subE: "modifyRow",
                    sessionID: MainContext.session,
                    rowModifyType: RowModifyType.sellLight,
                    row: { rowID: rowID, sellLight: false }
                }))
                MainContext.ws.send(JSON.stringify({
                    e: "trader",
                    subE: "forceRow",
                    sessionID: MainContext.session,
                    row: { rowID: rowID, buyLight: false, sellLight: true}
                }))
            }else {
                setForceLight(false)
            }
		}
	}, [forceLight])

    return useMemo(()=>{
        return (
            <>
            <span className="react-close-handle react-draggable-cancel" onClick={()=>{
                MainContext.ws.send(JSON.stringify({e: "trader", subE: "delRow", sessionID: MainContext.session, rowKey: rowKey}))
                props.onClose()
            }}>x</span>
            <div style={{padding: 10, display: "flex", height: "100%", backgroundColor: bgColor}}>
                <div style={{flex:1, height: "100%"}}>
                    <div style={{display: "flex" , height: "100%", flexDirection: "column", marginRight: 2}}>
                        <Symbol symbol={symbol} ws={MainContext.ws} rowID={rowID} selectedSymbol={selectedSymbol} callPut={callPut} underlying={underlying} fontsize={fontSize} startLight={startLight}
                            setSymbol={setSymbol} setSelectedSymbol={setSelectedSymbol}
                        />
                        <Quote color={color} type={"Symbol"} symbol={selectedSymbol} UBidPrice={DBidPrice} UAskPrice={DAskPrice} UBidVolume={DBidVolume} UAskVolume={DAskVolume}/>
                        <div className="react-draggable-cancel" style={{marginTop: 4, display: "flex"}}>
                            <SwitchableButton value={"ET"} disabled={startLight} color={color.buyColor} state={capBuyPriceLight} style={{fontSize: fontSize, padding: "2px 0", flex:1, marginRight: 2}}
                                onClick={()=>{
                                    setCapBuyPriceLight((old)=>{return !old})
                                }}
                            />
                            <SwitchableButton value={"XT"} disabled={startLight} color={color.sellColor} state={capSellPriceLight} style={{fontSize: fontSize, padding: "2px 0", flex: 1, marginRight: 2, marginLeft: 2}}
                                onClick={()=>{
                                    setCapSellPriceLight((old)=>{return !old})
                                }}
                            />
                            <SwitchableButton value={"AT"} disabled={startLight} color={color.AsColor} state={capAllPriceLight} style={{fontSize: fontSize, padding: "2px 0", flex:1, marginLeft: 2}}
                                onClick={()=>{
                                    setCapAllPriceLight((old)=>{return !old})
                                }}
                            />
                        </div>
                        <Quote color={color} type={"Underlying"} symbol={underlying} UBidPrice={UBidPrice} UAskPrice={UAskPrice} UBidVolume={UBidVolume} UAskVolume={UAskVolume}/>
                    </div>
                </div>
                <div style={{flex:2, marginLeft: 2, display: "flex", flexDirection: "column"}}>
                    <div style={{flex: 5, marginBottom: 2, display: "flex", flexDirection: "column"}}>
                        <UserStatus Pts={asset.Pts} AvaliablePts={asset.Available_Pts} Cost={asset.Cost} DBidPrice={DBidPrice} Turnover={asset.Turnover}/>
                        <ControlPanel color={color} fontSize={fontSize} AvaliablePts={asset.Available_Pts}
                            maxB={maxB} setMaxB={setMaxB} buyVolume={buyVolume} setBuyVolume={setBuyVolume}
                            minS={minS} setMinS={setMinS} sellVolume={sellVolume} setSellVolume={setSellVolume}
                            tbu={tbu} br={br} bv={bv} setTBU={setTBU} setBR={setBR} setBV={setBV}
                            tsu={tsu} sr={sr} sv={sv} setTSU={setTSU} setSR={setSR} setSV={setSV}
                            triggerBuyLight={buyLight} setTriggerBuyLight={setBuyLight}
                            triggerSellLight={sellLight} setTriggerSellLight={setSellLight}
                            triggerBSLight={BSLight} setTriggerBSLight={setBSLight}
                            AsLight={AsLight} setAsLight={setAsLight} ap={ap} setAP={setAP}
                            startLight={startLight} setStartLight={setStartLight}
                            updateLight={updateLight} setUpdateLight={setUpdateLight}
                            forceLight={forceLight} setForceLight={setForceLight}
                            capBuyPriceLight={capBuyPriceLight} capSellPriceLight={capSellPriceLight} capAllPriceLight={capAllPriceLight}
                            volumeSetting={volumeSetting}
                        />
                    </div>
                    <MessageTable messages={messages}/>
                </div>
            </div>
            </>
        )
    }, [
        symbol, selectedSymbol, asset, color, volumeSetting, underlying, callPut, UBidPrice, UBidVolume, UAskPrice, UAskVolume,
        buyVolume, sellVolume,
        capBuyPriceLight, capSellPriceLight, capAllPriceLight,
        buyLight, sellLight, BSLight, AsLight, ap,
        startLight, updateLight, forceLight,
        tbu, br, bv, maxB, tsu, sr, sv, minS, messages
    ])
}

export default OXTrader