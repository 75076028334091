export enum ActionType {
	None 	= 0,
	Add  	= 1,
	Del 	= 2,
	Sub		= 3,
	Modify 	= 10,
	Pending = 11,
	Load    = 20
}

export enum OrderSide {
	Buy		= 1,
	Sell	= 2
}

export enum OrderStatus {
	Partial		= 2,
	Full 		= 3,
	Cancel 		= 4,
    Inactive 	= 5,
	Reject 		= -1,
    None 		= -2
}

export enum RowModifyType {
	buyLight		= 1,
	sellLight		= 2,

	buyVolume		= 3,
	sellVolume		= 4,

	bv				= 5,
	sv				= 6,
	br				= 7,
	sr				= 8,
	tbu				= 9,
	tsu				= 10,
	maxB			= 11,
	minS			= 12,

	AsLight			= 13,
    ap				= 14,
    at				= 15,

	BShortSMAIndex	= 16,
	BLongSMAIndex	= 17,
	SShortSMAIndex	= 18,
	SLongSMAIndex	= 19,
	BCondition		= 20,
	SCondition		= 21,
	BMultiplier		= 22,
	SMultiplier		= 23,
	BMinTO			= 24,
	SMinTO			= 25,

	runningMode		= 26,
	userBuyLight	= 27,
	userSellLight	= 28
}

export enum PendingModifyType {
	Price			= 0,
	Volume			= 1,
	AutoPend		= 2,
	AutoPendSpread 	= 3,
	TriggerCancelK 	= 4,
	AutoCancel		= 5
}

export enum OrderType {
	Market 	= 1,
	Limit 	= 2
}

export enum TIF {
	Day			= 0,
	IOC			= 3,
	FOK			= 4,
	AtCrossing	= 9
}