import { useRef } from "react";
import { useState, useEffect, useMemo, useContext, useCallback } from "react";
import { Form, CloseButton, InputGroup, Table, FormControl, Button } from "react-bootstrap";

import { ParseBigNum } from "../../functions/NumberProcessor";

function QuoteRow({context, buyColor, sellColor, symbol, RemoveAction}){
	const MainContext = useContext(context)

	const [bid, setBid] = useState(0)
	const [ask, setAsk] = useState(0)
	const [bidS, setBidS] = useState(0)
	const [askS, setAskS] = useState(0)

	const [hit, setHit] = useState(null)
	const timerReset = useRef(null)

	useEffect(()=>{
		if (!MainContext.ws || !MainContext.session) return

		MainContext.ws.send(JSON.stringify({e: "getQuote", s: symbol}))

		// MainContext.ws.send(JSON.stringify({
		// 	e: "trader",
		// 	subE: "getQuote",
		// 	sessionID: MainContext.session,
		// 	s: symbol
		// }))
	}, [MainContext.ws, MainContext.session, symbol])

	useEffect(()=>{
		if (MainContext.quoteData[1] === symbol){
			setBid(parseFloat(MainContext.quoteData[2]))
			setAsk(parseFloat(MainContext.quoteData[3]))

			setBidS(parseInt(MainContext.quoteData[4]))
			setAskS(parseInt(MainContext.quoteData[5]))
		}
	}, [symbol, MainContext.quoteData])

	useEffect(()=>{
		if (MainContext.tradeData[1] === symbol){
			if (timerReset.current) clearTimeout(timerReset.current)

			const isTrade = parseInt(MainContext.tradeData[3]) > 0
			const isBid = parseInt(MainContext.tradeData[7]) === 0

			if (!isTrade) return
			setHit(isBid?"Ask":"Bid")

			timerReset.current = setTimeout(()=>{
				setHit(null)
				timerReset.current = null
			}, 500)
		}
	}, [symbol, MainContext.tradeData])

	return useMemo(()=>{
		return (
			<tr>
				<td>{symbol}</td>
				<td style={{textAlign: "center"}}>{ParseBigNum(bidS)}</td>
				<td style={{textAlign: "center", color: hit==="Bid"?"white":buyColor, backgroundColor: hit==="Bid"?buyColor:"white"}}>{bid}</td>
				<td style={{textAlign: "center", color: hit==="Ask"?"white":sellColor, backgroundColor: hit==="Ask"?sellColor:"white"}}>{ask}</td>
				<td style={{textAlign: "center"}}>{ParseBigNum(askS)}</td>
				<td>
					<div className="d-grid">
						<Button style={{fontSize: 12, padding: "0px 8px"}} onClick={()=>{
							RemoveAction(symbol)
						}} size="sm">Remove</Button>
					</div>
				</td>
			</tr>
		)
	}, [RemoveAction, buyColor, sellColor, symbol, bid, ask, bidS, askS, hit])
}

function QuoteTable({onClose, context}){
	const MainContext = useContext(context)

	const {buyColor, sellColor} = useMemo(()=>{
		const {buyColor, sellColor} = MainContext.clientSetting.setting
		return {buyColor, sellColor}
	}, [ MainContext.clientSetting.setting.buyColor, MainContext.clientSetting.setting.sellColor ])

	const [subscribeList, setSubscribeList] = useState(JSON.parse(localStorage.subscribeList??"[]"))
	const RemoveAction = useCallback((symbol)=>{
		setSubscribeList((now)=>{
			const list = now.filter((x)=>{ return x !== symbol})
			localStorage.subscribeList = JSON.stringify(list)
			return list
		})
	}, [])

	return useMemo(()=>{
		return (
			<div style={{display: "flex", flexDirection: "column", height: "100%"}}>
				<div style={{width: "100%", position: "sticky", display: "flex", backgroundColor: "#0d6efd"}}>
					<span className="text-white" style={{flex: 1, fontSize: 18, padding: 2, paddingLeft: 4}}>Quote Table</span>
					<div style={{flex:0, backgroundColor: "#dc3545"}}>
						<CloseButton className="react-draggable-cancel" variant="white" style={{fontSize: 18, padding: 6}} onClick={onClose}/>
					</div>
				</div>
				<div className="react-draggable-cancel" style={{flex: 0, paddingLeft: 10, paddingRight: 10, margin: "8px 0px"}}>
					<Form onSubmit={(e)=>{
						e.preventDefault()
						e.stopPropagation()
						const formData = new FormData(e.target)
						const symbol = formData.get("symbol").trim()
						if (isNaN(parseInt(symbol))) return 
						if (parseInt(symbol) >= 100000) return
						setSubscribeList((now)=>{
							if (now.findIndex((x)=>x===symbol) !== -1) return now
							const list = [...now, symbol]
							localStorage.subscribeList = JSON.stringify(list)
							return list
						})
					}}>
						<InputGroup style={{maxWidth: 200}}>
							<InputGroup.Text style={{fontSize: 14, padding: "4px 8px"}}>Symbol</InputGroup.Text>
							<FormControl style={{fontSize: 14, padding: "4px 8px"}} type="text" name="symbol"/>
							<Button type="submit" style={{fontSize: 14, padding: "4px 8px"}}>Add</Button>
						</InputGroup>
					</Form>
				</div>
				<div className="react-draggable-cancel" style={{flex: 1, paddingLeft: 10, paddingRight: 10, overflow: "hidden", overflowY: "auto"}}>
					<Table bordered striped hover size="sm">
						<thead>
							<tr style={{fontSize: 12, textAlign: "center"}}>
								<td>Symbol</td>
								<td style={{minWidth: 65}}>BidS</td>
								<td style={{minWidth: 65}}>Bid</td>
								<td style={{minWidth: 65}}>Ask</td>
								<td style={{minWidth: 65}}>AskS</td>
								<td style={{width: 80}}></td>
							</tr>
						</thead>
						<tbody>
							{
								subscribeList.map((x)=>{
									return <QuoteRow key={x} symbol={x} context={context} RemoveAction={RemoveAction} buyColor={buyColor} sellColor={sellColor}/>
								})
							}
						</tbody>
					</Table>
				</div>
			</div>
		)
	}, [context, onClose, subscribeList])
}

export default QuoteTable