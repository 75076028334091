import { useState, useEffect } from 'react'

import { Button, Container } from 'react-bootstrap'

function RightClickMenu(props){
    const [select, setSelect] = useState("")

    useEffect(() => {
        if (select !== ""){
            if (select === "setInPrice"){
                console.log("set in price: " + props.selectedPrice)
                props.setInPrice(props.selectedPrice)
            }else if (select === "cancelInPrice"){
                props.setInPrice(0)
            }
            props.setSelf(<></>)
        }
    }, [select, props])

    return (
        <Container className="d-grid border rounded react-draggable-cancel" style={{position: "absolute", left: props.cursorPageXY[0], top: props.cursorPageXY[1], padding: 8, width: 250, backgroundColor: "white"}} show="true">
            {
                props.inPrice===0?
                <Button size="sm" onClick={(v)=>{
                    console.log(v)
                    setSelect("setInPrice")
                }}>
                        Set in Price: {props.selectedPrice}
                </Button>:
                <Button size="sm" onClick={
                    (v)=>{setSelect("cancelInPrice")
                }}>
                    Cancel In Price
                </Button>
            }
        </Container>
    )
}

export default RightClickMenu