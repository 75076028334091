import {useState, useEffect, useMemo, useRef} from 'react'

import { GetSpread } from '../../../functions/StockHandle'
import {parseNumber} from './functions'

import { ProgressBar } from 'react-bootstrap'

function Quote(props){
	const lastBidPrice = useRef(0)
	const lastAskPrice = useRef(0)
	const lastBidVolume = useRef(0)
	const lastAskVolume = useRef(0)

	const color = useMemo(()=>{
		return props.color
	}, [props.color])

	const [BPColorLight, setBPColorLight] = useState(false)
	const [BVColorLight, setBVColorLight] = useState(false)
	const [APColorLight, setAPColorLight] = useState(false)
	const [AVColorLight, setAVColorLight] = useState(false)

	useEffect(()=>{
		if (color){
			if (lastBidPrice.current !== props.UBidPrice){
				if (lastBidPrice.current < props.UBidPrice)
					setBPColorLight(color.buyColor)
				else if (lastBidPrice.current > props.UBidPrice)
					setBPColorLight(color.sellColor)
				setTimeout(()=>{
					setBPColorLight(false)
				}, 1000)
			}

			if (lastAskPrice.current !== props.UAskPrice){
				if (lastAskPrice.current < props.UAskPrice)
					setAPColorLight(color.buyColor)
				else if (lastAskPrice.current > props.UAskPrice)
					setAPColorLight(color.sellColor)
				setTimeout(()=>{
					setAPColorLight(false)
				}, 1000)
			}

			if (lastBidVolume.current !== props.UBidVolume){
				if (lastBidVolume.current < props.UBidVolume)
					setBVColorLight(color.buyColor)
				else if (lastBidVolume.current > props.UBidVolume)
					setBVColorLight(color.sellColor)
				setTimeout(()=>{
					setBVColorLight(false)
				}, 1000)
			}

			if (lastAskVolume.current !== props.UAskVolume){
				if (lastAskVolume.current < props.UAskVolume)
					setAVColorLight(color.buyColor)
				else if (lastAskVolume.current > props.UAskVolume)
					setAVColorLight(color.sellColor)
				setTimeout(()=>{
					setAVColorLight(false)
				}, 1000)
			}
		}

		lastBidPrice.current = props.UBidPrice
		lastAskPrice.current = props.UAskPrice
		lastBidVolume.current = props.UBidVolume
		lastAskVolume.current = props.UAskVolume

	}, [props.UBidPrice, props.UAskPrice, props.UBidVolume, props.UAskVolume])

	return useMemo(()=>{
		let styleBPrice = {backgroundColor: BPColorLight?BPColorLight:"white", color: !BPColorLight?"black":"white", width: "50%"}
		let styleAPrice = {backgroundColor: APColorLight?APColorLight:"white", color: !APColorLight?"black":"white", width: "50%"}
		let styleBVolume = {backgroundColor: BVColorLight?BVColorLight:"white", color: !BVColorLight?"black":"white"}
		let styleAVolume = {backgroundColor: AVColorLight?AVColorLight:"white", color: !AVColorLight?"black":"white"}

		let flex = 2
		let progressbar = <></>
		if (props.type === "Underlying"){
			flex = 3
			let ratio = [50, 50]
			if (props.UBidVolume !== props.UAskVolume){
				let total = props.UBidVolume + props.UAskVolume
				ratio[0] = Math.round(props.UBidVolume / total * 100)
				ratio[1] = 100 - ratio[0]
			}
			progressbar = (
				<>
					<div style={{display: "flex"}}>
						<span style={{flex: 1, fontSize: 12, color: color.buyColor}}>{ratio[0]}%</span>
						<span style={{flex: 1, fontSize: 12, color: color.sellColor, textAlign: "right"}}>{ratio[1]}%</span>
					</div>
					<ProgressBar>
						<ProgressBar style={{backgroundColor: color.buyColor}} key={0} now={ratio[0]}/>
						<ProgressBar style={{backgroundColor: color.sellColor}} key={1} now={ratio[1]}/>
					</ProgressBar>
				</>
			)
		}

		const floating = ((""+GetSpread(props.UBidPrice)).length -1) - (""+GetSpread(props.UBidPrice)).indexOf(".")

		return (
			<div style={{flex, marginTop: 4, display: "flex", flexDirection: "column", border: "1px solid #CCCCCC", borderRadius: "5px", padding: 4, backgroundColor: "white"}}>
				<div style={{fontSize: 12}}>
					<strong>{props.type}:</strong> {props.symbol}
				</div>
				<table className="react-draggable-cancel" style={{flex: 1, width: "100%", marginBottom: 2}}>
					<tbody>
						<tr style={{fontSize: 12, textAlign: "right"}}>
							<td style={styleBPrice}>{props.UBidPrice.toFixed(floating)}</td>
							<td style={styleAPrice}>{props.UAskPrice.toFixed(floating)}</td>
						</tr>
						<tr style={{fontSize: 12, textAlign: "right"}}>
							<td style={styleBVolume}>{parseNumber(props.UBidVolume)}</td>
							<td style={styleAVolume}>{parseNumber(props.UAskVolume)}</td>
						</tr>
					</tbody>
				</table>
				{progressbar}
			</div>
		)
	}, [props.symbol, props.UBidPrice, props.UAskPrice, props.UBidVolume, props.UAskVolume, BPColorLight, APColorLight, BVColorLight, AVColorLight])
}

export default Quote