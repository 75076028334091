function ParseBigNum(num: number | string) {
	if (typeof num === "string")
		num = parseInt(num)

    if (num > 1000000)
		return Math.round(num / 10000) / 100 + "M"
	else if (num > 1000)
		return Math.round(num / 10) / 100 + "K"
    
	return num.toString()
}

export { ParseBigNum }