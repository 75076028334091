import { useEffect } from "react";
import { useCallback } from "react";
import { useState, useMemo, useRef, useContext } from "react";

import { Button, CloseButton, InputGroup, Table } from "react-bootstrap";
import Form from "react-bootstrap/Form"
import SwitchableButton from "./global_component/SwitchableButton";

import { ReturnNextUpPrice, ReturnNextDownPrice, GetSpread } from '../../functions/StockHandle'
import { OrderSide, OrderType, TIF } from "../../functions/Enum";
import Decimal from "decimal.js";
import InputValidation from "../../functions/InputValidation";

const ControlsWidth = 224

function ControlPanel({context, fontSize, color, DisableAction, AtCrossing}){
    const MainContext = useContext(context)

    const {buyColor, sellColor} = useMemo(()=>{return color}, [color])

    const [SymbolInput, setSymbolInput] = useState(null)
    const [selectedSymbol, setSelectedSymbol] = useState("")
    const selectedSymbolRef = useRef("")
    const [isBuy, setIsBuy] = useState(true)
    const [orderType, setOrderType] = useState(OrderType.Limit)
    const [price, setPrice] = useState(0)
    const [volume, setVolume] = useState(0)
    const [tif, setTIF] = useState(TIF.AtCrossing)

    useEffect(()=>{
        if (AtCrossing && tif !== TIF.AtCrossing)
            setTIF(TIF.AtCrossing)
        else if (!AtCrossing && tif === TIF.AtCrossing)
            setTIF(TIF.Day)

        if (!AtCrossing && orderType === OrderType.Market)
            setOrderType(OrderType.Limit)
    }, [AtCrossing, tif, orderType])

    const CleanUP = useCallback(()=>{
        const setZero = [setPrice, setVolume]
        setZero.forEach((x)=>{x(0)})
    }, [])

    const SymbolOnKeyPress = useCallback((e)=>{
        if (SymbolInput){
            const value = e.target.value.trim()
            const valueNum = parseInt(value)
            if (!InputValidation.integer(value) || valueNum >= 100000 || valueNum < 1){
                e.target.value = selectedSymbolRef.current
                return
            }

            e.target.value = selectedSymbolRef.current = value
            setSelectedSymbol(selectedSymbolRef.current)

            CleanUP()
        }
    }, [SymbolInput, CleanUP])
    const SymbolOnFocus = useCallback((e)=>{
        if (SymbolInput){
            selectedSymbolRef.current = ""
            e.target.value = selectedSymbolRef.current
            setSelectedSymbol(selectedSymbolRef.current)

            CleanUP()
        }
    }, [SymbolInput, CleanUP])

    const [PriceUpClick, PriceDownClick, LotUpClick, LotDownClick] = useMemo(()=>{
        return [
            ()=>{ setPrice((old)=>{ return ReturnNextUpPrice(old) }) },
            ()=>{ setPrice((old)=>{ return ReturnNextDownPrice(old) }) },
            ()=>{ setVolume(old=>old+1) },
            ()=>{ setVolume(old=>(old===1?1:old-1)) }
        ]
    },[])

    const [errMsg, setErrMsg] = useState("")

    //Guarding For Pending
	const [MaxAllowBuy, MinAllowSell] = useMemo(()=>{
		let maxAllowBuy = null
		let minAllowSell = null

        let maxPendBuy = null
        let minPendSell = null
		MainContext.pending.forEach((x)=>{
			if (x.symbol === selectedSymbol)
				if (x.side === OrderSide.Buy)
                    maxPendBuy = (maxPendBuy===null?x.price:Math.max(maxPendBuy, x.price))
				else if (x.side === OrderSide.Sell)
                    minPendSell = (minPendSell===null?x.price:Math.min(minPendSell, x.price))
		})

		MainContext.pendingRows.forEach((x)=>{
			if (x.symbol === selectedSymbol)
                minPendSell = (minPendSell===null?x.price:Math.min(minPendSell, x.price))
		})

        MainContext.marketTriggers.forEach((x)=>{
			if (x.symbol === selectedSymbol)
				if (x.side === OrderSide.Buy)
                    maxPendBuy = (maxPendBuy===null?x.price:Math.max(maxPendBuy, x.price))
				else if (x.side === OrderSide.Sell)
                    minPendSell = (minPendSell===null?x.price:Math.min(minPendSell, x.price))
		})

        maxAllowBuy = minPendSell===null?99999.0:ReturnNextDownPrice(new Decimal(minPendSell).div(1000).toNumber())
        minAllowSell = maxPendBuy===null?0.01:ReturnNextUpPrice(new Decimal(maxPendBuy).div(1000).toNumber())

		return [maxAllowBuy, minAllowSell]
	}, [selectedSymbol, MainContext.pending, MainContext.pendingRows, MainContext.marketTriggers])
    const SendMarketTriggerOnClick = useCallback(()=>{
        if (DisableAction) {
            setErrMsg("Not allow to use now.")
            return;
        }
        if (selectedSymbolRef.current === ""){
            setErrMsg("Symbol can't be empty")
            return;
        }
        if (typeof(price) === "string"){
            setErrMsg("Price must be a number.")
            return
        }
        if (orderType !== OrderType.Market && price <= 0){
            setErrMsg("Price must be > 0")
            return
        }
        if (typeof(volume) === "string"){
            setErrMsg("Volume must be a number.")
            return
        }
        if (volume <= 0){
            setErrMsg("Volume must be > 0")
            return
        }
        if (orderType === OrderType.Limit && ((isBuy && MaxAllowBuy < price) || (!isBuy && MinAllowSell > price))){
            setErrMsg("Guarding to pend order.")
            return
        }
        if (orderType === OrderType.Market && ((isBuy && MaxAllowBuy !== 99999.0) || (!isBuy && MinAllowSell !== 0.01))){
            setErrMsg("Guarding to pend market order.")
            return
        }

        MainContext.ws.send(JSON.stringify({
            e: "trader",
            subE: "marketTrigger",
            sessionID: MainContext.session,
            marketTrigger: {
                symbol: selectedSymbolRef.current,
                side: isBuy?OrderSide.Buy:OrderSide.Sell,
                OrderType: orderType,
                price: price * 1000,
                volume: volume,
                tif: tif
            }
        }))
    }, [MainContext.ws, MainContext.session, DisableAction, isBuy, MaxAllowBuy, MinAllowSell, orderType, price, volume, tif])

    return useMemo(()=>{
        return (
            <div style={{flex: 1, display: "flex", flexDirection: "column", justifyContent: 'space-around', borderRight: "1px solid black"}}>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 8}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <InputGroup className="react-draggable-cancel" style={{width: ControlsWidth}}>
                            <InputGroup.Text style={{fontSize: fontSize, padding:"4px 8px"}}>Symbol</InputGroup.Text>
                            <Form.Control type='text' ref={r=>setSymbolInput(r)} style={{fontSize: fontSize , padding:"4px 8px"}}
                                onFocus={SymbolOnFocus}
                                onChange={SymbolOnKeyPress}
                            />
                        </InputGroup>
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 8}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div style={{width: ControlsWidth}}>
                            <label>Price</label>
                            <InputGroup className="react-draggable-cancel">
                                <Button style={{fontSize: fontSize}} onClick={PriceDownClick}>-</Button>
                                <Form.Select style={{fontSize: fontSize, padding: "4px 16px 4px 8px"}} value={orderType} onChange={(e)=>{
                                    const value = parseInt(e.target.value)
                                    console.log(value)
                                    switch (value){
                                        case OrderType.Market:
                                            setOrderType(value)
                                            setPrice(0)
                                            break;
                                        case OrderType.Limit:
                                            setOrderType(value)
                                            break;
                                        default:
                                            console.log("unsupported order type " + value)
                                    }
                                }}>
                                    {
                                        Object.keys(OrderType).map((x)=>{
                                            if (!AtCrossing && x === "Market") return null
                                            return <option key={x} value={OrderType[x]}>{x}</option>
                                        }) 
                                    }
                                </Form.Select>
                                <Form.Control style={{flex: 1, fontSize: fontSize}} type="text" value={price} disabled={orderType===OrderType.Market}
                                    onBlur={(e)=>{
                                        const p = parseFloat(e.target.value)
                                        if (!isNaN(p) && new Decimal(p).mod(new Decimal( GetSpread(p))).toNumber() === 0){
                                            setPrice(p)
                                        }
                                    }}
                                    onChange={(e)=>{
                                        const p = parseFloat(e.target.value)
                                        if (!isNaN(p) && new Decimal(p).mod(new Decimal( GetSpread(p))).toNumber() === 0){
                                            if (p.toString() === e.target.value)
                                                setPrice(p)
                                            else
                                                setPrice(e.target.value)
                                            setErrMsg("")
                                        } else {
                                            setPrice(e.target.value)
                                            setErrMsg("Invalid Price.")
                                        }
                                    }}
                                />
                                <Button style={{fontSize: fontSize}} onClick={PriceUpClick}>+</Button>
                            </InputGroup>
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div style={{width: ControlsWidth}}>
                            <label>Volume</label>
                            <InputGroup className="react-draggable-cancel">
                                <Button style={{fontSize: fontSize}} onClick={LotDownClick}>-</Button>
                                <Form.Control style={{flex: 1, fontSize: fontSize}} type="text" value={volume} onChange={(e)=>{
                                    const v = parseInt(e.target.value)
                                    if (!isNaN(v)){
                                        setVolume(v)
                                        setErrMsg("")
                                    } else {
                                        setVolume(e.target.value)
                                        setErrMsg("Invalid Volume.")
                                    }
                                }}/>
                                <Button style={{fontSize: fontSize}} onClick={LotUpClick}>+</Button>
                            </InputGroup>
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div style={{width: ControlsWidth}}>
                            <label>TIF</label>
                            <InputGroup className="react-draggable-cancel">
                                <Form.Select value={tif} onChange={(e)=>{ setTIF(parseInt(e.target.value)) }}>
                                    {
                                        Object.keys(TIF).map((key)=>{
                                            if (AtCrossing && TIF[key] !== TIF.AtCrossing) return null
                                            if (!AtCrossing && TIF[key] === TIF.AtCrossing) return null
                                            return <option key={key} value={TIF[key]}>{key}</option>
                                        })
                                    }
                                </Form.Select>
                            </InputGroup>
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div style={{width: ControlsWidth, color: "red"}}>{errMsg}</div>
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 8}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div className="react-draggable-cancel" style={{display: 'flex', width: ControlsWidth, gap: 8}}>
                            <SwitchableButton style={{flex:1}} value='BUY' color={buyColor} state={isBuy} onClick={()=>{setIsBuy(true)}}/>
                            <SwitchableButton style={{flex:1}} value='SELL' color={sellColor} state={!isBuy} onClick={()=>{setIsBuy(false)}}/>
                            <Button style={{flex:1}} disabled={DisableAction} onClick={SendMarketTriggerOnClick}>Send</Button>
                        </div>
                    </div>
                </div>
            </div>
        )
        // eslint-disable-next-line
    }, [
        fontSize, SymbolOnKeyPress, SymbolOnFocus, PriceUpClick, PriceDownClick, LotUpClick, LotDownClick, SendMarketTriggerOnClick,
        isBuy, orderType, price, volume, tif, errMsg,
        DisableAction, AtCrossing
    ])
}

function StatusPanel({context, fontSize, color, DisableAction, DisableCancel}){
    const MainContext = useContext(context)

    const {buyColor, sellColor} = useMemo(()=>{return color}, [color])

    const marketTriggers = useMemo(()=>{ return MainContext.marketTriggers }, [MainContext.marketTriggers])

    const CancelAction = useCallback((rowID)=>{
        if (DisableAction) return

        if (MainContext.ws){
            MainContext.ws.send(JSON.stringify({
                e: "trader",
                subE: "cancelMarketTrigger",
                sessionID: MainContext.session,
                marketTrigger: { rowID: rowID }
            }))
        }
    }, [MainContext.ws, MainContext.session, DisableAction])

    return useMemo(()=>{
        return (
            <div className="noScrollbar" style={{flex: 2, paddingLeft: 10, paddingTop: 10, height: "100%", overflowY: "scroll"}}>
                <Table size="sm" bordered striped>
                    <thead>
                        <tr style={{fontSize: fontSize, textAlign: 'center'}}>
                            <td>ClientOID</td>
                            <td>RowID</td>
                            <td>Symbol</td>
                            <td>Side</td>
                            <td>Price</td>
                            <td>Volume</td>
                            <td>Cancel({marketTriggers.length})</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            marketTriggers.map((x)=>{
                                const side = x.side===OrderSide.Buy?"B":"S"
                                const price = x.price / 1000
                                const spreadlength = (""+GetSpread(price)).split('.').slice(-1)[0].length

                                return (
                                    <tr key={x.ClientOrderID}>
                                        <td>{x.ClientOrderID}</td>
                                        <td>{x.rowID}</td>
                                        <td style={{textAlign: "center"}}>{x.symbol}</td>
                                        <td style={{textAlign: "center", color: side==="B"?buyColor:sellColor}}>{side}</td>
                                        <td style={{textAlign: "right"}}>{x.OrderType===OrderType.Market?"Market":price.toFixed(spreadlength)}</td>
                                        <td style={{textAlign: "right"}}>{x.volume.toLocaleString()}</td>
                                        <td className="d-grid">
                                            <Button variant="danger" style={{fontSize: fontSize, padding: "0px 8px"}} disabled={DisableAction|DisableCancel}
                                                onClick={()=>{
                                                    CancelAction(x.rowID)
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
        )
        // eslint-disable-next-line
    }, [fontSize, color, marketTriggers, CancelAction, DisableAction])
}

function TimeTriggerPanel({onClose, context}){
    const MainContext = useContext(context)

    const color = useMemo(()=>{
		const {buyColor, sellColor, AsColor, callColor, putColor} = MainContext.clientSetting.setting
		return {buyColor, sellColor, AsColor, callColor, putColor}
        // eslint-disable-next-line
	}, [
        MainContext.clientSetting.setting.buyColor,
        MainContext.clientSetting.setting.sellColor,
        MainContext.clientSetting.setting.AsColor,
        MainContext.clientSetting.setting.callColor,
        MainContext.clientSetting.setting.putColor,
    ])

    const fontSize = useMemo(()=>{
		return MainContext.clientSetting.setting.fontSize
	}, [MainContext.clientSetting.setting.fontSize])

    const currTime = useMemo(()=>{ return MainContext.currTime }, [MainContext.currTime])
    const [DisableAction, DisableCancel, AtCrossing] = useMemo(()=>{
        const [hour, minutes] = [currTime.getHours(), currTime.getMinutes()]
        return [
            hour >= 16 || ( minutes === 59 && (hour === 8 || hour === 12 || hour === 15) ),
            hour < 9,
            hour < 9 || hour >= 13
        ]
    }, [currTime])

    return useMemo(()=>{
        return (
            <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
				<div style={{width: "100%", position: "sticky", display: "flex", backgroundColor: "#0d6efd"}}>
					<span className="text-white" style={{flex: 1, fontSize: 18, padding: 2, paddingLeft: 4}}>Time Trigger Panel</span>
					<div style={{flex:0, backgroundColor: "#dc3545"}}>
						<CloseButton className="react-draggable-cancel" variant="white" style={{fontSize: 18, padding: 6}} onClick={onClose}/>
					</div>
				</div>
                <div className="react-draggable-cancel" style={{paddingLeft: 10, paddingRight: 10, height: "100%", overflow: "hidden", overflowY: "auto", display: "flex", flexDirection: "row"}}>
                    <ControlPanel context={context} fontSize={fontSize} color={color} DisableAction={DisableAction} AtCrossing={AtCrossing}/>
                    <StatusPanel context={context} fontSize={fontSize} color={color} DisableAction={DisableAction} DisableCancel={DisableCancel}/>
                </div>
            </div>
        )
    }, [onClose, context, fontSize, color, DisableAction, DisableCancel, AtCrossing])
}

export default TimeTriggerPanel