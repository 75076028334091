export interface ClientSetting {
    [key:string] : any,

    shortcutKeyChange: boolean

    fontSize: number
    
    buyColor: string
    sellColor: string
    AsColor: string
    callColor: string
    putColor: string

    maxVolume: number,
    defaultVolume: number,

    capAllKey: string
    capBidKey: string
    capAskKey: string
    addKey: string
    minusKey: string
    triggerBuyKey: string
    triggerSellKey: string
    triggerAsKey: string
}

class ClientSettingHandle{
    setting: ClientSetting
    defaultSetting: ClientSetting

    setClientSetting: React.Dispatch<React.SetStateAction<ClientSettingHandle>> | null = null

    constructor(setClientSetting: React.Dispatch<React.SetStateAction<ClientSettingHandle>> | null = null){
        this.setClientSetting = setClientSetting

        this.defaultSetting = ClientSettingHandle.loadDefaultSetting()

        const currSetting = localStorage.getItem("clientSetting")

        if (typeof currSetting === "string"){
            let setting = JSON.parse(currSetting)

            Object.keys(this.defaultSetting).forEach((x)=>{
                if (typeof setting[x] === "undefined")
                    setting[x] = this.defaultSetting[x]
            })

            this.setting = setting
            return
        }
        
        this.setting = ClientSettingHandle.loadDefaultSetting()
    }

    saveSetting() {
        localStorage.clientSetting = JSON.stringify(this.setting)
    }

    reloadSetting() {
        if (this.setClientSetting)
            this.setClientSetting(new ClientSettingHandle(this.setClientSetting))
    }

    resetSetting (key: string) {
        this.setting[key] = this.defaultSetting[key]
    }

    static loadDefaultSetting() : ClientSetting {
        return {
            shortcutKeyChange: true,

            fontSize: 12,
            
            buyColor: "#C39297",
            sellColor: "#75A38E",
            AsColor: "#88A4CD",
            callColor: "#F9FFDB",
            putColor: "#F5E5FF",

            maxVolume: 1000,
            defaultVolume: 0,

            capAllKey: "KeyP",
            capBidKey: "KeyI",
            capAskKey: "KeyO",
            addKey: "Equal",
            minusKey: "Minus",
            triggerBuyKey: "KeyN",
            triggerSellKey: "KeyM",
            triggerAsKey: "KeyB"
        }
    }
}

export default ClientSettingHandle