import { useContext, useMemo, useState } from 'react'

import { Asset } from '../../../api/type'
import { ParseBigNum } from '../../../functions/NumberProcessor'
import { MainContextData } from '../../MainPage'

import { Table } from 'react-bootstrap'

interface SummaryRowProps {
	x: Asset
	context: React.Context<MainContextData>
	WarrCBBCs: { [key:string]: string }
}
function SummaryRow({x, context, WarrCBBCs}: SummaryRowProps){
	const MainContext = useContext(context)

	const quote = useMemo(()=>{
		if (typeof MainContext.bssQuote[x.symbol] === "undefined") return null
		return MainContext.bssQuote[x.symbol]
	}, [MainContext.bssQuote, x])

	return useMemo(()=>{
		const FPnL = quote === null ? "N/A" : (Math.round((quote.bidP / 1000 - x.Cost) * x.Pts * 100) / 100)

		const PnLColor =  x.Pnl>0?"#a3cfbb":x.Pnl<0?"#f1aeb5":"white"
		const FPnLColor = typeof(FPnL)==="string"||FPnL===0?"white":FPnL>0?"#a3cfbb":"#f1aeb5"

		return (
			<tr>
				<td>{x.symbol}</td>
				<td>{x.symbol.length===5?WarrCBBCs[x.symbol]:x.symbol}</td>
				<td className="align-right">{ParseBigNum(x.Pts)}</td>
				<td className="align-right">{Math.round(x.Cost*1000)/1000}</td>
				<td className="align-right" style={{backgroundColor: FPnLColor}}>{ParseBigNum(FPnL)}</td>
				<td className="align-right" style={{backgroundColor: PnLColor}}>{ParseBigNum(x.Pnl)}</td>
				<td className="align-right">{ParseBigNum(x.Turnover)}</td>
			</tr>
		)
	}, [x, WarrCBBCs, quote])
}


interface SummaryTableProps {
	context: React.Context<MainContextData>
}
function SummaryTable({context}: SummaryTableProps){
	const MainContext = useContext(context)

	const [sortItemName, setSortItemName] = useState("Pts")

	const color = useMemo(()=>{
		const {buyColor, sellColor} = MainContext.clientSetting.setting
		return {buyColor, sellColor}
	}, [MainContext.clientSetting.setting.buyColor, MainContext.clientSetting.setting.sellColor])

	const WarrCBBCs = useMemo(()=>{
		let all : { [key:string]: string } = {}
		MainContext.warrants.forEach((x) => {
			all[x[1]] = x[3]
		});
		MainContext.cbbcs.forEach((x) => {
			all[x[1]] = x[3]
		});
		return all
	}, [MainContext.warrants, MainContext.cbbcs])

	return useMemo(()=>{
		return (
			<Table className="react-draggable-cancel" style={{fontSize: 12, position: "relative", top: 8}} bordered striped size="sm">
				<thead>
					<tr>
						<th onClick={()=>{setSortItemName("symbol")}}>D {sortItemName==="symbol" && "▼"}</th>
						<th>U</th>
						<th onClick={()=>{setSortItemName("Pts")}}>Pts {sortItemName==="Pts" && "▼"}</th>
						<th onClick={()=>{setSortItemName("Cost")}}>Cost {sortItemName==="Cost" && "▼"}</th>
						<th>F.PnL</th>
						<th onClick={()=>{setSortItemName("Pnl")}}>PnL {sortItemName==="Pnl" && "▼"}</th>
						<th onClick={()=>{setSortItemName("Turnover")}}>TO {sortItemName==="Turnover" && "▼"}</th>
					</tr>    
				</thead>
				<tbody>
					{
						[...MainContext.assets].sort((a,b)=>{
							if (parseFloat(a[sortItemName]) > parseFloat(b[sortItemName])) return -1
							if (parseFloat(a[sortItemName]) < parseFloat(b[sortItemName])) return 1
							return 0
						}).map((x)=>{
							return (
								<SummaryRow key={x.symbol} x={x} context={context} WarrCBBCs={WarrCBBCs}/>
							)
						})
					}
				</tbody>
			</Table>
		)
	}, [context, MainContext.assets, WarrCBBCs, sortItemName])
}

export default SummaryTable