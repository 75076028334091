import { useState, useCallback, useEffect, useMemo } from "react"

import { LayoutItem } from "../../MainPage"

import { Button } from 'react-bootstrap'
import Form from "react-bootstrap/Form"
import Modal from "react-bootstrap/Modal"

interface TraderW2SetRowsProps {
    show: boolean
    setShow: React.Dispatch<React.SetStateAction<boolean>>
    setLayout: React.Dispatch<React.SetStateAction<LayoutItem[]>>
}
function TraderW2SetRows({show, setShow, setLayout}: TraderW2SetRowsProps){
    const [errMsg, setErrMsg] = useState("")

    const formHandle = useCallback<React.FormEventHandler<HTMLFormElement>>((e)=>{
        e.preventDefault();
        e.stopPropagation();

        const formData = new FormData(e.currentTarget)
        const value = parseInt(formData.get("rows")!.toString())

        if (isNaN(value) || value < 0) {
            setErrMsg("It is Not a positive integer.")
            return
        }
        if (value > 99) {
            setErrMsg("Rows number can't be more than 99.")
            return
        }
        if (value < 1) {
            setErrMsg("Rows number can't be less than 1.")
            return;
        }

        setLayout((last)=>{
            let newArr = last.slice()
            let maxKey = 0
            let maxY = 0
            last.forEach((x)=>{
                if (maxY < x["data-grid"].y + x["data-grid"].h)
                    maxY = x["data-grid"].y + x["data-grid"].h
                if (maxKey < x["key"])
                    maxKey = x["key"]
            })

            newArr.push({"key": maxKey+1, "data-grid": {x: 0, y: maxY, w:8, h: 96, minW: 8, minH: 20}, "component": "TraderW2", "rows": value})
            return newArr
        })
        setShow(false)

        e.currentTarget.reset()
    }, [setLayout, setShow])

    useEffect(()=>{
        setErrMsg("")
    }, [show])

    return useMemo(()=>{
        return (
            <Modal show={show} onHide={()=>{setShow(false)}}>
                <Modal.Header>
                    <Modal.Title>Setting How Many Rows</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={formHandle} noValidate>
                        <Form.Group className="mb-2">
                            <Form.Label>How many rows do you want?</Form.Label>
                            <Form.Control type="text" name="rows" isInvalid={errMsg!==""} defaultValue={1}/>
                            <Form.Control.Feedback type="invalid">{errMsg}</Form.Control.Feedback>
                        </Form.Group>
                        <Button type="submit">Create Rows</Button>
                    </Form>
                </Modal.Body>
            </Modal>
        )
    }, [show, setShow, formHandle, errMsg])
}

export default TraderW2SetRows