import { useMemo } from 'react'

import { Button } from 'react-bootstrap'

function SwitchableButton({value="Button", disabled=false, state=false, setState=()=>{}, onClick, color="#FF0000", style={}, className="", size=""}){
    const localStyle = useMemo(()=>{
        let s = style

        if (state){
            s.borderColor = color
            s.backgroundColor = color
            s.color = "#FFFFFF"
        }else{
            s.borderColor = color
            s.backgroundColor = "#FFFFFF"
            s.color = color
        }

        return s
    }, [style, state, color])

    const localOnClick = useMemo(()=>{
        if (!onClick)
            return ()=>{
                setState((old)=>{return !old})
            }
        else
            return onClick
    }, [onClick, setState])

    return useMemo(()=>{
        return (
            <Button className={className} disabled={disabled} size={size} style={localStyle} onClick={localOnClick}>
                {value}
            </Button>
        )
    }, [value, disabled, className, size, localOnClick, localStyle])
}

export default SwitchableButton