import { RequestContainer } from "../api/request"

class Connection {
    private ws              ?:  WebSocket
    private path            :   string

    private setConnState    ?:  (connState: boolean|number) => void     = (connState: boolean|number) => {}
    public  on_message      :   (msg: string) => void                   = () => {}

    private heartbeator     :   NodeJS.Timer | null = null
    private reconnector     :   NodeJS.Timer | null = null
    private reconnCounter   :   number              = 0

    public constructor(setConnectState: (connState: boolean|number) => void) {
        const host  = window.location.hostname==="localhost"?"alpha-trader.local":window.location.hostname
        const test  = localStorage.getItem("TWS")
        const proto = window.location.protocol==="https:"? "wss" : "ws"

        // production
        if (test === null || test === "false")
            this.path = proto + "://" + host + "/ws"
        // debug
        else
            this.path = proto + "://" + host + "/tws"

        this.setConnState = setConnectState
    }

    public connect() {
        if (typeof this.ws !== "undefined" && this.ws.readyState === WebSocket.OPEN) return 

        this.ws = new WebSocket(this.path)

        this.ws.onopen = () => {
            console.debug("[Connection] Connected.")
            // set to connected state
            this.setConnState && this.setConnState(true)
            // start heartbeat
            this.heartbeator = setInterval(()=>this.send(""), 1000)
        }

        this.ws.onmessage = (msg) => {
            console.debug("[Connection] Message Comes | " + msg.type + " | " + msg.data)
            // process msg for ui
            if (msg.data !== "")
                this.on_message(msg.data)
        }

        this.ws.onclose = () => {
            console.debug("[Connection] Disconnected.")

            // clean heartbeator
            if (this.heartbeator !== null) {
                clearInterval(this.heartbeator)
                this.heartbeator = null
            }

            // no need to reconnect and return conn state
            if (!this.setConnState) return

            // set to disconnected state
            this.setConnState(false)

            // set counter to 5 seconds
            this.reconnCounter = 10

            // start reconnect time count down
            this.reconnector = setInterval((): void => {
                // counter count down
                this.reconnCounter -= 1
                // update connect state for ui display
                if (this.reconnCounter <= 10)
                    this.setConnState && this.setConnState(this.reconnCounter)
                // return when counter not count down to zero
                if (this.reconnCounter > 0)
                    return
                // start reconnect when count down to zero
                clearInterval(this.reconnector!)
                this.connect()
            }, 1000)
        }
    }

    public send(msg: string | RequestContainer) {
        if (this.ws && this.ws.readyState === WebSocket.OPEN)
            if (msg instanceof RequestContainer)
                this.ws.send(JSON.stringify(msg))
            else
                this.ws.send(msg)
    }

    public close() {
        delete(this.setConnState)

        this.reconnector && clearInterval(this.reconnector)

        if (this.ws) {
            this.ws.close()
        }
    }
}

export { Connection }