import { useEffect } from "react";
import { useContext } from "react";
import { useRef } from "react";
import { useCallback } from "react";
import { useMemo, useState } from "react";

import { Button, CloseButton, Col, Form, FormCheck, FormControl, FormSelect, InputGroup, Row, Table } from "react-bootstrap";
import Sounds from "../../functions/SoundHandler";
import { ComponentProps, MainContextData } from "../MainPage";
import { Quote } from "../../api/type";

enum WatchTarget {
    BidPrice = 1,
    AskPrice = 2,

    BidSize = 3,
    AskSize = 4
}
const WatchCondition : {[key: string]: number} = {
    ">=" : 1,
    "<=" : 2
}
enum WatchType {
    Value = 1,
    Ratio = 2,

    BidSize = 3,
    AskSize = 4
}

class WatchItem{
    id :number = -1
    symbol: string
    target: WatchTarget
    condition: number
    value: number
    type: WatchType

    constructor(symbol: string, target: WatchTarget, condition=WatchCondition[">="], value=0, type = WatchType.Value){
        this.symbol = symbol
        this.target = target
        this.condition = condition
        this.value = value
        this.type = type
    }

    CheckCondition(bp=0, ap=0, bS=0, aS=0){
        if (this.target === WatchTarget.BidPrice){
            if (this.condition === WatchCondition[">="])
                return bp >= this.value
            else
                return bp <= this.value
        }
        if (this.target === WatchTarget.AskPrice){
            if (this.condition === WatchCondition[">="])
                return ap >= this.value
            else
                return ap <= this.value
        }

        if (this.type === WatchType.Value){
            if (this.target === WatchTarget.BidSize){
                if (this.condition === WatchCondition[">="])
                    return bS >= this.value
                else
                    return bS <= this.value
            }
    
            if (this.target === WatchTarget.AskSize){
                if (this.condition === WatchCondition[">="])
                    return aS >= this.value
                else
                    return aS <= this.value
            }
        }

        if (this.type === WatchType.BidSize){
            if (this.target === WatchTarget.AskSize){
                if (this.condition === WatchCondition[">="])
                    return aS >= bS
                else
                    return aS <= bS
            }
        }
        if (this.type === WatchType.AskSize){
            if (this.target === WatchTarget.BidSize){
                if (this.condition === WatchCondition[">="])
                    return bS >= aS
                else
                    return bS <= aS
            }
        }
        
        if (this.type === WatchType.Ratio){
            if (this.target === WatchTarget.BidSize){
                const br = bS / (bS+aS) * 100
                if (this.condition === WatchCondition[">="])
                    return br >= this.value
                else
                    return br <= this.value
            }
    
            if (this.target === WatchTarget.AskSize){
                const ar = aS / (bS+aS) * 100
                if (this.condition === WatchCondition[">="])
                    return ar >= this.value
                else
                    return ar <= this.value
            }
        }

        return false
    }
}


interface AddWatchListFormProps{
    context: React.Context<MainContextData>
    setWatchList: React.Dispatch<React.SetStateAction<WatchItem[]>>
}
function AddWatchListForm({context, setWatchList}: AddWatchListFormProps){
    const MainContext = useContext(context)

    const [symbol, setSymbol] = useState("")
    const [watchTarget, setWatchTarget] = useState(WatchTarget.BidSize)
    const [watchCondition, setWatchCondition] = useState(WatchCondition[">="])
    const [watchValue, setWatchValue] = useState<string|number>(1)
    const [watchType, setWatchType] = useState(WatchType.Value)

    useEffect(()=>{
        if (watchType === WatchType.BidSize || watchType === WatchType.AskSize){
            setWatchValue(0)
        }
    }, [watchType])

    const FormHandle = useCallback((e)=>{
        e.preventDefault()
        e.stopPropagation()

        if (symbol.length === 0 || isNaN(parseInt(symbol))) return
        if (typeof(watchValue) === "string" || (watchType === WatchType.Value && watchValue <= 0) || (watchType === WatchType.Ratio && (watchValue <= 0 || watchValue >= 100))) return

        if (MainContext.ws && MainContext.session){
            MainContext.ws.send(JSON.stringify({
                e: "trader",
                subE: "getQuote",
                sessionID: MainContext.session,
                s: symbol
            }))
        }

        setWatchList((old)=>{
            const item = new WatchItem(symbol, watchTarget, watchCondition, watchValue, watchType)
            if (old.length > 0) item.id = old[old.length - 1].id + 1
            else item.id = 0
            return [...old, item]
        })
    }, [setWatchList, MainContext.ws, MainContext.session, symbol, watchTarget, watchCondition, watchValue, watchType])

    return useMemo(()=>{
        return (
            <Form className="mt-2 mb-2" style={{width: "100%"}} onSubmit={FormHandle}>
                <Row>
                    <Col md={3}>
                        <InputGroup>
                            <InputGroup.Text style={{fontSize: 14, padding: "4px 6px"}}>Symbol</InputGroup.Text>
                            <FormControl style={{fontSize: 14, padding: "4px 6px"}} type="text" value={symbol} isInvalid={symbol!==""&&isNaN(parseInt(symbol))} required
                                onChange={(e)=>{ setSymbol(e.target.value.trim()) }}
                            />
                        </InputGroup>
                    </Col>
                    <Col md={7}>
                        <InputGroup>
                            <FormSelect style={{fontSize: 14, padding: "4px 6px"}} value={watchTarget} onChange={(e)=>{
                                const target = parseInt(e.target.value)
                                if (target === WatchTarget.BidPrice || target === WatchTarget.AskPrice)
                                    setWatchType(WatchType.Value)
                                if (target === WatchTarget.BidSize && watchType === WatchType.BidSize)
                                    setWatchType(WatchType.Value)
                                if (target === WatchTarget.AskSize && watchType === WatchType.AskSize)
                                    setWatchType(WatchType.Value)
                                setWatchTarget(target)
                            }}>
                                { Object.entries(WatchTarget).filter((x)=>Number.isNaN(Number(x[0]))).map((x)=>{ const value = x[1]; return <option key={value} value={value}>{x[0]}</option> }) }
                            </FormSelect>
                            <FormSelect style={{fontSize: 14, padding: "4px 6px"}} value={watchCondition} onChange={(e)=>{setWatchCondition(parseInt(e.target.value))}}>
                                { Object.keys(WatchCondition).map((x)=>{ const value = WatchCondition[x]; return <option key={value} value={value}>{x}</option> }) }
                            </FormSelect>
                            <FormControl style={{fontSize: 14, padding: "4px 6px"}} type="number" value={watchValue} required
                                isInvalid={
                                    typeof(watchValue) !== "string" && (
                                        (watchType === WatchType.Value && watchValue <= 0) ||
                                        (watchType === WatchType.Ratio && (watchValue <= 0 || watchValue >= 100))
                                    )
                                }
                                onBlur={(e)=>{
                                    const p = parseFloat(e.target.value)
                                    if (!isNaN(p)){
                                        setWatchValue(p)
                                    }
                                }}
                                onChange={(e)=>{
                                    setWatchValue((old)=>{
                                        const currentValue = parseFloat(e.target.value)
                                        if (isNaN(currentValue)) { // Input String
                                            if (e.target.value === "") return ""
                                            e.target.value = old.toString()
                                            return old
                                        }
                                        if (currentValue < 0) { // Number smaller than 0
                                            e.target.value = old.toString()
                                            return old
                                        }
                                        if (e.target.value !== currentValue.toString()) // Uncompleted number
                                            return e.target.value
                                        return currentValue
                                    })
                                }}
                            />
                            <FormSelect style={{fontSize: 14, padding: "4px 6px"}} value={watchType} onChange={(e)=>{setWatchType(parseInt(e.target.value))}}>
                                {
                                    Object.entries(WatchType).filter((x)=>Number.isNaN(Number(x[0]))).map((x)=>{
                                        const value = x[1]
                                        if (watchTarget === WatchTarget.BidPrice || watchTarget === WatchTarget.AskPrice){
                                            if (value !== WatchType.Value)
                                                return <option key={value} value={value} disabled>{x[0]}</option> 
                                        }
                                        if (watchTarget === WatchTarget.BidSize && value === WatchType.BidSize)
                                            return <option key={value} value={value} disabled>{x[0]}</option>
                                        if (watchTarget === WatchTarget.AskSize && value === WatchType.AskSize)
                                            return <option key={value} value={value} disabled>{x[0]}</option>
                                        return <option key={value} value={value}>{x[0]}</option>
                                    })
                                }
                            </FormSelect>
                        </InputGroup>
                    </Col>
                    <Col className="d-grid">
                        <Button style={{fontSize: 14, padding: "4px 6px"}} type="submit">Watch</Button>
                    </Col>
                </Row>
            </Form>
        )
    }, [symbol, watchTarget, watchCondition, watchValue, watchType, FormHandle])
}

interface WatchRowProps {
    context: React.Context<MainContextData>
    watchItem: WatchItem
    setWatchList: React.Dispatch<React.SetStateAction<WatchItem[]>>
}
function WatchRow({context, watchItem, setWatchList}: WatchRowProps){
    const MainContext = useContext(context)
    const [isOn, setIsOn] = useState(true)
    const [alert, setAlert] = useState(false)

    const checkedQuote = useRef<Quote|null>(null)
    const quote = useMemo(()=>{
        return MainContext.bssQuote[watchItem.symbol]
    }, [MainContext.bssQuote, watchItem])
    
    useEffect(()=>{
        if (!isOn || !quote) return
        
        if (quote === checkedQuote.current) return
        checkedQuote.current = quote

        const bidP = quote.bidP / 1000
        const askP = quote.askP / 1000
        const bidS = quote.bidS
        const askS = quote.askS

        if (watchItem.CheckCondition(bidP, askP, bidS, askS)) {
            setIsOn(false)
            setAlert(true)
            Sounds.Reject()
            setTimeout(()=>{
                setAlert(false)
            }, 5000)
        }
    }, [watchItem, isOn, quote])

    return useMemo(()=>{
        return (
            <tr className={alert?"bg-warning":""}>
                <td>
                    <FormCheck checked={isOn} onChange={(e)=>{
                        setIsOn(e.target.checked)
                        setAlert(false)
                    }}/>
                </td>
                <td>{watchItem.symbol}</td>
                <td>{Object.entries(WatchTarget).find(entry => entry[1] === watchItem.target)![0]}</td>
                <td>
                    { Object.keys(WatchCondition).find(key => WatchCondition[key] === watchItem.condition) }
                    &nbsp;
                    {
                        (watchItem.type === WatchType.BidSize || watchItem.type === WatchType.AskSize)?"":watchItem.value
                    }
                    {
                        watchItem.type === WatchType.BidSize?"BidSize":
                        watchItem.type === WatchType.AskSize?"AskSize":
                        watchItem.type === WatchType.Ratio?"%":""
                    }
                </td>
                <td style={{width: "100px"}}>
                    <div className="d-grid">
                        <Button size="sm" style={{padding: "0px 6px"}} onClick={()=>{
                            setWatchList((old)=>{
                                return old.filter(x => x !== watchItem)
                            })
                        }}>
                            Delete
                        </Button>
                    </div>
                </td>
            </tr>
        )
    }, [watchItem, setWatchList, isOn, alert])
}

interface WatchTableProps {
    context: React.Context<MainContextData>
    watchList: WatchItem[]
    setWatchList: React.Dispatch<React.SetStateAction<WatchItem[]>>
}

function WatchTable({context, watchList, setWatchList}: WatchTableProps){
    return useMemo(()=>{
        return (
            <div className="noScrollbar" style={{display: "relative", overflow: "scroll"}}>
                <Table bordered size="sm">
                    <thead>
                        <tr>
                            <td>Enable</td>
                            <td>Symbol</td>
                            <td>Target</td>
                            <td>Condition</td>
                            <td>Delete</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            watchList.map((x = WatchItem.prototype)=>{
                                return  <WatchRow key={x.id} context={context} watchItem={x} setWatchList={setWatchList}/>
                            })
                        }
                    </tbody>
                </Table>
            </div>
        )
    }, [context, watchList, setWatchList])
}

function WarningPanel({context, onClose}: ComponentProps){
    const [watchList, setWatchList] = useState<WatchItem[]>([])

    return useMemo(()=>{
        return (
            <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
				<div style={{width: "100%", position: "sticky", display: "flex", backgroundColor: "#0d6efd"}}>
					<span className="text-white" style={{flex: 1, fontSize: 18, padding: 2, paddingLeft: 4}}>Warning Panel</span>
					<div style={{flex:0, backgroundColor: "#dc3545"}}>
						<CloseButton className="react-draggable-cancel" variant="white" style={{fontSize: 18, padding: 6}} onClick={onClose}/>
					</div>
				</div>
                <div className="react-draggable-cancel" style={{paddingLeft: 10, paddingRight: 10, height: "100%", overflow: "hidden", overflowY: "auto", display: "flex", flexDirection: "column"}}>
                    <AddWatchListForm context={context} setWatchList={setWatchList}/>
                    <WatchTable context={context} watchList={watchList} setWatchList={setWatchList}/>
                </div>
            </div>
        )
    }, [context, onClose, watchList])
}

export default WarningPanel