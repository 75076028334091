import { useMemo, useEffect, useContext, useState } from 'react'

import { Decimal } from 'decimal.js'
import { TimeString } from '../../../functions/DateHandle'

import { Table } from 'react-bootstrap'

import { OrderSide, OrderStatus } from '../../../functions/Enum'
import { MainContextData } from '../../MainPage'

export type HistoryInfo = {
	Time: number
	D: string
} & (
	{
		Cost: number
		Side: "B"|"S"
		Pts: number
		PnL: number
	}
	|
	{
		Reason: string
		Side?: "B"|"S"
	}
)

interface HistoryRowProps {
	info: HistoryInfo
}
function HistoryRow({info}: HistoryRowProps) {
	const BGColor = useMemo(()=>{
		if ("Reason" in info) return "#9ec5fe"

		if (info.PnL > 0)
			return "#a3cfbb"
		else if (info.PnL < 0)
			return "#f1aeb5"
		else
			return "#9ec5fe"
	}, [info])

	return useMemo(()=>{
		const time = TimeString(new Date(info.Time), true)

		if ("Reason" in info) {
			if (typeof info.Side !== "undefined") {
				return (
					<tr>
						<td >{time}</td>
						<td style={{textAlign: "center"}}>{info.D}</td>
						<td style={{textAlign: "center", backgroundColor: "#fecba1"}}>{info.Side}</td>
						<td style={{backgroundColor: "#fecba1"}} colSpan={3}>{info.Reason}</td>
					</tr>
				)
			}
			
			return (
				<tr>
					<td >{time}</td>
					<td style={{textAlign: "center"}}>{info.D}</td>
					<td style={{backgroundColor: "#fecba1"}} colSpan={4}>{info.Reason}</td>
				</tr>
			)
		}

		return (
			<tr>
				<td>{time}</td>
				<td style={{textAlign: "center"}}>{info.D}</td>
				<td style={{textAlign: "center", backgroundColor: BGColor}}>{info.Side}</td>
				<td style={{textAlign: "right", backgroundColor: BGColor}}>{info.Cost}</td>
				<td style={{textAlign: "right", backgroundColor: BGColor}}>{info.Pts}</td>
				<td style={{textAlign: "right", backgroundColor: BGColor}}>{Math.round(info.PnL*1000)/1000}</td>
			</tr>
		)
	}, [info, BGColor])
}

interface HistoryTableProps {
	context: React.Context<MainContextData>
}
function HistoryTable(props: HistoryTableProps){
	const MainContext = useContext(props.context)
	const [history, setHistory] =  useState<HistoryInfo[]>([])

	useEffect(()=>{
		if (MainContext.history.length > 0){
			let Asset : {[key:string]: {Cost: number, Pts: number}} = {}
			let newHistory : HistoryInfo[] = []

			MainContext.history.forEach((x)=>{
				const Time = typeof(x.OrderTime) === "string" ? new Date(x.OrderTime).getTime() : x.OrderTime
				if (x.Status === OrderStatus.Full || x.Status === OrderStatus.Partial){
					if (!Asset[x.D]) {
						Asset[x.D] = {Cost: 0, Pts: 0}
					}
					
					const Cost = new Decimal(Asset[x.D].Cost)
					const Pts = Asset[x.D].Pts
					if (x.OSide === OrderSide.Buy){
						Asset[x.D].Cost = Cost.mul(Pts).add(new Decimal(x.Cost).mul(x.Shares)).div(Pts + x.Shares).toNumber()
						Asset[x.D].Pts = Pts + x.Shares
						newHistory.push({Time: Time, D: x.D, Cost: x.Cost, Side: "B", Pts: x.Shares, PnL: 0})
					}
					else {
						const PnL = new Decimal(x.Cost).minus(Cost).mul(x.Shares).toNumber()
						Asset[x.D].Pts = Pts - x.Shares
						if (Asset[x.D].Pts === 0)
							Asset[x.D].Cost = 0
						newHistory.push({Time: Time, D: x.D, Cost: x.Cost, Side: "S", Pts: x.Shares, PnL: PnL})
					}
				}
				else if (x.Status === OrderStatus.Cancel)
				{
					newHistory.push({Time: Time, D: x.D, Side: x.OSide===OrderSide.Buy?"B":"S", Reason: "Canceled / Expired"})
				}
				else if (x.Status === OrderStatus.Reject)
				{
					newHistory.push({Time: Time, D: x.D, Reason: x.Reason})
				}
			})

			setHistory(newHistory)
		}
	}, [MainContext.history])

	return useMemo(()=>{
		return (
			<Table className="react-draggable-cancel" style={{fontSize: 12, position: "relative", top: 8}} bordered size="sm">
				<thead>
					<tr style={{fontWeight: "bold"}}>
						<th>Time</th>
						<th>D</th>
						<th colSpan={4}>Msg</th>
					</tr>
				</thead>
				<tbody>
					{
						history.map((x, i)=>{
							console.log(x.Time)
							return (
								<HistoryRow key={i} info={x}/>
							)
						}).reverse()
					}
				</tbody>
			</Table>
		)
	}, [history])
}

export default HistoryTable