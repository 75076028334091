export enum MessageType {
    Connected = "connected",

    Login = "login",
    Logout = "logout",
    GetDeviceKey = "getDeviceKey",
    GetSessionID = "getSessionID",
    ReconnectBySessionID = "reconnectBySessionID",

    GetKlineChart = "getKlineChart",
    GetQuote = "getQuote",
    TradeData = "TradeData",
    QuoteData = "QuoteData",
    BrokerData = "BrokerData",

    UserSetting = "userSetting",

    Trader = "trader"
}

export enum UserSettingSubAction {
    CreditStatus = "creditStatus"
}

export enum TraderSubAction {
    Quote = "Quote",

    LoadAssets = "loadAssets",
    UpdateAssets = "updateAssets",

    LoadHistory = "loadHistory",
    ServerOrderResp = "serverRes_Order",

    Pending = "pending",
    MarketTrigger = "marketTrigger",
    TriggerPending = "triggerPending",

    ModifyPending = "modifyPending",
    CancelMarketTrigger = "cancelMarketTrigger"
}

export enum StatusType {
    EOF             = 1000,
    SQLError        = 5000,
    ServerTimeout   = 5001,
    Success         = 0,
    Error           = 1,
    MultipleSession = 2,
    MaxSession      = 3
}

export enum ClientVersion {
    Win     = 1,
    Excel   = 2,
    Equity  = 3
}