import { useState, useRef, useCallback, useEffect, useContext, useMemo } from 'react'

import { StatusType } from '../../api/enum'
import { parseTime, splitData, parseStartTime, parseIntervalToMinutes } from './KlineChart/functions'
import { CustomBBands, CustomEMA, CustomRSI } from '../../functions/CustomMath';
import InputValidation from '../../functions/InputValidation';

import ReactECharts from 'echarts-for-react';
import { Button } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import Nav from 'react-bootstrap/Nav'
import InputGroup from 'react-bootstrap/InputGroup'

import RightClickMenu from './KlineChart/RightClickMenu';

async function getOption(echart, data, dataZoom, inPrice){
	let splitedData = splitData(data)

	let parseData = []
	
	for (let i = 1; i < splitedData.chart.length; i++){
		const newV = splitedData.chart.slice()[i][1]
		const oldV = splitedData.chart.slice()[i-1][1]
		parseData.push((newV-oldV)/oldV)
	}

	let RSI_14 = splitedData.chart.map((x, i)=>{
		const RSICount = 14
		let lastN = splitedData.chart.slice(0, i + 1)
		let RSI = new CustomRSI(RSICount);

		lastN.forEach((x)=>{
			RSI.Add(x[1])
		})

		return RSI.RSI
	})

	const EMA12 = new CustomEMA(12)
	let EMA_12 = [NaN].concat(
		parseData.map((x)=>{
			EMA12.Add(x)

			return EMA12.VAL
		})
	)

	const EMA26 = new CustomEMA(26)
	let EMA_26 = [NaN].concat(
		parseData.map((x)=>{
			EMA26.Add(x)

			return EMA26.VAL
		})
	)

	let MACD_12_24 = []

	for (let i = 0; i < EMA_12.length; i++){
		MACD_12_24.push(EMA_12[i] - EMA_26[i]);
	}

	let BBands = new CustomBBands(splitedData.chart.map((x)=>{return x[1]}), 20, 2)

    let option = {
        tooltip: [{
            trigger: "axis"
        }],
        grid: [
			{ top:'5%', left: '10%', right: '10%', bottom: '50%' },
			{ left: '10%', right: '10%', top: '55%', height: '20%' },
			{ top:'35%', left: '10%', right: '10%', height: '15%' },
			{ left: '10%', right: '10%', top: '80%', height: '15%' }
		],
		dataZoom: {
			type: 'inside',
			xAxisIndex: [0, 1, 2, 3],
			start: dataZoom[0],
			end: dataZoom[1]
		},
		xAxis: [
			{
				data: splitedData.time,
				scale: true,
				min: 'dataMin',
				max: 'dataMax'
			},
			{
				gridIndex:1,
				data: splitedData.time,
				scale: true,
				min: 'dataMin',
				max: 'dataMax',
				axisLabel: { show: false },
				axisLine: { show: true },
				axisTick: { show: false },
				splitLine: { show: false }
			},
			{
				gridIndex: 2,
				data: splitedData.time,
				scale: true,
				min: 'dataMin',
				max: 'dataMax'
			},
			{
				gridIndex: 3,
				data: splitedData.time,
				min: 'dataMin',
				max: 'dataMax',
				axisLabel: { show: false },
				axisLine: { show: true },
				axisTick: { show: false }
			}
		],
        yAxis: [
			{
				scale: true,
				position: "right",
				splitNumber: 3,
				splitArea: {
					show: true
				}
			},
			{
				gridIndex: 1,
				scale: false,
				splitNumber: 2,
				
			},
			{
				gridIndex: 2,
				scale: true,
				position: "left",
				splitNumber: 1,
				splitLine: {
					show: false
				}
			},
			{
				gridIndex: 3,
				scale: false,
				min: 0,
				max: 100,
				splitNumber: 1
			}
		],
        series: [
            {
                name: "KLine",
                type: 'candlestick',
				animation: false,
                data: splitedData.chart
            },
			{
				name: 'Volume',
				type: 'bar',
				xAxisIndex: 2,
				yAxisIndex: 2,
				data: splitedData.amount,
				cursor: "move",
				animation: false,
				itemStyle: {
					color: "rgba(66,66,66,0.3)"
				}
			},
			{
				name: 'BBandsMiddle',
				type: 'line',
				showSymbol: false,
				data: BBands.Middle,
				animation: false,
				lineStyle: {
					width: 1
				}
			},
			{
				name: 'BBandsUp',
				type: 'line',
				showSymbol: false,
				data: BBands.high,
				animation: false,
				lineStyle: {
					width: 1
				}
			},
			{
				name: 'BBandsDown',
				type: 'line',
				showSymbol: false,
				data: BBands.low,
				animation: false,
				lineStyle: {
					width: 1
				}
			},
			{
				name: 'RSI',
				type: 'line',
				showSymbol: false,
				data: RSI_14,
				xAxisIndex: 3,
				yAxisIndex: 3,
				animation: false,
				lineStyle: {
					width: 1
				}
			},
			{
				name: 'EMA_12',
				type: 'line',
				showSymbol: false,
				data: EMA_12,
				xAxisIndex: 1,
				yAxisIndex: 1,
				animation: false,
				lineStyle: {
					width: 1
				}
			},
			{
				name: 'EMA_24',
				type: 'line',
				showSymbol: false,
				data: EMA_26,
				xAxisIndex: 1,
				yAxisIndex: 1,
				animation: false,
				lineStyle: {
					width: 1
				}
			},
			{
				name: 'MACD_12_24',
				type: 'bar',
				data: MACD_12_24,
				animation: false,
				xAxisIndex: 1,
				yAxisIndex: 1
			}
        ]
    }

	if (inPrice > 0){
		option.series.push({
			name: 'In Price',
			type: 'line',
			showSymbol: false,
			data: option.series[0].data.map((x)=>{return inPrice}),
			lineStyle: {
				width: 1
			}
		})
	}else{
		option.series.push({
			name: 'In Price',
			type: "line",
			data: option.series[0].data.map((x)=>{return "-"})
		})
	}

	const echartInstance = echart.getEchartsInstance()
	echartInstance.setOption(option)
}

function KlineChart(props){
	const MainContext = useContext(props.context)

	const ws = useMemo(()=>{return MainContext.ws}, [MainContext.ws])

    const [symbol, setSymbol] = useState("")
	const [subscribeSymbol, setSubSymbol] = useState("")
	const [timeInterval, setTimeInterval] = useState("1m")
	
	const [chartData, setChartData] = useState([])
	const tmpChartData = useRef([])
	const firstLoad = useRef(true)
	const allowTradeData = useRef(false)
	const [dataZoom, setDataZoom] = useState(false)

	const [echart, setEchart] = useState(null)
	const [inPrice, setInPrice] = useState(null)

	const [showContextMenu, setShowContextMenu] = useState(<></>)

	const echartMenu = useCallback((params)=>{
		const echartInstance = echart.getEchartsInstance()
		params.event.preventDefault()

		let cursorOffsetXY = [params.offsetX, params.offsetY]

		if (echartInstance.containPixel({seriesIndex:0}, cursorOffsetXY)){
			let selectedPrice = echartInstance.convertFromPixel({seriesIndex: 0}, cursorOffsetXY)[1]
			selectedPrice = Math.round(selectedPrice*100)/100
			setShowContextMenu(<RightClickMenu setSelf={setShowContextMenu} inPrice={inPrice} setInPrice={setInPrice} cursorPageXY={cursorOffsetXY} selectedPrice={selectedPrice}/>)
		}
	}, [echart, inPrice])

	const echartClick = useCallback(()=>{
		setShowContextMenu(<></>)
	}, [echart])

	const echartDataZoom = useCallback((params)=>{
		if (allowTradeData.current){
			setDataZoom([params.batch[0].start, params.batch[0].end])
		}
	}, [echart])

	const formHandle = useCallback((e)=>{
		if (typeof(e) !== "string"){
			e.preventDefault();
			e.stopPropagation();
		}
		if (symbol !== ""){
			const cleanSpaceSymbol = symbol.trim()
			if (!InputValidation.integer(cleanSpaceSymbol)){
				setSymbol(subscribeSymbol)
				return
			}

			if (cleanSpaceSymbol !== subscribeSymbol || e === "timeInterval"){
				ws.send(JSON.stringify({e: "getKlineChart", s: cleanSpaceSymbol, interval: parseIntervalToMinutes(timeInterval)}))
				setSubSymbol(cleanSpaceSymbol)
				tmpChartData.current = []
				setChartData([])
				setInPrice(null)
				setDataZoom(false)
				allowTradeData.current = false
				firstLoad.current = true
				if (echart){
					const echartInstance = echart.getEchartsInstance()
					echartInstance.setOption({})
				}
			}
		}
	}, [symbol, timeInterval])

	useEffect(()=>{
		if (symbol != "")
			formHandle("timeInterval")
	}, [timeInterval])

	useEffect(()=>{
		const klineData = MainContext.klineData
		if (klineData.s === subscribeSymbol && firstLoad.current === true){
			if (klineData.status === StatusType.EOF){
				setChartData(tmpChartData.current)
				firstLoad.current = false
				allowTradeData.current = true
				setInPrice(0)
			}else{
				const k = klineData.k
				tmpChartData.current.push([parseTime(k.time), k.o, k.c, k.l, k.h, k.v])
			}
		}
	}, [MainContext.klineData])

	useEffect(()=>{
        if (MainContext.tradeData.length > 0 && firstLoad.current === false && allowTradeData.current){
			if (MainContext.tradeData[1] === subscribeSymbol && MainContext.tradeData[2] !== "0"){
				let tradeData = MainContext.tradeData
				setChartData((old)=>{
					let newArr = old.slice()
					const current_i = newArr.length - 1

					const thisStartTime = parseStartTime(parseTime(tradeData[6]), "1m")
					const price = parseFloat(tradeData[2])
					const amount = parseFloat(tradeData[3])

					if (current_i === -1){
						newArr.push([thisStartTime, price, price, price, price, amount])
					}else if (newArr[current_i][0] === thisStartTime){
						newArr[current_i][2] = price
						newArr[current_i][5] += amount
						if (newArr[current_i][3] > price)
							newArr[current_i][3] = price
						if (newArr[current_i][4] < price)
							newArr[current_i][4] = price
					}else if (newArr[current_i][0] < thisStartTime){
						//const low = newArr[current_i][2] > price ? price : newArr[current_i][2]
						//const high = newArr[current_i][2] > price ? newArr[current_i][2] : price
						newArr.push([thisStartTime, price, price, price, price, amount]);
					}

					return newArr
				})
			}
        }
    }, [MainContext.tradeData])

	useEffect(()=>{
		if (chartData.length > 0 && firstLoad.current === false){
			let newDataZoom = null
			if (dataZoom === false){
				let start = parseInt((1 - (50/chartData.length))*100)
				newDataZoom = [start, 100]
				setDataZoom([start, 100])
			}

			getOption(echart, chartData, newDataZoom??dataZoom, inPrice)
		}else if (echart){
			const echartInstance = echart.getEchartsInstance()
			echartInstance.resize()
		}
	}, [echart, chartData, inPrice, dataZoom])

	useEffect(()=>{
		if (echart){
			const echartInstance = echart.getEchartsInstance()
			echartInstance.off()
			echartInstance.getZr().off()
			echartInstance.on("datazoom", echartDataZoom)
			echartInstance.getZr().on("contextmenu", echartMenu)
			echartInstance.getZr().on("click", echartClick)
			//echartInstance.getZr().on("mousemove", echartMouseOver)
		}
	}, [echart, echartMenu])

    return useMemo(()=>{
		return(
			<>
			<span className="react-close-handle react-draggable-cancel" onClick={()=>{
				props.onClose()
			}}>x</span>
			<div style={{display: "flex", flexDirection: "column", padding: 10, height: "100%", minWidth: "100px"}}>
				<div style={{flex: 0, height: "fit-content", display: "flex", flexDirection: "row"}}>
					<Form className="mb-2" style={{flex: 2}} noValidate onSubmit={formHandle}>
						<div style={{display: "flex"}}>
							<div style={{flex: 3, paddingRight: 4}}>
								<InputGroup size="sm">
									<InputGroup.Text style={{fontSize: 12, fontWeight: "bold", padding: "0px 2px"}}>D</InputGroup.Text>
									<Form.Control className="react-draggable-cancel" type="text"
										style={{fontSize: 12, padding: "0px 2px"}}
										onChange={(v)=>{
											const value = v.target.value.trim()
											if (!InputValidation.integer(value)){
												v.target.value = symbol
												return
											}
											setSymbol(v.target.value)
										}}
									/>
								</InputGroup>
							</div>
							<div style={{flex: 2, paddingLeft: 4}} className="d-grid">
								<Button className="react-draggable-cancel" type="submit" size="sm" style={{fontSize: 12, padding: "0px 4px"}}>Search</Button>
							</div>
						</div>
					</Form>
					<div style={{flex: 5}}></div>
					<div style={{flex: 0, flexShrink: 0, minWidth: 138.33, width: "fit-content"}}>
						<Nav variant="pills" defaultActiveKey="1m" onSelect={setTimeInterval}>
							<Nav.Item>
								<Nav.Link style={{fontSize: 12, padding: "0px 5px", border: "1px solid #0d6efd", marginLeft: 4}} eventKey="15m">15m</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link style={{fontSize: 12, padding: "0px 5px", border: "1px solid #0d6efd", marginLeft: 4}} eventKey="5m">5m</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link style={{fontSize: 12, padding: "0px 5px", border: "1px solid #0d6efd", marginLeft: 4}} eventKey="3m">3m</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link style={{fontSize: 12, padding: "0px 5px", border: "1px solid #0d6efd", marginLeft: 4}} eventKey="1m">1m</Nav.Link>
							</Nav.Item>
						</Nav>
					</div>
				</div>
				<ReactECharts className="react-draggable-cancel" style={{flex:1, flexGrow:1, flexShrink: 1}} ref={(e)=>{setEchart(e)}} option={{}} lazyUpdate/>
				{showContextMenu}
			</div>
			</>
    	)
	}, [showContextMenu, formHandle, symbol])
}

export default KlineChart