import Decimal from "decimal.js";

class CustomSMA {
	public Ready: boolean = false
	public VAL: number = 0

	private m_period: number
	private m_sum: Decimal = new Decimal(0)
	private m_records: Decimal[] = []

	constructor(period: number) {
		this.m_period = period
	}

	Add(value: number) {
		const value_in_decimal = new Decimal(value)
		this.m_records.push(value_in_decimal);

		if (this.Ready === false) {
			this.m_sum = this.m_sum.add(value_in_decimal)

			if (this.m_records.length == this.m_period) {
				this.Ready = true;
				this.VAL = this.m_sum.div(this.m_period).toNumber()
			}
			return
		}
		
		this.m_sum = this.m_sum.sub(this.m_records.splice(0, 1)[0]).add(value_in_decimal)
		this.VAL = this.m_sum.div(this.m_period).toNumber()
	}
}

class CustomEMA {
	public Ready		: boolean = false
	public VAL			: number = 0

	private m_value		: Decimal = new Decimal(0)
	private m_period	: number
	private m_counter	: number = 0
	private m_alpha		: Decimal

	constructor(period: number) {
		this.m_period = period
		this.m_alpha = new Decimal(2.0).div(period + 1)

		this.m_value = new Decimal(0)
	}

	Add(value: number) {
		this.m_value = this.m_alpha.mul(new Decimal(value).sub(this.m_value)).add(this.m_value)
		this.VAL = this.m_value.toNumber()

		this.m_counter++;
		if (!this.Ready && this.m_counter >= this.m_period)
			this.Ready = true;
	}
}

class CustomVWAP{
	public Ready: boolean = false
	public VAL: number = 0

	private m_period: number
	private m_price_sum: Decimal = new Decimal(0)
	private m_volume_sum: Decimal = new Decimal(0)
	private m_turnover_sum: Decimal = new Decimal(0)
	private m_prices: Decimal[] = []
	private m_volumes: Decimal[] = []
	private m_turnovers: Decimal[] = []

	constructor(period: number) {
		this.VAL = NaN
		this.Ready = false

		this.m_period = period
	}

	Add(price: number, volume: number){
		const p = new Decimal(price)
		const v = new Decimal(volume)
		const turnover = p.mul(v)

		this.m_prices.push(p)
		this.m_volumes.push(v)	
		this.m_turnovers.push(turnover)

		if (this.Ready === false) {
			this.m_price_sum = this.m_price_sum.add(p)
			this.m_volume_sum = this.m_volume_sum.add(v)
			this.m_turnover_sum = this.m_turnover_sum.add(turnover)

			if (this.m_prices.length == this.m_period){
				this.Ready = true
				this.VAL = (this.m_volume_sum.toNumber() > 0 ? this.m_turnover_sum.div(this.m_volume_sum) : this.m_price_sum.div(this.m_period)).toNumber()
			}
			return
		}

		this.m_price_sum = this.m_price_sum.add(p).sub(this.m_prices.splice(0,1)[0])
		this.m_volume_sum = this.m_volume_sum.add(v).sub(this.m_volumes.splice(0,1)[0])
		this.m_turnover_sum = this.m_turnover_sum.add(turnover).sub(this.m_turnovers.splice(0,1)[0])

		this.VAL = (this.m_volume_sum.toNumber() > 0 ? this.m_turnover_sum.div(this.m_volume_sum) : this.m_price_sum.div(this.m_period)).toNumber()
	}
}

class CustomRSI{
	public Ready: boolean = false
	public RSI: number = 0

	private m_prevVal ?: Decimal
	private m_gainEMA : CustomEMA
	private m_lossEMA : CustomEMA

    constructor(period: number) {
        this.m_gainEMA = new CustomEMA(period)
        this.m_lossEMA = new CustomEMA(period)
    }

    Add(val: number) {
		const value_in_decimal = new Decimal(val)

		if (typeof this.m_prevVal === "undefined"){
			this.m_prevVal = value_in_decimal
			return
		}
		
		const diff = value_in_decimal.sub(this.m_prevVal).abs().toNumber()

		if (value_in_decimal > this.m_prevVal) {
			this.m_gainEMA.Add(diff)
			this.m_lossEMA.Add(0)
		}
		else if (value_in_decimal < this.m_prevVal){
			this.m_gainEMA.Add(0)
			this.m_lossEMA.Add(diff)
		}

		if (this.m_gainEMA.Ready && this.m_lossEMA.Ready){
			this.RSI = 100 - (100 / (1 + (this.m_gainEMA.VAL / this.m_lossEMA.VAL)))
			this.Ready = true
		}

		this.m_prevVal = value_in_decimal
    }
}

function calculateMA(dayCount: number, data: number[]) {
	let result: number[] = []
	for (let i = 0, len = data.length; i < len; i++) {
		if (i < dayCount) {
			result.push(NaN);
			continue;
		}
		let sum = 0;
		for (let j = 0; j < dayCount; j++) {
			sum += +data[i - j];
		}
		result.push(Math.round((sum / dayCount)*1000000000)/1000000000);
	}
	return result;
}

function calculateAvg(data: number[]){
    let total = 0
    data.forEach((x)=>{
        total += x
    })
    return total / data.length
}

function calculateSD(data: number[]){
    let sub = 0
    const avg = calculateAvg(data)
    data.forEach((x)=>{
        let tmp = x - avg
        sub += tmp * tmp
    })
    return Math.sqrt(sub / data.length)
}

class CustomBBands{
	public Middle: number[]
	public high: number[]
	public low: number[]

	private data: number[]
	private N: number
	private K: number

    constructor(data: number[], N: number, K: number){
        this.N = N
        this.K = K
        this.data = data
        this.Middle = calculateMA(N, data)
        this.high = this.calculateHigh()
        this.low = this.calculateLow()
    }

    calculateHigh(){
        let highSD = []
        for (let i = 0; i < this.data.length; i++){
            let lastN = this.data.slice(i - (this.N * this.K) + 1, i + 1)
            highSD.push(this.Middle[i] + calculateSD(lastN))
        }
        return highSD
    }

    calculateLow(){
        let lowSD = []
        for (let i = 0; i < this.data.length; i++){
            let lastN = this.data.slice(i - (this.N * this.K) + 1, i + 1)
            lowSD.push(this.Middle[i] - calculateSD(lastN))
        }
        return lowSD
    }
}

export { calculateMA, calculateAvg, calculateSD, CustomEMA, CustomSMA, CustomVWAP, CustomRSI, CustomBBands }