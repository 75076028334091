import { useMemo } from 'react'

import Form from "react-bootstrap/Form"
import InputGroup from 'react-bootstrap/InputGroup'

function Symbol({
	fontSize,
	symbol, setSymbol, disableSymbolInput,
	selectedSymbol, setSelectedSymbol,
	SMAValue, LastAllBar, CurrBar,
	userBuyLight, userSellLight,
	BShortSMAIndex, BLongSMAIndex,
	SShortSMAIndex, SLongSMAIndex
}){

	const SMADisplay = useMemo(()=>{
		return (
			<>
			{
				Object.keys(SMAValue).map((x)=>{
					const ShowBackGoundColor = (
						(userBuyLight && (x === BShortSMAIndex || x === BLongSMAIndex)) ||
						(userSellLight && (x === SShortSMAIndex || x === SLongSMAIndex))
					)

					return (
						<InputGroup key={x} style={{flex: 0, minWidth: x==="12"?160:150}}>
							<InputGroup.Text style={{fontSize: fontSize, padding: "0px 4px", backgroundColor: ShowBackGoundColor?"rgba(13, 110, 253, 0.25)":""}}>VWAP({x})</InputGroup.Text>
							<InputGroup.Text style={{flex: 1, fontSize: fontSize, padding: "0px 4px", backgroundColor: ShowBackGoundColor?"rgba(13, 110, 253, 0.25)":""}}>{SMAValue[x]}</InputGroup.Text>
						</InputGroup>
					)
				})
			}
			</>
		)
	}, [fontSize, SMAValue, userBuyLight, userSellLight, BShortSMAIndex, BLongSMAIndex, SShortSMAIndex, SLongSMAIndex])

	const LastTODisplay = useMemo(()=>{
		return (
			<InputGroup style={{flex: 1, minWidth: 145}}>
				<InputGroup.Text style={{fontSize: fontSize, padding: "0px 4px"}}>LastTO(K)</InputGroup.Text>
				<InputGroup.Text style={{fontSize: fontSize, padding: "0px 4px", flex:1}}>{LastAllBar.length?Math.round(LastAllBar[LastAllBar.length-1].TO/1000):""}</InputGroup.Text>
			</InputGroup>
		)
	}, [fontSize, LastAllBar])

	const CurrTODisplay = useMemo(()=>{
		return (
			<InputGroup style={{flex: 1, minWidth: 145}}>
				<InputGroup.Text style={{fontSize: fontSize, padding: "0px 4px"}}>CurrTO(K)</InputGroup.Text>
				<InputGroup.Text style={{fontSize: fontSize, padding: "0px 4px", flex:1}}>{CurrBar?Math.round(CurrBar.TO/1000):""}</InputGroup.Text>
			</InputGroup>
		)
	}, [fontSize, CurrBar])

	return useMemo(()=>{
		return (
			<div style={{display: "flex", gap: 4}}>
				<InputGroup style={{fontSize: fontSize, width: "fit-content"}}>
					<InputGroup.Text style={{padding: "0 4px", fontSize: fontSize}}>Symbol</InputGroup.Text>
					<Form.Control disabled={disableSymbolInput} className="react-draggable-cancel" value={symbol} style={{padding: "0 4px", fontSize: fontSize, width: 60}} onChange={(v)=>{setSymbol(v.currentTarget.value)}}
						onBlur={(e)=>{e.target.value = selectedSymbol}}
						onKeyPress={(e)=>{
							if (e.code === "Enter" || e.code === "NumpadEnter"){
								if (symbol.length > 0){
									setSelectedSymbol(symbol)
								}
							}
						}}
					/>
				</InputGroup>
				{ SMADisplay }
				{ LastTODisplay }
				{ CurrTODisplay }
			</div>
		)
	}, [
		fontSize,
		symbol, selectedSymbol, disableSymbolInput,
		SMADisplay, LastTODisplay, CurrTODisplay
	])
}

export default Symbol