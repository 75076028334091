/**
 * Validation for checking if the string is an integer
 * @param {string} value - The string which need to validate
 * @returns {boolean}
 */
function integer(value: string){
    return /^[0-9]*$/.test(value)
}

/**
 * Validation for checking if the string is an float
 * @param {string} value - The string which need to validate
 * @returns {boolean}
 */
function float(value: string){
    return /^([1-9][0-9]*)+(\.[0-9]{1,})?$/.test(value)
}

export default {
    integer, float
}