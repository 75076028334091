import { useState, useEffect, useCallback} from 'react';
import { useParams } from 'react-router-dom';

import { MessageType, StatusType } from "../api/enum"
import { ResponseContainer } from '../api/response';
import { Connection } from '../functions/Connection';

import QRCode from 'qrcode.react';
import Card from 'react-bootstrap/Card'

function QRCodeLoginPage(){
    const { reason } = useParams()

    const [ws, setWS] = useState<Connection|null>(null)
    const [connState, setConnState] = useState<boolean|number>(false)

    const [sessionKey, setSessionKey] = useState<string>("")
    const [errMsg , setErrMsg] = useState<string>("")

    const onMsg = useCallback((msg: string)=>{
        const jsonData : ResponseContainer = JSON.parse(msg)

        if (jsonData.e === MessageType.GetDeviceKey){
            setSessionKey(jsonData.message)
        }
        else if (jsonData.e === MessageType.Login){
            switch (jsonData.status) {
                case StatusType.Success:
                    localStorage.userID = jsonData.userID
                    window.location.assign("/main")
                    break;
                case StatusType.Error:
                    setErrMsg("App's Username or Password Wrong!")
                    break;
                case StatusType.ServerTimeout:
                    setErrMsg("Server Timeout.")
                    break;
            }
        }
    }, [])

    useEffect(()=>{
        if (ws === null) {
            setWS(new Connection(setConnState))
            return
        }

        const conn = ws
        conn.on_message = onMsg
        conn.connect()

        return () => {
            conn.close()
        }
    }, [ws, onMsg])

    useEffect(()=>{
        if (connState !== true){
            setSessionKey("")
            setErrMsg("Server isn't connected." + (connState===false?"":connState>0?" Retry in " + connState + " seconds.":" Retry!"))
            return
        }

        setErrMsg("")
        ws && ws.send(JSON.stringify({e: "getDeviceKey"}))
    }, [ws, connState])

    return (
        <Card style={{margin: "5em auto", maxWidth: 450}}>
            <Card.Header className="bg-primary text-white">
                <span className="fs-3">QRCode Login</span>
            </Card.Header>
            <Card.Body>
                <div style={{margin: "50px auto", width: 300, height: 300}}>
                    <QRCode style={{display: sessionKey===""?"none":"block"}} level={"H"} size={300} value={sessionKey}/>
                </div>
                <p className="mb-2" style={{display: errMsg!==""?"block":"none", color: "red"}}>{errMsg}</p>
            </Card.Body>
            <Card.Footer style={{display: reason?"block":"none"}}>
                <span style={{display: reason?"block":"none", color: "red"}}>
                    {reason==="forceLogout"?"Someone logged in from other device or browser. Please login again.":reason==="logout"?"Logout Success.":""}
                </span>
            </Card.Footer>
        </Card>
    )
}

export default QRCodeLoginPage