import { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import Decimal from 'decimal.js'

import { ClientVersion } from '../../api/enum'
import { OrderSide } from '../../functions/Enum'

import { Container } from 'react-bootstrap'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'

import "./TopNav/index.css"
import { Connection } from '../../functions/Connection'
import { AcctData, Asset, Quote } from '../../api/type'
import { LayoutItem } from '../MainPage'

function parseNumber(num: number){
	if (num >= 1000){
		return (Math.round( num / 10 ) /100) + "K"
	}
	return num
}

function ShowTime(){
    const [time, setTime] = useState<string>("")
    const [timer, setTimer] = useState<NodeJS.Timer|null>(null)
    const timerSetted = useRef(false)

    useEffect(()=>{
        if (timer === null && timerSetted.current === false){
            timerSetted.current = true
            setTimer(
                setInterval(()=>{
                    const currentTime = new Date()
                    const [h,m,s] = [("0"+currentTime.getHours()).slice(-2), ("0"+currentTime.getMinutes()).slice(-2), ("0"+currentTime.getSeconds()).slice(-2)]
                    setTime(h+":"+m+":"+s)
                }, 50)
            )
        }
    }, [timer])

    return useMemo(()=>{
        return <Nav.Item style={{color: "white", padding: ".2em 1em"}}>{time}</Nav.Item>
    }, [time])
}

interface AddComponentListProps {
    acctData: AcctData

    setShowSetW2Row: React.Dispatch<React.SetStateAction<boolean>>
    setShowSetCheapURow: React.Dispatch<React.SetStateAction<boolean>>
    setShowSetCheapUV2Row: React.Dispatch<React.SetStateAction<boolean>>
    addLayout: (component: string, data_grid: {x: number, y: number, w: number, h: number, minW: number, minH: number}) => void
    
    rowCount: number
}
function AddComponentList({acctData, addLayout, setShowSetW2Row, setShowSetCheapURow, setShowSetCheapUV2Row, rowCount}: AddComponentListProps){
    return useMemo(()=>{
        let ShowableItem = []

        ShowableItem.push(
            <NavDropdown.Item key={"ResetLayout"} onClick={()=>{
                Object.keys(localStorage).forEach((x)=>{
                    if (x.startsWith("layout")){
                        delete(localStorage[x])
                    }
                })
                window.location.reload()
            }}>Reset Layout</NavDropdown.Item>
        )

        if (acctData.Quote){
            ShowableItem.push(
                <NavDropdown.Item key={"Chart"} onClick={()=>{
                    addLayout("Chart", {x: 0, y: 0, w:6, h:74, minW: 4, minH: 56})
                }}>Chart</NavDropdown.Item>
            )
        }

        if (acctData.Version === ClientVersion.Excel){
            ShowableItem.push(
                <NavDropdown.Item key={"W2Trader"} onClick={()=>{setShowSetW2Row(true)}}>W2Trader</NavDropdown.Item>
            )
        }

        if (acctData.Version === ClientVersion.Win){
            ShowableItem.push(
                <NavDropdown.Item disabled={rowCount >= acctData.MaxRow} key={"OXTrader"} onClick={()=>{
                    if (rowCount < acctData.MaxRow)
                        addLayout("OXTrader", {x: 0, y: 0, w:3, h: 48, minW: 3, minH: 48})
                }}>OXTrader</NavDropdown.Item>
            )
        }

        ShowableItem.push(
            <NavDropdown.Item key={"PendingPanel"} onClick={()=>{
                addLayout("PendingPanel", {x: 0, y: 0, w:7, h: 82, minW: 7, minH: 82})
            }}>Pending Panel</NavDropdown.Item>
        )
        ShowableItem.push(
            <NavDropdown.Item key={"TimeTriggerPanel"} onClick={()=>{
                addLayout("TimeTriggerPanel", {x: 0, y: 0, w:6, h: 82, minW: 6, minH: 82})
            }}>Time Trigger Panel</NavDropdown.Item>
        )
        ShowableItem.push(
            <NavDropdown.Item disabled={rowCount >= acctData.MaxRow} key={"TriggerPendingPanel"} onClick={()=>{
                addLayout("TriggerPendingPanel", {x: 0, y: 0, w:6, h: 82, minW: 6, minH: 82})
            }}>Trigger Pending Panel</NavDropdown.Item>
        )
        
        ShowableItem.push(
            <NavDropdown.Item key={"CheapUTrader"} onClick={()=>{setShowSetCheapURow(true)}}>CheapUTrader</NavDropdown.Item>
        )
        ShowableItem.push(
            <NavDropdown.Item key={"CheapUTraderV2"} onClick={()=>{setShowSetCheapUV2Row(true)}}>CheapUTraderV2</NavDropdown.Item>
        )

        if (acctData.Quote){
            ShowableItem.push(
                <NavDropdown.Item key={"QuotoPanel"} onClick={()=>{
                    addLayout("QuotoPanel", {x: 0, y: 0, w: 3, h: 82, minW: 2, minH: 82})
                }}>Quote Panel</NavDropdown.Item>
            )

            ShowableItem.push(
                <NavDropdown.Item key={"QuoteTable"} onClick={()=>{
                    addLayout("QuoteTable", {x: 0, y: 0, w: 4, h: 82, minW: 4, minH: 82})
                }}>Quote Table</NavDropdown.Item>
            )

            ShowableItem.push(
                <NavDropdown.Item key={"BrokerPanel"} onClick={()=>{
                    addLayout("BrokerPanel", {x: 0, y: 0, w: 3, h: 82, minW: 2, minH: 82})
                }}>Broker Panel</NavDropdown.Item>
            )
        }

        ShowableItem.push(
            <NavDropdown.Item key={"WarningPanel"} onClick={()=>{
                addLayout("WarningPanel", {x: 0, y: 0, w: 4, h: 82, minW: 4, minH: 82})
            }}>Warning Panel</NavDropdown.Item>
        )

        ShowableItem.push(
            <NavDropdown.Item key={"DetailPanel"} onClick={()=>{
                addLayout("DetailPanel", {x: 0, y: 0, w: 3, h: 42, minW: 2, minH: 42})
            }}>Detail Panel</NavDropdown.Item>
        )

        ShowableItem.push(
            <NavDropdown.Item key={"AdminPanel"} onClick={()=>{
                addLayout("AdminPanel", {x: 0, y: 0, w: 3, h: 42, minW: 2, minH: 42})
            }}>Admin Panel</NavDropdown.Item>
        )

        ShowableItem.push(
            <NavDropdown.Item key={"DemoPanel"} onClick={()=>{
                addLayout("DemoPanel", {x: 0, y: 0, w: 3, h: 84, minW: 2, minH: 84})
            }}>Demo Panel</NavDropdown.Item>
        )

        return (
            <>
                {
                    ShowableItem.map((x)=>{ return x })
                }
            </>
        )
    }, [acctData, rowCount, addLayout, setShowSetCheapURow, setShowSetCheapUV2Row, setShowSetW2Row])
}

interface TopNavProps {
    [key: string]: any

    ws: Connection,
    session: string | false

    setShowSettingPanel: React.Dispatch<React.SetStateAction<boolean>>
    setShowSetW2Row: React.Dispatch<React.SetStateAction<boolean>>
    setShowSetCheapURow: React.Dispatch<React.SetStateAction<boolean>>
    setShowSetCheapUV2Row: React.Dispatch<React.SetStateAction<boolean>>
    setLayout: React.Dispatch<React.SetStateAction<LayoutItem[]>>

    assets: Asset[]
    bssQuote: { [key:string]: Quote }
    layout: LayoutItem[]
    acctData: AcctData
}
function TopNav({
    ws, session, setShowSettingPanel, setShowSetW2Row, setShowSetCheapURow, setShowSetCheapUV2Row, setLayout,
    assets, bssQuote, layout, acctData,

    // any
    creditStatus, traderStream, pendings, marketTriggers
}: TopNavProps){
    const [triggerCount, setTriggerCount] = useState(0)

    const [PnL, FPnL, TO] = useMemo(()=>{
        let [pnl, fpnl, to] = [0, 0, 0]
        assets.forEach((x)=>{
            pnl += x.Pnl
            to += x.Turnover

            if (typeof bssQuote[x.symbol] !== "undefined")
                fpnl += new Decimal(bssQuote[x.symbol].bidP).div(1000).minus(x.Cost).mul(x.Pts).toNumber()
        })
        return [pnl, fpnl, to]
    }, [assets, bssQuote])

    const usingCredit = useMemo(()=>{
        let credit = 0
        pendings.forEach((x: any)=>{ if (x.side === OrderSide.Buy) credit += x.price * x.volume})
        marketTriggers.forEach((x: any)=>{ if (x.side === OrderSide.Buy) credit += x.price * x.volume })

        return credit / 1000
    }, [pendings, marketTriggers])

    const [rowCount] = useMemo(()=>{
        let rowCount = layout.filter((x)=>{
            if (x.component === "OXTrader")
                return true
            return false
        }).length
        return [rowCount]
    }, [layout])

    useEffect(()=>{
        if (traderStream) {
            if (traderStream.subE === "TriggerCount"){
                setTriggerCount(parseInt(traderStream.message))
            }
        }
    }, [traderStream])

    const onLogout = useCallback(()=>{
        if (ws && session)
            ws.send(JSON.stringify({e: "logout", sessionID: session}))
    }, [ws, session])

    const addLayout = useCallback((component: string, data_grid: {x: number, y: number, w: number, h: number, minW: number, minH: number})=>{
        setLayout((last)=>{
            let maxKey = 0
            let maxY = 0
            
            last.forEach((x)=>{
                if (maxY < x["data-grid"].y + x["data-grid"].h)
                    maxY = x["data-grid"].y + x["data-grid"].h
                if (maxKey < x["key"])
                    maxKey = x["key"]
            })

            data_grid.y = maxY

			return [...last, {"key": maxKey+1, "data-grid": data_grid, "component": component}]
        })
    }, [setLayout])

    return useMemo(()=>{
        return (
            <Navbar bg="dark" variant="dark">
                <Container fluid>
                    <Nav>
                        <NavDropdown title="Components">
                            <AddComponentList acctData={acctData} rowCount={rowCount}
                                addLayout={addLayout} setShowSetW2Row={setShowSetW2Row} setShowSetCheapURow={setShowSetCheapURow} setShowSetCheapUV2Row={setShowSetCheapUV2Row}
                            />
                        </NavDropdown>
                    </Nav>
                    <Nav className="me-auto" style={{marginLeft: 32, marginRight: 32}}>
                        <Nav.Item style={{color: "white", padding: ".2em 8px"}}>User: {acctData.AcctName}</Nav.Item>
                        <Nav.Item style={{color: "white", padding: ".2em 16px"}}>Credit: {(creditStatus.creditAmount/1000).toLocaleString()}</Nav.Item>
                        <Nav.Item style={{color: "white", padding: ".2em 16px"}}>{usingCredit.toLocaleString()}</Nav.Item>
                        <Nav.Item style={{color: "white", padding: ".2em 16px"}}>Trigger: {triggerCount}/{rowCount}(Max:{acctData.MaxRow?acctData.MaxRow:0})</Nav.Item>
                        <Nav.Item style={{color: FPnL>0?"#a3cfbb":FPnL<0?"#f1aeb5":"white", padding: ".2em 16px"}}>Floating: {Math.round(FPnL*100)/100}</Nav.Item>
                        <Nav.Item style={{color: PnL>0?"#a3cfbb":PnL<0?"#f1aeb5":"white", padding: ".2em 16px"}}>PnL: {Math.round(PnL*100)/100}</Nav.Item>
                        <Nav.Item style={{color: "white", padding: ".2em 16px"}}>TO: {parseNumber(TO)}</Nav.Item>
                    </Nav>
                    <Nav><ShowTime/></Nav>
                    <Nav>
                        <Nav.Link style={{padding: ".2em 16px"}} onClick={()=>{setShowSettingPanel(true)}}>Settings</Nav.Link>
                        <Nav.Link style={{padding: ".2em 8px"}} onClick={onLogout}>Logout</Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
        )
    }, [onLogout, addLayout, setShowSetCheapURow, setShowSetCheapUV2Row, setShowSettingPanel, setShowSetW2Row, acctData, creditStatus, PnL, FPnL, TO, rowCount, triggerCount, usingCredit])
}

export default TopNav