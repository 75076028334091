import { useEffect } from "react";
import { useCallback } from "react";
import { useState, useMemo, useRef, useContext } from "react";

import { Button, CloseButton, FormControl, InputGroup, Modal, Table } from "react-bootstrap";
import Form from "react-bootstrap/Form"
import SwitchableButton from "./global_component/SwitchableButton";

import { ReturnNextUpPrice, ReturnNextDownPrice, GetSpread } from '../../functions/StockHandle'
import { OrderSide, OrderStatus, PendingModifyType, OrderType } from "../../functions/Enum";
import Decimal from "decimal.js";
import { ParseBigNum } from "../../functions/NumberProcessor";
import InputValidation from "../../functions/InputValidation"

function Quote({fontSize, buyColor, sellColor, BidPrice, AskPrice, BidVolume, AskVolume}){
    const lastBidPrice = useRef(0)
    const lastBidVolume = useRef(0)
    const lastAskPrice = useRef(0)
    const lastAskVolume = useRef(0)

    const [BidPriceL, setBidPriceL] = useState(null)
    const [BidVolumeL, setBidVolumeL] = useState(null)
    const [AskPriceL, setAskPriceL] = useState(null)
    const [AskVolumeL, setAskVolumeL] = useState(null)

    const timer = useRef({bidP: null, askP: null, bidV: null, askV: null})

    useEffect(()=>{
        if (BidPrice > lastBidPrice.current)
            setBidPriceL(buyColor)
        else if (BidPrice < lastBidPrice.current)
            setBidPriceL(sellColor)

        if (BidVolume > lastBidVolume.current)
            setBidVolumeL(buyColor)
        else if (BidVolume < lastBidVolume.current)
            setBidVolumeL(sellColor)  
        
        if (AskPrice > lastAskPrice.current)
            setAskPriceL(buyColor)
        else if (AskPrice < lastAskPrice.current)
            setAskPriceL(sellColor)

        if (AskVolume > lastAskVolume.current)
            setAskVolumeL(buyColor)
        else if (AskVolume < lastAskVolume.current)
            setAskVolumeL(sellColor)  

        lastBidPrice.current = BidPrice
        lastAskPrice.current = AskPrice
        lastBidVolume.current = BidVolume
        lastAskVolume.current = AskVolume
    }, [BidPrice, AskPrice, BidVolume, AskVolume])

    useEffect(()=>{
        if (BidPriceL){
            if (timer.current.bidP)
                clearTimeout(timer.current.bidP)
            timer.current.bidP = setTimeout(()=>{setBidPriceL(null)}, 500)
        }

        if (BidVolumeL){
            if (timer.current.bidV)
                clearTimeout(timer.current.bidV)
            timer.current.bidV = setTimeout(()=>{setBidVolumeL(null)}, 500)
        }

        if (AskPriceL){
            if (timer.current.askP)
                clearTimeout(timer.current.askP)
            timer.current.askP = setTimeout(()=>{setAskPriceL(null)}, 500)
        }

        if (AskVolumeL){
            if (timer.current.askV)
                clearTimeout(timer.current.askV)
            timer.current.askV = setTimeout(()=>{setAskVolumeL(null)}, 500)
        }
    }, [BidPriceL, AskPriceL, BidVolumeL, AskVolumeL])

    const [BidPStyle, AskPStyle, BidVStyle, AskVStyle] = useMemo(()=>{
        return [
            {width: "8em", fontSize: fontSize, textAlign: 'center', lineHeight: '2rem', backgroundColor: BidPriceL, color: BidPriceL?"white":"black"},
            {width: "8em", fontSize: fontSize, textAlign: 'center', lineHeight: '2rem', backgroundColor: AskPriceL, color: AskPriceL?"white":"black"},
            {lineHeight: '2rem', textAlign: 'center', backgroundColor: BidVolumeL, color: BidVolumeL?"white":"black"},
            {lineHeight: '2rem', textAlign: 'center', backgroundColor: AskVolumeL, color: AskVolumeL?"white":"black"}
        ]
    }, [fontSize, BidPriceL, AskPriceL, BidVolumeL, AskVolumeL])

    return useMemo(()=>{
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <table className="react-draggable-cancel"
                    style={{border: '1px solid'}}
                >
                    <thead>
                        <tr style={{textAlign: 'center'}}>
                            <th style={{backgroundColor: buyColor, color: "white", borderBlockEnd: "1px solid black"}}>Bid</th>
                            <th style={{backgroundColor: sellColor, color: "white" , borderBlockEnd: "1px solid black"}}>Ask</th>
                        </tr>
                    </thead>
					<tbody>
						<tr style={{fontSize: fontSize}}>
							<td style={BidPStyle}>{BidPrice}</td>
							<td style={AskPStyle}>{AskPrice}</td>
						</tr>
						<tr style={{fontSize: fontSize}}>
							<td style={BidVStyle}>{ParseBigNum(BidVolume)}</td>
							<td style={AskVStyle}>{ParseBigNum(AskVolume)}</td>
						</tr>
					</tbody>
				</table>
            </div>
        )
    }, [
        fontSize,
        BidPrice, AskPrice, BidVolume, AskVolume,
        BidPStyle, AskPStyle, BidVStyle, AskVStyle
    ])
}

function ControlPanel({context, fontSize, color, AtCrossing}){
    const MainContext = useContext(context)

    const {buyColor, sellColor} = useMemo(()=>{return color}, [color])

    const [BidPrice, setBidPrice] = useState(0)
    const [BidVolume, setBidVolume] = useState(0)
    const [AskPrice, setAskPrice] = useState(0)
    const [AskVolume, setAskVolume] = useState(0)
    const [LotSize, setLotSize] = useState(100)

    const [SymbolInput, setSymbolInput] = useState(null)
    const [selectedSymbol, setSelectedSymbol] = useState("")
    const selectedSymbolRef = useRef("")
    const [isBuy, setIsBuy] = useState(true)
    const [orderType, setOrderType] = useState(OrderType.Limit)
    const [price, setPrice] = useState(0)
    const [lot, setLot] = useState(1)

    const [ForceSide, setForceSide] = useState("")

    useEffect(()=>{
        if (typeof MainContext.bssQuote[selectedSymbol] !== "undefined") {
            setBidPrice(MainContext.bssQuote[selectedSymbol].bidP / 1000)
            setAskPrice(MainContext.bssQuote[selectedSymbol].askP / 1000)

            setBidVolume(MainContext.bssQuote[selectedSymbol].bidS)
            setAskVolume(MainContext.bssQuote[selectedSymbol].askS)
            if (MainContext.bssQuote[selectedSymbol].lotS !== 0)
                setLotSize(MainContext.bssQuote[selectedSymbol].lotS)
        }
    }, [MainContext.bssQuote, selectedSymbol])

    useEffect(()=>{
        if (orderType === OrderType.Market) return

        if (price === 0 && BidPrice > 0 && AskPrice > 0)
        {
            if (isBuy) setPrice(AskPrice)
            else setPrice(BidPrice)
        }
    }, [AskPrice, price, orderType])

    useEffect(()=>{
        if (orderType === OrderType.Market && !AtCrossing)
            setOrderType(OrderType.Limit)
    }, [AtCrossing, orderType])

    const CleanUP = useCallback(()=>{
        const setZero = [setBidPrice, setAskPrice, setBidVolume, setAskVolume, setPrice]
        setZero.forEach((x)=>{x(0)})
        setOrderType(OrderType.Limit)
    }, [])

    const SymbolOnKeyPress = useCallback((e)=>{
        if (SymbolInput && MainContext.session && e.key === "Enter"){
            const value = e.target.value.trim()
            const valueNum = parseInt(value)
            if (!InputValidation.integer(value) || valueNum >= 100000 || valueNum < 1){
                e.target.value = selectedSymbolRef.current
                return
            }

            selectedSymbolRef.current = e.target.value.trim()
            setSelectedSymbol(selectedSymbolRef.current)
            e.target.value = selectedSymbolRef.current

            MainContext.ws.send(JSON.stringify({
                e: "trader",
                subE: "getQuote",
                sessionID: MainContext.session,
                s: selectedSymbolRef.current
            }))

            CleanUP()
        }
    }, [MainContext.ws, MainContext.session, SymbolInput])

    const [PriceUpClick, PriceDownClick, LotUpClick, LotDownClick] = useMemo(()=>{
        return [
            ()=>{ setPrice((old)=>{ if (old===0) return 1; return ReturnNextUpPrice(old) }) },
            ()=>{ setPrice((old)=>{ return ReturnNextDownPrice(old) }) },
            ()=>{setLot(old=>old+1)},
            ()=>{setLot(old=>(old===1?1:old-1))}
        ]
    },[])

    const [errMsg, setErrMsg] = useState("")

    //Guarding For Pending
	const [MaxAllowBuy, MinAllowSell] = useMemo(()=>{
		let maxAllowBuy = null
		let minAllowSell = null

        let maxPendBuy = null
        let minPendSell = null
		MainContext.pending.forEach((x)=>{
			if (x.symbol === selectedSymbol)
				if (x.side === OrderSide.Buy)
                    maxPendBuy = (maxPendBuy===null?x.price:Math.max(maxPendBuy, x.price))
				else if (x.side === OrderSide.Sell)
                    minPendSell = (minPendSell===null?x.price:Math.min(minPendSell, x.price))
		})

		MainContext.pendingRows.forEach((x)=>{
			if (x.symbol === selectedSymbol)
                minPendSell = (minPendSell===null?x.price:Math.min(minPendSell, x.price))
		})

        MainContext.marketTriggers.forEach((x)=>{
			if (x.symbol === selectedSymbol)
				if (x.side === OrderSide.Buy)
                    maxPendBuy = (maxPendBuy===null?x.price:Math.max(maxPendBuy, x.price))
				else if (x.side === OrderSide.Sell)
                    minPendSell = (minPendSell===null?x.price:Math.min(minPendSell, x.price))
		})

        maxAllowBuy = minPendSell===null?99999.0:ReturnNextDownPrice(new Decimal(minPendSell).div(1000).toNumber())
        minAllowSell = maxPendBuy===null?0.01:ReturnNextUpPrice(new Decimal(maxPendBuy).div(1000).toNumber())
		return [maxAllowBuy, minAllowSell]
	}, [selectedSymbol, MainContext.pending, MainContext.pendingRows, MainContext.marketTriggers])
    const SendPendingOnClick = useCallback((force=false)=>{
        if (selectedSymbolRef.current === ""){
            setErrMsg("Symbol can't be empty")
            return;
        }
        const realPrice = typeof(price)==="string"?parseFloat(price):price
        if (orderType === OrderType.Limit && (isNaN(realPrice) || realPrice <= 0)){
            setErrMsg("Price must be > 0")
            return
        }
        if (orderType === OrderType.Limit && new Decimal(realPrice).mod(GetSpread(realPrice)).toNumber() > 0){
            setErrMsg("Invalid Price.")
            return
        }
        if (isNaN(lot) || lot <= 0){
            setErrMsg("Lot must be > 0")
            return
        }
        if (LotSize <= 0){
            setErrMsg("Lot Size is not loaded.")
            return
        }
        if (orderType === OrderType.Limit && ((isBuy && MaxAllowBuy < price) || (!isBuy && MinAllowSell > price))){
            setErrMsg("Guarding to pend order.")
            return
        }
        if (orderType === OrderType.Market && ((isBuy && MaxAllowBuy !== 99999.0) || (!isBuy && MinAllowSell !== 0.01))){
            setErrMsg("Guarding to pend market order.")
            return
        }

        if (!force && !localStorage.diableForcePendingGuard){
            if ((isBuy && realPrice >= AskPrice) || (!isBuy && realPrice <= BidPrice)){
                setForceSide(isBuy?"Buy":"Sell")
                return
            }
        }
        MainContext.ws.send(JSON.stringify({
            e: "trader",
            subE: "pending",
            sessionID: MainContext.session,
            pending: {
                symbol: selectedSymbolRef.current,
                side: isBuy?OrderSide.Buy:OrderSide.Sell,
                price: Math.round(realPrice * 1000),
                volume: Math.round(lot * LotSize),
                OrderType: orderType
            }
        }))
    }, [MainContext.ws, MainContext.session, BidPrice, AskPrice, isBuy, MaxAllowBuy, MinAllowSell, price, lot, LotSize, orderType])

    return useMemo(()=>{
        return (
            <div style={{flex: 3, display: "flex", flexDirection: "column", justifyContent: 'space-around', borderRight: "1px solid black"}}>
                <Modal show={ForceSide!==""}>
                    <Modal.Header className="bg-danger text-white">
                        <Modal.Title>Force {ForceSide} Warning</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure want to Force {ForceSide}?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={()=>{ SendPendingOnClick(true); setForceSide("") }}>Yes</Button>
                        <Button variant="success" onClick={()=>{ setForceSide("") }}>No, I don't</Button>
                    </Modal.Footer>
                </Modal>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 8}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <InputGroup className="react-draggable-cancel" style={{width: "14rem"}}>
                            <InputGroup.Text style={{fontSize: fontSize, padding:"4px 8px"}}>Symbol</InputGroup.Text>
                            <Form.Control type='text' ref={r=>setSymbolInput(r)} style={{fontSize: fontSize , padding:"4px 8px"}}
                                onKeyPress={SymbolOnKeyPress}
                            />
                        </InputGroup>
                    </div>
                    <Quote fontSize={fontSize} buyColor={buyColor} sellColor={sellColor} BidPrice={BidPrice} AskPrice={AskPrice} BidVolume={BidVolume} AskVolume={AskVolume} />
                </div>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 8}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div style={{width: "14rem"}}>
                            <label>Price</label>
                            <InputGroup className="react-draggable-cancel">
                                <Button style={{fontSize: fontSize}} onClick={PriceDownClick}>-</Button>
                                <Form.Select style={{fontSize: fontSize, padding: "4px 16px 4px 8px"}} value={orderType} onChange={(e)=>{
                                    const value = parseInt(e.target.value)

                                    switch (value){
                                        case OrderType.Market:
                                            setOrderType(value)
                                            setPrice(0)
                                            break;
                                        case OrderType.Limit:
                                            setOrderType(value)
                                            break;
                                        default:
                                            console.log("unsupported order type " + value)
                                    }
                                }}>
                                    {
                                        Object.keys(OrderType).map((x)=>{
                                            if (!AtCrossing && x === "Market") return null
                                            return <option key={x} value={OrderType[x]}>{x}</option>
                                        })
                                    }
                                </Form.Select>
                                <FormControl style={{flex: 1, fontSize: fontSize}} value={price} disabled={orderType === OrderType.Market} onChange={(e)=>{
                                    setPrice((old)=>{
                                        const currentValue = parseFloat(e.target.value)
                                        if (isNaN(currentValue)) { // Input String
                                            if (e.target.value === "") return ""
                                            e.target.value = old
                                            return old
                                        }
                                        if (currentValue < 0) { // Number smaller than 0
                                            e.target.value = old
                                            return old
                                        }
                                        if (e.target.value !== currentValue.toString()) // Uncompleted number
                                            return e.target.value
                                        return currentValue
                                    })
                                }}/>
                                <Button style={{fontSize: fontSize}} onClick={PriceUpClick}>+</Button>
                            </InputGroup>
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div style={{width: "14rem"}}>
                            <label>Lot</label>
                            <InputGroup className="react-draggable-cancel">
                                <Button style={{fontSize: fontSize}} onClick={LotDownClick}>-</Button>
                                <FormControl style={{flex: 1, fontSize: fontSize}} value={lot} onChange={(e)=>{
                                    setLot((old)=>{
                                        const currentValue = parseInt(e.target.value)
                                        if (isNaN(currentValue)){
                                            if (e.target.value === "") return ""
                                            e.target.value = old
                                            return old
                                        }

                                        if (currentValue < 0) {
                                            e.target.value = old
                                            return old
                                        }
                                        return currentValue
                                    })
                                }}/>
                                <Button style={{fontSize: fontSize}} onClick={LotUpClick}>+</Button>
                            </InputGroup>
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div style={{width: "14rem"}}>
                            <InputGroup className="react-draggable-cancel">
                                <InputGroup.Text>Volume</InputGroup.Text>
                                <InputGroup.Text style={{flex: 1}}>{lot * LotSize}</InputGroup.Text>
                            </InputGroup>
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div style={{width: "14rem", color: "red"}}>{errMsg}</div>
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 8}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div className="react-draggable-cancel" style={{display: 'flex', width: "14rem", gap: 8}}>
                            <SwitchableButton style={{flex:1}} value='BUY' color={buyColor} state={isBuy} onClick={()=>{setIsBuy(true)}}/>
                            <SwitchableButton style={{flex:1}} value='SELL' color={sellColor} state={!isBuy} onClick={()=>{setIsBuy(false)}}/>
                            <Button style={{flex:1}} onClick={()=>{SendPendingOnClick(false)}}>Send</Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }, [
        fontSize, AtCrossing, SymbolOnKeyPress, PriceUpClick, PriceDownClick, LotUpClick, LotDownClick, SendPendingOnClick,
        ForceSide, isBuy, price, lot, orderType, errMsg,
        BidPrice, AskPrice, BidVolume, AskVolume, LotSize
    ])
}

function PendingRow({pending, fontSize, buyColor, sellColor, CancelAction, ModifyAction, DisableCancel}){
    const AutoCancelFlag = useRef(false)
    const [AutoCancel, setAutoCancel] = useState(false)
    const [TriggerCancelRef, setTriggerCancelRef] = useState(null)

    const [side, color, price, spreadlength] = useMemo(()=>{
        const side_str = pending.side===OrderSide.Buy?"B":"S"
        const p = new Decimal(pending.price).div(1000).toNumber()
        const spread_length = (""+GetSpread(p)).split('.').slice(-1)[0].length

        return [ side_str, side_str==="B"?buyColor:sellColor, p, spread_length ]
    }, [pending, buyColor, sellColor])

    useEffect(()=>{
        if (pending && TriggerCancelRef){
            if (pending.trigger_cancel_k)
                TriggerCancelRef.value = pending.trigger_cancel_k / 1000
            else
                TriggerCancelRef.value = 0
        }
    }, [pending, TriggerCancelRef])

    return useMemo(()=>{
        return (
            <tr>
                <td>{pending.ClientOrderID}</td>
                <td>{pending.rowID}</td>
                <td style={{textAlign: "center"}}>{pending.symbol}</td>
                <td style={{textAlign: "center", color: color}}>{side}</td>
                <td style={{textAlign: "right"}}>{price.toFixed(spreadlength)}</td>
                <td style={{textAlign: "right"}}>{pending.volume.toLocaleString()}</td>
                <td>
                    <div className="d-grid">
                        <Button variant={AutoCancel?"success":"danger"} style={{fontSize: fontSize, padding: "0px 8px"}} disabled={DisableCancel}
                            onClick={()=>{
                                if (AutoCancelFlag.current !== false) {
                                    clearTimeout(AutoCancelFlag.current)
                                    AutoCancelFlag.current = false
                                    setAutoCancel(false)
                                    return
                                }

                                const time = new Date()
                                const [year, month, day, hour, minutes] = [time.getFullYear(), time.getMonth(), time.getDate(), time.getHours(), time.getMinutes()]
                                
                                // 09:15 ~ 09:30, set a cancel timer
                                if (hour === 9 && minutes >= 15 && minutes < 30){
                                    const time0930 = new Date(year, month, day, 9, 30, 0)
                                    AutoCancelFlag.current = setTimeout(()=>{ CancelAction(pending.rowID) }, time0930.getTime() - time.getTime())
                                    setAutoCancel(true)
                                    return
                                }

                                // if (hour === 12 && minutes < 30){
                                //     const time1300 = new Date(year, month, day, 13, 0, 0)
                                //     setAutoCancel(true)
                                //     AutoCancelFlag.current = setTimeout(()=>{ CancelAction(pending.rowID) }, time1300.getTime() - time.getTime())
                                //     return
                                // }
                                
                                CancelAction(pending.rowID)
                            }}
                        >
                            {AutoCancel?"Auto.Cancel":"Cancel"}
                        </Button>
                    </div>
                </td>
                <td>
                    {
                        pending.auto_cancel === -1 ? "Disabled" :
                        <div className="d-grid">
                            <Button style={{fontSize: fontSize, padding: "0px 8px"}} disabled={pending.auto_cancel===-1} onClick={()=>{
                                ModifyAction(pending.rowID, PendingModifyType.AutoCancel, -1)
                            }}>
                                {pending.auto_cancel}
                            </Button>
                        </div>
                    }
                </td>
                <td>
                    <InputGroup>
                        <FormControl ref={r=>setTriggerCancelRef(r)} type="text" style={{fontSize: fontSize, padding: "0px 8px", width: 40}}
                            onBlur={(e)=>{ e.target.value = pending.trigger_cancel_k / 1000 }}
                            onKeyPress={(e)=>{
                                if (e.key !== "Enter") return
                                const value = parseInt(e.target.value)
                                if (isNaN(value) || value < 0){
                                    e.target.value = pending.trigger_cancel_k
                                    return 
                                }
                                ModifyAction(pending.rowID, PendingModifyType.TriggerCancelK, value * 1000)
                            }}
                        />
                        <InputGroup.Text style={{fontSize: fontSize, padding: "0px 8px"}}>K</InputGroup.Text>
                    </InputGroup>
                </td>
                <td>
                    <InputGroup>
                        <Button style={{fontSize: fontSize, padding: "0px 8px"}} onClick={()=>{
                            if (pending.auto_pend_spread > 1)
                                ModifyAction(pending.rowID, PendingModifyType.AutoPendSpread, pending.auto_pend_spread - 1)
                        }}>-</Button>
                        <span style={{flex: 1, width: 10, textAlign: "center", fontSize: fontSize, padding: "0px 4px", backgroundColor: "#e9ecef", border: "1px solid #ced4da"}}>{pending.auto_pend_spread}</span>
                        <Button style={{fontSize: fontSize, padding: "0px 8px"}} onClick={()=>{
                            if (pending.auto_pend_spread < 99)
                                ModifyAction(pending.rowID, PendingModifyType.AutoPendSpread, pending.auto_pend_spread + 1)
                        }}>+</Button>
                    </InputGroup>
                </td>
                <td>
                    <div className="d-grid">
                        <Button style={{fontSize: fontSize, padding: "0px 8px"}}
                            variant={(pending.auto_pend??false)?"success":"secondary"}
                            onClick={()=>{
                                ModifyAction(pending.rowID, PendingModifyType.AutoPend, !pending.auto_pend)
                            }}
                        >
                            {pending.auto_pend?"Running":"Stopped"}
                        </Button>
                    </div>
                </td>
            </tr>
        )
    }, [pending, pending.volume, side, color, price, spreadlength, AutoCancel, CancelAction, ModifyAction, DisableCancel])
}

function StatusPanel({context, fontSize, color, DisableCancel}){
    const MainContext = useContext(context)

    const {buyColor, sellColor} = useMemo(()=>{return color}, [color])

    const pending = useMemo(()=>{ return MainContext.pending }, [MainContext.pending])

    const CancelAction = useCallback((rowID)=>{
        if (MainContext.ws){
            MainContext.ws.send(JSON.stringify({
                e: "trader",
                subE: "cancelPending",
                sessionID: MainContext.session,
                pending: { rowID: rowID }
            }))
        }
    }, [MainContext.ws, MainContext.session])
    const ModifyAction = useCallback((rowID, modifyType=PendingModifyType.AutoPend, value=0)=>{
        const message = {
            e: "trader",
            subE: "modifyPending",
            sessionID: MainContext.session,
            pendingModifyType: modifyType,
            pending: {
                rowID: rowID,
                auto_pend: false,
                auto_pend_spread: 0,
                trigger_cancel_k: 0,
                auto_cancel: -1
            }
        }

        if (modifyType === PendingModifyType.AutoPend)
            message.pending.auto_pend = value
        else if (modifyType === PendingModifyType.AutoPendSpread)
            message.pending.auto_pend_spread = value
        else if (modifyType === PendingModifyType.TriggerCancelK)
            message.pending.trigger_cancel_k = value
        else if (modifyType === PendingModifyType.AutoCancel)
            message.pending.auto_cancel = value

        if (MainContext.ws) {
            MainContext.ws.send(JSON.stringify(message))
        }
    }, [MainContext.ws, MainContext.session])

    return useMemo(()=>{
        return (
            <div className="noScrollbar" style={{flex: 9, paddingLeft: 10, paddingTop: 10, height: "100%", overflowY: "scroll"}}>
                <Table size="sm" bordered striped>
                    <thead>
                        <tr style={{fontSize: fontSize, textAlign: 'center'}}>
                            <td>ClientOID</td>
                            <td>RowID</td>
                            <td>Symbol</td>
                            <td>Side</td>
                            <td>Price</td>
                            <td>Volume</td>
                            <td>Cancel</td>
                            <td>C.Timer</td>
                            <td>T.Cancel</td>
                            <td>AP.Spread</td>
                            <td>Auto Pend</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            pending.map((x)=>(
                                <PendingRow key={x.ClientOrderID}
                                    pending={x} CancelAction={CancelAction} ModifyAction={ModifyAction} DisableCancel={DisableCancel}
                                    fontSize={fontSize} buyColor={buyColor} sellColor={sellColor}
                                />
                            ))
                        }
                    </tbody>
                </Table>
            </div>
        )
    }, [fontSize, buyColor, sellColor, pending, CancelAction, ModifyAction, DisableCancel])
}

function PendingPanel({onClose, context}){
    const MainContext = useContext(context)

    const color = useMemo(()=>{
		const {buyColor, sellColor, AsColor, callColor, putColor} = MainContext.clientSetting.setting
		return {buyColor, sellColor, AsColor, callColor, putColor}
	}, [
        MainContext.clientSetting.setting.buyColor,
        MainContext.clientSetting.setting.sellColor,
        MainContext.clientSetting.setting.AsColor,
        MainContext.clientSetting.setting.callColor,
        MainContext.clientSetting.setting.putColor,
    ])

    const fontSize = useMemo(()=>{
		return MainContext.clientSetting.setting.fontSize
	}, [MainContext.clientSetting.setting.fontSize])

    const currTime = useMemo(()=>{ return MainContext.currTime }, [MainContext.currTime])
    const [AtCrossing, DisableCancel] = useMemo(()=>{
        const [hour, minutes] = [ currTime.getHours(), currTime.getMinutes() ]
        return [
            (hour === 9 && minutes < 20) || hour < 9 || hour === 16,
            /*(hour === 9 && minutes >= 15 && minutes < 30) ||*/ (hour === 12 && minutes < 30) || (hour === 16 && minutes > 6)
        ]
    }, [currTime])

    return useMemo(()=>{
        return (
            <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
                <div style={{width: "100%", position: "sticky", display: "flex", backgroundColor: "#0d6efd"}}>
                    <span className="text-white" style={{flex: 1, fontSize: 18, padding: 2, paddingLeft: 4}}>Pending Panel</span>
                    <div style={{flex:0, backgroundColor: "#dc3545"}}>
                        <CloseButton className="react-draggable-cancel" variant="white" style={{fontSize: 18, padding: 6}} onClick={onClose}/>
                    </div>
                </div>
                <div className="react-draggable-cancel" style={{paddingLeft: 10, paddingRight: 10, height: "100%", overflow: "hidden", overflowY: "auto", display: "flex"}}>
                    <ControlPanel context={context} fontSize={fontSize} color={color} AtCrossing={AtCrossing}/>
                    <StatusPanel context={context} fontSize={fontSize} color={color} DisableCancel={DisableCancel}/>
                </div>
            </div>
        )
    }, [onClose, context, fontSize, color, AtCrossing, DisableCancel])
}

export default PendingPanel