import React, { useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { ComponentProps } from '../MainPage'

import { Container } from 'react-bootstrap'

import TraderW2 from './TradeW2List/TraderW2'

function TraderW2List({onClose, item, context}: ComponentProps){
	const {ws, session, clientSetting} = useContext(context)
	const {id: pageID} = useParams()

	const rowKeys = useMemo(()=>{
		if (typeof item.rows === "undefined")
			item.rows = 5

		const row = []
		for (let i = 0; i < item.rows; i++)
			row.push(pageID + "-W" + item["key"] + "-" +(i+1))

		return row
	}, [item, pageID])

	const color = useMemo(()=>{
		const buyColor  = clientSetting.setting.buyColor
		const sellColor = clientSetting.setting.sellColor
		const AsColor   = clientSetting.setting.AsColor
		const callColor = clientSetting.setting.callColor
		const putColor  = clientSetting.setting.putColor
		return {buyColor, sellColor, AsColor, callColor, putColor}
	}, [clientSetting.setting.buyColor, clientSetting.setting.sellColor, clientSetting.setting.AsColor, clientSetting.setting.callColor, clientSetting.setting.putColor])

	const volumeSetting = useMemo(()=>{
		return {
			maxVolume: clientSetting.setting.maxVolume,
			defaultVolume: clientSetting.setting.defaultVolume
		}
	}, [clientSetting.setting.maxVolume, clientSetting.setting.defaultVolume])

	const shortcutKeySetting = useMemo(()=>{
		const capAllKey = clientSetting.setting.capAllKey
		const capBidKey = clientSetting.setting.capBidKey
		const capAskKey = clientSetting.setting.capAskKey
		const addKey = clientSetting.setting.addKey
		const minusKey = clientSetting.setting.minusKey
		const triggerBuyKey = clientSetting.setting.triggerBuyKey
		const triggerSellKey = clientSetting.setting.triggerSellKey
		const triggerAsKey = clientSetting.setting.triggerAsKey
		return {capAllKey, capBidKey, capAskKey, addKey, minusKey, triggerBuyKey, triggerSellKey, triggerAsKey}
		// eslint-disable-next-line
	}, [clientSetting.setting.shortcutKeyChange])

    return useMemo(()=>{
        return (
			<>
			<span className="react-close-handle react-draggable-cancel" onClick={()=>{
				rowKeys.forEach((x)=>{
					ws.send(JSON.stringify({e: "trader", subE: "delRow", sessionID: session, rowKey: x}))
				})
				onClose()
			}}>x</span>
			<Container className="noScrollbar" fluid style={{display:"flex", flexDirection: "column", overflowY: "auto", padding: 10, height: "100%"}}>
				{
					rowKeys.map((key)=>{
						return <TraderW2 key={key} rowKey={key} color={color} volumeSetting={volumeSetting} shortcutKeySetting={shortcutKeySetting} context={context}/>
					})
				}
			</Container>
			</>
		)
	}, [onClose, context, rowKeys, color, volumeSetting, shortcutKeySetting, ws, session])
}

export default TraderW2List