function TimeString(datetime: Date, isMillisecond: boolean = false): string {
    if (isMillisecond)
        return ("0"+datetime.getHours()).slice(-2) + ":" + ("0"+datetime.getMinutes()).slice(-2) + ":" + ("0"+datetime.getSeconds()).slice(-2) + "." + ("000" + datetime.getMilliseconds()).slice(-3)
    return ("0"+datetime.getHours()).slice(-2) + ":" + ("0"+datetime.getMinutes()).slice(-2) + ":" + ("0"+datetime.getSeconds()).slice(-2)
}

function CurrentTimeString(): string {
    const nowTime = new Date()
    return TimeString(nowTime)
}

export { CurrentTimeString, TimeString }