import { useMemo, useEffect, useContext, useState } from 'react'

import { Decimal } from 'decimal.js'

import { TimeString } from '../../../functions/DateHandle'
import { MainContextData } from '../../MainPage'
import { HistoryInfo } from './History'

import { Form, Table } from 'react-bootstrap'

import { OrderSide, OrderStatus } from '../../../functions/Enum'

interface RecordRowProps {
	info: HistoryInfo
}

function RecordRow({info}: RecordRowProps){
	const PnLColor = useMemo(()=>{
		if ("Reason" in info) return "#9ec5fe"

		if (info.PnL > 0)
			return "#a3cfbb"
		else if (info.PnL < 0)
			return "#f1aeb5"
		else
			return "#9ec5fe"
	}, [info])

	return useMemo(()=>{
		const time = TimeString(new Date(info.Time))

		if ("Reason" in info) return <></>

		return (
			<tr>
				<td>{time}</td>
				<td style={{textAlign: "center"}}>{info.D}</td>
				<td style={{textAlign: "center"}}>{info.Side}</td>
				<td style={{textAlign: "right"}}>{info.Cost}</td>
				<td style={{textAlign: "right"}}>{info.Pts}</td>
				<td style={{textAlign: "right", backgroundColor: PnLColor}}>{Math.round(info.PnL*1000)/1000}</td>
			</tr>
		)
	}, [info, PnLColor])
}

interface RecordTableProps {
	context: React.Context<MainContextData>
}
function RecordTable({context}: RecordTableProps){
	const MainContext = useContext(context)
	const [symbol, setSymbol] = useState("")
	const [history, setHistory] =  useState<HistoryInfo[]>([])
	const [inputSymbol, setInputSymbol] = useState<HTMLInputElement|null>(null)

	const color = useMemo(()=>{
		const {buyColor, sellColor} = MainContext.clientSetting.setting
		return {buyColor, sellColor}
	}, [MainContext.clientSetting.setting.buyColor, MainContext.clientSetting.setting.sellColor])

	useEffect(()=>{
		if (inputSymbol)
			inputSymbol.value = symbol
	}, [symbol, inputSymbol])

	useEffect(()=>{
		if (MainContext.history.length > 0){
			let Asset : {[key:string]: {Cost: number, Pts: number}} = {}
			let newHistory: HistoryInfo[] = []
			MainContext.history.forEach((x)=>{
				const time = typeof(x.OrderTime) === "string"?Date.parse(x.OrderTime):x.OrderTime

				if (x.Status === OrderStatus.Full || x.Status === OrderStatus.Partial){
					if (!Asset[x.D])
						Asset[x.D] = {Cost: 0, Pts: 0}

					const Cost = new Decimal(Asset[x.D].Cost)
					const Pts = Asset[x.D].Pts
					if (x.OSide === OrderSide.Buy){
						Asset[x.D].Cost = Cost.mul(Pts).add(new Decimal(x.Cost).mul(x.Shares)).div(Pts + x.Shares).toNumber()
						Asset[x.D].Pts = Pts + x.Shares
						newHistory.push({Time: time, D: x.D, Cost: x.Cost, Side: "B", Pts: x.Shares, PnL: 0})
					}else{
						const PnL = new Decimal(x.Cost).minus(Cost).mul(x.Shares).toNumber()
						Asset[x.D].Pts = Pts - x.Shares
						if (Asset[x.D].Pts === 0)
							Asset[x.D].Cost = 0
						newHistory.push({Time: time, D: x.D, Cost: x.Cost, Side: "S", Pts: x.Shares, PnL: PnL})
					}
				}
			})
			setHistory(newHistory)
		}
	}, [MainContext.history])

	return useMemo(()=>{
		return (
			<Table className="react-draggable-cancel" style={{fontSize: 12, position: "relative", top: 8}} bordered striped size="sm">
				<thead>
					<tr style={{fontWeight: "bold"}}>
						<td>Time</td>
						<td style={{width: "fit-content"}}>
							<Form.Control ref={setInputSymbol} placeholder={"D"} type="text" onKeyPress={(e)=>{
								if (e.code === "Enter" || e.code === "NumpadEnter")
									setSymbol(e.currentTarget.value)
							}} style={{width: 45, fontSize: 12, padding: "0px 2px"}}/>
						</td>
						<td>Side</td>
						<td>Cost</td>
						<td>Pts</td>
						<td>PnL</td>
					</tr>
				</thead>
				<tbody>
					{
						history.filter((x)=>{
							if (x.D !== symbol && symbol !== "")
								return false
							return true
						}).map((x, i)=>{
							return (
								<RecordRow key={i} info={x}/>
							)
						}).reverse()
					}
				</tbody>
			</Table>
		)
	}, [history, symbol])
}

export default RecordTable