import { useState, useCallback, useEffect } from "react"

import { Button } from 'react-bootstrap'
import Form from "react-bootstrap/Form"
import Modal from "react-bootstrap/Modal"
import { LayoutItem } from "../../MainPage"

interface CheapUTraderSetRowsProps {
    show: boolean
    setShow: React.Dispatch<React.SetStateAction<boolean>>
    setLayout: React.Dispatch<React.SetStateAction<LayoutItem[]>>
} 
function CheapUTraderSetRows({show, setShow, setLayout}: CheapUTraderSetRowsProps){
    const [rows, setRows] = useState<string|null>(null)
    const [errMsg, setErrMsg] = useState("")

    const formHandle = useCallback((e)=>{
        e.preventDefault();
        e.stopPropagation();
        
        const value = parseInt(rows??"")

        if (/^[\d]*$/.test(rows??"") === false || value < 0 || isNaN(value)){
            setErrMsg("It is Not a positive integer.")
            return
        }
        if (value >= 100) {
            setErrMsg("Rows number can't be more than 99.")
            return
        }
        if (value < 1) {
            setErrMsg("Rows number can't be less than 1.")
            return
        }

        setLayout((last)=>{
            let maxKey = 0
            let maxY = 0
            
            last.forEach((x)=>{
                if (maxY < x["data-grid"].y + x["data-grid"].h)
                    maxY = x["data-grid"].y + x["data-grid"].h
                if (maxKey < x["key"])
                    maxKey = x["key"]
            })

            return [...last, {"key": maxKey+1, "data-grid": {x: 0, y: maxY, w:8, h: 96, minW: 8, minH: 20}, "component": "CheapUTrader", "rows": value}]
        })

        setShow(false)
    }, [rows, setShow, setLayout])

    useEffect(()=>{
        if (!show)
            setErrMsg("")
    }, [show])

    return (
        <Modal show={show} onHide={()=>{setShow(false)}}>
            <Modal.Header>
                <Modal.Title>Setting How Many Rows</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={formHandle} noValidate>
                    <Form.Group className="mb-2">
                        <Form.Label>How many rows do you want?</Form.Label>
                        <Form.Control type="text" isInvalid={errMsg!==""} onChange={(v)=>{setRows(v.currentTarget.value)}}/>
                        <Form.Control.Feedback type="invalid">{errMsg}</Form.Control.Feedback>
                    </Form.Group>
                    <Button type="submit">Create Rows</Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default CheapUTraderSetRows