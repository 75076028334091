import { useEffect, useState } from 'react'
import { Table, ProgressBar } from 'react-bootstrap'
import { ParseBigNum } from '../../../functions/NumberProcessor';
import { GetSpreadTableType, ReturnNextNDownPrice, ReturnNextNUpPrice } from '../../../functions/StockHandle';

function BidBook(props){
	return (
		<Table size="sm" bordered striped hover style={{marginBottom: 0}}>
			<thead>
				<tr className="bg-primary text-white" style={{fontSize: 16, lineHeight: "0.8em"}}>
					<td colSpan={2} style={{textAlign: "center"}}>Bid</td>
				</tr>
				<tr style={{fontSize: 14, lineHeight: "0.95em"}}>
					<td>Price</td>
					<td style={{textAlign: "right"}}>Amount</td>
				</tr>
			</thead>
			<tbody>
				{
					props.order.slice(0, 10).map((x, i) => {
						let color = "black";
						if (x.price === 0)
							return (
								<tr key={i} style={{fontSize: 14, lineHeight: "0.8em"}}>
									<td>&nbsp;</td>
									<td> </td>
								</tr>
							)
						if (x.amount > 100000){
							color = "red"
						}else if (x.amount > 10000){
							color = "blue"
						}else if (x.amount > 100){
							color = "green"
						}
						return (
							<tr key={i} style={{fontSize: 14, lineHeight: "0.8em"}}>
								<td style={{color: color}}>{x.price}</td>
								<td style={{textAlign: "right", color: color}}>{ParseBigNum(x.amount)}</td>
							</tr>
						)	
					})
				}
			</tbody>
		</Table>
	)
}

function AskBook(props){
	return (
		<Table size="sm" bordered striped hover style={{marginBottom: 0}}>
			<thead>
				<tr className="bg-danger text-white" style={{fontSize: 16, lineHeight: "0.8em"}}>
					<td colSpan={2} style={{textAlign: "center"}}>Ask</td>
				</tr>
				<tr style={{fontSize: 14, lineHeight: "0.95em"}}>
					<td>Price</td>
					<td style={{textAlign: "right"}}>Amount</td>
				</tr>
			</thead>
			<tbody>
				{
					
					props.order.slice(0, 10).map((x, i) => {
						let color = "black";
						if (x.price === 0)
							return (
								<tr key={i} style={{fontSize: 14, lineHeight: "0.8em"}}>
									<td>&nbsp;</td>
									<td> </td>
								</tr>
							)
						if (x.amount > 100000){
							color = "red"
						}else if (x.amount > 10000){
							color = "blue"
						}else if (x.amount > 100){
							color = "green"
						}
						return (
							<tr key={i} style={{fontSize: 14, lineHeight: "0.8em"}}>
								<td style={{color: color}}>{x.price}</td>
								<td style={{textAlign: "right", color: color}}>{ParseBigNum(x.amount)}</td>
							</tr>
						)	
					})
				}
			</tbody>
		</Table>
	)
}

function OrderBook(props){
	const [bidOrder, setBidOrder] = useState([])
	const [askOrder, setAskOrder] = useState([])

	const [progress, setProgress] = useState([50, 50])

	useEffect(()=>{
		let arr = []
		setProgress([50, 50])
		for (let i = 0; i < 10; i++){
			arr.push({price: 0, amount: 0})
		}
		setBidOrder(<BidBook order={arr}/>)
		setAskOrder(<AskBook order={arr}/>)
	}, [props.symbol])

	useEffect(()=>{
		if (props.quoteData){
			(async () => {
				const spread_table_type = GetSpreadTableType(props.quoteData[1])
				let bidAmount = 0
				let askAmount = 0
				let bids = []
				let asks = []
				for (let i = 0; i < (props.quoteData.length - 5) / 2; i++){
					const parseBid = parseInt(props.quoteData[4+i*2])
					const parseAsk = parseInt(props.quoteData[5+i*2])
					bidAmount += parseBid
					askAmount += parseAsk
					bids.push({price: ReturnNextNDownPrice(parseFloat(props.quoteData[2]), i, spread_table_type), amount: parseBid})
					asks.push({price: ReturnNextNUpPrice(parseFloat(props.quoteData[3]), i, spread_table_type), amount: parseAsk})
				}
				for (let i = 0; i < 20 - bids.length; i++)
					bids.push({price: 0, amount: 0})
				for (let i = 0; i < 20 - asks.length; i++)
					asks.push({price: 0, amount: 0})

				let total = bidAmount + askAmount
				setProgress([bidAmount/total*100, askAmount/total*100])
				setBidOrder(<BidBook order={bids}/>)
				setAskOrder(<AskBook order={asks}/>)
			})()
		}
	}, [props.quoteData])

	return (
		<>
			<ProgressBar className="mb-2" style={{fontSize: 14, height: 16, borderRadius: 4}}>
				<ProgressBar variant="primary" now={progress[0]} label={Math.round(progress[0]*100)/100+"%"} key={1} />
				<ProgressBar variant="danger" now={progress[1]} label={Math.round(progress[1]*100)/100+"%"} key={2} />
			</ProgressBar>
			<div style={{display: "flex", flexDirection: "row"}}>
				<div style={{flex:1, paddingRight: 4}}>
					{bidOrder}
				</div>
				<div style={{flex:1, paddingLeft: 4}}>
					{askOrder}
				</div>
			</div>
		</>
	)
}

export default OrderBook;