function calculateMA(dayCount, data) {
	let result = [];
	for (let i = 0, len = data.length; i < len; i++) {
		if (i < dayCount) {
			result.push('-');
			continue;
		}
		let sum = 0;
		for (let j = 0; j < dayCount; j++) {
			sum += +data[i - j][1];
		}
		result.push(Math.round((sum / dayCount)*1000000000)/1000000000);
	}
	return result;
}

function parseTime(time){
    return parseInt(time) * 1000
}

function parseIntervalToMinutes(interval){
    let intervalBase = parseInt(interval.substring(0, "123".substring(0,"123".length-1)))
    if (interval.slice(-1) === "h"){
        intervalBase = intervalBase * 60;
    }else if (interval.slice(-1) === "d"){
        intervalBase = intervalBase * 24 * 60;
    }
    return intervalBase;
}

function parseStartTime(time, interval){
    let intervalBase = parseInt(interval.substring(0, "123".substring(0,"123".length-1)))
    if (interval.slice(-1) === "s"){
        intervalBase = intervalBase * 1000;
    }else if (interval.slice(-1) === "m"){
        intervalBase = intervalBase * 60 * 1000;
    }else if (interval.slice(-1) === "h"){
        intervalBase = intervalBase * 60 * 60 * 1000;
    }else if (interval.slice(-1) === "d"){
        intervalBase = intervalBase * 24 * 60 * 60 * 1000;
    }
    return time - (time % intervalBase)
}

function shortTime(time, interval="1m"){
    const date = new Date(time)
    const hour = ("0"+date.getHours()).slice(-2)
    const minutes = ("0"+date.getMinutes()).slice(-2)
    const seconds = ("0"+date.getSeconds()).slice(-2)

    return hour+":"+minutes+":"+seconds
}

function splitData(chartData){
    let splitedData = {time: [], chart: [], amount: []}

    chartData.forEach((x) => {
        splitedData.time.push(shortTime(x[0]))
        splitedData.chart.push(x.slice(1, 5))
        splitedData.amount.push(x[5])
    });

    return splitedData;
}

export { calculateMA, parseTime, parseStartTime, parseIntervalToMinutes, shortTime, splitData }