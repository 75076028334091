import React, { useState, useEffect, useRef, useMemo, useContext, useCallback } from 'react'
import { Decimal } from 'decimal.js'

import { StatusType } from '../../../api/enum'
import { OrderSide, OrderStatus, RowModifyType } from '../../../functions/Enum'
import { GetSpread, GetSpreadTableType, ReturnNextDownPrice, ReturnNextUpPrice, ReturnNextNUpPrice } from '../../../functions/StockHandle'
import Sounds from '../../../functions/SoundHandler'
import { CustomVWAP } from '../../../functions/CustomMath'
import ErrorMsg from '../../../functions/ErrorMsg'

import { Button } from 'react-bootstrap'
import Form from "react-bootstrap/Form"
import InputGroup from 'react-bootstrap/InputGroup'
import Card from "react-bootstrap/Card"
import SwitchableButton from '../global_component/SwitchableButton'

import Symbol from './CheapUTraderV2/Symbol'

function returnStyle(color, fontSize, minWidth=null){
	let style = {}
	style.InputClass = "react-draggable-cancel form-control border-"+color
	style.InputStyle = {padding: "0 4px", fontSize: fontSize, borderColor: color}
	style.InputButtonStyle = {flex: 1, padding: "0 4px", fontSize: fontSize, borderColor: color, color: color}
	style.LabelClass = "text-white"
	style.LabelStyle = {padding: "0 4px", fontSize: fontSize, backgroundColor: color, borderColor: color, minWidth: minWidth}
	return style
}

function Asset({fontSize, SpreadLength, asset, DBidPrice}){
	const [LabelStyle, ValueStyle] = useMemo(()=>{
		return [{fontSize: fontSize, padding: "0px 4px"}, {fontSize: fontSize, padding: "0px 4px", flex: 1}]
	}, [fontSize])

	const FPnL = useMemo(()=>{
		return new Decimal(DBidPrice).minus(new Decimal(asset.Cost)).toNumber() * asset.Pts
	}, [asset, DBidPrice])

	return useMemo(()=>{

		return (
			<div style={{display: "flex", gap: 4}}>
				<InputGroup style={{display: "flex", minWidth: 80}}>
					<InputGroup.Text style={LabelStyle}>Pts</InputGroup.Text>
					<InputGroup.Text style={ValueStyle}>{asset.Pts / 1000}</InputGroup.Text>
				</InputGroup>
				<InputGroup style={{display: "flex", minWidth: 95}}>
					<InputGroup.Text style={LabelStyle}>Cost</InputGroup.Text>
					<InputGroup.Text style={ValueStyle}>{asset.Cost.toFixed(SpreadLength)}</InputGroup.Text>
				</InputGroup>
				<InputGroup style={{display: "flex", minWidth: 95}}>
					<InputGroup.Text style={LabelStyle}>FPnL</InputGroup.Text>
					<InputGroup.Text style={ValueStyle}>{FPnL.toFixed(0)}</InputGroup.Text>
				</InputGroup>
				<InputGroup style={{display: "flex", minWidth: 95}}>
					<InputGroup.Text style={LabelStyle}>PnL</InputGroup.Text>
					<InputGroup.Text style={ValueStyle}>{asset.Pnl.toFixed(0)}</InputGroup.Text>
				</InputGroup>
			</div>
		)
	}, [LabelStyle, ValueStyle, SpreadLength, asset, FPnL])
}

function ASControl({
	fontSize, disableInput, SpreadLength,
	ap, at, setAT, AsLight, setAsLight, isPending, disableASLight,
	AsStyle, AsColor
}){
	const [ATInput, setATInput] = useState(null)

	useEffect(()=>{
		if (ATInput){
			ATInput.value = at
		}
	}, [ATInput, at])

	const ATKeyPress = useCallback((e)=>{
		const value = e.target.value.trim()
		if (value !== ""){
			const parsedValue = parseInt(value)
			if (parsedValue >= 0){
				setAT(parsedValue)
				return
			}
		}
		else if (e.target.value === ""){
			setAT(0)
			return
		}
		e.target.value = at
	}, [at])

	return useMemo(()=>{
		return (
			<div style={{display: "flex", gap: 4}}>
				<SwitchableButton value="AS" disabled={disableInput || disableASLight}
					color={isPending?"rgb(254, 203, 161)":AsColor} style={{fontSize: fontSize, padding: "0px 4px", minWidth: 80, flex: 0}} state={AsLight} setState={setAsLight}
				/>
				<InputGroup style={{width: 80, display:"flex"}}>
					<InputGroup.Text className={AsStyle.LabelClass} style={AsStyle.LabelStyle}>AP</InputGroup.Text>
					<InputGroup.Text className={AsStyle.InputClass} style={AsStyle.InputStyle}>{isPending?ReturnNextNUpPrice(ap, at).toFixed(SpreadLength):""}</InputGroup.Text>
				</InputGroup>
				<InputGroup style={{width: 60, display:"flex"}}>
					<InputGroup.Text className={AsStyle.LabelClass} style={AsStyle.LabelStyle}>AT</InputGroup.Text>
					<Form.Control disabled={disableInput} type='text' ref={r=>setATInput(r)} className={AsStyle.InputClass} style={AsStyle.InputStyle}
						onBlur={ATKeyPress}
					/>
				</InputGroup>
			</div>
		)
	}, [fontSize, disableInput, disableASLight, SpreadLength, AsLight, ap, at, AsStyle, AsColor, isPending, ATKeyPress])
}

function Conditions({fontSize, selectedSymbol, disableInput, disableCondition, AddErrorMsg, condition, setCondition}){
	return useMemo(()=>{
		return (
			<div style={{display: "flex", minWidth: 'fit-content', gap: "4px"}}>
				{
					Object.keys(condition).filter((x)=>x!=="SMA").map((x)=>{
						return (
							<InputGroup key={x} style={{minWidth: "fit-content"}}>
								<InputGroup.Text style={{fontSize: fontSize, padding: "0px 4px"}}>{x}</InputGroup.Text>
								<InputGroup.Text style={{fontSize: fontSize, padding: "0px 4px"}}>
									<Form.Check checked={condition[x]} disabled={disableInput} onChange={(e)=>{
										if (disableCondition){
											AddErrorMsg(Date.now(), selectedSymbol, ErrorMsg.NotAllowChangeCondition)
											return
										}

										setCondition((last)=>{
											const newCondition = {}
											Object.keys(last).forEach((y)=>{
												newCondition[y] = last[y]
											})
											newCondition[x] = !last[x]
											return newCondition
										})
									}}/>
								</InputGroup.Text>
							</InputGroup>
						)
					})
				}
			</div>
		)
	}, [fontSize, selectedSymbol, disableInput, disableCondition, AddErrorMsg, condition])
}

function SMASelect({fontSize, selectedSymbol, disableInput, disableCondition, AddErrorMsg, SelectedSMA, setSelectedSMA}){
	return useMemo(()=>{
		return (
			<InputGroup style={{flex:0, minWidth: "fit-content"}}>
				<InputGroup.Text style={{fontSize: fontSize, padding: "0px 4px"}}>VWAP</InputGroup.Text>
				{
					Object.keys(SelectedSMA).map((x)=>{
						return (
							<React.Fragment key={x}>
								<InputGroup.Text key={x} style={{fontSize: fontSize, padding: "0px 4px"}}>{x}</InputGroup.Text>
								<InputGroup.Text key={x+"Input"} style={{fontSize: fontSize, padding: "0px 4px"}}>
									<Form.Check disabled={disableInput} checked={SelectedSMA[x]} onChange={()=>{
										if (disableCondition){
											AddErrorMsg(Date.now(), selectedSymbol, ErrorMsg.NotAllowChangeCondition)
											return
										}

										setSelectedSMA((last)=>{
											const newSelection = {}
											Object.keys(last).forEach((y)=>{
												newSelection[y] = last[y]
											})
											newSelection[x] = !last[x]
											return newSelection
										})
									}}/>
								</InputGroup.Text>
							</React.Fragment>
						)
					})
				}
			</InputGroup>
		)
	}, [fontSize, selectedSymbol, disableInput, disableCondition, AddErrorMsg, SelectedSMA])
}

function UserInputs({
	selectedSymbol, Side, fontSize, shortcutKeySetting, SpreadLength, sideStyle, sideColor,
	disableInput, disableLight, disableFLight, disableCondition, AddErrorMsg,
	triggerVolume, setTriggerVolume, triggerPrice, setTriggerPrice, volume, setVolume, price, setPrice,
	MinTO, setMinTO, Multiplier, setMultiplier, SelectedSMA, setSelectedSMA, Condition, setCondition,
	userLight, UserLightBtnOnClick, forceLight, setForceLight,
	UBidPrice, UAskPrice, DBidPrice, DAskPrice, LotSize, maxVolume
}){
	const [BVInput, setBVInput] = useState(null)
	const [BMinTOInput, setBMinTOInput] = useState(null)
	const [BMultiplierInput, setBMultiplierInput] = useState(null)

	const [DefaultUPrice, DefaultDPrice] = useMemo(()=>{
		return [Side==="BUY"?UAskPrice:UBidPrice, Side==="BUY"?DAskPrice:DBidPrice]
	}, [Side, UBidPrice, UAskPrice, DBidPrice, DAskPrice])

	useEffect(()=>{
		if (BVInput){
			BVInput.value = triggerVolume
		}
	}, [triggerVolume, BVInput])

	useEffect(()=>{
		if (BMinTOInput){
			BMinTOInput.value = MinTO
		}
	}, [MinTO, BMinTOInput])

	useEffect(()=>{
		if (BMultiplierInput){
			BMultiplierInput.value = Multiplier
		}
	}, [Multiplier, BMultiplierInput])

	const TiggerPriceKeyPress = useCallback((e)=>{
		if (e.code === shortcutKeySetting.capAllKey)
			setTriggerPrice(DefaultUPrice)
		else if (e.code === shortcutKeySetting.capBidKey)
			setTriggerPrice(UBidPrice)
		else if (e.code === shortcutKeySetting.capAskKey)
			setTriggerPrice(UAskPrice)
		else if (e.code === shortcutKeySetting.addKey)
			setTriggerPrice((currentValue)=>{return ReturnNextUpPrice(currentValue)})
		else if (e.code === shortcutKeySetting.minusKey)
			setTriggerPrice((currentValue)=>{return ReturnNextDownPrice(currentValue)})
	}, [shortcutKeySetting, DefaultUPrice, UBidPrice, UAskPrice])

	const PriceKeyPress = useCallback((e)=>{
		if (e.code === shortcutKeySetting.capAllKey)
			setPrice(DefaultDPrice)
		else if (e.code === shortcutKeySetting.capBidKey)
			setPrice(DBidPrice)
		else if (e.code === shortcutKeySetting.capAskKey)
			setPrice(DAskPrice)
		else if (e.code === shortcutKeySetting.addKey)
			setPrice((last)=>ReturnNextUpPrice(last))
		else if (e.code === shortcutKeySetting.minusKey)
			setPrice((last)=>ReturnNextDownPrice(last))
	}, [shortcutKeySetting, DefaultDPrice, DBidPrice, DAskPrice])

	const VolumeKeyMethod = useCallback((e)=>{
		if (e.type === "keypress"){
			if (e.code === shortcutKeySetting.addKey)
				setVolume((old)=>{
					const value = new Decimal(old).add(new Decimal(LotSize).div(1000)).toNumber()
					return value > maxVolume ? old : value
				})
			else if (e.code === shortcutKeySetting.minusKey)
				setVolume((old)=>{
					const value = old === 0 ? old : new Decimal(old).minus(new Decimal(LotSize).div(1000)).toNumber()
					return value
				})
		}
	}, [shortcutKeySetting, volume, maxVolume, LotSize])

	const BVSVKeyPress = useCallback((e)=>{
			const value = e.target.value.trim()
			if (value !== ""){
				const parsedValue = parseInt(value)
				if (parsedValue >= 1){
					setTriggerVolume(parsedValue)
					return
				}
			}
			else if (e.target.value === ""){
				setTriggerVolume(0)
				return
			}
			e.target.value = triggerVolume
	}, [triggerVolume])

	const MinTOKeyPress= useCallback((e)=>{
			const value = e.target.value.trim()
			if (value !== ""){
				const parsedValue = parseInt(value)
				if (parsedValue >= 0){
					setMinTO(parsedValue)
					return
				}
			}
			e.target.value = MinTO
	}, [MinTO])

	const MultiplierKeyPress = useCallback((e)=>{
			const value = e.target.value.trim()
			if (value !== ""){
				const parsedValue = parseFloat(value)
				if (parsedValue > 0){
					setMultiplier(parsedValue)
					return
				}
			}
			e.target.value = Multiplier
	}, [Multiplier])

	const FBuyLightBtn = useMemo(()=>{
		const Price = Side==="BUY"?DAskPrice:DBidPrice
		const Value = "F" + Side[0] + ": " + Price.toFixed(SpreadLength)
		return (
			<SwitchableButton value={Value} disabled={disableFLight}
				color={sideColor} style={{fontSize: fontSize, padding: "0px 4px", minWidth: 100, flex: 0}}
				state={forceLight} setState={setForceLight}
			/>
		)
	}, [Side, fontSize, forceLight, DBidPrice, DAskPrice, SpreadLength, disableFLight])

	const BuyLightBtn = useMemo(()=>{
		return (
			<SwitchableButton value={Side} disabled={disableLight}
				color={sideColor} style={{fontSize: fontSize, padding: "0px 4px", minWidth: 100, flex: 0}}
				state={userLight} onClick={UserLightBtnOnClick}
			/>
		)
	}, [Side, fontSize, userLight, UserLightBtnOnClick, disableLight])

	return useMemo(()=>{
		const BVSV = Side[0]+"V(K)"
		const TBUTSU = "T" + Side[0] + "U"
		const MaxBMinS = Side==="BUY"?"MaxB":"MinS"

		return (
			<>
				<InputGroup style={{flex: 2}}>
					<InputGroup.Text className={sideStyle.LabelClass} style={sideStyle.LabelStyle}>Vol(K)</InputGroup.Text>
					<Button className='bg-white' style={sideStyle.InputButtonStyle} disabled={disableInput}
						onKeyPress={VolumeKeyMethod}
					>{volume}</Button>
				</InputGroup>
				<InputGroup style={{flex: 2}}>
					<InputGroup.Text className={sideStyle.LabelClass} style={sideStyle.LabelStyle}>{TBUTSU}</InputGroup.Text>
					<Button className='bg-white' style={sideStyle.InputButtonStyle} disabled={disableInput}
						onDoubleClick={()=>{setTriggerPrice(DefaultUPrice)}}
						onKeyPress={TiggerPriceKeyPress}
					>
						{triggerPrice.toFixed(SpreadLength)}
					</Button>
				</InputGroup>
				<InputGroup style={{flex: 2}}>
					<InputGroup.Text className={sideStyle.LabelClass} style={sideStyle.LabelStyle}>{BVSV}</InputGroup.Text>
					<Form.Control type='text' ref={(r)=>{setBVInput(r)}} disabled={disableInput} className={sideStyle.InputClass} style={sideStyle.InputStyle}
						onBlur={BVSVKeyPress}
					/>
				</InputGroup>
				<InputGroup style={{flex: 2}}>
					<InputGroup.Text className={sideStyle.LabelClass} style={sideStyle.LabelStyle}>{MaxBMinS}</InputGroup.Text>
					<Button disabled={disableInput} className="react-draggable-cancel bg-white" style={sideStyle.InputButtonStyle}
						onDoubleClick={()=>{ setPrice(DefaultDPrice) }}
						onKeyPress={PriceKeyPress}
					>
						{price.toFixed(SpreadLength)}
					</Button>
				</InputGroup>
				<InputGroup style={{flex: 2, minWidth: 120}}>
					<InputGroup.Text className={sideStyle.LabelClass} style={sideStyle.LabelStyle}>MinTO(K)</InputGroup.Text>
					<Form.Control type='text' ref={(r)=>setBMinTOInput(r)} disabled={disableInput} className={sideStyle.InputClass} style={sideStyle.InputStyle}
						onBlur={MinTOKeyPress}
					/>
				</InputGroup>
				<InputGroup style={{flex: 1, minWidth: 120}}>
					<InputGroup.Text className={sideStyle.LabelClass} style={sideStyle.LabelStyle}>Multiplier</InputGroup.Text>
					<Form.Control type='text' ref={(r)=>setBMultiplierInput(r)} disabled={disableInput} className={sideStyle.InputClass} style={sideStyle.InputStyle}
						onBlur={MultiplierKeyPress}
					/>
				</InputGroup>
				{FBuyLightBtn}
				{BuyLightBtn}
				<Conditions fontSize={fontSize} disableInput={disableInput} selectedSymbol={selectedSymbol} disableCondition={disableCondition} AddErrorMsg={AddErrorMsg} condition={Condition} setCondition={setCondition}/>
				<SMASelect fontSize={fontSize} disableInput={disableInput} selectedSymbol={selectedSymbol} disableCondition={disableCondition} AddErrorMsg={AddErrorMsg} SelectedSMA={SelectedSMA} setSelectedSMA={setSelectedSMA}/>
			</>
		)
	}, [
		fontSize, SpreadLength,
		selectedSymbol, disableInput, disableCondition, AddErrorMsg,
		triggerVolume, triggerPrice, volume, price, MinTO, Multiplier, SelectedSMA, Condition,
		BVSVKeyPress, TiggerPriceKeyPress, VolumeKeyMethod, PriceKeyPress, MinTOKeyPress, MultiplierKeyPress,
		FBuyLightBtn, BuyLightBtn,
		DefaultUPrice, UBidPrice, UAskPrice, DefaultDPrice, DBidPrice, DAskPrice
	])
}

function CheapUTrader(props){
	const MainContext = useContext(props.context)
    const AllowAddRow = useRef(false)
	const AllowModify = useRef(false)

	const [updateTimer, setUpdateTimer] = useState(null)
	const [updater, setUpdater] = useState(true)

	const rowKey = useMemo(()=>{return props.rowKey}, [])
    const [rowID, setRowID] = useState(null)
	
	const [symbol, setSymbol] = useState("")
	const [selectedSymbol, setSelectedSymbol] = useState(null)

	const [underlying, callPut] = useMemo(()=>{
        let [underlying, callPut] = [selectedSymbol, "Call"]

        if (selectedSymbol){
            if (selectedSymbol.length === 5){
                let data = []
                if (selectedSymbol[0] === "1" || selectedSymbol[0] === "2")
                    data = MainContext.warrants.filter((x)=>{
                        if (x[1] === selectedSymbol)
                            return true
                        return false
                    })
                else if (selectedSymbol[0] === "5" || selectedSymbol[0] === "6")
                    data = MainContext.cbbcs.filter((x)=>{
                        if (x[1] === selectedSymbol)
                            return true
                        return false
                    })
                
                if (data.length === 1){
                    underlying = data[0][3]
                    callPut = data[0][5]
                }
            }
        }

        return [underlying, callPut]
    }, [selectedSymbol, MainContext.warrants, MainContext.cbbcs, MainContext.ws])

	const asset = useMemo(()=>{
		const result = MainContext.assets.filter((x)=>{
			if (x.symbol === selectedSymbol)
				return true
			return false
		})

		if (result.length !== 1)
			return {Pnl: 0, Pts: 0, Cost: 0, Available_Pts: 0}

		return result[0]
	}, [MainContext.assets, selectedSymbol])

	const [UBidPrice, setUBidPrice] = useState(0)
    const [UBidVolume, setUBidVolume] = useState(0)
    const [UAskPrice, setUAskPrice] = useState(0)
    const [UAskVolume, setUAskVolume] = useState(0)
	const [DBidPrice, setDBidPrice] = useState(0)
    const [DBidVolume, setDBidVolume] = useState(0)
    const [DAskPrice, setDAskPrice] = useState(0)
    const [DAskVolume, setDAskVolume] = useState(0)
	const [LotSize, setLotSize] = useState(0)

	const fontSize = useMemo(()=>{
		return props.fontSize
	}, [props.fontSize])

	const {buyColor, sellColor, AsColor, callColor, putColor} = useMemo(()=>{
		return props.color
	}, [props.color])
	const {maxVolume, defaultVolume} = useMemo(()=>{
		return props.volumeSetting
	}, [props.volumeSetting])
	const shortcutKeySetting = useMemo(()=>{
		return props.shortcutKeySetting
	}, [props.shortcutKeySetting])

	const buyStyle = useMemo(()=>{return (returnStyle(buyColor, fontSize, "3.5em"))}, [buyColor, fontSize])
	const sellStyle = useMemo(()=>{return (returnStyle(sellColor, fontSize, "3.5em"))}, [sellColor, fontSize])
	const AsStyle = useMemo(()=>{return (returnStyle(AsColor, fontSize))}, [AsColor, fontSize])

	const [buyLight, setBuyLight] = useState(false)
	const [sellLight, setSellLight] = useState(false)
	const [fbuyLight, setFBuyLight] = useState(false)
	const [fsellLight, setFSellLight] = useState(false)

	const [SMA3, setSMA3] = useState(new CustomVWAP(3))
	const [SMA7, setSMA7] = useState(new CustomVWAP(7))
	const [SMA12, setSMA12] = useState(new CustomVWAP(12))
	const [SMAValue, setSMAValue] = useState({"3":0, "7":0, "12":0})

	const [LastAllBar, setLastAllBar] = useState([])
	const tmpAllBar = useRef([])
	const [CurrBar, setCurrBar] = useState(null)
	const loadingBar = useRef(false)

	const [runningMode, setRunningMode] = useState(0)
	const [userBuyLight, setUserBuyLight] = useState(false)
	const [userSellLight, setUserSellLight] = useState(false)

	const [BMinTO, setBMinTO] = useState(0)
	const [BMultiplier, setBMultiplier] = useState(1)
	const [BCondition, setBCondition] = useState({"BV": true, "SMA": false, "TO.J": false, "↑": false})
	const [buySelectSMA, setBuySelectSMA] = useState({"3":false, "7": false, "12": false})
	const [BShortSMAIndex, BLongSMAIndex] = useMemo(()=>{
		let short = null, long = null, error = false

		Object.keys(buySelectSMA).sort((a,b)=>{return parseInt(a) - parseInt(b)}).forEach((x)=>{
			if (!error && buySelectSMA[x] === true){
				if (short === null) short = x
				else if (long === null) long = x
				else error = true
			}
		})

		setBCondition((last)=>{
			const newCondition = {}
			Object.keys(last).forEach((x)=>{
				newCondition[x] = last[x]
			})
			newCondition["SMA"] = !error && short !== null && long !== null
			return newCondition
		})

		return (error?[null, null]:[short, long])
	}, [buySelectSMA])

	const [SMinTO, setSMinTO] = useState(0)
	const [SMultiplier, setSMultiplier] = useState(1)
	const [SCondition, setSCondition] = useState({"SV": true, "SMA": false, "TO.J": false, "↓": false})
	const [sellSelectSMA, setSellSelectSMA] = useState({"3":false, "7": false, "12": false})
	const [SShortSMAIndex, SLongSMAIndex] = useMemo(()=>{
		let short = null, long = null, error = false

		Object.keys(sellSelectSMA).sort((a,b)=>{return parseInt(a) - parseInt(b)}).forEach((x)=>{
			if (!error && sellSelectSMA[x] === true){
				if (short === null) short = x
				else if (long === null) long = x
				else error = true
			}
		})

		setSCondition((last)=>{
			const newCondition = {}
			Object.keys(last).forEach((x)=>{
				newCondition[x] = last[x]
			})
			newCondition["SMA"] = !error && short !== null && long !== null
			return newCondition
		})

		return (error?[null, null]:[short, long])
	}, [sellSelectSMA])

	const [tradeHittingSide, setTradeHittingSide] = useState(0)

	const [tbu, setTBU] = useState(0)
	const [maxB, setMaxB] = useState(0)
	const [br, setBR] = useState(0)
	const [bv, setBV] = useState(0)
	const [buyVolume, setBuyVolume] = useState(defaultVolume)
	const [tsu, setTSU] = useState(0)
	const [minS, setMinS] = useState(0)
	const [sr, setSR] = useState(0)
	const [sv, setSV] = useState(0)
	const [sellVolume, setSellVolume] = useState(defaultVolume)

	const [AsLight, setAsLight] = useState(false)
	const [isPending, setIsPending] = useState(false)
	const resendPending = useRef(false)
	const [ap, setAP] = useState(0)
	const [at, setAT] = useState(1)

	//Guarding For Pending
	useEffect(()=>{
		if (rowID && MainContext.setPendingRows){
			if (isPending)
				MainContext.setPendingRows((old)=>{ return [...old, {rowID: rowID, symbol: selectedSymbol, price: new Decimal(ap).mul(1000).toNumber()}] })
			else
				MainContext.setPendingRows((old)=>{ return old.filter((x)=>{ return x.rowID !== rowID })})
		}
	}, [MainContext.setPendingRows, rowID, isPending, ap])
	const [MaxAllowBuy, MinAllowSell] = useMemo(()=>{
		let maxAllowBuy = null
		let minAllowSell = null

        let maxPendBuy = null
        let minPendSell = null
		MainContext.pending.forEach((x)=>{
			if (x.symbol === selectedSymbol)
				if (x.side === OrderSide.Buy)
                    maxPendBuy = (maxPendBuy===null?x.price:Math.max(maxPendBuy, x.price))
				else if (x.side === OrderSide.Sell)
                    minPendSell = (minPendSell===null?x.price:Math.min(minPendSell, x.price))
		})

		MainContext.pendingRows.forEach((x)=>{
			if (x.symbol === selectedSymbol)
                minPendSell = (minPendSell===null?x.price:Math.min(minPendSell, x.price))
		})

        MainContext.marketTriggers.forEach((x)=>{
			if (x.symbol === selectedSymbol)
				if (x.side === OrderSide.Buy)
                    maxPendBuy = (maxPendBuy===null?x.price:Math.max(maxPendBuy, x.price))
				else if (x.side === OrderSide.Sell)
                    minPendSell = (minPendSell===null?x.price:Math.min(minPendSell, x.price))
		})

        maxAllowBuy = minPendSell===null?99999.0:ReturnNextDownPrice(new Decimal(minPendSell).div(1000).toNumber())
        minAllowSell = maxPendBuy===null?0.01:ReturnNextUpPrice(new Decimal(maxPendBuy).div(1000).toNumber())

		return [maxAllowBuy, minAllowSell]
	}, [selectedSymbol, MainContext.pending, MainContext.pendingRows, MainContext.marketTriggers])
	useEffect(()=>{
		if (MaxAllowBuy < maxB){
			setUserBuyLight(false)
		}

		if (MinAllowSell > minS || MinAllowSell > ap){
			setUserSellLight(false)
			setAsLight(false)
		}
	}, [MaxAllowBuy, MinAllowSell, maxB, minS, ap])

	const cleanUP = useCallback(() => {
        const setNull = [setRowID, setCurrBar]
        setNull.forEach((e)=>{ e(null) });

        const setFalse = [setBuyLight, setSellLight, setUserBuyLight, setAsLight, setIsPending]
        setFalse.forEach((e)=>{ e(false) });

        const setZero = [
			setTBU, setTSU, setMaxB, setMinS, setAP,
			setUBidPrice, setUBidVolume, setUAskPrice, setUAskVolume, setDBidPrice, setDAskPrice, setDBidVolume, setDAskVolume,
			setBR, setBV, setSR, setSV, setBMinTO, setSMinTO, setRunningMode, setTradeHittingSide, setLotSize
		]
        setZero.forEach((e)=>{ e(0) })

		const setOne = [setBMultiplier, setSMultiplier, setAT]
		setOne.forEach((e)=>{ e(1) })

		const setEmptyString = []
		setEmptyString.forEach((e)=>{ e("") })

		const setEmptyArr = [setLastAllBar]
		setEmptyArr.forEach((e)=>{ e([]) })
		tmpAllBar.current = []

		setSMA3(new CustomVWAP(3))
		setSMA7(new CustomVWAP(7))
		setSMA12(new CustomVWAP(12))

		setBuyVolume(defaultVolume)
		setSellVolume(defaultVolume)

		setBCondition({"BV": true, "SMA": false, "TO.J": false, "↑": false})
		setSCondition({"SV": true, "SMA": false, "TO.J": false, "↓": false})
		setBuySelectSMA({"3":false, "7": false, "12": false})
		setSellSelectSMA({"3":false, "7": false, "12": false})
    }, [])

	useEffect(()=>{
        if (MainContext.session){
			cleanUP()
			setSymbol("")
			setSelectedSymbol(null)

			AllowAddRow.current = false
            MainContext.ws.send(JSON.stringify({e: "trader", subE: "loadRow", rowKey: rowKey, sessionID: MainContext.session}))

			if (updateTimer === null){
				setUpdateTimer(setInterval(()=>{
					setUpdater((last)=>!last)
				},5))
			}
        }
    }, [MainContext.session])

	useEffect(()=>{
        if (!MainContext.traderStream)
            return

        const traderStream = MainContext.traderStream
        if (traderStream.subE === "createRow"){
            if (traderStream.rowKey === rowKey && traderStream.status === StatusType.Success){
                setRowID(traderStream.rowID)
			}
			else if(traderStream.rowKey === rowKey && traderStream.status === StatusType.Error)
			{
				AllowAddRow.current = true
				AllowModify.current = true
				loadingBar.current = false
				cleanUP()
				setSymbol("")
                setSelectedSymbol(null)
			}
        }
        else if (traderStream.subE === "loadRow"){
            if (traderStream.rowKey === rowKey){
                if (traderStream.status === StatusType.Success){
                    AllowAddRow.current = false
					AllowModify.current = false
                    setRowID(traderStream.row.rowID)
                    setSelectedSymbol(traderStream.row.symbol)
                    setSymbol(traderStream.row.symbol)

					setUserBuyLight(traderStream.row.userBuyLight)
					setBuyLight(traderStream.row.buyLight)
					setUserSellLight(traderStream.row.userSellLight)
					setSellLight(traderStream.row.sellLight)
					setAsLight(traderStream.row.AsLight)

					setBuyVolume(traderStream.row.buyVolume/1000)
					setSellVolume(traderStream.row.sellVolume/1000)
					
					setBV(traderStream.row.bv/1000)
					setSV(traderStream.row.sv/1000)
					setBR(traderStream.row.br)
					setSR(traderStream.row.sr)
					setTBU(traderStream.row.tbu/1000)
					setTSU(traderStream.row.tsu/1000)
					setMaxB(traderStream.row.maxB/1000)
					setMinS(traderStream.row.minS/1000)

					setIsPending(traderStream.row.isPending)
					setAP(traderStream.row.ap/1000)
					setAT(traderStream.row.at)

					const BuySelectedSMA = {"3": false, "7":false, "12":false}
					if (traderStream.row.BShortSMAIndex)
						BuySelectedSMA[traderStream.row.BShortSMAIndex] = true
					if (traderStream.row.BLongSMAIndex)
						BuySelectedSMA[traderStream.row.BLongSMAIndex] = true
					setBuySelectSMA(BuySelectedSMA)
					const SellSelectedSMA = {"3": false, "7":false, "12":false}
					if (traderStream.row.SShortSMAIndex)
						SellSelectedSMA[traderStream.row.SShortSMAIndex] = true
					if (traderStream.row.SLongSMAIndex)
						SellSelectedSMA[traderStream.row.SLongSMAIndex] = true
					setSellSelectSMA(SellSelectedSMA)

					setBCondition(traderStream.row.BCondition)
					setSCondition(traderStream.row.SCondition)
					setBMinTO(traderStream.row.BMinTO)
					setSMinTO(traderStream.row.SMinTO)
					setBMultiplier(traderStream.row.BMultiplier)
					setSMultiplier(traderStream.row.SMultiplier)
					setUserBuyLight(traderStream.row.userBuyLight)
					setUserSellLight(traderStream.row.userSellLight)
					setRunningMode(traderStream.row.runningMode)

                    setTimeout(()=>{
                        AllowAddRow.current = true
						AllowModify.current = true
                    }, 5)
                }else{
					AllowAddRow.current = true
					AllowModify.current = true
                    cleanUP()
                }
            }
        }
		else if (traderStream.subE === "serverRes_Order"){
			const serverRes_Order = traderStream.serverRes_Order
			if (serverRes_Order.RowID === rowID){
				if (serverRes_Order.Status === OrderStatus.Full || serverRes_Order.Status === OrderStatus.Reject){
					AllowModify.current = false
					if (serverRes_Order.OSide === OrderSide.Buy){
						setBuyLight(false)
						setFBuyLight(false)
						setUserBuyLight(false)
						setRunningMode(0)

						if (serverRes_Order.Status === OrderStatus.Full){
							Sounds.BuyDone()
							setAP(serverRes_Order.Cost)

							if (AsLight){
								if (isPending){
									MainContext.ws.send(JSON.stringify({
										e: "trader",
										subE: "cancelPending",
										sessionID: MainContext.session,
										row: { rowID: rowID }
									}))
								}
								const targetPrice = ReturnNextNUpPrice(serverRes_Order.Cost, at)
								MainContext.ws.send(JSON.stringify({
									e: "trader",
									subE: "pendingRow",
									sessionID: MainContext.session,
									row: { rowID: rowID, buyLight: false, sellLight: true, ap: targetPrice * 1000}
								}))
								setIsPending(true)
							}
						}
						else
							Sounds.Reject()
					}
					else if (serverRes_Order.OSide === OrderSide.Sell){
						setSellLight(false)
						setFSellLight(false)
						setAsLight(false)
						setUserSellLight(false)
						setRunningMode(0)
						setIsPending(false)

						if (serverRes_Order.Status === OrderStatus.Full){
							Sounds.SellDone()
						}
						else
							Sounds.Reject()
					}
					setTimeout(()=>{
						AllowModify.current = true
					}, 5)
				}

				if (serverRes_Order.Status === OrderStatus.Cancel)
				{
					if (resendPending.current === false){
						setAsLight(false)
						setIsPending(false)
					}
					else
					{
						resendPending.current = false
					}
				}
			}
		}
		else if (traderStream.subE === "Quote"){
            if (traderStream.s === underlying && AllowModify.current){
                setUBidPrice(new Decimal(traderStream.quote.bidP).div(1000).toNumber())
                setUAskPrice(new Decimal(traderStream.quote.askP).div(1000).toNumber())
                setUBidVolume(traderStream.quote.bidS)
                setUAskVolume(traderStream.quote.askS)
            }
            if (traderStream.s === selectedSymbol && AllowModify.current){
				const bidPrice = new Decimal(traderStream.quote.bidP).div(1000).toNumber()
				const askPrice = new Decimal(traderStream.quote.askP).div(1000).toNumber()
                setDBidPrice(bidPrice)
                setDAskPrice(askPrice)
                setDBidVolume(traderStream.quote.bidS)
                setDAskVolume(traderStream.quote.askS)
				setLotSize(traderStream.quote.lotS)
            }
        }
    }, [MainContext.traderStream])

	useEffect(()=>{
		if (AllowModify.current === true){
			cleanUP()
		}

        if (AllowAddRow.current === true){
            if (!rowID && selectedSymbol){
                MainContext.ws.send(JSON.stringify({
					e: "trader",
					subE: "createRow",
					sessionID: MainContext.session,
					row: {
						rowKey: rowKey,
						symbol: selectedSymbol,

						buyLight: false,
						sellLight: false,

						buyVolume: defaultVolume * 1000,
						sellVolume: defaultVolume * 1000,

						bv: 0,
						sv: 0,
						br: 0,
						sr: 0,
						tbu: 0,
						tsu: 0,
						maxB: 0,
						minS: 0,

						AsLight: false,
						ap: 0,
						at: 1,

						BShortSMAIndex: null,
						BLongSMAIndex: null,
						SShortSMAIndex: null,
						SLongSMAIndex: null,
						BCondition: {"BV": true, "SMA": false, "TO.J": false, "↑": false},
						SCondition: {"SV": true, "SMA": false, "TO.J": false, "↓": false},
						BMultiplier: 1,
						SMultiplier: 1,
						userBuyLight: false,
						userSellLight: false,
						runningMode: 0
					}
				}))
            }
			else{
                MainContext.ws.send(JSON.stringify({e: "trader", subE: "delRow", sessionID: MainContext.session, rowKey: rowKey}))
				if (selectedSymbol){
					MainContext.ws.send(JSON.stringify({
						e: "trader",
						subE: "createRow",
						sessionID: MainContext.session,
						row: {
							rowKey: rowKey,
							symbol: selectedSymbol,

							buyLight: false,
							sellLight: false,

							buyVolume: defaultVolume * 1000,
							sellVolume: defaultVolume * 1000,

							bv: 0,
							sv: 0,
							br: 0,
							sr: 0,
							tbu: 0,
							tsu: 0,
							maxB: 0,
							minS: 0,

							AsLight: false,
							ap: 0,
							at: 1,

							BShortSMAIndex: null,
							BLongSMAIndex: null,
							SShortSMAIndex: null,
							SLongSMAIndex: null,
							BCondition: {"BV": true, "SMA": false, "TO.J": false, "↑": false},
							SCondition: {"SV": true, "SMA": false, "TO.J": false, "↓": false},
							BMultiplier: 1,
							SMultiplier: 1,
							userBuyLight: false,
							userSellLight: false,
							runningMode: 0
						}
					}))
				}
            }
        }
    }, [selectedSymbol])

	//Get Quote When getNewRowID
	useEffect(()=>{
		if (rowID && underlying){
			loadingBar.current = true

			MainContext.ws.send(JSON.stringify({e: "getKlineChart", s: underlying, interval: 0.5}))

			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "subscribeRow",
				sessionID: MainContext.session,
				row: { rowID: rowID }
			}))
		}
	}, [underlying, rowID])

	// Loading Bar and Create Bar
	useEffect(()=>{
		const klineData = MainContext.klineData
		if (klineData.s === underlying && loadingBar.current === true){
			if (klineData.status === StatusType.EOF){
				setSMAValue({"3": Math.round(SMA3.VAL*1000)/1000, "7": Math.round(SMA7.VAL*1000)/1000, "12": Math.round(SMA12.VAL*1000)/1000})
				setLastAllBar([...tmpAllBar.current])
				loadingBar.current = false
			}else{
				const k = klineData.k
				k.time = k.time * 1000
				if (new Date(k.time).getHours() === 12) return
				const bar = {time: k.time, open: k.o, close: k.c, low: k.l, high: k.h, volume: k.v, TO: (k.v * k.c)}

				SMA3.Add(bar.close, bar.volume)
				SMA7.Add(bar.close, bar.volume)
				SMA12.Add(bar.close, bar.volume)

				tmpAllBar.current.push(bar)
			}
		}
	}, [underlying, MainContext.klineData])
	useEffect(()=>{
		if (loadingBar.current === true) return

		const tradeData = MainContext.tradeData
		if (underlying === tradeData[1]){
			const tradeP = parseFloat(tradeData[2])
			if (tradeP === 0) return

			if (tradeP === DBidPrice)
				setTradeHittingSide(1)
			else if (tradeP === DAskPrice)
				setTradeHittingSide(2)
			
			const tradeVol = parseFloat(tradeData[3])
			const tradeTime = parseInt(tradeData[6]) * 1000
			const tradeTimeBase = tradeTime - (tradeTime % 30000)

			setCurrBar((last)=>{
				if (last === null || last.time !== tradeTimeBase){
					if (last !== null){
						SMA3.Add(last.close, last.volume)
						SMA7.Add(last.close, last.volume)
						SMA12.Add(last.close, last.volume)

						setSMAValue({"3": Math.round(SMA3.VAL*1000)/1000, "7": Math.round(SMA7.VAL*1000)/1000, "12": Math.round(SMA12.VAL*1000)/1000})

						setLastAllBar((curr)=>[...curr, last])
					}
					return {
						time: tradeTimeBase,
						open: tradeP, close: tradeP, low: tradeP, high: tradeP,
						volume: tradeVol,
						TO: tradeP*tradeVol
					}
				}
				
				return {
					time: tradeTimeBase,
					open: last.open, close: tradeP, low: Math.min(tradeP,last.low), high: Math.max(tradeP,last.high),
					volume: last.volume + tradeVol,
					TO: last.TO + tradeP*tradeVol
				}
			})
		}
	}, [underlying, MainContext.tradeData])

	// Send Modified value to server
	useEffect(()=>{
		if (rowID && AllowModify.current){
			if (buyVolume >= 0){
				MainContext.ws.send(JSON.stringify({
					e: "trader",
					subE: "modifyRow",
					sessionID: MainContext.session,
					rowModifyType: RowModifyType.buyVolume,
					row: { rowID: rowID, buyVolume: buyVolume*1000 }
				}))
				if (buyVolume === 0){
					setBuyLight(false)
				}
			}
		}
	}, [buyVolume])
	useEffect(()=>{
		if (rowID && AllowModify.current){
			if (buyVolume >= 0){
				MainContext.ws.send(JSON.stringify({
					e: "trader",
					subE: "modifyRow",
					sessionID: MainContext.session,
					rowModifyType: RowModifyType.sellVolume,
					row: { rowID: rowID, sellVolume: sellVolume*1000 }
				}))
				if (sellVolume === 0){
					setSellLight(false)
				}
			}
		}
	}, [sellVolume])
	useEffect(()=>{
		if (rowID && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.bv,
				row: { rowID: rowID, bv: bv*1000 }
			}))
		}
	}, [bv])
	useEffect(()=>{
		if (rowID && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.sv,
				row: { rowID: rowID, sv: sv*1000 }
			}))
		}
	}, [sv])
	useEffect(()=>{
		if (rowID && tbu > 0 && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.tbu,
				row: { rowID: rowID, tbu: tbu*1000 }
			}))
		}
	}, [tbu])
	useEffect(()=>{
		if (rowID && tsu > 0 && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.tsu,
				row: { rowID: rowID, tsu: tsu*1000 }
			}))
		}
	}, [tsu])
	useEffect(()=>{
		if (rowID && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.br,
				row: { rowID: rowID, br: br }
			}))
		}
	}, [br, tbu])
	useEffect(()=>{
		if (rowID && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.sr,
				row: { rowID: rowID, sr: sr }
			}))
		}
	}, [sr, tsu])
	useEffect(()=>{
		if (rowID){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.maxB,
				row: { rowID: rowID, maxB: maxB*1000 }
			}))
		}
	}, [maxB])
	useEffect(()=>{
		if (rowID){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.minS,
				row: { rowID: rowID, minS: minS*1000 }
			}))
		}
	}, [minS])
	useEffect(()=>{
		if (rowID){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.ap,
				row: { rowID: rowID, ap: ap*1000 }
			}))
		}
	}, [ap])
	useEffect(()=>{
		if (rowID){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.at,
				row: { rowID: rowID, at: at }
			}))
		}
	}, [at])
	useEffect(()=>{
		if (rowID){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.BShortSMAIndex,
				row: { rowID: rowID, BShortSMAIndex: BShortSMAIndex }
			}))
		}
	}, [BShortSMAIndex])
	useEffect(()=>{
		if (rowID){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.BLongSMAIndex,
				row: { rowID: rowID, BLongSMAIndex: BLongSMAIndex }
			}))
		}
	}, [BLongSMAIndex])
	useEffect(()=>{
		if (rowID && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.SShortSMAIndex,
				row: { rowID: rowID, SShortSMAIndex: SShortSMAIndex }
			}))
		}
	}, [SShortSMAIndex])
	useEffect(()=>{
		if (rowID && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.SLongSMAIndex,
				row: { rowID: rowID, SLongSMAIndex: SLongSMAIndex }
			}))
		}
	}, [SLongSMAIndex])
	useEffect(()=>{
		if (rowID && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.BCondition,
				row: { rowID: rowID, BCondition: BCondition }
			}))
		}
	}, [BCondition])
	useEffect(()=>{
		if (rowID && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.SCondition,
				row: { rowID: rowID, SCondition: SCondition }
			}))
		}
	}, [SCondition])
	useEffect(()=>{
		if (rowID && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.BMinTO,
				row: { rowID: rowID, BMinTO: BMinTO }
			}))
		}
	}, [BMinTO])
	useEffect(()=>{
		if (rowID && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.SMinTO,
				row: { rowID: rowID, SMinTO: SMinTO }
			}))
		}
	}, [SMinTO])
	useEffect(()=>{
		if (rowID && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.BMultiplier,
				row: { rowID: rowID, BMultiplier: BMultiplier }
			}))
		}
	}, [BMultiplier])
	useEffect(()=>{
		if (rowID && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.SMultiplier,
				row: { rowID: rowID, SMultiplier: SMultiplier }
			}))
		}
	}, [SMultiplier])
	useEffect(()=>{
		if (rowID && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.runningMode,
				row: { rowID: rowID, runningMode: runningMode }
			}))
		}
	}, [runningMode])
	useEffect(()=>{
		if (rowID){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.userBuyLight,
				row: { rowID: rowID, userBuyLight: userBuyLight }
			}))
		}
	}, [userBuyLight])
	useEffect(()=>{
		if (rowID){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.userSellLight,
				row: { rowID: rowID, userSellLight: userSellLight }
			}))
		}
	}, [userSellLight])
	useEffect(()=>{
		if (rowID && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.buyLight,
				row: { rowID: rowID, buyLight: buyLight }
			}))
		}
	}, [buyLight])
	useEffect(()=>{
		if (rowID && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.sellLight,
				row: { rowID: rowID, sellLight: sellLight }
			}))
		}
	}, [sellLight])
	useEffect(()=>{
		if (rowID && AllowModify.current){
			if (isPending){
				MainContext.ws.send(JSON.stringify({
					e: "trader",
					subE: "cancelPending",
					sessionID: MainContext.session,
					row: { rowID: rowID }
				}))
			}
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.AsLight,
				row: { rowID: rowID, AsLight: AsLight }
			}))
		}
	}, [AsLight])
	useEffect(()=>{
		if (rowID && AllowModify.current && fbuyLight){
			if (MaxAllowBuy < DAskPrice){
				Sounds.Error()
				MainContext.AddErrorMsg(Date.now(), selectedSymbol, ErrorMsg.NotAllowBuyWhenPending)
				setFBuyLight(false)
				return
			}

			if (buyVolume <= 0){
				MainContext.AddErrorMsg(Date.now(), selectedSymbol, ErrorMsg.BuyVolumeError)
				Sounds.Error()
				setFBuyLight(false)
				return
			}


			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "forceRow",
				sessionID: MainContext.session,
				row: { rowID: rowID, buyLight: true, sellLight: false}
			}))
		}
	}, [fbuyLight])
	useEffect(()=>{
		if (rowID && AllowModify.current && fsellLight){
			if (MinAllowSell > DBidPrice){
				Sounds.Error()
				MainContext.AddErrorMsg(Date.now(), selectedSymbol, ErrorMsg.NotAllowSellWhenPending)
				setFSellLight(false)
				return
			}

			if (sellVolume <= 0){
				MainContext.AddErrorMsg(Date.now(), selectedSymbol, ErrorMsg.SellVolumeError)
				Sounds.Error()
				setFSellLight(false)
				return
			}

			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "forceRow",
				sessionID: MainContext.session,
				row: { rowID: rowID, buyLight: false, sellLight: true}
			}))
		}
	}, [fsellLight])

	useEffect(()=>{
		if (rowID && AllowModify.current && AsLight && isPending){
			const targetPrice = ReturnNextNUpPrice(ap, at)

			resendPending.current = true
			MainContext.ws.send(JSON.stringify({
                e: "trader",
                subE: "cancelPending",
                sessionID: MainContext.session,
                row: { rowID: rowID }
            }))
			MainContext.ws.send(JSON.stringify({
                e: "trader",
                subE: "pendingRow",
                sessionID: MainContext.session,
                row: { rowID: rowID, buyLight: false, sellLight: true, ap: targetPrice * 1000}
            }))
		}
	}, [ap, at, AsLight, isPending])

	useEffect(()=>{
		if (buyVolume === 0 && sellVolume === 0){
			setBuyVolume(LotSize / 1000)
			setSellVolume(LotSize / 1000)
		}
	}, [LotSize])
	useEffect(()=>{
        if (UBidPrice !== 0 && UAskPrice !== 0 && rowID){
			if (tbu === 0)
                if (callPut === "Call" || callPut === "Bull")
                    setTBU(UAskPrice)
                else
                    setTBU(UBidPrice)
            if (tsu === 0)
                if (callPut === "Call" || callPut === "Bull")
                    setTSU(UBidPrice)
                else
                    setTSU(UAskPrice)

			if (loadingBar.current === true) return
			const currTime = new Date()
			const currTimeBase = currTime - (currTime % 30000)
			if (currTime.getHours() === 12){
				currTime.setTime(currTime.getTime() + 60*60*1000- currTime.getTime() % 60*60*1000)
			}
			if (UBidPrice < UAskPrice && currTime.getHours() !== 12){
				setCurrBar((last)=>{
					if (last === null || last.time !== currTimeBase){
						if (last !== null){
							SMA3.Add(last.close, last.volume)
							SMA7.Add(last.close, last.volume)
							SMA12.Add(last.close, last.volume)
	
							setSMAValue({"3": Math.round(SMA3.VAL*1000)/1000, "7": Math.round(SMA7.VAL*1000)/1000, "12": Math.round(SMA12.VAL*1000)/1000})
	
							setLastAllBar((curr)=>[...curr, last])
						}
						return {
							time: currTimeBase,
							open: UBidPrice, close: UBidPrice, low: UBidPrice, high: UBidPrice,
							volume: 0, TO: 0
						}
					}
					
					return {
						time: currTimeBase,
						open: last.open, close: UBidPrice, low: Math.min(UBidPrice,last.low), high: Math.max(UBidPrice,last.high),
						volume: last.volume, TO: last.TO
					}
				})
			}
        }
    }, [UBidPrice, UAskPrice, updater])
	useEffect(()=>{
		if (DBidPrice > 0 && DAskPrice > 0 && rowID){
			if (maxB === 0)
				setMaxB(DAskPrice)
			if (minS === 0)
				setMinS(DBidPrice)
			if (ap === 0)
				setAP(DAskPrice)
		}
	}, [DBidPrice, DAskPrice])

	useEffect(()=>{
		if (LastAllBar.length === 0 || CurrBar == null || !AllowModify.current) return

		if (userBuyLight){
			if (runningMode === 0){
				let inputFailure = false
				if (false && BCondition["SMA"] && !BCondition["TO.J"] && !BCondition["↑"] && !BCondition["BV"] && (BShortSMAIndex === null || BLongSMAIndex === null || SMAValue[BShortSMAIndex] >= SMAValue[BLongSMAIndex]) ){
					MainContext.AddErrorMsg(Date.now(), selectedSymbol, ErrorMsg.BuySMAError)
					inputFailure = true
				}
				else if (BCondition["TO.J"] && (typeof(BMultiplier) != 'number' || BMultiplier <= 0))
					inputFailure = true
				else if (BCondition["BV"] && (typeof(BMultiplier) != 'number' || bv <= 0))
					inputFailure = true

				if (inputFailure){
					setUserBuyLight(false)
					return
				}

				// Only SMA || Only TOJump || Only ↑ || (SMA, TOJump, ↑ Mixed)
				if (!BCondition["BV"])
					setRunningMode(1)
				// Only BV
				else if (BCondition["BV"] && !BCondition["SMA"] && !BCondition["TO.J"] && !BCondition["↑"])
					setRunningMode(2)
				// BV and Any Other Condition
				else if (BCondition["BV"] && (BCondition["SMA"] || BCondition["TO.J"] || BCondition["↑"]))
					setRunningMode(3)
				else
					setUserBuyLight(false)
			}
			else{
				const SMACondition = !BCondition["SMA"] || (
					SMAValue[BShortSMAIndex] >= SMAValue[BLongSMAIndex]
				)
				const TOJumpCondition = !BCondition["TO.J"] || (
					CurrBar.TO >= LastAllBar[LastAllBar.length - 1].TO * BMultiplier && CurrBar.TO > BMinTO * 1000
				)
				const UpCondition = !BCondition["↑"] || (
					tradeHittingSide === 2
				)

				if (runningMode === 1){
					if (SMACondition && TOJumpCondition && UpCondition){
						setUserBuyLight(false)
						MainContext.ws.send(JSON.stringify({
							e: "trader",
							subE: "forceRow",
							sessionID: MainContext.session,
							row: { rowID: rowID, buyLight: true, sellLight: false, ap: maxB * 1000}
						}))
					}
				}
				else if (runningMode === 2){
					if (!buyLight) setBuyLight(true)
				}
				else if (runningMode === 3){
					if (SMACondition && TOJumpCondition && UpCondition && !buyLight) setBuyLight(true)
					else if ((!SMACondition || !TOJumpCondition || !UpCondition) && buyLight) setBuyLight(false)
				}
			}
		}
		else{
			setBuyLight(false)
		}

		if (userSellLight){
			if (runningMode === 0){
				let inputFailure = false
				if (false && SCondition["SMA"] && !SCondition["TO.J"] && !SCondition["SV"] && (SShortSMAIndex === null || SLongSMAIndex === null || SMAValue[SShortSMAIndex] <= SMAValue[SLongSMAIndex]) ){
					MainContext.AddErrorMsg(Date.now(), selectedSymbol, ErrorMsg.ShortSMAError)
					inputFailure = true
				}
				else if (SCondition["TO.J"] && (typeof(BMultiplier) != 'number' || SMultiplier <= 0))
					inputFailure = true
				else if (SCondition["SV"] && (typeof(BMultiplier) != 'number' || sv <= 0))
					inputFailure = true

				if (inputFailure){
					setUserSellLight(false)
					return
				}

				// Only SMA || Only TOJump || Only ↓ || (SMA, TOJump, ↓ Mixed)
				if (!SCondition["SV"])
					setRunningMode(1)
				// Only SV
				else if (SCondition["SV"] && !SCondition["SMA"] && !SCondition["TO.J"] && !SCondition["↓"])
					setRunningMode(2)
				// SV and Any Other Condition
				else if (SCondition["SV"] && (SCondition["SMA"] || SCondition["TO.J"] || SCondition["↓"]))
					setRunningMode(3)
				else
					setUserSellLight(false)
			}
			else{
				const SMACondition = !SCondition["SMA"] || (
					SMAValue[SShortSMAIndex] <= SMAValue[SLongSMAIndex]
				)
				const TOJumpCondition = !SCondition["TO.J"] || (
					CurrBar.TO >= LastAllBar[LastAllBar.length - 1].TO * BMultiplier && CurrBar.TO > SMinTO * 1000
				)
				const DownCondition = !SCondition["↓"] || (
					tradeHittingSide === 1
				)

				if (runningMode === 1){
					if (SMACondition && TOJumpCondition && DownCondition){
						setUserSellLight(false)
						MainContext.ws.send(JSON.stringify({
							e: "trader",
							subE: "forceRow",
							sessionID: MainContext.session,
							row: { rowID: rowID, buyLight: false, sellLight: true, ap: minS * 1000}
						}))
					}
				}
				else if (runningMode === 2){
					if (!sellLight) setSellLight(true)
				}
				else if (runningMode === 3){
					if (SMACondition && TOJumpCondition && DownCondition && !sellLight) setSellLight(true)
					else if ((!SMACondition || !TOJumpCondition || !DownCondition) && sellLight) setSellLight(false)
				}
			}
		}
		else{
			setSellLight(false)
		}

		if (!userBuyLight && !userSellLight) setRunningMode(0)
	}, [
		userBuyLight, userSellLight, buyLight, sellLight, runningMode, tradeHittingSide,
		BCondition, maxB, buyVolume, BShortSMAIndex, BLongSMAIndex, BMultiplier, bv, BMinTO,
		SCondition, minS, sellVolume, SShortSMAIndex, SLongSMAIndex, SMultiplier, sv, SMinTO,
		SMAValue, LastAllBar, CurrBar, DAskVolume
	])

	const BuyLightBtnOnClick = useCallback(()=>{
		if (userBuyLight === true){
			setUserBuyLight(false)
			return 
		}

		if (MaxAllowBuy < maxB){
			Sounds.Error()
			MainContext.AddErrorMsg(Date.now(), selectedSymbol, ErrorMsg.NotAllowBuyWhenPending)
			return
		}

		if (buyVolume <= 0){
			Sounds.Error()
			MainContext.AddErrorMsg(Date.now(), selectedSymbol, ErrorMsg.BuyVolumeError)
			return
		}

		//Check Any One Condition is on
		if (!BCondition["SMA"] && !BCondition["TO.J"] && !BCondition["↑"] && !BCondition["BV"]){
			Sounds.Error()
			MainContext.AddErrorMsg(Date.now(), selectedSymbol, ErrorMsg.NoConditionError)
			return
		}

		if (!BCondition["SMA"]){
			setBuySelectSMA({"3": false, "7": false, "12": false})
		}

		//Check input valid
		let inputFailure = false
		if (BCondition["SMA"] && (BShortSMAIndex === null || BLongSMAIndex === null ))
			inputFailure = true
		else if (BCondition["TO.J"] && (typeof(BMultiplier) != 'number' || BMultiplier <= 0 || CurrBar === null || LastAllBar.length === 0)){
			if (BMultiplier <= 0)
				MainContext.AddErrorMsg(Date.now(), selectedSymbol, ErrorMsg.BuyTOMultiplierError)
			inputFailure = true
		}
		else if (BCondition["BV"] && (typeof(bv) != 'number' || bv <= 0)){
			MainContext.AddErrorMsg(Date.now(), selectedSymbol, ErrorMsg.BVError)
			inputFailure = true
		}
		if (inputFailure){
			Sounds.Error()
			return
		}

		setUserBuyLight(true)
	}, [selectedSymbol, MaxAllowBuy, maxB, buyVolume, userBuyLight, BCondition, BMultiplier, BShortSMAIndex, BLongSMAIndex, bv, LastAllBar, CurrBar])

	const SellLightBtnOnClick = useCallback(()=>{
		if (userSellLight === true){
			setUserSellLight(false)
			return
		}

		if (MinAllowSell > minS){
			Sounds.Error()
			MainContext.AddErrorMsg(Date.now(), selectedSymbol, ErrorMsg.NotAllowSellWhenPending)
			return
		}

		if (sellVolume <= 0){
			Sounds.Error()
			MainContext.AddErrorMsg(Date.now(), selectedSymbol, ErrorMsg.SellVolumeError)
			return
		}

		//Check Any One Condition is on
		if (!SCondition["SMA"] && !SCondition["TO.J"] && !SCondition["↓"] && !SCondition["SV"]){
			Sounds.Error()
			MainContext.AddErrorMsg(Date.now(), selectedSymbol, ErrorMsg.NoConditionError)
			return
		}

		if (!SCondition["SMA"]){
			setSellSelectSMA({"3": false, "7": false, "12": false})
		}

		//Check input valid
		let inputFailure = false
		if (SCondition["SMA"] && (SShortSMAIndex === null || SLongSMAIndex === null ))
			inputFailure = true
		else if (SCondition["TO.J"] && (typeof(SMultiplier) != 'number' || SMultiplier <= 0 || CurrBar === null || LastAllBar.length === 0)){
			if (SMultiplier <= 0)
				MainContext.AddErrorMsg(Date.now(), selectedSymbol, ErrorMsg.SellTOMultiplierError)
			inputFailure = true
		}
		else if (SCondition["SV"] && (typeof(sv) != 'number' || sv <= 0)){
			MainContext.AddErrorMsg(Date.now(), selectedSymbol, ErrorMsg.SVError)
			inputFailure = true
		}
		if (inputFailure){
			Sounds.Error()
			return
		}

		setUserSellLight(true)
	}, [selectedSymbol, MinAllowSell, minS, sellVolume, userSellLight, SCondition, SMultiplier, SShortSMAIndex, SLongSMAIndex, sv, LastAllBar, CurrBar])

	return useMemo(()=>{
		const disableSymbolInput = userBuyLight||userSellLight||isPending
		const disableInput = selectedSymbol===null||isPending
		const disableAS = selectedSymbol===null

		const SpreadLength = (("" + GetSpread(DBidPrice, GetSpreadTableType(selectedSymbol))).split(".")[1]??"").length

		return (
			<Card tabIndex={5} style={{marginBottom: "4px"}} className='border rounded'>
				<Card.Header style={{display: "flex", padding: "4px", gap: "4px", backgroundColor: callColor}}>
					<Symbol fontSize={fontSize} disableSymbolInput={disableSymbolInput}
						symbol={symbol} setSymbol={setSymbol} selectedSymbol={selectedSymbol} setSelectedSymbol={setSelectedSymbol}
						SMAValue={SMAValue} LastAllBar={LastAllBar} CurrBar={CurrBar}
						AsLight={AsLight} setAsLight={setAsLight}
						AsColor={AsColor}
						userBuyLight={userBuyLight} userSellLight={userSellLight}
						BShortSMAIndex={BShortSMAIndex} BLongSMAIndex={BLongSMAIndex}
						SShortSMAIndex={SShortSMAIndex} SLongSMAIndex={SLongSMAIndex}
					/>
					<div style={{flex: 1}}></div>
					<ASControl fontSize={fontSize} disableInput={disableAS} SpreadLength={SpreadLength}
						ap={ap} setAP={setAP} at={at} setAT={setAT}
						AsLight={AsLight} setAsLight={setAsLight} isPending={isPending} disableASLight={MinAllowSell > ap && !AsLight}
						AsStyle={AsStyle} AsColor={AsColor}
					/>
					<Asset fontSize={fontSize} SpreadLength={SpreadLength} asset={asset} DBidPrice={DBidPrice}/>
				</Card.Header>
				<Card.Body style={{padding: "4px", display: "flex", flexDirection: "column", gap: 4}}>
					<div style={{display: "flex", gap: 3, width: "100%"}}>
						<UserInputs selectedSymbol={selectedSymbol}
							Side={"BUY"} fontSize={fontSize} SpreadLength={SpreadLength} shortcutKeySetting={shortcutKeySetting}
							disableInput={disableInput} disableLight={userSellLight || selectedSymbol === null} disableFLight={selectedSymbol===null}
							triggerPrice={tbu} setTriggerPrice={setTBU}
							price={maxB} setPrice={setMaxB}
							triggerVolume={bv} setTriggerVolume={setBV}
							volume={buyVolume} setVolume={setBuyVolume} maxVolume={maxVolume}
							MinTO={BMinTO} setMinTO={setBMinTO}
							Multiplier={BMultiplier} setMultiplier={setBMultiplier}
							SelectedSMA={buySelectSMA} setSelectedSMA={setBuySelectSMA}
							Condition={BCondition} setCondition={setBCondition}
							disableCondition={userBuyLight||disableInput} AddErrorMsg={MainContext.AddErrorMsg}

							userLight={userBuyLight} UserLightBtnOnClick={BuyLightBtnOnClick}
							forceLight={fbuyLight} setForceLight={setFBuyLight}
							sideStyle={buyStyle} sideColor={buyColor}
							UBidPrice={UBidPrice} UAskPrice={UAskPrice} DBidPrice={DBidPrice} DAskPrice={DAskPrice} LotSize={LotSize}
						/>
					</div>
					<div style={{display: "flex", gap: 3, width: "100%"}}>
						<UserInputs selectedSymbol={selectedSymbol}
							Side={"SELL"} fontSize={fontSize} SpreadLength={SpreadLength} shortcutKeySetting={shortcutKeySetting}
							disableInput={disableInput} disableLight={userBuyLight || selectedSymbol === null} disableFLight={selectedSymbol===null}
							triggerPrice={tsu} setTriggerPrice={setTSU}
							price={minS} setPrice={setMinS}
							triggerVolume={sv} setTriggerVolume={setSV}
							volume={sellVolume} setVolume={setSellVolume} maxVolume={maxVolume}
							MinTO={SMinTO} setMinTO={setSMinTO}
							Multiplier={SMultiplier} setMultiplier={setSMultiplier}
							SelectedSMA={sellSelectSMA} setSelectedSMA={setSellSelectSMA}
							Condition={SCondition} setCondition={setSCondition}
							disableCondition={userSellLight||disableInput} AddErrorMsg={MainContext.AddErrorMsg}

							userLight={userSellLight} UserLightBtnOnClick={SellLightBtnOnClick}
							forceLight={fsellLight} setForceLight={setFSellLight}
							sideStyle={sellStyle} sideColor={sellColor}
							UBidPrice={UBidPrice} UAskPrice={UAskPrice} DBidPrice={DBidPrice} DAskPrice={DAskPrice} LotSize={LotSize}
						/>
					</div>
				</Card.Body>
			</Card>
		)
	}, [
		fontSize, shortcutKeySetting, BuyLightBtnOnClick, SellLightBtnOnClick,
		symbol, selectedSymbol, LastAllBar, CurrBar, asset,
		buyVolume, sellVolume, buySelectSMA, sellSelectSMA, userBuyLight, userSellLight, BMultiplier, SMultiplier, BCondition, SCondition,
		ap, at, tbu, tsu, UBidPrice, UAskPrice, DBidPrice, DAskPrice,
		bv, sv, maxB, minS, buyLight, sellLight, AsLight, fbuyLight, fsellLight, isPending,
		SMAValue, BShortSMAIndex, BLongSMAIndex,
		MaxAllowBuy, MinAllowSell
	])
}

export default CheapUTrader