import { useState, useMemo, useContext, useCallback } from "react";

import { ComponentProps, MainContextData } from "../MainPage";

import { Button, CloseButton, InputGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form"


function AdminPanel({onClose, context}: ComponentProps){
	const {ws, session} = useContext<MainContextData>(context)

    const [mtOffsetCorrection, setMTOffsetCorrection] = useState<""|number>("")
    const [mtSent, setMtSent] = useState(false)

	const UpdateMarketTriggerOffset = useCallback<React.FormEventHandler<HTMLFormElement>>((e)=>{
		e.preventDefault()
		e.stopPropagation()

		const formData = new FormData(e.currentTarget)
		const offsetCorrection = parseInt(formData.get("offsetCorrection")!.toString())

		if (isNaN(offsetCorrection)) return

        if (ws) {
            ws.send(JSON.stringify({
                e: "adminSetting",
                subE: "mtOffsetCorrection",
                sessionID: session,
                mtOffsetCorrection: mtOffsetCorrection
            }))

            setMtSent(true)
            setTimeout(() => setMtSent(false), 500)
        }
    }, [ws, session, mtOffsetCorrection])

	return useMemo(()=>{
		return (
			<div style={{display: "flex", flexDirection: "column", height: "100%"}}>
				<div style={{width: "100%", position: "sticky", display: "flex", backgroundColor: "#0d6efd"}}>
					<span className="text-white" style={{flex: 1, fontSize: 18, padding: 2, paddingLeft: 4}}>Admin Panel</span>
					<div style={{flex:0, backgroundColor: "#dc3545"}}>
						<CloseButton className="react-draggable-cancel" variant="white" style={{fontSize: 18, padding: 6}} onClick={onClose}/>
					</div>
				</div>
				<div className="react-draggable-cancel" style={{flex: 1, paddingLeft: 10, paddingRight: 10, overflow: "hidden", overflowY: "auto"}}>
					<Form onSubmit={UpdateMarketTriggerOffset}>
						<Form.Group>
						<Form.Label>Market Trigger Offset Correction</Form.Label>
							<InputGroup>
								<Form.Control type="number" name="offsetCorrection" defaultValue={0} placeholder="Time Offset" step={1} required/>
								<Button type="submit" variant={mtSent?"danger":"primary"} disabled={mtSent===true} style={{minWidth: 90}}>{mtSent?"Sended":"Update"}</Button>
							</InputGroup>
						</Form.Group>
					</Form>
				</div>
			</div>
		)
	}, [ onClose, mtOffsetCorrection, mtSent, UpdateMarketTriggerOffset ])
}

export default AdminPanel