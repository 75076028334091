import { useState, useEffect, useMemo, useCallback } from 'react'
import { Decimal } from 'decimal.js'

import { Button, InputGroup } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import SwitchableButton from '../global_component/SwitchableButton'

import { GetSpread, ReturnNextDownPrice, ReturnNextUpPrice } from '../../../functions/StockHandle'

function BuySellTriggerRow({
	price, setPrice,
	volume, setVolume, volumeSetting,
	color, fontSize, buySell,
	capBuyPriceLight, capSellPriceLight, capAllPriceLight,
	lightState, setLightState, startLightState, disableInput
}){
	const [PriceInput, setPriceInput] = useState(null)
	const [VolumeInput, setVolumeInput] = useState(null)

	useEffect(()=>{
		if (PriceInput){
			PriceInput.value = price
		}
	}, [PriceInput, price])

	useEffect(()=>{
		if (VolumeInput){
			VolumeInput.value = volume
		}
	}, [VolumeInput, volume])

	const PriceOnKeyPress = useCallback((e)=>{
		if (PriceInput && (e.code === "Enter" || e.code === "NumpadEnter" || e.type === "blur")){
			const parseValue = parseFloat(PriceInput.value)
			if (parseValue > 0){
				const value = new Decimal(parseValue)
				setPrice(value.minus(value.mod(GetSpread(value))).toNumber())
			}else{
				PriceInput.value = price
			}
		}
	}, [PriceInput, price])

	const VolumeOnKeyPress = useCallback((e)=>{
		if (VolumeInput && (e.code === "Enter" || e.code === "NumpadEnter" || e.type === "blur")){
			const parseValue = parseFloat(VolumeInput.value)
			if (parseValue >= 0 && parseValue <= volumeSetting.maxVolume){
				const value = new Decimal(parseValue)
				VolumeInput.value = value.minus(value.mod(0.01)).toNumber()
				setVolume(value.minus(value.mod(0.01)).toNumber())
			}else{
				VolumeInput.value = volume
			}
		}
	}, [VolumeInput, volume, setVolume, volumeSetting])

	const addBtnOnClick = useCallback(()=>{
		if (price !== "")
			setPrice((old)=>{
				return ReturnNextUpPrice(old)
			})
	}, [price])
	const minusBtnOnClick = useCallback(()=>{
		if (price !== ""){
			setPrice((old)=>{
				return ReturnNextDownPrice(old)
			})
		}
	}, [price])

	return useMemo(()=>{
		const padding = "0px 4px"

		return (
			<div style={{display: "flex", marginBottom: 6}}>
				<Form.Control className="react-draggable-cancel" type="text" ref={(r)=>{setPriceInput(r)}}  style={{width: 60, marginRight: 4, fontSize: fontSize, padding}} disabled={disableInput || (capBuyPriceLight && buySell === "Buy") || (capSellPriceLight && buySell === "Sell") || capAllPriceLight} onBlur={PriceOnKeyPress} onKeyPress={PriceOnKeyPress}/>
				<Button className="react-draggable-cancel" style={{width: 26.5, marginRight: 4, fontSize, padding}} onClick={minusBtnOnClick} disabled={disableInput || (capBuyPriceLight && buySell === "Buy") || (capSellPriceLight && buySell === "Sell") || capAllPriceLight}>-</Button>
				<Button className="react-draggable-cancel" style={{width: 26.5, marginRight: 4, fontSize, padding}} onClick={addBtnOnClick} disabled={disableInput || (capBuyPriceLight && buySell === "Buy") || (capSellPriceLight && buySell === "Sell") || capAllPriceLight}>+</Button>
				<InputGroup className="react-draggable-cancel" style={{width: 60}}>
					<InputGroup.Text style={{fontSize, padding}}>K</InputGroup.Text>
					<Form.Control type="text" ref={(r)=>{setVolumeInput(r)}} style={{fontSize, padding}} disabled={disableInput} onBlur={VolumeOnKeyPress} onKeyPress={VolumeOnKeyPress}/>
				</InputGroup>
				<div style={{flex: 1, fontSize: fontSize}}/>
				<SwitchableButton disabled={startLightState} className="react-draggable-cancel" style={{width: 60, fontSize: fontSize, padding}} state={lightState} setState={setLightState} color={color} value={buySell}/>
			</div>
		)
	}, [
		price, volume,
		color, disableInput, startLightState, lightState,
		capBuyPriceLight, capSellPriceLight, capAllPriceLight,
		VolumeOnKeyPress
	])
}

function AutoSellTriggerRow({color, fontSize, BSLightState, setBSLightState, AsLightState, setAsLightState, ap, setAP, startLightState, disableASLight}){
	const [APInput, setAPInput] = useState(null)

	useEffect(()=>{
		if (APInput){
			APInput.value = ap
		}
	}, [APInput, ap])

	const PriceOnKeyPress = useCallback((e)=>{
		if (APInput && (e.code === "Enter" || e.code === "NumpadEnter" || e.type === "blur")){
			const parseValue = parseFloat(APInput.value)
			if (parseValue >= 0.01){
				const value = new Decimal(parseValue)
				setAP(value.minus(value.mod(GetSpread(value))).toNumber())
			}else{
				APInput.value = ap
			}
		}
	}, [APInput, ap])

	const addBtnOnClick = useCallback(()=>{
		if (ap !== "")
			setAP((old)=>{
				return ReturnNextUpPrice(old)
			})
	}, [ap])
	const minusBtnOnClick = useCallback(()=>{
		if (ap !== ""){
			setAP((old)=>{
				return ReturnNextDownPrice(old)
			})
		}
	}, [ap])

	return useMemo(()=>{
		const padding = "0px 4px"

		return (
			<div style={{display: "flex"}}>
				<Form.Control className="react-draggable-cancel" type="text" ref={(r)=>{setAPInput(r)}} style={{width: 60, marginRight: 4, fontSize, padding}} onBlur={PriceOnKeyPress} onKeyPress={PriceOnKeyPress}/>
				<Button className="react-draggable-cancel" style={{width: 26.5, marginRight: 4, fontSize, padding}} onClick={minusBtnOnClick}>-</Button>
				<Button className="react-draggable-cancel" style={{width: 26.5, marginRight: 4, fontSize, padding}} onClick={addBtnOnClick}>+</Button>
				<SwitchableButton className="react-draggable-cancel" style={{width: 60, fontSize, padding}} state={AsLightState} setState={setAsLightState} color={color} value="Auto S" disabled={disableASLight}/>
				<div style={{flex: 1, fontSize: fontSize}}/>
				<SwitchableButton disabled={startLightState} className="react-draggable-cancel" style={{width: 60, fontSize, padding}} state={BSLightState} setState={setBSLightState} color={color} value="B&S"/>
			</div>
		)
	}, [color, startLightState, disableASLight, BSLightState, AsLightState, ap])
}

function UTriggerRow({
	color, fontSize,
	capBuyPriceLight, capSellPriceLight, capAllPriceLight,
	triggerUP, triggerUR, triggerUV, setTriggerUP, setTriggerUR, setTriggerUV,
	btnValue, btnState, setBtnState, disableBtn, disableInput
}){

	const [UPInput, setUPInput] = useState(null)
	const [URInput, setURInput] = useState(null)
	const [UVInput, setUVInput] = useState(null)

	useEffect(()=>{
		if (UPInput){
			if (parseFloat(UPInput.value) !== triggerUP)
				UPInput.value = triggerUP
		}
	}, [triggerUP, UPInput])
	useEffect(()=>{
		if (URInput){
			if (parseInt(URInput.value) !== triggerUR)
				URInput.value = triggerUR
		}
	}, [triggerUR, URInput])
	useEffect(()=>{
		if (UVInput){
			if (parseFloat(UVInput.value) !== triggerUV)
				UVInput.value = triggerUV
		}
	}, [triggerUV, UVInput])

	const UPOnKeyPress = useCallback((e)=>{
		if (UPInput && (e.code === "Enter" || e.code === "NumpadEnter" || e.type === "blur")){
			const parseValue = parseFloat(UPInput.value)
			if (parseValue >= 0.01){
				const value = new Decimal(parseValue)
				setTriggerUP(value.minus(value.mod(GetSpread(value))).toNumber())
			}else{
				UPInput.value = triggerUP
			}
		}
	}, [UPInput, triggerUP])

	const UROnKeyPress = useCallback((e)=>{
		if (URInput && (e.code === "Enter" || e.code === "NumpadEnter" || e.type === "blur")){
			const value = parseInt(URInput.value)
			if (value >= 0 && value < 100){
				URInput.value = triggerUR
				setTriggerUR(value)
			}else{
				URInput.value = triggerUR
			}
		}
	}, [URInput, triggerUR])

	const UVOnKeyPress = useCallback((e)=>{
		if (UVInput && (e.code === "Enter" || e.code === "NumpadEnter" || e.type === "blur")){
			const parseValue = parseFloat(UVInput.value)
			if (parseValue >= 0){
				const value = new Decimal(parseValue)
				UVInput.value = value.minus(value.mod(0.01)).toNumber()
				setTriggerUV(value.minus(value.mod(0.01)).toNumber())
			}else{
				UVInput.value = triggerUV
			}
		}
	}, [UVInput, triggerUV])

	const addBtnOnClick = useCallback(()=>{
		if (triggerUP !== "")
			setTriggerUP((old)=>{
				return ReturnNextUpPrice(old)
			})
	}, [triggerUP, setTriggerUP])
	const minusBtnOnClick = useCallback(()=>{
		if (triggerUP !== "")
			setTriggerUP((old)=>{
				return ReturnNextDownPrice(old)
			})
	}, [triggerUP, setTriggerUP])

	return useMemo(()=>{
		const padding = "0px 4px"

		return (
			<div className="react-draggable-cancel" style={{display: "flex", marginBottom: 6}}>
				<Form.Control type="text" style={{width: 60, marginRight: 4, fontSize, padding}} ref={(r)=>{setUPInput(r)}} disabled={disableInput || (capBuyPriceLight && btnValue === "Update") || (capSellPriceLight && btnValue === "Force") || capAllPriceLight} onBlur={UPOnKeyPress} onKeyPress={UPOnKeyPress}/>
				<Button style={{width: 26.5, marginRight: 4, fontSize, padding}} disabled={disableInput || (capBuyPriceLight && btnValue === "Update") || (capSellPriceLight && btnValue === "Force") || capAllPriceLight} onClick={minusBtnOnClick}>-</Button>
				<Button style={{width: 26.5, marginRight: 4, fontSize, padding}} disabled={disableInput || (capBuyPriceLight && btnValue === "Update") || (capSellPriceLight && btnValue === "Force") || capAllPriceLight} onClick={addBtnOnClick}>+</Button>
				<InputGroup style={{width: 60, marginRight: 4}}>
					<InputGroup.Text style={{fontSize, padding}}>%</InputGroup.Text>
					<Form.Control type="text" ref={(r)=>{setURInput(r)}} style={{fontSize, padding}} disabled={disableInput} onBlur={UROnKeyPress} onKeyPress={UROnKeyPress} />
				</InputGroup>
				<InputGroup style={{width: 60, marginRight: 4}}>
					<InputGroup.Text style={{fontSize, padding}}>K</InputGroup.Text>
					<Form.Control type="text" ref={(r)=>{setUVInput(r)}} style={{fontSize, padding}} disabled={disableInput} onBlur={UVOnKeyPress} onKeyPress={UVOnKeyPress} />
				</InputGroup>
				<div className="react-draggable-handle" style={{flex: 1}}></div>
				<SwitchableButton style={{width: 60, fontSize: fontSize, padding: "0px 4px"}} disabled={disableBtn} state={btnState} setState={setBtnState} color={color} value={btnValue}/>
			</div>
		)
	}, [
		color, fontSize, disableInput,
		capBuyPriceLight, capSellPriceLight, capAllPriceLight, 
		btnState, disableBtn, UPOnKeyPress, triggerUP, triggerUR, triggerUV
	])
}

function StartRow({color, fontSize, startLightState, startBtnOnClick, disableStartBtn}){
	return useMemo(()=>{
		const padding = "0px 4px"
		
		return (
			<div style={{display: "flex", marginBottom: 6}}>
				<div className="react-draggable-handle" style={{flex: 1, fontSize: fontSize}}/>
				<SwitchableButton className="react-draggable-cancel" style={{width: 60, fontSize: fontSize, padding}} state={startLightState} disabled={disableStartBtn} onClick={startBtnOnClick} color={color} value={startLightState?"Stop":"Start"}/>
			</div>
		)
	}, [color, startLightState, disableStartBtn, startBtnOnClick])
}

function ControlPanel(
	{
		color, volumeSetting, fontSize, AvaliablePts,
		capBuyPriceLight, capSellPriceLight, capAllPriceLight,
		maxB, setMaxB, buyVolume, setBuyVolume,
		minS, setMinS, sellVolume, setSellVolume,
		tbu, br, bv, setTBU, setBR, setBV,
		tsu, sr, sv, setTSU, setSR, setSV,
		AsLight, setAsLight, ap, setAP,
		triggerBuyLight, setTriggerBuyLight,
		triggerSellLight, setTriggerSellLight,
		triggerBSLight, setTriggerBSLight,
		startLight, setStartLight,
		updateLight, setUpdateLight,
		forceLight, setForceLight
	}
){

	const [startBtnOnClick, disableStartBtn] =  useMemo(() => {
		if (startLight){
			return [()=>{
				setStartLight(false)
			}, false]
		}else{
			if (triggerBuyLight || triggerSellLight || triggerBSLight)
				return [()=>{
					setStartLight(true)
				}, false]
			else
				return [()=>{}, true]
		}
	}, [startLight, triggerBuyLight, triggerSellLight, triggerBSLight])

	return useMemo(() => {
		return (
			<div style={{marginTop: 4, flex: 1, display: "flex", flexDirection: "column"}}>
				<BuySellTriggerRow buySell={"Buy"} fontSize={fontSize} price={maxB} setPrice={setMaxB}
					volume={buyVolume} setVolume={setBuyVolume} volumeSetting={volumeSetting}
					capBuyPriceLight={capBuyPriceLight} capSellPriceLight={capSellPriceLight} capAllPriceLight={capAllPriceLight}
					color={color.buyColor} disableInput={startLight && !updateLight}
					startLightState={startLight} lightState={triggerBuyLight} setLightState={setTriggerBuyLight}
				/>
				<BuySellTriggerRow buySell={"Sell"} fontSize={fontSize} price={minS} setPrice={setMinS}
					volume={sellVolume} setVolume={setSellVolume} volumeSetting={volumeSetting}
					capBuyPriceLight={capBuyPriceLight} capSellPriceLight={capSellPriceLight} capAllPriceLight={capAllPriceLight}
					color={color.sellColor} disableInput={startLight && !updateLight}
					startLightState={startLight} lightState={triggerSellLight} setLightState={setTriggerSellLight}
				/>
				<AutoSellTriggerRow fontSize={fontSize} color={color.AsColor}
					startLightState={startLight} BSLightState={triggerBSLight} setBSLightState={setTriggerBSLight}
					AsLightState={AsLight} setAsLightState={setAsLight} ap={ap} setAP={setAP} disableASLight={sellVolume === 0 || (AvaliablePts === 0 && !startLight && !triggerSellLight)}
				/>
				<div style={{flex:1}}></div>
				<StartRow color={color.AsColor} fontSize={fontSize} startLightState={startLight} disableStartBtn={disableStartBtn} startBtnOnClick={startBtnOnClick}/>
				<UTriggerRow color={color.sellColor} fontSize={fontSize}
					capBuyPriceLight={capBuyPriceLight} capSellPriceLight={capSellPriceLight} capAllPriceLight={capAllPriceLight}
					triggerUP={tbu} triggerUR={br} triggerUV={bv} 
					setTriggerUP={setTBU} setTriggerUR={setBR} setTriggerUV={setBV}
					btnValue={"Update"} disableBtn={!startLight} disableInput={startLight && !updateLight} btnState={updateLight} setBtnState={setUpdateLight}
				/>
				<UTriggerRow color={color.buyColor} fontSize={fontSize}
					capBuyPriceLight={capBuyPriceLight} capSellPriceLight={capSellPriceLight} capAllPriceLight={capAllPriceLight}
					triggerUP={tsu} triggerUR={sr} triggerUV={sv}
					setTriggerUP={setTSU} setTriggerUR={setSR} setTriggerUV={setSV}
					btnValue={"Force"} disableBtn={!startLight} disableInput={startLight && !updateLight} btnState={forceLight} setBtnState={setForceLight}
				/>
			</div>
		)
	}, [
		color, volumeSetting, AvaliablePts,
		maxB, minS, buyVolume, sellVolume, 
		capBuyPriceLight, capSellPriceLight, capAllPriceLight,
		triggerBuyLight, triggerSellLight, triggerBSLight, AsLight,
		startLight, updateLight, forceLight,
		tbu, br, bv,
		tsu, sr, sv,
		ap
	])
}

export default ControlPanel