import { useState, useEffect, useRef, useMemo, useContext, useCallback } from 'react'
import { Decimal } from 'decimal.js'

import { StatusType } from '../../../api/enum'
import { GetSpread, GetSpreadTableType, ReturnNextDownPrice, ReturnNextNUpPrice, ReturnNextUpPrice } from '../../../functions/StockHandle'

import { Button } from 'react-bootstrap'
import Form from "react-bootstrap/Form"
import InputGroup from 'react-bootstrap/InputGroup'
import Card from "react-bootstrap/Card"
import SwitchableButton from '../global_component/SwitchableButton'

import Symbol from './CheapUTrader/Symbol'
import { CustomSMA } from '../../../functions/CustomMath'
import ErrorMsg from '../../../functions/ErrorMsg'
import Sounds from '../../../functions/SoundHandler'

const RowModifyType = {
	buyLight: 1,
	sellLight: 2,

	buyVolume: 3,
	sellVolume: 4,

	bv: 5,
	sv: 6,
	br: 7,
	sr: 8,
	tbu: 9,
	tsu: 10,
	maxB: 11,
	minS: 12,

	AsLight: 13,
    ap: 14,
    at: 15,

	BShortSMAIndex: 16,
	BLongSMAIndex: 17,
	SShortSMAIndex: 18,
	SLongSMAIndex: 19,
	BCondition: 20,
	SCondition: 21,
	BMultiplier: 22,
	SMultiplier: 23,
	BMinTO: 24,
	SMinTO: 25,

	runningMode: 26,
	userBuyLight: 27,
	userSellLight: 28,
}

const OrderStatus = {
	Full: 3,
	Partial: 2,
	Cancel: 4,
	Reject: -1
}

const OrderSide = {
	Buy: 1,
	Sell: 2
}

function returnStyle(color, fontSize){
	let style = {}
	style.InputClass = "react-draggable-cancel form-control border-"+color
	style.InputStyle = {padding: "0 4px", fontSize: fontSize, borderColor: color}
	style.InputButtonStyle = {flex: 1, padding: "0 4px", fontSize: fontSize, borderColor: color, color: color}
	style.LabelClass = "text-white"
	style.LabelStyle = {padding: "0 4px", fontSize: fontSize, backgroundColor: color, borderColor: color}
	return style
}

function parseNumber(num){
    if (num >= 1000){
        return Math.round(num/10) /100 + "K"
    }
    return num
}

function UserHolds({Pts, AvaliablePts, Cost, DBidPrice, fontSize, buyColor, sellColor, AsColor}){
	return useMemo(()=>{
		let FPnL = Math.round((DBidPrice-Cost)*Pts*100)/100
		const PtsColor = Pts>0?AsColor:undefined;
		const PnlColor = FPnL>0?buyColor:FPnL<0?sellColor:undefined;

		return (
			<div style={{display: "flex"}}>
				<InputGroup style={{fontSize: fontSize, width: "fit-content", marginLeft: 4}}>
					<InputGroup.Text style={{padding: "0 4px", fontSize: fontSize}}>Cost</InputGroup.Text>
					<InputGroup.Text style={{padding: "0 4px", fontSize: fontSize}}>{Cost}</InputGroup.Text>
				</InputGroup>
				<InputGroup style={{fontSize: fontSize, width: "fit-content", marginLeft: 4}}>
					<InputGroup.Text style={{padding: "0 4px", fontSize: fontSize}}>Ava.Pts</InputGroup.Text>
					<InputGroup.Text className={AvaliablePts>0?"text-white":""} style={{padding: "0 4px", fontSize: fontSize, backgroundColor: PtsColor}}>{parseNumber(AvaliablePts)}</InputGroup.Text>
				</InputGroup>
				<InputGroup style={{fontSize: fontSize, width: "fit-content", marginLeft: 4}}>
					<InputGroup.Text style={{padding: "0 4px", fontSize: fontSize}}>F.PnL</InputGroup.Text>
					<InputGroup.Text className={Math.abs(FPnL)>0?"text-white":""} style={{padding: "0 4px", fontSize: fontSize, backgroundColor: PnlColor}}>{FPnL}</InputGroup.Text>
				</InputGroup>
			</div>
		)
	}, [Pts, AvaliablePts, Cost, DBidPrice])
}

function MultiplierInput({fontSize, disableInput, Multiplier, setMultiplier}){
	const [MultiplierInput, setMultiplierInput] = useState(null)

	useEffect(()=>{
		if (MultiplierInput){
			MultiplierInput.value = Multiplier
		}
	}, [Multiplier, setMultiplierInput])

	const MultiplierKeyPress = useCallback((e)=>{
			const value = e.target.value.trim()
			if (value !== ""){
				const parsedValue = parseFloat(value)
				if (parsedValue > 0){
					setMultiplier(parsedValue)
					return
				}
			}
			else if (e.target.value === ""){
				setMultiplier(1)
				return
			}
			e.target.value = Multiplier
	}, [Multiplier])

	return useMemo(()=>{
		return (
			<InputGroup style={{flex: 2}}>
				<InputGroup.Text style={{fontSize: fontSize, padding: "0px 4px"}}>Multiplier</InputGroup.Text>
				<Form.Control type='text' defaultValue={Multiplier} ref={(r)=>{setMultiplierInput(r)}} disabled={disableInput} style={{fontSize: fontSize, padding: "0px 4px"}}
					onBlur={MultiplierKeyPress}
				/>
			</InputGroup>
		)
	}, [fontSize, disableInput, MultiplierKeyPress])
}

function UserInputs({
	fontSize, shortcutKeySetting, disabledInput,
	buyStyle, sellStyle,
	tbu, setTBU, tsu, setTSU, maxB, setMaxB, minS, setMinS,
	UBidPrice, UAskPrice, DBidPrice, DAskPrice,
	bv, setBV, sv, setSV
}){
	const [BVInput, setBVInput] = useState(null)
	const [SVInput, setSVInput] = useState(null)

	useEffect(()=>{
		if (BVInput){
			BVInput.value = bv
		}
	}, [bv, BVInput])
	useEffect(()=>{
		if (SVInput){
			SVInput.value = sv
		}
	}, [sv, SVInput])

	const TBUKeyPress = useCallback((e)=>{
		if (e.code === shortcutKeySetting.capBidKey)
			setTBU(UBidPrice)
		else if (e.code === shortcutKeySetting.capAskKey)
			setTBU(UAskPrice)
		else if (e.code === shortcutKeySetting.addKey)
			setTBU((currentValue)=>{return ReturnNextUpPrice(currentValue)})
		else if (e.code === shortcutKeySetting.minusKey)
			setTBU((currentValue)=>{return ReturnNextDownPrice(currentValue)})
	}, [shortcutKeySetting, UBidPrice, UAskPrice])

	const TSUKeyPress = useCallback((e)=>{
		if (e.code === shortcutKeySetting.capBidKey)
			setTSU(UBidPrice)
		else if (e.code === shortcutKeySetting.capAskKey)
			setTSU(UAskPrice)
		else if (e.code === shortcutKeySetting.addKey)
			setTSU((currentValue)=>{return ReturnNextUpPrice(currentValue)})
		else if (e.code === shortcutKeySetting.minusKey)
			setTSU((currentValue)=>{return ReturnNextDownPrice(currentValue)})
	}, [shortcutKeySetting, UBidPrice, UAskPrice])

	const MaxBKeyPress = useCallback((e)=>{
		if (e.code === shortcutKeySetting.capBidKey)
			setMaxB(DBidPrice)
		else if (e.code === shortcutKeySetting.capAskKey)
			setMaxB(DAskPrice)
		else if (e.code === shortcutKeySetting.addKey)
			setMaxB((last)=>ReturnNextUpPrice(last))
		else if (e.code === shortcutKeySetting.minusKey)
			setMaxB((last)=>ReturnNextDownPrice(last))
	}, [shortcutKeySetting, DBidPrice, DAskPrice])

	const MinSKeyPress = useCallback((e)=>{
		if (e.code === shortcutKeySetting.capBidKey)
			setMinS(DBidPrice)
		else if (e.code === shortcutKeySetting.capAskKey)
			setMinS(DAskPrice)
		else if (e.code === shortcutKeySetting.addKey)
			setMinS((last)=>ReturnNextUpPrice(last))
		else if (e.code === shortcutKeySetting.minusKey)
			setMinS((last)=>ReturnNextDownPrice(last))
	}, [shortcutKeySetting, DBidPrice, DAskPrice])

	const BVKeyPress = useCallback((e)=>{
			const value = e.target.value.trim()
			if (value !== ""){
				const parsedValue = parseInt(value)
				if (parsedValue >= 1){
					setBV(parsedValue)
					return
				}
			}
			else if (e.target.value === ""){
				setBV(0)
				return
			}
			e.target.value = bv
	}, [bv])

	const SVKeyPress = useCallback((e)=>{
			const value = e.target.value.trim()
			if (value !== ""){
				const parsedValue = parseInt(value)
				if (parsedValue >= 1){
					setSV(parsedValue)
					return
				}
			}
			else if (e.target.value === ""){
				setSV(0)
				return
			}
			e.target.value = sv
	}, [sv])

	return useMemo(()=>{
		return (
			<>
				<InputGroup style={{flex: 2}}>
					<InputGroup.Text className={buyStyle.LabelClass} style={buyStyle.LabelStyle}>BV(K)</InputGroup.Text>
					<Form.Control type='text' ref={(r)=>{setBVInput(r)}} disabled={disabledInput} className={buyStyle.InputClass} style={buyStyle.InputStyle}
						onBlur={BVKeyPress}
					/>
				</InputGroup>
				<InputGroup style={{flex: 2}}>
					<InputGroup.Text className={buyStyle.LabelClass} style={buyStyle.LabelStyle}>TBU</InputGroup.Text>
					<Button className='bg-white' style={buyStyle.InputButtonStyle} disabled={disabledInput}
						onDoubleClick={()=>{setTBU(UAskPrice)}}
						onKeyPress={TBUKeyPress}
					>
						{tbu}
					</Button>
				</InputGroup>
				<InputGroup style={{marginLeft: 4, fontSize: fontSize, width: 100}}>
					<InputGroup.Text className={buyStyle.LabelClass} style={buyStyle.LabelStyle}>MaxB</InputGroup.Text>
					<Button disabled={disabledInput} className="react-draggable-cancel bg-white" style={buyStyle.InputButtonStyle}
						onDoubleClick={()=>{ setMaxB(DAskPrice) }}
						onKeyPress={MaxBKeyPress}
					>
						{maxB}
					</Button>
				</InputGroup>
				<InputGroup style={{flex: 2}}>
					<InputGroup.Text className={sellStyle.LabelClass} style={sellStyle.LabelStyle}>MinS</InputGroup.Text>
					<Button disabled={disabledInput} className="react-draggable-cancel bg-white" style={sellStyle.InputButtonStyle}
						onDoubleClick={()=>{ setMinS(DBidPrice) }}
						onKeyPress={MinSKeyPress}
					>
						{minS}
					</Button>
				</InputGroup>
				<InputGroup style={{flex: 2}}>
					<InputGroup.Text className={sellStyle.LabelClass} style={sellStyle.LabelStyle}>TSU</InputGroup.Text>
					<Button className='bg-white' style={sellStyle.InputButtonStyle} disabled={disabledInput}
						onDoubleClick={()=>{setTSU(UBidPrice)}}
						onKeyPress={TSUKeyPress}
					>
						{tsu}
					</Button>
				</InputGroup>
				<InputGroup style={{flex: 2}}>
					<InputGroup.Text className={sellStyle.LabelClass} style={sellStyle.LabelStyle}>SV(K)</InputGroup.Text>
					<Form.Control type='text' ref={(r)=>{setSVInput(r)}} disabled={disabledInput} className={sellStyle.InputClass} style={sellStyle.InputStyle}
						onBlur={SVKeyPress}
					/>
				</InputGroup>
			</>
		)
	}, [
		fontSize, disabledInput,
		tbu, tsu, bv, sv, maxB, minS,
		TBUKeyPress, MaxBKeyPress, BVKeyPress,
		TSUKeyPress, MinSKeyPress, SVKeyPress,
		UBidPrice, UAskPrice, DBidPrice, DAskPrice
	])
}

function SMASelect({fontSize, disableInput, SMAValue, SelectedSMA, setSelectedSMA}){
	return useMemo(()=>{
		return (
			<>
				<InputGroup style={{flex: 3, minWidth: 130, display: "flex"}}>
					<InputGroup.Text style={{fontSize: fontSize, padding: "0px 4px"}}>MA(3)</InputGroup.Text>
					<InputGroup.Text style={{fontSize: fontSize, padding: "0px 4px"}}>
						<Form.Check disabled={disableInput} checked={SelectedSMA["3"]} onChange={()=>{
							setSelectedSMA((last)=>{
								const newSelection = {}
								Object.keys(last).forEach((x)=>{
									newSelection[x] = last[x]
								})
								newSelection["3"] = !last["3"]
								return newSelection
							})
						}}/>
					</InputGroup.Text>
					<InputGroup.Text style={{fontSize: fontSize, padding: "0px 4px", flex:1}}>{SMAValue["3"]}</InputGroup.Text>
				</InputGroup>
				<InputGroup style={{flex: 3, minWidth: 130, display: "flex"}}>
					<InputGroup.Text style={{fontSize: fontSize, padding: "0px 4px"}}>MA(7)</InputGroup.Text>
					<InputGroup.Text style={{fontSize: fontSize, padding: "0px 4px"}}>
						<Form.Check disabled={disableInput} checked={SelectedSMA["7"]} onChange={()=>{
							setSelectedSMA((last)=>{
								const newSelection = {}
								Object.keys(last).forEach((x)=>{
									newSelection[x] = last[x]
								})
								newSelection["7"] = !last["7"]
								return newSelection
							})
						}}/>
					</InputGroup.Text>
					<InputGroup.Text style={{fontSize: fontSize, padding: "0px 4px", flex:1}}>{SMAValue["7"]}</InputGroup.Text>
				</InputGroup>
				<InputGroup style={{flex: 3, minWidth: 130, display: "flex"}}>
					<InputGroup.Text style={{fontSize: fontSize, padding: "0px 4px"}}>MA(12)</InputGroup.Text>
					<InputGroup.Text style={{fontSize: fontSize, padding: "0px 4px"}}>
						<Form.Check disabled={disableInput} checked={SelectedSMA["12"]} onChange={()=>{
							setSelectedSMA((last)=>{
								const newSelection = {}
								Object.keys(last).forEach((x)=>{
									newSelection[x] = last[x]
								})
								newSelection["12"] = !last["12"]
								return newSelection
							})
						}}/>
					</InputGroup.Text>
					<InputGroup.Text style={{fontSize: fontSize, padding: "0px 4px", flex:1}}>{SMAValue["12"]}</InputGroup.Text>
				</InputGroup>
			</>
		)
	}, [fontSize, disableInput, SMAValue, SelectedSMA])
}

function Conditions({fontSize, disableInput, condition, setCondition}){
	return useMemo(()=>{
		return (
			<div style={{display: "flex", minWidth: 'fit-content', gap: "4px"}}>
				{
					Object.keys(condition).map((x)=>{
						return (
							<InputGroup key={x} style={{minWidth: "fit-content"}}>
								<InputGroup.Text style={{fontSize: fontSize, padding: "0px 4px"}}>{x}</InputGroup.Text>
								<InputGroup.Text style={{fontSize: fontSize, padding: "0px 4px"}}>
									<Form.Check checked={condition[x]} disabled={disableInput} onChange={(e)=>{
										setCondition((last)=>{
											const newCondition = {}
											Object.keys(last).forEach((y)=>{
												newCondition[y] = last[y]
											})
											newCondition[x] = !last[x]
											return newCondition
										})
									}}/>
								</InputGroup.Text>
							</InputGroup>
						)
					})
				}
			</div>
		)
	}, [fontSize, disableInput, condition])
}

function CheapUTrader(props){
	const MainContext = useContext(props.context)
    const AllowAddRow = useRef(false)
	const AllowModify = useRef(false)

	const [updateTimer, setUpdateTimer] = useState(null)
	const [updater, setUpdater] = useState(true)

	const rowKey = useMemo(()=>{return props.rowKey}, [])
    const [rowID, setRowID] = useState(null)
	
	const [symbol, setSymbol] = useState("")
	const [selectedSymbol, setSelectedSymbol] = useState(null)

	const [underlying, callPut] = useMemo(()=>{
        let [underlying, callPut] = [selectedSymbol, "Call"]

        if (selectedSymbol){
            if (selectedSymbol.length === 5){
                let data = []
                if (selectedSymbol[0] === "1" || selectedSymbol[0] === "2")
                    data = MainContext.warrants.filter((x)=>{
                        if (x[1] === selectedSymbol)
                            return true
                        return false
                    })
                else if (selectedSymbol[0] === "5" || selectedSymbol[0] === "6")
                    data = MainContext.cbbcs.filter((x)=>{
                        if (x[1] === selectedSymbol)
                            return true
                        return false
                    })
                
                if (data.length === 1){
                    underlying = data[0][3]
                    callPut = data[0][5]
                }
            }
        }

        return [underlying, callPut]
    }, [selectedSymbol, MainContext.warrants, MainContext.cbbcs, MainContext.ws])

	const asset = useMemo(()=>{
		const result = MainContext.assets.filter((x)=>{
			if (x.symbol === selectedSymbol)
				return true
			return false
		})

		if (result.length !== 1)
			return {Pnl: 0, Pts: 0, Cost: 0, Available_Pts: 0}

		return result[0]
	}, [MainContext.assets, selectedSymbol])

	const [UBidPrice, setUBidPrice] = useState(0)
    const [UBidVolume, setUBidVolume] = useState(0)
    const [UAskPrice, setUAskPrice] = useState(0)
    const [UAskVolume, setUAskVolume] = useState(0)
	const [DBidPrice, setDBidPrice] = useState(0)
    const [DBidVolume, setDBidVolume] = useState(0)
    const [DAskPrice, setDAskPrice] = useState(0)
    const [DAskVolume, setDAskVolume] = useState(0)

	const fontSize = useMemo(()=>{
		return props.fontSize
	}, [props.fontSize])

	const {buyColor, sellColor, AsColor, callColor, putColor} = useMemo(()=>{
		return props.color
	}, [props.color])
	const {maxVolume, defaultVolume} = useMemo(()=>{
		return props.volumeSetting
	}, [props.volumeSetting])
	const shortcutKeySetting = useMemo(()=>{
		return props.shortcutKeySetting
	}, [props.shortcutKeySetting])

	const buyStyle = useMemo(()=>{return (returnStyle(buyColor, fontSize))}, [buyColor, fontSize])
	const sellStyle = useMemo(()=>{return (returnStyle(sellColor, fontSize))}, [sellColor, fontSize])
	const AsStyle = useMemo(()=>{return (returnStyle(AsColor, fontSize))}, [AsColor, fontSize])

	const [buyLight, setBuyLight] = useState(false)
	const [sellLight, setSellLight] = useState(false)
	const [fbuyLight, setFBuyLight] = useState(false)
	const [fsellLight, setFSellLight] = useState(false)

	const [SMA3, setSMA3] = useState(new CustomSMA(3))
	const [SMA7, setSMA7] = useState(new CustomSMA(7))
	const [SMA12, setSMA12] = useState(new CustomSMA(12))
	const [SMAValue, setSMAValue] = useState({"3":0, "7":0, "12":0})

	const [LastAllBar, setLastAllBar] = useState([])
	const tmpAllBar = useRef([])
	const [CurrBar, setCurrBar] = useState(null)
	const loadingBar = useRef(false)

	const [userBuyLight, setUserBuyLight] = useState(false)
	const [runningMode, setRunningMode] = useState(0)
	const [volume, setVolume] = useState(defaultVolume)
	const [userMultiplier, setUserMultiplier] = useState(1)
	const [userSelectSMA, setUserSelectSMA] = useState({"3":true, "7": true, "12": false})
	const [shortSMAIndex, longSMAIndex] = useMemo(()=>{
		let short = null, long = null, error = false

		Object.keys(userSelectSMA).sort((a,b)=>{return parseInt(a) - parseInt(b)}).forEach((x)=>{
			if (!error && userSelectSMA[x] === true){
				if (short === null) short = x
				else if (long === null) long = x
				else error = true
			}
		})

		return (error?[null, null]:[short, long])
	}, [userSelectSMA])
	const [userCondition, setUserCondition] = useState({"BV/SV": false, SMA: false, TOJump: false})

	const [tbu, setTBU] = useState(0)
	const [maxB, setMaxB] = useState(0)
	const [br, setBR] = useState(0)
	const [bv, setBV] = useState(0)
	const [tsu, setTSU] = useState(0)
	const [minS, setMinS] = useState(0)
	const [sr, setSR] = useState(0)
	const [sv, setSV] = useState(0)

	const [AsLight, setAsLight] = useState(false)

	const [MaxAllowBuy, MinAllowSell] = useMemo(()=>{
		let maxAllowBuy = null
		let minAllowSell = null

        let maxPendBuy = null
        let minPendSell = null
		MainContext.pending.forEach((x)=>{
			if (x.symbol === selectedSymbol)
				if (x.side === OrderSide.Buy)
                    maxPendBuy = (maxPendBuy===null?x.price:Math.max(maxPendBuy, x.price))
				else if (x.side === OrderSide.Sell)
                    minPendSell = (minPendSell===null?x.price:Math.min(minPendSell, x.price))
		})

		MainContext.pendingRows.forEach((x)=>{
			if (x.symbol === selectedSymbol)
                minPendSell = (minPendSell===null?x.price:Math.min(minPendSell, x.price))
		})

        MainContext.marketTriggers.forEach((x)=>{
			if (x.symbol === selectedSymbol)
				if (x.side === OrderSide.Buy)
                    maxPendBuy = (maxPendBuy===null?x.price:Math.max(maxPendBuy, x.price))
				else if (x.side === OrderSide.Sell)
                    minPendSell = (minPendSell===null?x.price:Math.min(minPendSell, x.price))
		})

        maxAllowBuy = minPendSell===null?99999.0:ReturnNextDownPrice(new Decimal(minPendSell).div(1000).toNumber())
        minAllowSell = maxPendBuy===null?0.01:ReturnNextUpPrice(new Decimal(maxPendBuy).div(1000).toNumber())

		return [maxAllowBuy, minAllowSell]
	}, [selectedSymbol, MainContext.pending, MainContext.pendingRows, MainContext.marketTriggers])
	useEffect(()=>{
		if (MaxAllowBuy < maxB){
			setUserBuyLight(false)
		}

		if (MinAllowSell > minS){
			setSellLight(false)
			setAsLight(false)
		}
	}, [MaxAllowBuy, MinAllowSell, maxB, minS])

	const cleanUP = useCallback(() => {
        const setNull = [setRowID, setCurrBar]
        setNull.forEach((e)=>{ e(null) });

        const setFalse = [setBuyLight, setSellLight, setUserBuyLight, setAsLight]
        setFalse.forEach((e)=>{ e(false) });

        const setZero = [
			setTBU, setTSU, setMaxB, setMinS
			, setUBidPrice, setUBidVolume, setUAskPrice, setUAskVolume, setDBidPrice, setDAskPrice, setDBidVolume, setDAskVolume
			, setBR, setBV, setSR, setSV
		]
        setZero.forEach((e)=>{ e(0) })

		const setOne = [setUserMultiplier]
		setOne.forEach((e)=>{ e(1) })

		const setEmptyString = []
		setEmptyString.forEach((e)=>{ e("") })

		const setEmptyArr = [setLastAllBar]
		setEmptyArr.forEach((e)=>{ e([]) })
		tmpAllBar.current = []

		setSMA3(new CustomSMA(3))
		setSMA7(new CustomSMA(7))
		setSMA12(new CustomSMA(12))

		setVolume(defaultVolume)
    }, [])

	useEffect(()=>{
        if (MainContext.session){
			cleanUP()
			setSymbol("")
			setSelectedSymbol(null)

			AllowAddRow.current = false
            MainContext.ws.send(JSON.stringify({e: "trader", subE: "loadRow", rowKey: rowKey, sessionID: MainContext.session}))

			if (updateTimer === null){
				setUpdateTimer(setInterval(()=>{
					setUpdater((last)=>!last)
				},5))
			}
        }
    }, [MainContext.session])

	useEffect(()=>{
        if (!MainContext.traderStream)
            return

        const traderStream = MainContext.traderStream
        if (traderStream.subE === "createRow"){
            if (traderStream.rowKey === rowKey && traderStream.status === StatusType.Success){
                setRowID(traderStream.rowID)
			}
			else if(traderStream.rowKey === rowKey && traderStream.status === StatusType.Error)
			{
				AllowAddRow.current = true
				AllowModify.current = true
				loadingBar.current = false
				cleanUP()
				setSymbol("")
                setSelectedSymbol(null)
			}
        }
        else if (traderStream.subE === "loadRow"){
            if (traderStream.rowKey === rowKey){
                if (traderStream.status === StatusType.Success){
                    AllowAddRow.current = false
					AllowModify.current = false
                    setRowID(traderStream.row.rowID)
                    setSelectedSymbol(traderStream.row.symbol)
                    setSymbol(traderStream.row.symbol)

					setUserBuyLight(traderStream.row.userBuyLight)
					setBuyLight(traderStream.row.buyLight)
					setSellLight(traderStream.row.sellLight)
					setAsLight(traderStream.row.AsLight)

					setVolume(traderStream.row.buyVolume/1000)
					
					setBV(traderStream.row.bv/1000)
					setSV(traderStream.row.sv/1000)
					setBR(traderStream.row.br)
					setSR(traderStream.row.sr)
					setTBU(traderStream.row.tbu/1000)
					setTSU(traderStream.row.tsu/1000)
					setMaxB(traderStream.row.maxB/1000)
					setMinS(traderStream.row.minS/1000)

					const SelectedSMA = {"3": false, "7":false, "12":false}
					if (traderStream.row.BShortSMAIndex != null)
						SelectedSMA[traderStream.row.BShortSMAIndex] = true
					if (traderStream.row.BLongSMAIndex != null)
						SelectedSMA[traderStream.row.BLongSMAIndex] = true
					setUserSelectSMA(SelectedSMA)
					setUserCondition(traderStream.row.BCondition)
					setUserMultiplier(traderStream.row.BMultiplier)
					setUserBuyLight(traderStream.row.userBuyLight)
					setRunningMode(traderStream.row.runningMode)

                    setTimeout(()=>{
                        AllowAddRow.current = true
						AllowModify.current = true
                    }, 5)
                }else{
					AllowAddRow.current = true
					AllowModify.current = true
                    cleanUP()
                }
            }
        }
		else if (traderStream.subE === "serverRes_Order"){
			const serverRes_Order = traderStream.serverRes_Order
			if (serverRes_Order.RowID === rowID){
				if (serverRes_Order.Status === OrderStatus.Full || serverRes_Order.Status === OrderStatus.Reject){
					AllowModify.current = false
					if (serverRes_Order.OSide === OrderSide.Buy){
						setBuyLight(false)
						setFBuyLight(false)
						setUserBuyLight(false)
						if (AsLight){
							AllowModify.current = true
							setSellLight(true)
						}
					}
					else if (serverRes_Order.OSide === OrderSide.Sell){
						setSellLight(false)
						setFSellLight(false)
					}
					setTimeout(()=>{
						AllowModify.current = true
					}, 5)
				}
			}
		}
		else if (traderStream.subE === "Quote"){
            if (traderStream.s === underlying && AllowModify.current){
                setUBidPrice(new Decimal(traderStream.quote.bidP).div(1000).toNumber())
                setUAskPrice(new Decimal(traderStream.quote.askP).div(1000).toNumber())
                setUBidVolume(traderStream.quote.bidS)
                setUAskVolume(traderStream.quote.askS)
            }
            if (traderStream.s === selectedSymbol && AllowModify.current){
				const bidPrice = new Decimal(traderStream.quote.bidP).div(1000).toNumber()
				const askPrice = new Decimal(traderStream.quote.askP).div(1000).toNumber()
                setDBidPrice(bidPrice)
                setDAskPrice(askPrice)
                setDBidVolume(traderStream.quote.bidS)
                setDAskVolume(traderStream.quote.askS)
            }
        }
    }, [MainContext.traderStream])

	useEffect(()=>{
		if (AllowModify.current === true){
			cleanUP()
		}

        if (AllowAddRow.current === true){
            if (!rowID && selectedSymbol){
                MainContext.ws.send(JSON.stringify({
					e: "trader",
					subE: "createRow",
					sessionID: MainContext.session,
					row: {
						rowKey: rowKey,
						symbol: selectedSymbol,

						buyLight: false,
						sellLight: false,

						buyVolume: defaultVolume * 1000,
						sellVolume: defaultVolume * 1000,

						bv: 0,
						sv: 0,
						br: 0,
						sr: 0,
						tbu: 0,
						tsu: 0,
						maxB: 0,
						minS: 0,

						AsLight: false,
						ap: 0,
						at: 1,

						BShortSMAIndex: shortSMAIndex,
						BLongSMAIndex: longSMAIndex,
						BCondition: userCondition,
						BMultiplier: userMultiplier,
						userBuyLight: userBuyLight,
						runningMode: runningMode
					}
				}))
            }
			else{
                MainContext.ws.send(JSON.stringify({e: "trader", subE: "delRow", sessionID: MainContext.session, rowKey: rowKey}))
				if (selectedSymbol){
					MainContext.ws.send(JSON.stringify({
						e: "trader",
						subE: "createRow",
						sessionID: MainContext.session,
						row: {
							rowKey: rowKey,
							symbol: selectedSymbol,

							buyLight: false,
							sellLight: false,

							buyVolume: defaultVolume * 1000,
							sellVolume: defaultVolume * 1000,

							bv: 0,
							sv: 0,
							br: 0,
							sr: 0,
							tbu: 0,
							tsu: 0,
							maxB: 0,
							minS: 0,

							AsLight: false,
							ap: 0,
							at: 1,

							BShortSMAIndex: shortSMAIndex,
							BLongSMAIndex: longSMAIndex,
							BCondition: userCondition,
							BMultiplier: userMultiplier,
							userBuyLight: userBuyLight,
							runningMode: runningMode
						}
					}))
				}
            }
        }
    }, [selectedSymbol])

	//Get Quote When getNewRowID
	useEffect(()=>{
		if (rowID && selectedSymbol){
			loadingBar.current = true
			MainContext.ws.send(JSON.stringify({e: "getKlineChart", s: selectedSymbol, interval: 0.5}))

			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "subscribeRow",
				sessionID: MainContext.session,
				row: { rowID: rowID }
			}))
		}
	}, [selectedSymbol, rowID])

	useEffect(()=>{
		const klineData = MainContext.klineData
		if (klineData.s === selectedSymbol && loadingBar.current === true){
			if (klineData.status === StatusType.EOF){
				setSMAValue({"3": Math.round(SMA3.VAL*1000)/1000, "7": Math.round(SMA7.VAL*1000)/1000, "12": Math.round(SMA12.VAL*1000)/1000})
				setLastAllBar((last)=>[...tmpAllBar.current])
				loadingBar.current = false
			}else{
				const k = klineData.k
				k.time = k.time * 1000
				if (new Date(k.time).getHours() === 12) return
				const bar = {time: k.time, open: k.o, close: k.c, low: k.l, high: k.h, TO: (k.v * k.c)}

				SMA3.Add(bar.close)
				SMA7.Add(bar.close)
				SMA12.Add(bar.close)

				tmpAllBar.current.push(bar)
			}
		}
	}, [MainContext.klineData, selectedSymbol])
	useEffect(()=>{
		if (loadingBar.current === true) return
		const tradeData = MainContext.tradeData
		if (selectedSymbol === tradeData[1]){
			const tradeP = parseFloat(tradeData[2])
			if (tradeP === 0) return
			const tradeVol = parseFloat(tradeData[3])
			const tradeTime = parseInt(tradeData[6]) * 1000
			const tradeTimeBase = tradeTime - (tradeTime % 30000)

			setCurrBar((last)=>{
				if (last === null || last.time !== tradeTimeBase){
					if (last !== null){
						SMA3.Add(last.close)
						SMA7.Add(last.close)
						SMA12.Add(last.close)

						setSMAValue({"3": Math.round(SMA3.VAL*1000)/1000, "7": Math.round(SMA7.VAL*1000)/1000, "12": Math.round(SMA12.VAL*1000)/1000})

						setLastAllBar((curr)=>[...curr, last])
					}
					return {
						time: tradeTimeBase,
						open: tradeP, close: tradeP, low: tradeP, high: tradeP,
						TO: tradeP*tradeVol
					}
				}
				
				return {
					time: tradeTimeBase,
					open: last.open, close: tradeP, low: Math.min(tradeP,last.low), high: Math.max(tradeP,last.high),
					TO: last.TO + tradeP*tradeVol
				}
			})
		}
	}, [selectedSymbol, MainContext.tradeData])

	// Send Modified value to server
	useEffect(()=>{
		if (rowID && AllowModify.current){
			if (volume >= 0){
				MainContext.ws.send(JSON.stringify({
					e: "trader",
					subE: "modifyRow",
					sessionID: MainContext.session,
					rowModifyType: RowModifyType.buyVolume,
					row: { rowID: rowID, buyVolume: volume*1000 }
				}))
				MainContext.ws.send(JSON.stringify({
					e: "trader",
					subE: "modifyRow",
					sessionID: MainContext.session,
					rowModifyType: RowModifyType.sellVolume,
					row: { rowID: rowID, sellVolume: volume*1000 }
				}))
				if (volume === 0){
					setBuyLight(false)
					setSellLight(false)
				}
			}
		}
	}, [volume])
	useEffect(()=>{
		if (rowID && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.bv,
				row: { rowID: rowID, bv: bv*1000 }
			}))
		}
	}, [bv])
	useEffect(()=>{
		if (rowID && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.sv,
				row: { rowID: rowID, sv: sv*1000 }
			}))
		}
	}, [sv])
	useEffect(()=>{
		if (rowID && tbu > 0 && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.tbu,
				row: { rowID: rowID, tbu: tbu*1000 }
			}))
		}
	}, [tbu])
	useEffect(()=>{
		if (rowID && tsu > 0 && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.tsu,
				row: { rowID: rowID, tsu: tsu*1000 }
			}))
		}
	}, [tsu])
	useEffect(()=>{
		if (rowID && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.br,
				row: { rowID: rowID, br: br }
			}))
		}
	}, [br, tbu])
	useEffect(()=>{
		if (rowID && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.sr,
				row: { rowID: rowID, sr: sr }
			}))
		}
	}, [sr, tsu])
	useEffect(()=>{
		if (rowID){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.maxB,
				row: { rowID: rowID, maxB: maxB*1000 }
			}))
		}
	}, [maxB])
	useEffect(()=>{
		if (rowID){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.minS,
				row: { rowID: rowID, minS: minS*1000 }
			}))
		}
	}, [minS])
	useEffect(()=>{
		if (rowID){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.BShortSMAIndex,
				row: { rowID: rowID, BShortSMAIndex: shortSMAIndex }
			}))
		}
	}, [shortSMAIndex])
	useEffect(()=>{
		if (rowID){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.BLongSMAIndex,
				row: { rowID: rowID, BLongSMAIndex: longSMAIndex }
			}))
		}
	}, [longSMAIndex])
	useEffect(()=>{
		if (rowID){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.BCondition,
				row: { rowID: rowID, BCondition: userCondition }
			}))
		}
	}, [userCondition])
	useEffect(()=>{
		if (rowID){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.BMultiplier,
				row: { rowID: rowID, BMultiplier: userMultiplier }
			}))
		}
	}, [userMultiplier])
	useEffect(()=>{
		if (rowID){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.runningMode,
				row: { rowID: rowID, runningMode: runningMode }
			}))
		}
	}, [runningMode])
	useEffect(()=>{
		if (rowID){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.userBuyLight,
				row: { rowID: rowID, userBuyLight: userBuyLight }
			}))
		}
	}, [userBuyLight])
	useEffect(()=>{
		if (rowID && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.buyLight,
				row: { rowID: rowID, buyLight: buyLight }
			}))
		}
	}, [buyLight])
	useEffect(()=>{
		if (rowID && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.sellLight,
				row: { rowID: rowID, sellLight: sellLight }
			}))
		}
	}, [sellLight])
	useEffect(()=>{
		if (rowID && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.AsLight,
				row: { rowID: rowID, AsLight: AsLight }
			}))
		}
	}, [AsLight])
	useEffect(()=>{
		if (rowID && AllowModify.current && fbuyLight){
			if (MaxAllowBuy < DAskPrice){
				Sounds.Error()
				MainContext.AddErrorMsg(Date.now(), selectedSymbol, ErrorMsg.NotAllowBuyWhenPending)
				setFBuyLight(false)
				return
			}

			if (volume <= 0){
				MainContext.AddErrorMsg(Date.now(), selectedSymbol, ErrorMsg.VolumeError)
				Sounds.Error()
				setFBuyLight(false)
				return
			}

			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "forceRow",
				sessionID: MainContext.session,
				row: { rowID: rowID, buyLight: true, sellLight: false}
			}))
		}
	}, [fbuyLight])
	useEffect(()=>{
		if (rowID && AllowModify.current && fsellLight){
			if (MinAllowSell > DBidPrice){
				Sounds.Error()
				MainContext.AddErrorMsg(Date.now(), selectedSymbol, ErrorMsg.NotAllowSellWhenPending)
				setFSellLight(false)
				return
			}

			if (volume <= 0){
				MainContext.AddErrorMsg(Date.now(), selectedSymbol, ErrorMsg.VolumeError)
				Sounds.Error()
				setFSellLight(false)
				return
			}

			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "forceRow",
				sessionID: MainContext.session,
				row: { rowID: rowID, buyLight: false, sellLight: true}
			}))
		}
	}, [fsellLight])

	useEffect(()=>{
        if (UBidPrice !== 0 && UAskPrice !== 0 && rowID){
			if (tbu === 0)
                if (callPut === "Call" || callPut === "Bull")
                    setTBU(UAskPrice)
                else
                    setTBU(UBidPrice)
            if (tsu === 0)
                if (callPut === "Call" || callPut === "Bull")
                    setTSU(UBidPrice)
                else
                    setTSU(UAskPrice)
        }
    }, [UBidPrice, UAskPrice])
	useEffect(()=>{
		if (DBidPrice > 0 && DAskPrice > 0 && rowID){
			if (maxB === 0)
				setMaxB(DAskPrice)
			if (minS === 0)
				setMinS(DBidPrice)

			if (loadingBar.current === true) return
			const currTime = new Date()
			const currTimeBase = currTime - (currTime % 30000)
			if (DBidPrice < DAskPrice && currTime.getHours() !== 12){
				setCurrBar((last)=>{
					if (last === null || last.time !== currTimeBase){
						if (last !== null){
							SMA3.Add(last.close)
							SMA7.Add(last.close)
							SMA12.Add(last.close)
	
							setSMAValue({"3": Math.round(SMA3.VAL*1000)/1000, "7": Math.round(SMA7.VAL*1000)/1000, "12": Math.round(SMA12.VAL*1000)/1000})
	
							setLastAllBar((curr)=>[...curr, last])
						}
						return {
							time: currTimeBase,
							open: DBidPrice, close: DBidPrice, low: DBidPrice, high: DBidPrice,
							TO: 0
						}
					}
					
					return {
						time: currTimeBase,
						open: last.open, close: DBidPrice, low: Math.min(DBidPrice,last.low), high: Math.max(DBidPrice,last.high),
						TO: last.TO
					}
				})
			}
		}
	}, [DBidPrice, DAskPrice, updater])

	useEffect(()=>{
		if (LastAllBar.length === 0 || CurrBar == null || !AllowModify.current){
			if (AllowModify.current){
				setBuyLight(false)
				setSellLight(false)
			}
			return
		}

		if (userBuyLight){
			if (runningMode === 0){
				let inputFailure = false
				if (userCondition["SMA"] && (shortSMAIndex === null || longSMAIndex === null || SMAValue[shortSMAIndex] >= SMAValue[longSMAIndex]) ){
					MainContext.AddErrorMsg(Date.now(), selectedSymbol, "Short SMA can't >= Long SMA when turning on Buy Light")
					inputFailure = true
				}
				else if (userCondition["TOJump"] && (typeof(userMultiplier) != 'number' || userMultiplier <= 0))
					inputFailure = true
				else if (userCondition["BV/SV"] && (typeof(userMultiplier) != 'number' || bv <= 0))
					inputFailure = true

				if (inputFailure){
					setUserBuyLight(false)
					return
				}

				//Only SMA
				if (userCondition["SMA"] && !userCondition["TOJump"] && !userCondition["BV/SV"])
					setRunningMode(1)
				//Only TOJump
				else if (userCondition["TOJump"] && !userCondition["SMA"] && !userCondition["BV/SV"])
					setRunningMode(2)
				//Only BV
				else if (userCondition["BV/SV"] && !userCondition["SMA"] && !userCondition["TOJump"])
					setRunningMode(3)
				// SMA and TOJump
				else if (userCondition["SMA"] && userCondition["TOJump"] && !userCondition["BV/SV"])
					setRunningMode(4)
				// SMA and BV
				else if (userCondition["SMA"] && !userCondition["TOJump"] && userCondition["BV/SV"])
					setRunningMode(5)
				// TOJump and BV
				else if (!userCondition["SMA"] && userCondition["TOJump"] && userCondition["BV/SV"])
					setRunningMode(6)
				// ALL
				else if (userCondition["SMA"] && userCondition["TOJump"] && userCondition["BV/SV"])
					setRunningMode(7)
				else
					setUserBuyLight(false)
			}
			else{
				const SMACondition = !userCondition["SMA"] || (SMAValue[shortSMAIndex] >= SMAValue[longSMAIndex])
				const TOJumpCondition = !userCondition["TOJump"] || (CurrBar.TO >= LastAllBar[LastAllBar.length - 1].TO * userMultiplier)

				if (runningMode === 1){
					if (SMACondition){
						setUserBuyLight(false)
						MainContext.ws.send(JSON.stringify({
							e: "trader",
							subE: "forceRow",
							sessionID: MainContext.session,
							row: { rowID: rowID, buyLight: true, sellLight: false, ap: maxB * 1000}
						}))
					}
				}
				else if (runningMode === 2){
					if (TOJumpCondition){
						setUserBuyLight(false)
						MainContext.ws.send(JSON.stringify({
							e: "trader",
							subE: "forceRow",
							sessionID: MainContext.session,
							row: { rowID: rowID, buyLight: true, sellLight: false, ap: maxB * 1000}
						}))
					}
				}
				else if (runningMode === 3){
					if (!buyLight) setBuyLight(true)
				}
				else if (runningMode === 4){
					if (SMACondition && TOJumpCondition){
						setUserBuyLight(false)
						MainContext.ws.send(JSON.stringify({
							e: "trader",
							subE: "forceRow",
							sessionID: MainContext.session,
							row: { rowID: rowID, buyLight: true, sellLight: false, ap: maxB * 1000}
						}))
					}
				}
				else if (runningMode === 5){
					if (SMACondition && !buyLight) setBuyLight(true)
					else if (!SMACondition && buyLight) setBuyLight(false)
				}
				else if (runningMode === 6){
					if (TOJumpCondition && !buyLight) setBuyLight(true)
					else if (!TOJumpCondition && buyLight) setBuyLight(false)
				}
				else if (runningMode === 7){
					if (SMACondition && TOJumpCondition && !buyLight) setBuyLight(true)
					else if ((!SMACondition || !TOJumpCondition) && buyLight) setBuyLight(false)
				}
			}
		}
		else{
			setBuyLight(false)
			setRunningMode(0)
		}
	}, [
		userBuyLight, buyLight, runningMode,
		userCondition, maxB, volume, shortSMAIndex, longSMAIndex, userMultiplier, bv,
		SMAValue, LastAllBar, CurrBar, DAskVolume
	])

	const BuyLightBtnOnClick = useCallback(()=>{
		if (userBuyLight === true){
			setUserBuyLight(false)
			return
		}

		if (MaxAllowBuy < maxB){
			Sounds.Error()
			MainContext.AddErrorMsg(Date.now(), selectedSymbol, ErrorMsg.NotAllowBuyWhenPending)
			return
		}

		if (volume <= 0){
			Sounds.Error()
			MainContext.AddErrorMsg(Date.now(), selectedSymbol, ErrorMsg.VolumeError)
			return
		}

		//Check Any One Condition is on
		if (!userCondition["SMA"] && !userCondition["TOJump"] && !userCondition["BV/SV"]){
			Sounds.Error()
			MainContext.AddErrorMsg(Date.now(), selectedSymbol, ErrorMsg.NoConditionError)
			return
		}

		//Check input valid
		let inputFailure = false
		if (userCondition["SMA"] && (shortSMAIndex === null || longSMAIndex === null )){
			MainContext.AddErrorMsg(Date.now(), selectedSymbol, ErrorMsg.SMASelectionError)
			inputFailure = true
		}
		else if (userCondition["TOJump"] && (typeof(userMultiplier) != 'number' || userMultiplier <= 0)){
			MainContext.AddErrorMsg(Date.now(), selectedSymbol, ErrorMsg.TOMultiplierError)
			inputFailure = true
		}
		else if (userCondition["BV/SV"] && (typeof(bv) != 'number' || bv <= 0)){
			MainContext.AddErrorMsg(Date.now(), selectedSymbol, ErrorMsg.BVError)
			inputFailure = true
		}
		if (inputFailure){
			Sounds.Error()
			return
		}

		setUserBuyLight(true)
	}, [MainContext.AddErrorMsg, MaxAllowBuy, maxB, volume, userBuyLight, selectedSymbol, userCondition, userMultiplier, shortSMAIndex, longSMAIndex, bv])

	const SellLightBtnOnClick = useCallback(()=>{
		if (sellLight) setSellLight(false)

		if (MinAllowSell > minS){
			Sounds.Error()
			MainContext.AddErrorMsg(Date.now(), selectedSymbol, ErrorMsg.NotAllowSellWhenPending)
			return
		}

		if (volume <= 0){
			Sounds.Error()
			MainContext.AddErrorMsg(Date.now(), selectedSymbol, ErrorMsg.VolumeError)
			return
		}

		//Check input valid
		let inputFailure = false
		if (sv <= 0) {
			MainContext.AddErrorMsg(Date.now(), selectedSymbol, ErrorMsg.SVError)
			inputFailure = true
		}
		if (inputFailure){
			Sounds.Error()
			return
		}

		setSellLight(true)
	}, [selectedSymbol, MainContext.AddErrorMsg, MinAllowSell, minS, sellLight, volume, sv])

	return useMemo(()=>{
		const disableSymbolInput = userBuyLight||sellLight
		const disableInput = selectedSymbol===null
		const disableCondition = (userBuyLight||sellLight||selectedSymbol===null)

		const SpreadLength = (("" + GetSpread(DBidPrice, GetSpreadTableType(selectedSymbol))).split(".")[1]??"").length

		return (
			<Card tabIndex={5} style={{marginBottom: "4px"}} className='border rounded'>
				<Card.Header style={{display: "flex", padding: "4px", gap: "4px"}}>
					<Symbol fontSize={fontSize} disableInput={disableInput} disableSymbolInput={disableSymbolInput}
						symbol={symbol} setSymbol={setSymbol} selectedSymbol={selectedSymbol} setSelectedSymbol={setSelectedSymbol}
						volume={volume} setVolume={setVolume} defaultVolume={defaultVolume} maxVolume={maxVolume}
						userBuyLight={userBuyLight} BuyLightBtnOnClick={BuyLightBtnOnClick}
						sellLight={sellLight} SellLightBtnOnClick={SellLightBtnOnClick}
						AsLight={AsLight} setAsLight={setAsLight}
						shortcutKeySetting={shortcutKeySetting} buyColor={buyColor} sellColor={sellColor} AsColor={AsColor}
					/>
					<div style={{flex: 1}}></div>
					<Conditions fontSize={fontSize} disableInput={disableCondition} condition={userCondition} setCondition={setUserCondition}/>
					<div style={{display: "flex", flex: 1, gap: 4}}>
						<InputGroup style={{flex: 1, minWidth: "9rem"}}>
							<InputGroup.Text style={{fontSize: fontSize, padding: "0px 4px"}}>LastTO(K)</InputGroup.Text>
							<InputGroup.Text style={{fontSize: fontSize, padding: "0px 4px", flex:1}}>{LastAllBar.length?Math.round(LastAllBar[LastAllBar.length-1].TO/1000):""}</InputGroup.Text>
						</InputGroup>
						<InputGroup style={{flex: 1, minWidth: "9rem"}}>
							<InputGroup.Text style={{fontSize: fontSize, padding: "0px 4px"}}>CurrTO(K)</InputGroup.Text>
							<InputGroup.Text style={{fontSize: fontSize, padding: "0px 4px", flex:1}}>{CurrBar?Math.round(CurrBar.TO/1000):""}</InputGroup.Text>
						</InputGroup>
					</div>
					<SwitchableButton value={"FB: "+ DAskPrice.toFixed(SpreadLength)} disabled={sellLight || selectedSymbol === null}
						color={buyColor} style={{fontSize: fontSize, padding: "0px 4px", minWidth: 80}} state={fbuyLight} setState={setFBuyLight}
					/>
					<SwitchableButton value={"FS: "+ DBidPrice.toFixed(SpreadLength)} disabled={sellLight || selectedSymbol === null}
						color={sellColor} style={{fontSize: fontSize, padding: "0px 4px", minWidth: 80}} state={fsellLight} setState={setFSellLight}
					/>
				</Card.Header>
				<Card.Body style={{padding: "4px"}}>
					<div style={{display: "flex", gap: "4px", width: "100%"}}>
						<UserInputs fontSize={fontSize} disabledInput={disableInput}
							Multiplier={userMultiplier} setMultiplier={setUserMultiplier}
							tbu={tbu} setTBU={setTBU} UBidPrice={UBidPrice} shortcutKeySetting={shortcutKeySetting}
							tsu={tsu} setTSU={setTSU} UAskPrice={UAskPrice}
							maxB={maxB} setMaxB={setMaxB} DBidPrice={DBidPrice}
							minS={minS} setMinS={setMinS} DAskPrice={DAskPrice}
							bv={bv} setBV={setBV} buyStyle={buyStyle}
							sv={sv} setSV={setSV} sellStyle={sellStyle}
						/>
						<SMASelect fontSize={fontSize} disableInput={disableCondition} SMAValue={SMAValue}
							SelectedSMA={userSelectSMA} setSelectedSMA={setUserSelectSMA}
						/>
						<MultiplierInput fontSize={fontSize} disableInput={disableInput} Multiplier={userMultiplier} setMultiplier={setUserMultiplier}/>
					</div>
				</Card.Body>
			</Card>
		)
	}, [
		fontSize,
		symbol, selectedSymbol, LastAllBar, CurrBar,
		volume, userSelectSMA, userBuyLight, userMultiplier, userCondition,
		tbu, tsu, UBidPrice, UAskPrice, DBidPrice, DAskPrice,
		bv, sv, maxB, minS, buyLight, sellLight, AsLight, fbuyLight, fsellLight,
		BuyLightBtnOnClick, SellLightBtnOnClick,
		SMAValue, shortSMAIndex, longSMAIndex
	])
}

export default CheapUTrader