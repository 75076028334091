import { Decimal } from 'decimal.js'

export enum SpreadTableType {
    Normal  = 0,
    ETF     = 1,
    Cash    = 2
}
const SpreadTableMap : {[key: string]: SpreadTableType} = {}

async function LoadSpreadTableMap() {
    if (Object.keys(SpreadTableMap).length !== 0) return

    const resp = await (await fetch("https://archive.aq-techs.com/csv/spread_table_map.csv")).text()
    const lines = resp.split("\n")

    lines.forEach((x)=>{
        const trimedLine = x.trim()
        if (trimedLine.length === 0) return

        const parts = trimedLine.split(",")
        SpreadTableMap[parts[0]] = (
            parts[1].startsWith("D") ? SpreadTableType.ETF :
            parts[1].startsWith("B") ? SpreadTableType.Cash :
            SpreadTableType.Normal
        )
    })
}

function GetSpreadTableType(symbol: string | number) {
    if (typeof(symbol) === "string")
        symbol = symbol.trim()
    if (typeof(symbol) === "number")
        symbol = symbol.toString()

    if (Object.keys(SpreadTableMap).indexOf(symbol) === -1)
        return SpreadTableType.Normal

    return SpreadTableMap[symbol]
}

function GetSpread(price: number, spread_table_type: SpreadTableType = SpreadTableType.Normal) {
    if (spread_table_type === SpreadTableType.ETF){
        if (price < 0.01)   return 0.000
        if (price < 1)      return 0.001
        if (price < 5)      return 0.002
        if (price < 10)     return 0.005
        if (price < 20)     return 0.010
        if (price < 100)    return 0.020
        if (price < 200)    return 0.050
        if (price < 500)    return 0.100
        if (price < 1000)   return 0.200
        if (price < 2000)   return 0.500
        return 1.000
    }
    else if (spread_table_type === SpreadTableType.Cash){
        return 0.050
    }

    if (price < 0.01)   return 0.000
    if (price < 0.25)   return 0.001
    if (price < 0.5)    return 0.005
    if (price < 10)     return 0.010
    if (price < 20)     return 0.020
    if (price < 100)    return 0.050
    if (price < 200)    return 0.100
    if (price < 500)    return 0.200
    if (price < 1000)   return 0.500
    return 1.000
}

function ReturnNextUpPrice(price: number, spread_table_type: SpreadTableType = SpreadTableType.Normal) {
    let spread = new Decimal(0);
    if (spread_table_type === SpreadTableType.Normal){
        if (price < 0.01)        spread = new Decimal(0.000)
        else if (price < 0.25)   spread = new Decimal(0.001)
        else if (price < 0.5)    spread = new Decimal(0.005)
        else if (price < 10)     spread = new Decimal(0.010)
        else if (price < 20)     spread = new Decimal(0.020)
        else if (price < 100)    spread = new Decimal(0.050)
        else if (price < 200)    spread = new Decimal(0.100)
        else if (price < 500)    spread = new Decimal(0.200)
        else if (price < 1000)   spread = new Decimal(0.500)
        else                     spread = new Decimal(1.000)
    }
    else if (spread_table_type === SpreadTableType.ETF){
        if (price < 0.01)        spread = new Decimal(0.000)
        else if (price < 1)      spread = new Decimal(0.001)
        else if (price < 5)      spread = new Decimal(0.002)
        else if (price < 10)     spread = new Decimal(0.005)
        else if (price < 20)     spread = new Decimal(0.010)
        else if (price < 100)    spread = new Decimal(0.020)
        else if (price < 200)    spread = new Decimal(0.050)
        else if (price < 500)    spread = new Decimal(0.100)
        else if (price < 1000)   spread = new Decimal(0.200)
        else if (price < 2000)   spread = new Decimal(0.500)
        else                     spread = new Decimal(1.000)
    }
    else if (spread_table_type === SpreadTableType.Cash){
        if (price < 0.5)         spread = new Decimal(0.000)
        else                     spread = new Decimal(0.050)
    }
    return spread.add(price).toNumber();
}

function ReturnNextDownPrice(price: number, spread_table_type: SpreadTableType = SpreadTableType.Normal) {
    let spread = new Decimal(0);
    if (spread_table_type === SpreadTableType.Normal){
        if (price <= 0.01)        spread = new Decimal(0.000)
        else if (price <= 0.25)   spread = new Decimal(0.001)
        else if (price <= 0.5)    spread = new Decimal(0.005)
        else if (price <= 10)     spread = new Decimal(0.010)
        else if (price <= 20)     spread = new Decimal(0.020)
        else if (price <= 100)    spread = new Decimal(0.050)
        else if (price <= 200)    spread = new Decimal(0.100)
        else if (price <= 500)    spread = new Decimal(0.200)
        else if (price <= 1000)   spread = new Decimal(0.500)
        else spread = new Decimal(1.000)
    }
    else if (spread_table_type === SpreadTableType.ETF){
        if (price <= 0.01)        spread = new Decimal(0.000)
        else if (price <= 1)      spread = new Decimal(0.001)
        else if (price <= 5)      spread = new Decimal(0.002)
        else if (price <= 10)     spread = new Decimal(0.005)
        else if (price <= 20)     spread = new Decimal(0.010)
        else if (price <= 100)    spread = new Decimal(0.020)
        else if (price <= 200)    spread = new Decimal(0.050)
        else if (price <= 500)    spread = new Decimal(0.100)
        else if (price <= 1000)   spread = new Decimal(0.200)
        else if (price <= 2000)   spread = new Decimal(0.500)
        else spread = new Decimal(1.000)
    }
    else if (spread_table_type === SpreadTableType.Cash){
        if (price <= 0.5)         spread = new Decimal(0.000)
        else                      spread = new Decimal(0.050)
    }
    return new Decimal(price).minus(spread).toNumber();
}

function ReturnNextNUpPrice(price: number, n: number, spread_table_type: SpreadTableType = SpreadTableType.Normal) {
    let newPrice = price;
    for (let i = 0; i < n; i++)
        newPrice = ReturnNextUpPrice(newPrice, spread_table_type)
    return newPrice;
}

function ReturnNextNDownPrice(price: number, n: number, spread_table_type: SpreadTableType = SpreadTableType.Normal) {
    let newPrice = price;
    for (let i = 0; i < n; i++)
        newPrice = ReturnNextDownPrice(newPrice, spread_table_type)
    return newPrice;
}

export { LoadSpreadTableMap, GetSpread, GetSpreadTableType, ReturnNextUpPrice, ReturnNextDownPrice, ReturnNextNUpPrice, ReturnNextNDownPrice }