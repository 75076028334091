import { useCallback, useContext, useMemo, useState } from "react";
import { Button, Form, InputGroup, CloseButton } from "react-bootstrap";
import { OrderSide, OrderType, TIF } from "../../functions/Enum";

function DemoPanel({onClose, context}){
    const MainContext = useContext(context)

    const [side, setSide] = useState(OrderSide.Buy)
    const onsubmit = useCallback(async (e)=>{
        e.preventDefault()
        e.stopPropagation()
        
        const formData = new FormData(e.currentTarget)
        const symbol = formData.get("symbol")
        const price = parseFloat(formData.get("price"))
        const qty = parseFloat(formData.get("qty"))
        const tif = parseInt(formData.get("tif"))
        const timeInterval = parseInt(formData.get("timeInterval"))
        const count = parseInt(formData.get("count"))

        const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

        // console.log(symbol, side, price, qty, tif, timeInterval, count)

        for (let i = 0; i < count; i++) {
            MainContext.ws.send(JSON.stringify({
                e: "trader",
                subE: "pending",
                sessionID: MainContext.session,
                pending: {
                    symbol: symbol,
                    side: side,
                    price: Math.round(price * 1000),
                    volume: qty,
                    OrderType: OrderType.Limit,
                    tif: tif
                }
            }))

            await sleep(timeInterval)
        }
    }, [MainContext.ws, side])

    return useMemo(()=>{
        return (
            <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
                <div style={{width: "100%", position: "sticky", display: "flex", backgroundColor: "#0d6efd"}}>
                    <span className="text-white" style={{flex: 1, fontSize: 18, padding: 2, paddingLeft: 4}}>Demo Panel</span>
                    <div style={{flex:0, backgroundColor: "#dc3545"}}>
                        <CloseButton className="react-draggable-cancel" variant="white" style={{fontSize: 18, padding: 6}} onClick={onClose}/>
                    </div>
                </div>
                <div style={{padding: "0.5rem"}}>
                    <Form style={{display: "flex", flexDirection: "column", gap: "0.5rem"}} onSubmit={onsubmit}>
                        <InputGroup>
                            <InputGroup.Text>Symbol</InputGroup.Text>
                            <Form.Control type="text" name="symbol" required/>
                        </InputGroup>
                        <InputGroup style={{display: "flex"}}>
                            <Button style={{flex: 1}} className={side===OrderSide.Buy?"btn-success":"btn-secondary"} onClick={()=>setSide(OrderSide.Buy)}>Buy</Button>
                            <Button style={{flex: 1}} className={side===OrderSide.Sell?"btn-danger":"btn-secondary"} onClick={()=>setSide(OrderSide.Sell)}>Sell</Button>
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Text>Price</InputGroup.Text>
                            <Form.Control type="text" name="price" required/>
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Text>Qty</InputGroup.Text>
                            <Form.Control type="text" name="qty" required/>
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Text>TIF</InputGroup.Text>
                            <Form.Select name="tif" required>
                                { Object.entries(TIF).map((x)=><option key={x[1]} value={x[1]}>{x[0]}</option>) }
                            </Form.Select>
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Text>Time Interval(ms)</InputGroup.Text>
                            <Form.Control type="text" name="timeInterval" defaultValue={100} required/>
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Text>Count</InputGroup.Text>
                            <Form.Control type="text" name="count" defaultValue={1} required/>
                        </InputGroup>
                        <div className="d-grid">
                            <Button type="submit">Send</Button>
                        </div>
                    </Form>
                </div>
            </div>
        )
    }, [onClose, onsubmit])
}

export default DemoPanel