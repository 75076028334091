import { useState, useCallback, useContext, useMemo } from 'react'

import { Button } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'

import TradeRecord from './QuotoPanel/TradeRecord'
import OrderBook from './QuotoPanel/OrderBook'
import InputValidation from '../../functions/InputValidation'

function QuotoPanel({context, onClose}){
	const MainContext = useContext(context)

	const [symbol, setSymbol] = useState("")
	const [subscribeSymbol, setSubSymbol] = useState("")

	const formHandle = useCallback((e)=>{
		e.preventDefault();
		e.stopPropagation();

		const cleanSpaceSymbol = symbol.replaceAll(" ", "").replaceAll(".", "").replaceAll("+","")

		if (cleanSpaceSymbol !== "" && cleanSpaceSymbol !== subscribeSymbol){
			MainContext.ws.send(JSON.stringify({e: "getQuote", s: cleanSpaceSymbol}))
			setSubSymbol(cleanSpaceSymbol)
		}
	}, [symbol, MainContext.ws, subscribeSymbol])

	const tradeData = useMemo(()=>{
		if (subscribeSymbol === MainContext.tradeData[1])
			return MainContext.tradeData
		return null
	}, [subscribeSymbol, MainContext.tradeData])

	const quoteData = useMemo(()=>{
		if (subscribeSymbol === MainContext.quoteData[1])
			return MainContext.quoteData
		return null
	}, [subscribeSymbol, MainContext.quoteData])

	return useMemo(()=>{
		return (
			<>
				<span className="react-close-handle react-draggable-cancel" onClick={()=>{onClose()}}>x</span>
				<div style={{padding: 10, height: "100%", display: "flex", flexDirection: "column"}}>
					<Form className="mb-2" style={{flex: 0, height: "fit-content"}} noValidate onSubmit={formHandle}>
						<div className="react-draggable-cancel" style={{display: "flex", gap: 8}}>
							<div style={{flex: 9}}>
								<InputGroup>
									<InputGroup.Text style={{fontSize: 12, fontWeight: "bold", padding: "4px 12px"}}>Symbol</InputGroup.Text>
									<Form.Control type="text" style={{fontSize: 12, padding: "4px 12px"}} onChange={(v)=>{
										const value = v.target.value.trim()
										if (!InputValidation.integer(value)){
											v.target.value = symbol
											return
										}
										v.target.value = value
										setSymbol(v.target.value)
									}}/>
								</InputGroup>
							</div>
							<div className="d-grid" style={{flex: 3}}>
								<Button style={{fontSize: 12, padding: "4px 12px"}} type="submit">Search</Button>
							</div>
						</div>
					</Form>
					<div className='noScrollbar' style={{flex: 1, overflowY: "scroll"}}>
						<TradeRecord symbol={subscribeSymbol} tradeData={tradeData} queueData={quoteData}/>
						<OrderBook symbol={subscribeSymbol} quoteData={quoteData}/>
					</div>
				</div>
			</>
		)
	}, [onClose, symbol, subscribeSymbol, tradeData, quoteData, formHandle])
}

export default QuotoPanel