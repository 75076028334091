import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { StatusType } from "../api/enum"
import { LoginRequest } from '../api/request';
import { ResponseContainer } from '../api/response'
import { Connection } from '../functions/Connection'

import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import { DropdownButton } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';

function LoginPage(){
    const { reason } = useParams()

    const [connection, setWS] = useState<Connection|null>(null)
    const [connState, setConnState] = useState<boolean|number>(false)

    const [username, setUsername] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [errMsg, setErrMsg] = useState<string>("")

    useEffect(()=>{
        if (reason === "logout" || reason === "forceLogout") {
            delete(localStorage.userID)
            Object.keys(localStorage).forEach((x)=>{
                if (x.indexOf("sessionID-") === 0)
                    delete(localStorage[x])
            })
        }
    }, [reason])

    const onMsg = useCallback((msg: string)=>{
        if (msg === "") return

        const jsonData : ResponseContainer = JSON.parse(msg)
        if (jsonData.e === "login") {
            switch (jsonData.status) {
                case StatusType.Success:
                    localStorage.userID = jsonData.userID
                    window.location.assign("/main")
                    break;
                case StatusType.Error:
                    setErrMsg("Username or Password Wrong!")
                    break;
                case StatusType.ServerTimeout:
                    setErrMsg("Server Timeout.")
                    break;
            }
        }
    }, [])

    useEffect(()=>{
        if (connection === null) {
            setWS(new Connection(setConnState))
            return
        }

        const conn = connection
        conn.on_message  = onMsg
        conn.connect()

        return () => {
            conn.close()
        }
    }, [connection, onMsg])

    useEffect(()=>{
        if (connState !== true){
            setErrMsg("Server isn't connected." + (connState===false?"":connState>0?" Retry in " + connState + " seconds.":" Retry!"))
            return
        }
        
        setErrMsg("")
    }, [connState])

    const formHandle = useCallback((e) => {
        e.preventDefault()
        e.stopPropagation()

        if (username === "") {
            setErrMsg("Username is required.")
            return
        }
        if (password === ""){
            setErrMsg("Password is required.")
            return
        }

        if (connection) connection.send(new LoginRequest(username, password))
    }, [username, password, connection])

    return (
        <Card style={{margin: "5em auto", maxWidth: 650}}>
            <Card.Header className="bg-primary text-white">
                <span className="fs-3">Login</span>
            </Card.Header>
            <Card.Body>
                <Form onSubmit={formHandle}>
                    <Form.Group className="mb-4">
                        <Form.Label>Username</Form.Label>
                        <Form.Control type="text" isInvalid={errMsg.indexOf("Username")>=0} onChange={(v)=>{
                            setUsername(v.currentTarget.value)
                        }}/>
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" isInvalid={errMsg.indexOf("Password")>=0} onChange={(v)=>{
                            setPassword(v.currentTarget.value)
                        }}/>
                    </Form.Group>
                    <p className="mb-4" style={{display: errMsg!==""?"block":"none", color: "red"}}>{errMsg}</p>

                    <div style={{display: "flex", gap: 4}}>
                        <Button type="submit">Login</Button>
                        <Button href="/qrcodelogin" as='a'>QRCode Login</Button>
                        <DropdownButton title="教學">
                            <DropdownItem href="https://youtu.be/72hPJw4Dtyw">中文(简体)</DropdownItem>
                            <DropdownItem href="https://youtu.be/2vM9kI49VyE">中文(繁體)</DropdownItem>
                        </DropdownButton>
                    </div>
                </Form>
            </Card.Body>
            <Card.Footer style={{display: reason?"block":"none"}}>
                <span style={{display: reason?"block":"none", color: "red"}}>{reason==="forceLogout"?"Someone logged in from other device or browser. Please login again.":reason==="maxSession"?"Sessions more than allowed.":reason==="logout"?"Logout Success.":""}</span>
            </Card.Footer>
        </Card>
    );
}

export default LoginPage