import { MessageType, UserSettingSubAction } from "./enum"

class RequestContainer {
    public e : MessageType
    constructor(e: MessageType){
        this.e = e
    }
}

class LoginRequest extends RequestContainer {
    username: string
    password: string
    constructor(username: string, password: string){
        super(MessageType.Login)
        this.username = username
        this.password = password
    }
}

class GetSessionIDRequest extends RequestContainer {
    userID: string
    constructor(userID: string) {
        super(MessageType.GetSessionID)
        this.userID = userID
    }
}

class GetQuoteRequest extends RequestContainer {
    s: string
    constructor(symbol: string) {
        super(MessageType.GetQuote)
        this.s = symbol
    }
}

//==============================
// User Setting Requests
//==============================

class UserSettingRequest extends RequestContainer {
    subE: UserSettingSubAction
    constructor (subE: UserSettingSubAction) {
        super(MessageType.UserSetting)
        this.subE = subE
    }
}

class UserCreditStatusRequest extends UserSettingRequest {
    sessionID: string
    constructor(sessionID: string) {
        super(UserSettingSubAction.CreditStatus)
        this.sessionID = sessionID
    }
}

//==============================
// Trader Requests
//==============================



export {
    RequestContainer, UserSettingRequest,

    LoginRequest, GetSessionIDRequest,

    GetQuoteRequest,

    UserCreditStatusRequest
}