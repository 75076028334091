import { OrderSide, TIF } from "./Enum"

class TriggerPending{
    row_id_ : number
    symbol_ : string
    volume_ : number
    side_ : number
    tif_ : number

    BVorSV : number
    BRorSR : number
    TBUorTSU : number
    MAXBorMINS : number

    constructor (symbol="", side=OrderSide.Buy){
        this.row_id_ = 0
        this.symbol_ = symbol
        this.volume_ = 0
        this.side_ = side
        this.tif_ = TIF.Day

        this.BVorSV = 0
        this.BRorSR = 0
        this.TBUorTSU = 0
        this.MAXBorMINS = 0.0
    }

    GetMAXBorMINS() { return this.MAXBorMINS / 1000 }
    GetTBUorTSU()   { return this.TBUorTSU / 1000 }
    GetBRorSR()     { return this.BRorSR }
    GetBVorSV()     { return this.BVorSV }
    GetVolume()     { return this.volume_ }
    GetTIF()        { return this.tif_ }

    SetMAXBorMINS(p: number)  { this.MAXBorMINS = Math.round(p * 1000) }
    SetTBUorTSU(p: number)    { this.TBUorTSU = Math.round(p * 1000) }
    SetBRorSR(r: number)      { this.BRorSR = Math.floor(r) }
    SetBVorSV(v: number)      { this.BVorSV = Math.floor(v) }
    SetVolume(v: number)      { this.volume_ = Math.floor(v) }
    SetTIF(t: number)         { this.tif_ = t }
}

export {TriggerPending}