import {useMemo} from 'react'

import { InputGroup } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'

function Symbol(props){
	const fontStyle = useMemo(()=>{
		return {fontSize: props.fontsize, padding: "0px 2px"}
	}, [props.fontSize])

	return useMemo(()=>{
		return (
			<InputGroup className="react-draggable-cancel">
				<InputGroup.Text style={fontStyle}><strong>D</strong></InputGroup.Text>
				<Form.Control type="text" value={props.symbol} disabled={props.startLight} style={{padding: "0px 2px", fontSize: props.fontsize}} onChange={(v)=>{props.setSymbol(v.currentTarget.value)}}
					onBlur={(e)=>{e.target.value = props.selectedSymbol}}
					onKeyPress={(e)=>{
						if (e.code === "Enter" || e.code === "NumpadEnter"){
							if (props.symbol.length > 0){
								props.setSelectedSymbol(props.symbol)
							}
						}
					}}
				/>
				<InputGroup.Text style={fontStyle}><strong>U</strong></InputGroup.Text>
				<InputGroup.Text style={{padding: "0px 2px", minWidth: 10, fontSize: props.fontsize}}>{("00000"+(props.underlying===null?"":props.underlying)).slice(-5)}</InputGroup.Text>
				<InputGroup.Text style={fontStyle}><strong>{props.callPut}</strong></InputGroup.Text>
			</InputGroup>
		)
	}, [props.fontSize, props.symbol, props.selectedSymbol, props.callPut, props.underlying, props.setSelectedSymbol, props.startLight])
}

export default Symbol