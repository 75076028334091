import { useState, useEffect } from "react";

import Modal from "react-bootstrap/Modal";

function ConnectionPopUp({connStatus}){
    const [showCountdown, setShowCountDown] = useState(false)

    useEffect(()=>{
        if (connStatus === true || connStatus === null)
            setShowCountDown(false)
        else
            setShowCountDown(true)
    }, [connStatus])

    return (
        <Modal show={connStatus!==true}>
            <Modal.Header className="bg-danger text-white">
                <Modal.Title>{connStatus===null?"正在連接伺服器":"失去連線"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <svg style={{width: "100%", height: 200}} width="200px" height="200px" viewBox="0 0 1024 1024">
                    <path fill="#AAA" d="M832.6 191.4c-84.6-84.6-221.5-84.6-306 0l-96.9 96.9 51 51 96.9-96.9c53.8-53.8 144.6-59.5 204 0 59.5 59.5 53.8 150.2 0 204l-96.9 96.9 51.1 51.1 96.9-96.9c84.4-84.6 84.4-221.5-.1-306.1zM446.5 781.6c-53.8 53.8-144.6 59.5-204 0-59.5-59.5-53.8-150.2 0-204l96.9-96.9-51.1-51.1-96.9 96.9c-84.6 84.6-84.6 221.5 0 306s221.5 84.6 306 0l96.9-96.9-51-51-96.8 97zM260.3 209.4a8.03 8.03 0 0 0-11.3 0L209.4 249a8.03 8.03 0 0 0 0 11.3l554.4 554.4c3.1 3.1 8.2 3.1 11.3 0l39.6-39.6c3.1-3.1 3.1-8.2 0-11.3L260.3 209.4z"/>
                </svg>

                <div style={{display: showCountdown?"block":"none", textAlign:"center"}}>
                    請檢查你的網絡連接是否正常，我們會持續嘗試重新連線<br/><br/>
                    {connStatus>0?connStatus+"秒後重試":"正在重試..."}
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ConnectionPopUp