const AudioControl = new AudioContext();

async function beep(freq: number, duration: number, vol: number) {
    const oscillator = AudioControl.createOscillator();
    const gain = AudioControl.createGain();

    oscillator.connect(gain);
    oscillator.frequency.value = freq;
    oscillator.type = "square";
    gain.connect(AudioControl.destination);
    gain.gain.value = vol * 0.01;

    oscillator.start(AudioControl.currentTime);
    oscillator.stop(AudioControl.currentTime + duration * 0.001);
}

const Sounds = {
    Reject: ()=>{
        beep(100, 100, 100)
        setTimeout(()=>{
            beep(100, 200, 100)
        }, 300)
    },
    Error: ()=>{
        beep(100, 200, 100)
    },
    BuyDone: ()=>{
        beep(261, 100, 100)
        setTimeout(()=>{
            beep(493, 200, 100)
        }, 150)
    },
    SellDone: ()=>{
        beep(493, 100, 100)
        setTimeout(()=>{
            beep(261, 200, 100)
        }, 150)
    }
}

export default Sounds