import { useState, useEffect, useCallback } from 'react';

import { Tabs, Tab, InputGroup, Button } from 'react-bootstrap'
import Modal from "react-bootstrap/Modal"
import Form from 'react-bootstrap/Form'
import ClientSettingHandle, { ClientSetting } from '../../functions/ClientSettingHandle';

function CheckKeyCanUse(setting: ClientSetting, key: string){
	const allKeys = Object.keys(setting).filter((x)=>x.indexOf("Key") >= 0)

	let canUse = true

	allKeys.forEach((x)=>{
		if (setting[x] === key){
			canUse = false
			return
		}
	})
	
	return canUse
}

interface ReplaceDefaultBtnProps {
	item: string
	clientSetting: ClientSettingHandle
}
function ReplaceDefaultBtn({item, clientSetting}: ReplaceDefaultBtnProps){
	return (
		<Button onClick={()=>{
			clientSetting.setting[item] = clientSetting.defaultSetting[item]
			clientSetting.saveSetting()
			clientSetting.reloadSetting()
		}}>Reset</Button>
	)
}

interface SettingPanelProps {
	show: boolean
	setShow: React.Dispatch<React.SetStateAction<boolean>>
	clientSetting: ClientSettingHandle
}
function SettingPanel({show, setShow, clientSetting}: SettingPanelProps){
	const [selectPage, setSelectPage] = useState("color")
	const [maxVolume, setMaxVolume] = useState(clientSetting.setting.maxVolume)
	const [defaultVolume, setDefaultVolume] = useState(clientSetting.setting.defaultVolume)

	useEffect(() => {
		if (show){
			setSelectPage("color")
		}
	}, [show])

	useEffect(() => {
		if (!show) {
			setMaxVolume(clientSetting.setting.maxVolume)
			setDefaultVolume(clientSetting.setting.defaultVolume)
		}
	}, [show, clientSetting])

	const ResetAllHandle = useCallback(()=>{
		if (selectPage === "color"){
			Object.keys(clientSetting.defaultSetting).filter((x)=>{
				if (x.indexOf("Color") >= 0)
					return true
				return false
			}).forEach((x)=>{
				clientSetting.resetSetting(x)
			})
		}else if (selectPage === "inputProtect"){
			clientSetting.resetSetting("maxVolume")
			clientSetting.resetSetting("defaultVolume")
		}else if (selectPage === "shortCutKey"){
			const currentShortcutKey = clientSetting.setting.shortcutKeyChange
			Object.keys(clientSetting.defaultSetting).filter((x)=>{
				if (x.indexOf("Key") >= 0)
					return true
				return false
			}).forEach((x)=>{
				clientSetting.resetSetting(x)
			})
			clientSetting.setting.shortcutKeyChange = !currentShortcutKey
		}
		clientSetting.saveSetting()
		clientSetting.reloadSetting()
	}, [selectPage, clientSetting])

	return (
		<Modal size="lg" show={show} onHide={()=>{setShow(false)}}>
			<Modal.Header closeButton>
				<Modal.Title>Settings</Modal.Title>
			</Modal.Header>
			<Modal.Body style={{padding: "16px 8px"}}>
				<Tabs className="mb-2" activeKey={selectPage} onSelect={(v)=>{ setSelectPage(v!) }}>
					<Tab eventKey="color" title="Color">
						<div className="mb-2" style={{display: "flex"}}>
							<InputGroup style={{flex: 1,marginRight: 2}}>
								<InputGroup.Text>Buy / Increase</InputGroup.Text>
								<Form.Control type="color" value={clientSetting.setting.buyColor} onChange={(v)=>{
									clientSetting.setting.buyColor = v.currentTarget.value
									clientSetting.saveSetting()
									clientSetting.reloadSetting()
								}} />
								<ReplaceDefaultBtn clientSetting={clientSetting} item={"buyColor"}/>
							</InputGroup>
							<InputGroup style={{flex: 1, marginLeft: 2, marginRight: 2}}>
								<InputGroup.Text>Sell / Decrease</InputGroup.Text>
								<Form.Control type="color" value={clientSetting.setting.sellColor} onChange={(v)=>{
									clientSetting.setting.sellColor = v.currentTarget.value
									clientSetting.saveSetting()
									clientSetting.reloadSetting()
								}} />
								<ReplaceDefaultBtn clientSetting={clientSetting} item={"sellColor"}/>
							</InputGroup>
							<InputGroup style={{flex: 1, marginLeft: 2}}>
								<InputGroup.Text>Auto Sell</InputGroup.Text>
								<Form.Control type="color" value={clientSetting.setting.AsColor} onChange={(v)=>{
									clientSetting.setting.AsColor = v.currentTarget.value
									clientSetting.saveSetting()
									clientSetting.reloadSetting()
								}} />
								<ReplaceDefaultBtn clientSetting={clientSetting} item={"AsColor"}/>
							</InputGroup>
						</div>
						<div style={{display: "flex"}}>
							<InputGroup style={{flex: 1, marginRight: 2}}>
								<InputGroup.Text>Call</InputGroup.Text>
								<Form.Control type="color" value={clientSetting.setting.callColor} onChange={(v)=>{
									clientSetting.setting.callColor = v.currentTarget.value
									clientSetting.saveSetting()
									clientSetting.reloadSetting()
								}} />
								<ReplaceDefaultBtn clientSetting={clientSetting} item={"callColor"}/>
							</InputGroup>
							<InputGroup style={{flex: 1, marginLeft: 2}}>
								<InputGroup.Text>Put</InputGroup.Text>
								<Form.Control type="color" value={clientSetting.setting.putColor} onChange={(v)=>{
									clientSetting.setting.putColor = v.currentTarget.value
									clientSetting.saveSetting()
									clientSetting.reloadSetting()
								}} />
								<ReplaceDefaultBtn clientSetting={clientSetting} item={"putColor"}/>
							</InputGroup>
						</div>
					</Tab>
					<Tab eventKey="inputProtect" title="Input Protection">
						<div className="mb-2" style={{display: "flex"}}>
							<InputGroup style={{flex: 1, marginRight: 2}}>
								<InputGroup.Text>Max Volume(K)</InputGroup.Text>
								<Form.Control type="number" min={0} step={1} value={maxVolume}
									onChange={(v)=>{ setMaxVolume(parseInt(v.currentTarget.value)) }}
									onKeyPress={(e)=>{
										if (e.key === "Enter"){
											let volume = parseInt(e.currentTarget.value)
											if (volume > 0 && volume % 1 === 0){
												clientSetting.setting.maxVolume = volume
												clientSetting.saveSetting()
											}
											clientSetting.reloadSetting()
										}
									}}
								/>
								<ReplaceDefaultBtn clientSetting={clientSetting} item={"maxVolume"}/>
							</InputGroup>
							<InputGroup style={{flex: 1, marginLeft: 2}}>
								<InputGroup.Text>Default Volume(K)</InputGroup.Text>
								<Form.Control type="number" min={0} step={1} value={defaultVolume}
									onChange={(v)=>{ setDefaultVolume(parseInt(v.currentTarget.value)) }}
									onKeyPress={(e)=>{
										if (e.key === "Enter"){
											let volume = parseInt(e.currentTarget.value)
											if (volume > 0 && volume % 1 === 0){
												clientSetting.setting.defaultVolume = volume
												clientSetting.saveSetting()
											}
											clientSetting.reloadSetting()
										}
									}}
								/>
								<ReplaceDefaultBtn clientSetting={clientSetting} item={"defaultVolume"}/>
							</InputGroup>
						</div>
					</Tab>
					<Tab eventKey="shortCutKey" title="Short Cut Key">
						<div className="mb-2" style={{display: "flex"}}>
							<InputGroup style={{flex: 1, marginRight: 2}}>
								<InputGroup.Text style={{width: 130}}>Switch Buy</InputGroup.Text>
								<Button variant="outline-secondary" style={{flex: 1}} onKeyPress={(e)=>{
									e.preventDefault();
									if (e.code !== "Enter" && e.code !== "NumpadEnter" && CheckKeyCanUse(clientSetting.setting, e.code)){
										clientSetting.setting.shortcutKeyChange = !clientSetting.setting.shortcutKeyChange
										clientSetting.setting.triggerBuyKey = e.code
										clientSetting.saveSetting()
										clientSetting.reloadSetting()
									}
								}}>{clientSetting.setting.triggerBuyKey}</Button>
								<ReplaceDefaultBtn clientSetting={clientSetting} item={"triggerBuyKey"}/>
							</InputGroup>
							<InputGroup style={{flex: 1, marginLeft: 2}}>
								<InputGroup.Text style={{width: 130}}>Switch Sell</InputGroup.Text>
								<Button variant="outline-secondary" style={{flex: 1}} onKeyPress={(e)=>{
									e.preventDefault();
									if (e.code !== "Enter" && e.code !== "NumpadEnter" && CheckKeyCanUse(clientSetting.setting, e.code)){
										clientSetting.setting.shortcutKeyChange = !clientSetting.setting.shortcutKeyChange
										clientSetting.setting.triggerSellKey = e.code
										clientSetting.saveSetting()
										clientSetting.reloadSetting()
									}
								}}>{clientSetting.setting.triggerSellKey}</Button>
								<ReplaceDefaultBtn clientSetting={clientSetting} item={"triggerSellKey"}/>
							</InputGroup>
						</div>
						<div className="mb-2" style={{display: "flex"}}>
							<InputGroup style={{flex: 1, marginRight: 2}}>
								<InputGroup.Text style={{width: 130}}>Get Price</InputGroup.Text>
								<Button variant="outline-secondary" style={{flex: 1}} onKeyPress={(e)=>{
									e.preventDefault();
									if (e.code !== "Enter" && e.code !== "NumpadEnter" && CheckKeyCanUse(clientSetting.setting, e.code)){
										clientSetting.setting.shortcutKeyChange = !clientSetting.setting.shortcutKeyChange
										clientSetting.setting.capAllKey = e.code
										clientSetting.saveSetting()
										clientSetting.reloadSetting()
									}
								}}>{clientSetting.setting.capAllKey}</Button>
								<ReplaceDefaultBtn clientSetting={clientSetting} item={"capAllKey"}/>
							</InputGroup>
							<div style={{flex: 1, marginLeft: 2}}></div>
						</div>
						<div className="mb-2" style={{display: "flex"}}>
							<InputGroup style={{flex: 1, marginRight: 2}}>
								<InputGroup.Text style={{width: 130}}>Get Bid</InputGroup.Text>
								<Button variant="outline-secondary" style={{flex: 1}} onKeyPress={(e)=>{
									e.preventDefault();
									if (e.code !== "Enter" && e.code !== "NumpadEnter" && CheckKeyCanUse(clientSetting.setting, e.code)){
										clientSetting.setting.shortcutKeyChange = !clientSetting.setting.shortcutKeyChange
										clientSetting.setting.capBidKey = e.code
										clientSetting.saveSetting()
										clientSetting.reloadSetting()
									}
								}}>{clientSetting.setting.capBidKey}</Button>
								<ReplaceDefaultBtn clientSetting={clientSetting} item={"capBidKey"}/>
							</InputGroup>
							<InputGroup style={{flex: 1, marginLeft: 2}}>
								<InputGroup.Text style={{width: 130}}>Get Ask</InputGroup.Text>
								<Button variant="outline-secondary" style={{flex: 1}} onKeyPress={(e)=>{
									e.preventDefault();
									if (e.code !== "Enter" && e.code !== "NumpadEnter" && CheckKeyCanUse(clientSetting.setting, e.code)){
										clientSetting.setting.shortcutKeyChange = !clientSetting.setting.shortcutKeyChange
										clientSetting.setting.capAskKey = e.code
										clientSetting.saveSetting()
										clientSetting.reloadSetting()
									}
								}}>{clientSetting.setting.capAskKey}</Button>
								<ReplaceDefaultBtn clientSetting={clientSetting} item={"capAskKey"}/>
							</InputGroup>
						</div>
						<div style={{display: "flex"}}>
							<InputGroup style={{flex: 1, marginRight: 2}}>
								<InputGroup.Text style={{width: 130}}>Add</InputGroup.Text>
								<Button variant="outline-secondary" style={{flex: 1}} onKeyPress={(e)=>{
									e.preventDefault();
									if (e.code !== "Enter" && e.code !== "NumpadEnter" && CheckKeyCanUse(clientSetting.setting, e.code)){
										clientSetting.setting.shortcutKeyChange = !clientSetting.setting.shortcutKeyChange
										clientSetting.setting.addKey = e.code
										clientSetting.saveSetting()
										clientSetting.reloadSetting()
									}
								}}>{clientSetting.setting.addKey}</Button>
								<ReplaceDefaultBtn clientSetting={clientSetting} item={"addKey"}/>
							</InputGroup>
							<InputGroup style={{flex: 1, marginLeft: 2}}>
								<InputGroup.Text style={{width: 130}}>Minus</InputGroup.Text>
								<Button variant="outline-secondary" style={{flex: 1}} onKeyPress={(e)=>{
									e.preventDefault();
									if (e.code !== "Enter" && e.code !== "NumpadEnter" && CheckKeyCanUse(clientSetting.setting, e.code)){
										clientSetting.setting.shortcutKeyChange = !clientSetting.setting.shortcutKeyChange
										clientSetting.setting.minusKey = e.code
										clientSetting.saveSetting()
										clientSetting.reloadSetting()
									}
								}}>{clientSetting.setting.minusKey}</Button>
								<ReplaceDefaultBtn clientSetting={clientSetting} item={"minusKey"}/>
							</InputGroup>
						</div>
					</Tab>
					<Tab eventKey="other" title="Other">
						<div className="mb-2" style={{display: "flex"}}>
							<InputGroup style={{flex: 1, marginRight: 2}}>
								<InputGroup.Text style={{width: 130}}>Font Size</InputGroup.Text>
								<Form.Select value={clientSetting.setting.fontSize} onChange={(e)=>{
									clientSetting.setting.fontSize = parseInt(e.target.value)
									clientSetting.saveSetting()
									clientSetting.reloadSetting()
								}}>
									<option value={12}>Small</option>
									<option value={14}>Middle</option>
									<option value={16}>Large</option>
								</Form.Select>
								<ReplaceDefaultBtn clientSetting={clientSetting} item={"fontSize"}/>
							</InputGroup>
						</div>
					</Tab>
				</Tabs>
			</Modal.Body>
			<Modal.Footer style={{padding: "2px 4px"}}>
				<Button className="mb-2" onClick={ResetAllHandle}>Reset All</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default SettingPanel