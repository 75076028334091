import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { useParams } from 'react-router-dom';
import { Responsive, WidthProvider } from 'react-grid-layout';

import { ClientVersion, MessageType, StatusType, TraderSubAction, UserSettingSubAction } from '../api/enum';
import { ResponseContainer, GetKlineChartResp } from '../api/response';
import { AcctData, Asset, Quote, ServerOrderResp } from '../api/type';
import { ActionType, OrderSide, OrderStatus } from '../functions/Enum';
import { Connection } from '../functions/Connection';
import { LoadSpreadTableMap } from '../functions/StockHandle';
import ClientSettingHandle, { ClientSetting } from '../functions/ClientSettingHandle';

import SettingPanel from './MainPage/SettingPanel';
import KlineChart from './MainPage/KlineChart';
import QuotoPanel from './MainPage/QuotoPanel';
import DetailPanel from './MainPage/DetailPanel';
import ConnectionPopUp from './MainPage/DisconnectPopUp';
import TraderW2List from './MainPage/TraderW2List';
import TraderW2SetRows from './MainPage/TradeW2List/TraderW2SetRows'
import CheapUTraderList from './MainPage/CheapUTraderList';
import CheapUTraderSetRows from './MainPage/CheapUTraderList/CheapUTraderSetRows';
import OXTrader from './MainPage/OXTrader';
import BrokerPanel from './MainPage/BrokerPanel';
import TopNav from './MainPage/TopNav';

import CheapUTraderV2List from './MainPage/CheapUTraderV2List';
import CheapUTraderV2SetRows from './MainPage/CheapUTraderV2List/CheapUTraderV2SetRows';
import PendingPanel from './MainPage/PendingPanel';
import TimeTriggerPanel from './MainPage/TimeTriggerPanel';
import TriggerPendingPanel from './MainPage/TriggerPendingPanel';
import AdminPanel from './MainPage/AdminPanel';
import QuoteTable from './MainPage/QuoteTable';
import Sounds from '../functions/SoundHandler';
import { TriggerPending } from '../functions/Types';
import WarningPanel from './MainPage/WarningPanel';
import DemoPanel from './MainPage/DemoPanel';
import { GetSessionIDRequest, UserCreditStatusRequest } from '../api/request';

const ResponsiveGridLayout = WidthProvider(Responsive);
const LatestLayoutVersion = "20230522"

export interface MainContextData {
	[key:string]: any

	ws: Connection,
	session: string | false

	assets: Asset[]
	history: ServerOrderResp[]

	warrants: string[][]
	cbbcs: string[][]
	brokers: {[key:string]:string}

	bssQuote: {[key:string]: Quote}
	klineData: GetKlineChartResp | {}
	tradeData: string[]
	quoteData: string[]
	brokerData: {[key:string]: string[][][]}

	clientSetting: ClientSettingHandle
}
const MainContext = React.createContext<MainContextData>({
	ws: new Connection(()=>{}),
	session: false,

	assets: [],
	history: [],

	warrants: [],
	cbbcs: [],
	brokers: {},

	bssQuote: {},
	klineData: {},
	tradeData: [],
	quoteData: [],
	brokerData: {},

	clientSetting: new ClientSettingHandle()
});

export interface LayoutItem {
	key: number
	component: string
	rows?: number
	"data-grid": {
		x: number
		y: number
		w: number
		h: number
		minW: number
		minH: number
	}
}

function loadLayout(id: string): LayoutItem[] {
	// Reset Layout when using old layout version
	if (localStorage.layoutVersion !== LatestLayoutVersion){
		Object.keys(localStorage).forEach((k)=>{
			if (k.startsWith("layout"))
				localStorage.removeItem(k)
		})
		localStorage.layoutVersion = LatestLayoutVersion
	}

	// Check if layout exists
	const layoutStr = localStorage.getItem("layout" + id)
	if (layoutStr !== null)
		return JSON.parse(localStorage["layout"+id]).layout

	// Return new version layout
	return (
		// For AQTech Trading
		[
			{"key":1 ,"data-grid":{"x":0 ,"y":0 ,"w":4 ,"h":82,"minW":4 ,"minH":56},"component":"Chart"},
			{"key":2 ,"data-grid":{"x":4 ,"y":0 ,"w":4 ,"h":82,"minW":4 ,"minH":56},"component":"Chart"},
			{"key":3 ,"data-grid":{"x":8 ,"y":0 ,"w":2 ,"h":82,"minW":2 ,"minH":82},"component":"QuotoPanel"},
			{"key":4 ,"data-grid":{"x":10,"y":0 ,"w":2 ,"h":82,"minW":2 ,"minH":82},"component":"BrokerPanel"},
			{"key":5 ,"data-grid":{"x":10,"y":82,"w":2 ,"h":60,"minW":2 ,"minH":42},"component":"DetailPanel"},
			{"key":6 ,"data-grid":{"x":0 ,"y":82,"w":10,"h":60,"minW":10,"minH":48},"component":"CheapUTraderV2", "rows": 5},
			{"key":7 ,"data-grid":{"x":0 ,"y":82,"w":7 ,"h":82,"minW":7 ,"minH":82},"component":"PendingPanel"},
			{"key":8 ,"data-grid":{"x":0 ,"y":82,"w":6 ,"h":82,"minW":6 ,"minH":82},"component":"TimeTriggerPanel"},
			{"key":9 ,"data-grid":{"x":0 ,"y":82,"w":6 ,"h":82,"minW":6 ,"minH":82},"component":"TriggerPendingPanel"},
			{"key":10,"data-grid":{"x":0 ,"y":82,"w":6 ,"h":82,"minW":4 ,"minH":82},"component":"WarningPanel"}
		]

		// For Window Version Customer
		// [
		// 	{"key":1,"data-grid":{"x":0,"y":0,"w":3,"h":48,"minW":3,"minH":48},"component":"OXTrader"},
		// 	{"key":2,"data-grid":{"x":3,"y":0,"w":3,"h":48,"minW":3,"minH":48},"component":"OXTrader"},
		// 	{"key":3,"data-grid":{"x":6,"y":0,"w":3,"h":48,"minW":3,"minH":48},"component":"OXTrader"},
		// 	{"key":4,"data-grid":{"x":9,"y":0,"w":3,"h":48,"minW":2,"minH":42},"component":"DetailPanel"},
		// 	{"key":5,"data-grid":{"x":0,"y":48,"w":3,"h":48,"minW":3,"minH":48},"component":"OXTrader"},
		// 	{"key":6,"data-grid":{"x":3,"y":48,"w":3,"h":48,"minW":3,"minH":48},"component":"OXTrader"},
		// 	{"key":7,"data-grid":{"x":6,"y":48,"w":3,"h":48,"minW":3,"minH":48},"component":"OXTrader"},
		// 	{"key":8,"data-grid":{"x":9,"y":48,"w":3,"h":48,"minW":3,"minH":48},"component":"OXTrader"},
		// 	{"key":9,"data-grid":{"x":0,"y":96,"w":3,"h":48,"minW":3,"minH":48},"component":"OXTrader"},
		// 	{"key":10,"data-grid":{"x":3,"y":96,"w":3,"h":48,"minW":3,"minH":48},"component":"OXTrader"},
		// 	{"key":11,"data-grid":{"x":6,"y":96,"w":3,"h":48,"minW":3,"minH":48},"component":"OXTrader"},
		// 	{"key":12,"data-grid":{"x":9,"y":96,"w":3,"h":48,"minW":3,"minH":48},"component":"OXTrader"},
		// 	// {"key":13,"data-grid":{"x":0,"y":144,"w":8,"h":48,"minW":8,"minH":48},"component":"CheapUTrader", "rows": 5}
		// ]

		// For Excel Version Customer
		// [
		// 	{"key":1,"data-grid":{"x":0,"y":0,"w":4,"h":70,"minW":4,"minH":56},"component":"Chart"},
		// 	{"key":2,"data-grid":{"x":4,"y":0,"w":5,"h":70,"minW":4,"minH":56},"component":"Chart"},
		// 	{"key":3,"data-grid":{"x":9,"y":0,"w":3,"h":82,"minW":2,"minH":82},"component":"QuotoPanel"},
		// 	{"key":4,"data-grid":{"x":0,"y":70,"w":9,"h":189,"minW":8,"minH":20},"component":"TraderW2","rows":20},
		// 	{"key":5,"data-grid":{"x":9,"y":82,"w":3,"h":56,"minW":2,"minH":42},"component":"DetailPanel"},
		// 	{"key":6,"data-grid":{"x":9,"y":138,"w":3,"h":82,"minW":2,"minH":82},"component":"QuotoPanel"}
		// ]
	)
}

export interface ComponentProps {
	item	: LayoutItem
	context	: React.Context<MainContextData>
	onClose	: ()=>void
}
function ShowComponent({item, context, onClose} : ComponentProps){
	const { component } = item

	if (component === "TraderW2")
		return <TraderW2List onClose={onClose} context={context} item={item}/>
	else if (component === "OXTrader")
		return <OXTrader onClose={onClose} context={context} item={item}/>
	else if (component === "CheapUTrader")
		return <CheapUTraderList onClose={onClose} context={context} item={item}/>
	else if (component === "CheapUTraderV2")
		return <CheapUTraderV2List onClose={onClose} context={context} item={item}/>
	else if (component === "Chart")
		return <KlineChart onClose={onClose} context={context} item={item}/>
	else if (component === "QuotoPanel")
		return <QuotoPanel onClose={onClose} context={context}/>
	else if (component === "DetailPanel")
		return <DetailPanel onClose={onClose} context={context} item={item}/>
	else if (component === "BrokerPanel")
		return <BrokerPanel onClose={onClose} context={context} item={item}/>
	else if (component === "PendingPanel")
		return <PendingPanel onClose={onClose} context={context}/>
	else if (component === "TimeTriggerPanel")
		return <TimeTriggerPanel onClose={onClose} context={context}/>
	else if (component === "TriggerPendingPanel")
		return <TriggerPendingPanel onClose={onClose} context={context}/>
	else if (component === "AdminPanel")
		return <AdminPanel onClose={onClose} context={context} item={item}/>
	else if (component === "QuoteTable")
		return <QuoteTable onClose={onClose} context={context}/>
	else if (component === "WarningPanel")
		return <WarningPanel onClose={onClose} context={context} item={item}/>
	else if (component === "DemoPanel")
		return <DemoPanel onClose={onClose} context={context}/>
	
	return <>{component}</>
}

function App(){
	const { id } = useParams()
	const [ws, setWS] = useState<Connection|null>(null)
	const [session, setSession] = useState<false|string>(false)
	const [acctData, setAcctData] = useState<AcctData>({
		AcctID: "", AcctName: "", AcctIndex: 0,
		IsAdmin: false, AllowEquity: false, AllowCBBC: false, MaxTriggerOn: 0, MaxRow: 0, Quote: false, Version: ClientVersion.Win, MaxSessions: 1
	})
	const [creditStatus, setCreditStatus] = useState({creditAmount: 0, dailyLossLimit: 0})
	
	const [currTime, setCurrTime] = useState(new Date())
    const updateTimer = useRef<NodeJS.Timer|null>(null)
	useEffect(() => {
		updateTimer.current = setInterval(()=>{ setCurrTime(new Date())}, 1000)

		return () => {
			if (updateTimer.current !== null)
				clearInterval(updateTimer.current)
			updateTimer.current = null
		}
	}, [])

	const [connStatus, setConnStatus] = useState<boolean|number>(false)
	//Bypass
	//const [connStatus, setConnStatus] = useState(true)

	const [clientSetting, setClientSetting] = useState(new ClientSettingHandle())
	const [showSettingPanel, setShowSettingPanel] = useState(false)
	const [showSetW2Row, setShowSetW2Row] = useState(false)
	const [showSetCheapURow, setShowSetCheapURow] = useState(false)
	const [showSetCheapUV2Row, setShowSetCheapUV2Row] = useState(false)

	const [warrants, setWarrants] = useState<string[][]>([])
	const [cbbcs, setCBBCs] = useState<string[][]>([])
	const [brokers, setBrokers] = useState<{[key:string]:string}>({})

	const [layout, setLayout] = useState<LayoutItem[]>([])
	//Bypass
	//const [layout, setLayout] = useState(loadLayout(id))

	//const [ShowLayout, setShowLayout] = useState(<></>)
	const layoutFirstLoad = useRef(true)

	const [klineData, setKlineData] = useState<GetKlineChartResp|{}>({})
	const [tradeData, setTradeData] = useState<string[]>([])
	const [quoteData, setQuoteData] = useState<string[]>([])
	const [brokerData, setBrokerData] = useState<{[key:string]: string[][][]}>({})
	const tmpBrokerData = useRef<{currentSymbol: string, brokerQueue: string[][][]}|null>(null)

	const [traderStream, setTraderStream] = useState<any>(null)
	const bssQuoteRef = useRef<{[key:string]: Quote}>({})
	const [bssQuote, setBSSQuote] = useState<{[key:string]: Quote}>({})
	const [assets, setAssets] = useState<Asset[]>([])
	const historyRef = useRef<ServerOrderResp[]>([])
	const [history, setHistory] = useState<ServerOrderResp[]>([])
	const pendingsRef = useRef<{[key:string]: any}>({})
	const [pendings, setPendings] = useState<any[]>([])
	const [pendingRows, setPendingRows] = useState<any[]>([])
	const marketTriggersRef = useRef<any[]>([])
	const [marketTriggers, setMarketTriggers] = useState<any[]>([])
	const triggerPendingsRef = useRef<TriggerPending[]>([])
	const [triggerPendings, setTriggerPendings] = useState<TriggerPending[]>([])

	const AddErrorMsg = useCallback((time: number, D: string, Msg: string)=>{
		setHistory((last)=>[
			...last,
			{
				AcctID: acctData.AcctID,
				RawMsg: "",
				RowID: 0,
				OrderTime: time,
				D: D,
				OSide: OrderSide.Buy,
				Cost: 0,
				Shares: 0,
				Status: OrderStatus.Reject,
				Reason: Msg
			}
		])
	}, [acctData])

	const SortByTurnover = useCallback((a,b) => {
		return b.Turnover - a.Turnover
	}, [])

	const onMsg = useCallback((msg: string)=>{
		if (typeof id === "undefined") return

		const jsonData = JSON.parse(msg)
		const json : ResponseContainer = jsonData

		if (json.e === MessageType.Connected) {
			setSession(false)
			const userID = localStorage.getItem("userID")
			const sessionID = localStorage.getItem("sessionID-"+id)
			if (userID !== null && sessionID === null)
				ws!.send(new GetSessionIDRequest(userID))
			else if (sessionID !== null)
				ws!.send(JSON.stringify({e: "reconnectBySessionID", sessionID: localStorage["sessionID-"+id]}))
			else
				window.location.assign("/login")
		}
		else if (json.e === MessageType.GetSessionID) {
			switch (json.status) {
				case StatusType.Success:
					localStorage.setItem("sessionID-"+id, json.sessionID)
					setSession(json.sessionID)
					setAcctData(json.AcctData)
					setLayout(loadLayout(id))

					setTimeout(()=>{
						layoutFirstLoad.current = false
					}, 50)
					break;
				case StatusType.Error:
					window.location.assign("/login")
					break;
			}
		}
		else if (json.e === MessageType.ReconnectBySessionID) {
			switch (json.status) {
				case StatusType.Success:
					setSession(localStorage.getItem("sessionID-"+id)!)
					setAcctData(json.AcctData)
					setLayout(loadLayout(id))

					setTimeout(()=>{
						layoutFirstLoad.current = false
					}, 50)
					break;
				case StatusType.MultipleSession:
					const nextID = parseInt(id)+1
					window.location.assign("./"+nextID)
					break;
				case StatusType.Error:
					ws!.send(new GetSessionIDRequest(localStorage.getItem("userID")!))
					break;
			}
		}
		else if (json.e === MessageType.GetKlineChart) {
			setKlineData(json)
		}
		else if (json.e === "TradeData") {
			setTradeData(json.message.split("|"))
		}
		else if (json.e === "QuoteData") {
			setQuoteData(json.message.split("|"))
		}
		else if (json.e === "BrokerData") {
			const parts = json.message.split("|")

			const symbol = parts[1]
			const side = parseInt(parts[2])
			const level = parseInt(parts[3])

			if (side === 0 && level === 0)
				tmpBrokerData.current = {currentSymbol: symbol, brokerQueue: [[], []]}

			if (tmpBrokerData.current === null || tmpBrokerData.current.currentSymbol !== symbol) return

			const brokerQueue : string[] = []
			for (let i = 4; i < parts.length; i++){
				brokerQueue.push(parts[i])
			}
			tmpBrokerData.current.brokerQueue[side].push(brokerQueue)

			if (side === 1 && level === 9 && tmpBrokerData.current !== null){
				const tmpbd = tmpBrokerData.current
				setBrokerData((last)=>{
					const newBrokerQueue = Object.assign({}, last)
					newBrokerQueue[symbol] = tmpbd.brokerQueue
					return newBrokerQueue
				})
			}
		}
		else if (json.e === MessageType.UserSetting) {
			if (json.subE === UserSettingSubAction.CreditStatus){
				setCreditStatus({creditAmount: json.creditAmount, dailyLossLimit: json.dailyLossLimit})
			}
		}
		else if (json.e === MessageType.Logout) {
			if (json.status === StatusType.Success)
				window.location.assign("/login/logout")
			else if (json.status === StatusType.Error)
				window.location.assign("/login/forceLogout")
			else if (json.status === StatusType.MaxSession)
				window.location.assign("/login/maxSession")	
		}
		else if (json.e === MessageType.Trader) {
			setTraderStream(jsonData)

			if (json.subE === TraderSubAction.Quote) {
				bssQuoteRef.current[json.s] = json.quote;
				setBSSQuote(Object.assign({}, bssQuoteRef.current))
			}
			else if (json.subE === TraderSubAction.LoadAssets || json.subE === TraderSubAction.UpdateAssets) {
				setAssets((last)=>{
					const arr = last.filter((x)=>x.symbol !== json.asset.symbol)
					arr.push(json.asset)
					return arr.sort(SortByTurnover)
				})
			}
			else if (json.subE === TraderSubAction.LoadHistory) {
				//const time = new Date(jsonData.serverRes_Order.OrderTime)
				if (json.serverRes_Order.Reason.startsWith("2054"))
					return

				json.serverRes_Order.OrderTime = Date.now()

				historyRef.current.push(jsonData.serverRes_Order)
				setHistory([...historyRef.current])
			}
			else if (json.subE === TraderSubAction.ServerOrderResp) {
				const serverRes_Order = json.serverRes_Order
				serverRes_Order.OrderTime = Date.now()

				historyRef.current.push(serverRes_Order)
				setHistory([...historyRef.current])
				
				if (pendingsRef.current[serverRes_Order.RowID.toString()]) {
					switch (serverRes_Order.Status){
						case OrderStatus.Partial:
							if (serverRes_Order.Shares > 5000){
								serverRes_Order.OSide === OrderSide.Buy ? Sounds.BuyDone() : Sounds.SellDone()
							}
							const target = pendingsRef.current[serverRes_Order.RowID.toString()]
							target.volume -= serverRes_Order.Shares
							setPendings((old)=>{ return [...old]})
							break
						case OrderStatus.Full:
							serverRes_Order.OSide === OrderSide.Buy ? Sounds.BuyDone() : Sounds.SellDone()

							delete(pendingsRef.current[serverRes_Order.RowID.toString()])
							setPendings((old)=>{ return old.filter((x)=>{return x.rowID !== serverRes_Order.RowID}) })
							break
						case OrderStatus.Cancel:
							delete(pendingsRef.current[serverRes_Order.RowID.toString()])
							setPendings((old)=>{ return old.filter((x)=>{return x.rowID !== serverRes_Order.RowID}) })
							break
						case OrderStatus.Reject:
							if (!serverRes_Order.Reason.startsWith("2054"))
								Sounds.Reject()
							delete(pendingsRef.current[serverRes_Order.RowID.toString()])
							setPendings((old)=>{ return old.filter((x)=>{return x.rowID !== serverRes_Order.RowID}) })
							break
						default:
							console.log(serverRes_Order.Status + " is not expected in pending order response.")
					}
				}
				else {
					switch (serverRes_Order.Status){
						case OrderStatus.Reject:
							if (!serverRes_Order.Reason.startsWith("2054"))
								Sounds.Reject()
							break
						default:
							console.log(serverRes_Order.Status + " is not expected in order response sound process.")
					}
				}
			}
			else if (jsonData.subE === "pending") {
				pendingsRef.current[jsonData.pending.rowID] = jsonData.pending
				setPendings((old)=>{ return [...old, jsonData.pending].sort((a,b)=>{return a.ClientOrderID - b.ClientOrderID}) })

				const index = marketTriggersRef.current.findIndex((x)=>{ return x.rowID === jsonData.pending.rowID })
				if (index !== -1){
					marketTriggersRef.current.splice(index, 1)
					setMarketTriggers(()=>{ return [...marketTriggersRef.current] })
				}
			}
			else if (jsonData.subE === "modifyPending") {
				pendingsRef.current[jsonData.pending.rowID] = jsonData.pending
				setPendings((old) => {
					return old.map((x)=>{
						if (x.ClientOrderID == jsonData.pending.ClientOrderID) return jsonData.pending
						return x
					})
				})
			}
			else if (jsonData.subE === "marketTrigger") {
				marketTriggersRef.current.push(jsonData.marketTrigger)
				setMarketTriggers(()=>{ return [...marketTriggersRef.current].sort((a,b)=>{return a.ClientOrderID - b.ClientOrderID}) })
			}
			else if (jsonData.subE === "cancelMarketTrigger") {
				const index = marketTriggersRef.current.findIndex((x)=>{ return x.rowID === jsonData.marketTrigger.rowID })
				if (index !== -1){
					marketTriggersRef.current.splice(index, 1)
					setMarketTriggers(()=>{ return [...marketTriggersRef.current] })
				}
			}
			else if (jsonData.subE === "triggerPending") {
				switch (jsonData.type){
					case ActionType.Add:
					case ActionType.Load:
						jsonData.triggerPending.__proto__ = TriggerPending.prototype
						triggerPendingsRef.current.push(jsonData.triggerPending)
						setTriggerPendings([...triggerPendingsRef.current])
						break;
					case ActionType.Del:
						const index = triggerPendingsRef.current.findIndex((x)=>{ return x.row_id_ == jsonData.triggerPending.row_id_ })
						if (index !== -1){
							triggerPendingsRef.current.splice(index, 1)
							setTriggerPendings([...triggerPendingsRef.current])
						}
						break;
					default:
						console.log("Trigger Pending Resp >> Type: " + jsonData.type + " is not supported.")
				}
			}
		}
	}, [ws, id])

	useEffect(() => {
		if (!localStorage.userID){
			window.location.assign("/login")
			return
		}

		if (ws === null) {
			setWS(new Connection(setConnStatus))
			return
		}
		
		LoadSpreadTableMap()

		fetch("/csv/warrant_search_results.csv").then(r=>r.text()).then((d)=>{
			let rows = d.split("\r\n").slice(1).map((x)=>{return x.split(",")})
			setWarrants(rows)
		})
		fetch("/csv/cbbc_search_results.csv").then(r=>r.text()).then((d)=>{
			let rows = d.split("\r\n").slice(1).map((x)=>{return x.split(",")})
			setCBBCs(rows)
		})
		fetch("/csv/BrokerIDMap.csv").then(r=>r.text()).then((d)=>{
			let rows = d.replaceAll("\r", "").split("\n")
			const BrokerID2CompanyName: {[key:string]:string} = {}
			rows.forEach((x)=>{
				if (x.length > 0){
					const parts = x.split(",")
					for (let i = 1; i < parts.length; i++)
						BrokerID2CompanyName[parts[i]] = parts[0]
				}
			})
			setBrokers(BrokerID2CompanyName)
		})

		const conn = ws
		conn.on_message = onMsg
		conn.connect()

		return () => {
			conn.close()
		}
	}, [ws, onMsg])

	useEffect(()=>{
		if (ws === null || !session) return
		
		setHistory([])
		pendingsRef.current = {}
		setPendings([])

		ws.send(JSON.stringify({e: "trader", subE: "loadAssets", sessionID: session}))
		ws.send(JSON.stringify({e: "trader", subE: "loadHistory", sessionID: session}))
		ws.send(JSON.stringify({e: "trader", subE: "loadPending", sessionID: session}))
		ws.send(JSON.stringify({e: "trader", subE: "loadMarketTrigger", sessionID: session}))
		ws.send(JSON.stringify({e: "trader", subE: "triggerPending", sessionID: session, type: ActionType.Load}))

		ws.send(new UserCreditStatusRequest(session))
	}, [ws, session])

	const traderStreamSubscribed = useRef<string[]>([])
	useEffect(()=>{
		if (ws === null) return

		assets.forEach((x) => {
			if (traderStreamSubscribed.current.indexOf(x.symbol) !== -1) return

			ws.send(JSON.stringify({e: "trader", subE: "getQuote", sessionID: session, s: x.symbol}))
			traderStreamSubscribed.current.push(x.symbol)
		})
	}, [assets])

	useEffect(() => {
		if (!clientSetting.setClientSetting){
			setClientSetting(new ClientSettingHandle(setClientSetting))
		}
	}, [clientSetting])

	useEffect(() => {
		if (layoutFirstLoad.current === false) {
			const saveingLayout = {
				layout: layout.sort((a, b)=>{
					if (a["data-grid"].y > b["data-grid"].y)
						return 1
					if (a["data-grid"].y < b["data-grid"].y)
						return -1
					return 0
				})
			}

			localStorage.setItem("layout"+id, JSON.stringify(saveingLayout))
		}
	}, [layout])

	const ShowLayout = useMemo(()=>{
		return (
			<ResponsiveGridLayout style={{display: "block"}} draggableCancel=".react-draggable-cancel" draggableHandle={".react-draggable-handle"} className="layout" rowHeight={4} margin={[2,2]}
				breakpoints={{xlg: 2000, lg: 1400, md: 1000, sm: 768, xs: 480, xxs: 0}}
				cols={{xlg: 16, lg: 12, md: 8, sm: 6, xs: 4, xxs: 2}}
				onLayoutChange={(newLayout)=>{
					if (layoutFirstLoad.current !== false) return

					setLayout((last)=>{
						let newArr: LayoutItem[] = []

						newLayout.forEach((x) => {
							const key = parseInt(x.i)
							const item = last.filter((x)=>x.key === key)[0]
							
							let thisItem = Object.assign({}, item, {
								"data-grid": {x: x.x, y: x.y, w: x.w, h: x.h, minW: x.minW, minH: x.minH}
							})
							newArr.push(thisItem)
						})

						return newArr
					})
				}}
			>
				{
					layout.map((item) => {
						return (
							<div key={item["key"]} className="react-draggable-handle" style={{border: "1px solid", backgroundColor: "white"}} data-grid={item["data-grid"]}>
								<ShowComponent onClose={()=>{
									setLayout((last)=>{
										return last.filter(x => x["key"] !== item["key"])
									})
								}} context={MainContext} item={item}/>
							</div>
						)
					})
				}
			</ResponsiveGridLayout>
		)
	}, [layout, MainContext])

	if (ws === null) return <></>

	return (
		<>
			<ConnectionPopUp connStatus={connStatus}/>
			<TopNav ws={ws} session={session} setLayout={setLayout} assets={assets} creditStatus={creditStatus}
				setShowSettingPanel={setShowSettingPanel} setShowSetW2Row={setShowSetW2Row} setShowSetCheapURow={setShowSetCheapURow} setShowSetCheapUV2Row={setShowSetCheapUV2Row}
				traderStream={traderStream} bssQuote={bssQuote} layout={layout} acctData={acctData}

				pendings={pendings} marketTriggers={marketTriggers}
			/>
			<TraderW2SetRows show={showSetW2Row} setShow={setShowSetW2Row} setLayout={setLayout}/>
			<CheapUTraderSetRows show={showSetCheapURow} setShow={setShowSetCheapURow} setLayout={setLayout}/>
			<CheapUTraderV2SetRows show={showSetCheapUV2Row} setShow={setShowSetCheapUV2Row} setLayout={setLayout}/>
			<SettingPanel show={showSettingPanel} setShow={setShowSettingPanel} clientSetting={clientSetting}/>
			<MainContext.Provider value={{
				ws: ws, session: session, AddErrorMsg: AddErrorMsg, currTime: currTime,
				traderStream: traderStream,
				assets: assets, history: history,
				pending: pendings, pendingRows: pendingRows, setPendingRows: setPendingRows, marketTriggers: marketTriggers, triggerPendings: triggerPendings,
				klineData: klineData, tradeData: tradeData, quoteData: quoteData, brokerData: brokerData,
				bssQuote: bssQuote,
				clientSetting: clientSetting, warrants: warrants, cbbcs: cbbcs, brokers: brokers
			}}>
				{ShowLayout}
			</MainContext.Provider>
		</>
	);
}

export default App
export { MainContext }