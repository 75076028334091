import { useState, useEffect, useRef, useContext, useMemo, useCallback } from "react";
import Decimal from "decimal.js";

import { GetSpread, ReturnNextUpPrice, ReturnNextDownPrice } from "../../functions/StockHandle";
import { TriggerPending } from "../../functions/Types";
import { ActionType, OrderSide, TIF } from "../../functions/Enum";

import { CloseButton, Form, InputGroup, Button, Table } from "react-bootstrap";
import SwitchableButton from "./global_component/SwitchableButton";
import InputValidation from "../../functions/InputValidation";

function ControlPanel({context, fontSize, color}){
    const MainContext = useContext(context)

    const {buyColor, sellColor} = useMemo(()=>{return color}, [color])

    const [BidPrice, setBidPrice] = useState(0)
    const [AskPrice, setAskPrice] = useState(0)
    const [LotSize, setLotSize] = useState(1)

    useEffect(()=>{
        if (MainContext.traderStream){
            const jsonData = MainContext.traderStream
            if (jsonData.subE === "Quote" && jsonData.s === selectedSymbolRef.current){
                setBidPrice(jsonData.quote.bidP / 1000)
                setAskPrice(jsonData.quote.askP / 1000)
                    
                setLotSize(jsonData.quote.lotS||1)
            }
        }
    }, [MainContext.traderStream])

    const [selectedSymbol, setSelectedSymbol] = useState("")
    const selectedSymbolRef = useRef("")

    const [isBuy, setIsBuy] = useState(true)
    const [price, setPrice] = useState(0)
    const [volume, setVolume] = useState(0)
    const [t_price, setTPrice] = useState(0)
    const [t_vol, setTVol] = useState(0)
    const [t_ratio, setTRatio] = useState(0)
    const [t_tif, setTTIF] = useState(TIF.Day)

    useEffect(()=>{
        if (price !== 0) return

        if (isBuy && AskPrice > 0){
            setPrice(AskPrice)
            return 
        }

        if (!isBuy && BidPrice > 0){
            setPrice(BidPrice)
            return 
        }
    }, [BidPrice, AskPrice, isBuy, price])

    const CleanUP = useCallback(()=>{
        const setZero = [setBidPrice, setAskPrice, setPrice, setVolume, setTPrice, setTVol, setTRatio]
        setZero.forEach((x)=>{x(0)})
    }, [])

    const SymbolOnKeyPress = useCallback((e)=>{
        if (e.key !== "Enter") return 
            
        const value = e.target.value.trim()
        const valueNum = parseInt(value)
        if (!InputValidation.integer(value) || valueNum < 1 || valueNum >= 100000){
            selectedSymbolRef.current = ""
            e.target.value = selectedSymbolRef.current
            setSelectedSymbol(selectedSymbolRef.current)
            setErrMsg("Invaild Symbol")
            return
        }
        
        selectedSymbolRef.current = value
        e.target.value = selectedSymbolRef.current
        setSelectedSymbol(selectedSymbolRef.current)
        setErrMsg("")

        MainContext.ws.send(JSON.stringify({
            e: "trader",
            subE: "getQuote",
            sessionID: MainContext.session,
            s: selectedSymbolRef.current
        }))
    }, [MainContext.ws, MainContext.session])
    const SymbolOnFocus = useCallback((e)=>{
        selectedSymbolRef.current = ""
        e.target.value = selectedSymbolRef.current
        setSelectedSymbol(selectedSymbolRef.current)

        CleanUP()
    }, [CleanUP])

    const [PriceUpClick, PriceDownClick, LotUpClick, LotDownClick] = useMemo(()=>{
        return [
            ()=>{ setPrice((old)=>{ return ReturnNextUpPrice(old) }) },
            ()=>{ setPrice((old)=>{ return ReturnNextDownPrice(old) }) },
            ()=>{ setVolume(old=>{
                if (old < LotSize) return LotSize
                return Math.floor((old + LotSize) / LotSize) * LotSize
            }) },
            ()=>{ setVolume(old=>{
                if (old - LotSize <= 0) return 0
                return Math.floor((old - LotSize) / LotSize) * LotSize
            }) }
        ]
    }, [LotSize])

    const [errMsg, setErrMsg] = useState("")

    //Guarding For Pending
	const [MaxAllowBuy, MinAllowSell] = useMemo(()=>{
		let maxAllowBuy = null
		let minAllowSell = null

        let maxPendBuy = null
        let minPendSell = null
		MainContext.pending.forEach((x)=>{
			if (x.symbol === selectedSymbol)
				if (x.side === OrderSide.Buy)
                    maxPendBuy = (maxPendBuy===null?x.price:Math.max(maxPendBuy, x.price))
				else if (x.side === OrderSide.Sell)
                    minPendSell = (minPendSell===null?x.price:Math.min(minPendSell, x.price))
		})

		MainContext.pendingRows.forEach((x)=>{
			if (x.symbol === selectedSymbol)
                minPendSell = (minPendSell===null?x.price:Math.min(minPendSell, x.price))
		})

        MainContext.marketTriggers.forEach((x)=>{
			if (x.symbol === selectedSymbol)
				if (x.side === OrderSide.Buy)
                    maxPendBuy = (maxPendBuy===null?x.price:Math.max(maxPendBuy, x.price))
				else if (x.side === OrderSide.Sell)
                    minPendSell = (minPendSell===null?x.price:Math.min(minPendSell, x.price))
		})

        maxAllowBuy = minPendSell===null?99999.0:ReturnNextDownPrice(new Decimal(minPendSell).div(1000).toNumber())
        minAllowSell = maxPendBuy===null?0.01:ReturnNextUpPrice(new Decimal(maxPendBuy).div(1000).toNumber())

		return [maxAllowBuy, minAllowSell]
	}, [selectedSymbol, MainContext.pending, MainContext.pendingRows, MainContext.marketTriggers])
    const SendTriggerPendingOnClick = useCallback(()=>{
        // Symbol check
        if (selectedSymbolRef.current === ""){ setErrMsg("Symbol can't be empty"); return }
        if (typeof(price) === "string"){ setErrMsg("Price must be a number."); return }
        if (price <= 0){ setErrMsg("Price must be > 0"); return }
        if (typeof(volume) === "string"){ setErrMsg("Volume must be a number."); return }
        if (volume <= 0){ setErrMsg("Volume must be > 0"); return }
        // Trigger check
        if (typeof(t_price) === "string"){ setErrMsg("Trigger Price must be a number."); return }
        if (t_price <= 0){ setErrMsg("Price must be > 0"); return }
        if (typeof(t_vol) === "string"){ setErrMsg("Trigger K must be >= 0."); return }
        if (typeof(t_ratio) === "string"){ setErrMsg("Trigger % must be >= 0 and < 100."); return }
        // Guarding
        if ((isBuy && MaxAllowBuy < price) || (!isBuy && MinAllowSell > price)){
            setErrMsg("Guarding to pend order.")
            return
        }

        const tp = new TriggerPending(
            selectedSymbolRef.current,
            isBuy?OrderSide.Buy:OrderSide.Sell
        )
        tp.SetMAXBorMINS(price)
        tp.SetVolume(volume)
        tp.SetTBUorTSU(t_price)
        tp.SetBVorSV(t_vol)
        tp.SetBRorSR(t_ratio)
        tp.SetTIF(t_tif)

        MainContext.ws.send(JSON.stringify({
            e: "trader",
            subE: "triggerPending",
            sessionID: MainContext.session,
            type: ActionType.Add,
            triggerPending: tp
        }))
    }, [MainContext.ws, MainContext.session, isBuy, MaxAllowBuy, MinAllowSell, price, volume, t_price, t_vol, t_ratio, t_tif])

    return useMemo(()=>{
        return (
            <div style={{flex: 1, display: "flex", flexDirection: "column", justifyContent: 'space-around', borderRight: "1px solid black"}}>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 8}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <InputGroup className="react-draggable-cancel" style={{width: "14rem"}}>
                            <InputGroup.Text style={{fontSize: fontSize, padding:"4px 8px"}}>Symbol</InputGroup.Text>
                            <Form.Control type='text' style={{fontSize: fontSize , padding:"4px 8px"}}
                                onFocus={SymbolOnFocus}
                                onKeyPress={SymbolOnKeyPress}
                            />
                        </InputGroup>
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 8}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div style={{width: "14rem"}}>
                            <label>Price</label>
                            <InputGroup className="react-draggable-cancel">
                                <Button style={{fontSize: fontSize}} onClick={PriceDownClick}>-</Button>
                                <Form.Control style={{flex: 1, fontSize: fontSize}} type="text" value={price}
                                    onBlur={(e)=>{
                                        const p = parseFloat(e.target.value)
                                        if (!isNaN(p) && new Decimal(p).mod(new Decimal( GetSpread(p))).toNumber() === 0){
                                            setPrice(p)
                                        }
                                    }}
                                    onChange={(e)=>{
                                        const p = parseFloat(e.target.value)
                                        if (!isNaN(p) && new Decimal(p).mod(new Decimal( GetSpread(p))).toNumber() === 0){
                                            if (p.toString() === e.target.value)
                                                setPrice(p)
                                            else
                                                setPrice(e.target.value)
                                            setErrMsg("")
                                        } else {
                                            setPrice(e.target.value)
                                            setErrMsg("Invalid Price.")
                                        }
                                    }}
                                />
                                <Button style={{fontSize: fontSize}} onClick={PriceUpClick}>+</Button>
                            </InputGroup>
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div style={{width: "14rem"}}>
                            <label>Volume</label>
                            <InputGroup className="react-draggable-cancel">
                                <Button style={{fontSize: fontSize}} onClick={LotDownClick}>-</Button>
                                <Form.Control style={{flex: 1, fontSize: fontSize}} type="text" value={volume} onChange={(e)=>{
                                    const v = parseInt(e.target.value)
                                    if (!isNaN(v)){
                                        setVolume(v)
                                        setErrMsg("")
                                    } else {
                                        setVolume(e.target.value)
                                        setErrMsg("Invalid Volume.")
                                    }
                                }}/>
                                <Button style={{fontSize: fontSize}} onClick={LotUpClick}>+</Button>
                            </InputGroup>
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div style={{width: "14rem"}}>
                            <label>TIF</label>
                            <Form.Select className="react-draggable-cancel" value={t_tif} onChange={(e)=>{setTTIF(parseInt(e.target.value))}}>
                                {
                                    Object.entries(TIF).map((v)=>{
                                        return <option key={v[1]} value={v[1]}>{v[0]}</option>
                                    })
                                }
                            </Form.Select>
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div style={{width: "14rem"}}>
                            <label>Trigger</label>
                            <InputGroup className="mb-1">
                                <InputGroup.Text style={{fontSize: fontSize}}>Price</InputGroup.Text>
                                <Form.Control style={{flex: 1, fontSize: fontSize}} type="text" value={t_price}
                                    onBlur={(e)=>{
                                        const p = parseFloat(e.target.value)
                                        if (!isNaN(p) && new Decimal(p).mod(new Decimal( GetSpread(p))).toNumber() === 0){
                                            setTPrice(p)
                                        }
                                    }}
                                    onChange={(e)=>{
                                        const p = parseFloat(e.target.value)
                                        if (!isNaN(p) && new Decimal(p).mod(new Decimal( GetSpread(p))).toNumber() === 0){
                                            if (p.toString() === e.target.value)
                                                setTPrice(p)
                                            else
                                                setTPrice(e.target.value)
                                            setErrMsg("")
                                        } else {
                                            setTPrice(e.target.value)
                                            setErrMsg("Invalid Trigger Price.")
                                        }
                                    }}
                                />
                            </InputGroup>
                            <InputGroup className="react-draggable-cancel">
                                <InputGroup.Text style={{fontSize: fontSize}}>K</InputGroup.Text>
                                <Form.Control style={{flex: 1, fontSize: fontSize}} type="text" value={t_vol}
                                    onChange={(e)=>{
                                        const v = parseInt(e.target.value)
                                        if (!isNaN(v) && v >= 0){
                                            setTVol(v)
                                            setErrMsg("")
                                        } else {
                                            setTVol(e.target.value)
                                            setErrMsg("Invalid Volume.")
                                        }
                                    }}
                                />
                                <Form.Control style={{flex: 1, fontSize: fontSize, textAlign: "right"}} type="text" value={t_ratio}
                                    onChange={(e)=>{
                                        const v = parseInt(e.target.value)
                                        if (!isNaN(v) && v >= 0 && v < 100){
                                            setTRatio(v)
                                            setErrMsg("")
                                        } else {
                                            setTRatio(e.target.value)
                                            setErrMsg("Invalid Volume.")
                                        }
                                    }}
                                />
                                <InputGroup.Text style={{fontSize: fontSize}}>%</InputGroup.Text>
                            </InputGroup>
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div style={{width: "14rem", color: "red"}}>{errMsg}</div>
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 8}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div className="react-draggable-cancel" style={{display: 'flex', width: "14rem", gap: 8}}>
                            <SwitchableButton style={{flex:1}} value='BUY' color={buyColor} state={isBuy} onClick={()=>{setIsBuy(true)}}/>
                            <SwitchableButton style={{flex:1}} value='SELL' color={sellColor} state={!isBuy} onClick={()=>{setIsBuy(false)}}/>
                            <Button style={{flex:1}} onClick={SendTriggerPendingOnClick}>Send</Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }, [
        fontSize, buyColor, sellColor,
        SymbolOnKeyPress, SymbolOnFocus, PriceUpClick, PriceDownClick, LotUpClick, LotDownClick, SendTriggerPendingOnClick,
        isBuy, price, volume, t_price, t_vol, t_ratio, t_tif, errMsg
    ])
}

function StatusPanel({context, fontSize, color}){
    const MainContext = useContext(context)

    const {buyColor, sellColor} = useMemo(()=>{return color}, [color])

    const triggerPendings = useMemo(()=>{ return MainContext.triggerPendings }, [MainContext.triggerPendings])

    const CancelAction = useCallback((row_id_)=>{
        if (MainContext.ws){
            MainContext.ws.send(JSON.stringify({
                e: "trader",
                subE: "triggerPending",
                sessionID: MainContext.session,
                type: ActionType.Del,
                triggerPending: { row_id_: row_id_ }
            }))
        }
    }, [MainContext.ws, MainContext.session])

    return useMemo(()=>{
        return (
            <div className="noScrollbar" style={{flex: 2, paddingLeft: 10, paddingTop: 10, height: "100%", overflowY: "scroll"}}>
                <Table size="sm" bordered striped>
                    <thead>
                        <tr style={{fontSize: fontSize, textAlign: 'center'}}>
                            <td>RowID</td>
                            <td>Symbol</td>
                            <td>Side</td>
                            <td>Price</td>
                            <td>Volume</td>
                            <td>T.P</td>
                            <td>T.V(K)</td>
                            <td>T.R(%)</td>
                            <td>Cancel({triggerPendings.length})</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            triggerPendings.map((x=TriggerPending.prototype)=>{
                                x.__proto__ = TriggerPending.prototype
                                const isBuy = x.side_=== OrderSide.Buy

                                const price = x.GetMAXBorMINS()
                                const p_decimal = (""+GetSpread(price)).split('.').slice(-1)[0].length

                                const t_price = x.GetTBUorTSU()
                                const tp_decimal = (""+GetSpread(t_price)).split('.').slice(-1)[0].length

                                return (
                                    <tr key={x.row_id_}>
                                        <td>{x.row_id_}</td>
                                        <td style={{textAlign: "center"}}>{x.symbol_}</td>
                                        <td style={{textAlign: "center", color: isBuy?buyColor:sellColor}}>{isBuy?"B":"S"}</td>
                                        <td style={{textAlign: "right"}}>{price.toFixed(p_decimal)}</td>
                                        <td style={{textAlign: "right"}}>{x.volume_}</td>
                                        <td style={{textAlign: "right"}}>{t_price.toFixed(tp_decimal)}</td>
                                        <td style={{textAlign: "right"}}>{x.GetBVorSV()}</td>
                                        <td style={{textAlign: "right"}}>{x.GetBRorSR()}</td>
                                        <td>
                                            <div className="d-grid">
                                                <Button variant="danger" size="sm" style={{fontSize: fontSize, padding: "0px 8px"}}
                                                    onClick={()=>{
                                                        CancelAction(x.row_id_)
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
        )
    }, [fontSize, buyColor, sellColor, triggerPendings, CancelAction])
}

function TriggerPendingPanel({onClose, context}){
    const MainContext = useContext(context)

    const color = useMemo(()=>{
		const {buyColor, sellColor, AsColor, callColor, putColor} = MainContext.clientSetting.setting
		return {buyColor, sellColor, AsColor, callColor, putColor}
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
        MainContext.clientSetting.setting.buyColor,
        MainContext.clientSetting.setting.sellColor,
        MainContext.clientSetting.setting.AsColor,
        MainContext.clientSetting.setting.callColor,
        MainContext.clientSetting.setting.putColor,
    ])

    const fontSize = useMemo(()=>{
		return MainContext.clientSetting.setting.fontSize
	}, [MainContext.clientSetting.setting.fontSize])

    return useMemo(()=>{
        return (
            <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
				<div style={{width: "100%", position: "sticky", display: "flex", backgroundColor: "#0d6efd"}}>
					<span className="text-white" style={{flex: 1, fontSize: 18, padding: 2, paddingLeft: 4}}>Trigger Pending Panel</span>
					<div style={{flex:0, backgroundColor: "#dc3545"}}>
						<CloseButton className="react-draggable-cancel" variant="white" style={{fontSize: 18, padding: 6}} onClick={onClose}/>
					</div>
				</div>
                <div className="react-draggable-cancel" style={{paddingLeft: 10, paddingRight: 10, height: "100%", overflow: "hidden", overflowY: "auto", display: "flex", flexDirection: "row"}}>
                    <ControlPanel context={context} fontSize={fontSize} color={color} />
                    <StatusPanel context={context} fontSize={fontSize} color={color} />
                </div>
            </div>
        )
    }, [context, onClose, fontSize, color])
}

export default TriggerPendingPanel