import { useState, useEffect, useRef, useMemo, useContext, useCallback } from 'react'
import { Decimal } from 'decimal.js'

import { StatusType } from '../../../api/enum'
import { ReturnNextNUpPrice, ReturnNextUpPrice, ReturnNextDownPrice } from '../../../functions/StockHandle'
import { OrderSide, OrderStatus, RowModifyType } from '../../../functions/Enum'

import { Button } from 'react-bootstrap'
import Form from "react-bootstrap/Form"
import InputGroup from 'react-bootstrap/InputGroup'
import Card from "react-bootstrap/Card"
import SwitchableButton from '../global_component/SwitchableButton'

import Symbol from './TraderW2/Symbol'
import { BuyControl, SellControl } from './TraderW2/BuySellControl'
import ErrorMsg from '../../../functions/ErrorMsg'
import Sounds from '../../../functions/SoundHandler'

function returnStyle(color, fontSize){
	let style = {}
	style.InputClass = "react-draggable-cancel form-control border-"+color
	style.InputStyle = {padding: "0 4px", fontSize: fontSize, borderColor: color}
	style.InputButtonStyle = {flex: 1, padding: "0 4px", fontSize: fontSize, borderColor: color, color: color}
	style.LabelClass = "text-white"
	style.LabelStyle = {padding: "0 4px", fontSize: fontSize, backgroundColor: color, borderColor: color}
	return style
}

function AsControl({
		ap, at, selectedSymbol, disabled,
		setAP, setAT,
		AsLight, setAsLight,
		AsColor, AsStyle, fontSize, shortcutKeySetting
}){
	const [APInput, setAPInput] = useState(null)
	const [ATInput, setATInput] = useState(null)

	useEffect(()=>{
		if (APInput){
			APInput.value = ap
		}
	}, [APInput, ap])

	useEffect(()=>{
		if (ATInput){
			ATInput.value = at
		}
	}, [ATInput, at])

	return useMemo(()=>{
		return (
			<div style={{flex:2, margin:2, display: "flex"}}>
				<Button size="sm" className="react-draggable-cancel" variant={AsLight?AsColor:"outline-secondary"} /*disabled={selectedSymbol===null}*/ disabled={disabled}
					style={{flex: 2, marginLeft: 4, padding: "0 4px", fontSize: fontSize, color: (AsLight)?"white":"" , borderColor: (AsLight)?AsColor:"", backgroundColor: (AsLight)?AsColor:""}}
					onClick={()=>{
						if (!AsLight) setAsLight(true)
						else setAsLight(false)
					}}
				>
					AS
				</Button>
				<InputGroup style={{flex: 3, marginLeft: 4, fontSize: fontSize}}>
					<InputGroup.Text className={AsStyle.LabelClass} style={AsStyle.LabelStyle}>AP</InputGroup.Text>
					<Form.Control className={AsStyle.InputClass} style={AsStyle.InputStyle} ref={setAPInput}
						onBlur={(e)=>{
							if (/^[\d]*[.]*(\d)*$/.test(e.target.value)){
								const value = parseFloat(e.target.value)
								if (value > 0){
									e.target.value = Math.round(value*1000)/1000
									setAP(Math.round(value*1000)/1000)
									return
								}
							}
							e.target.value = ap
						}}
						onKeyPress={(e)=>{
							if (e.code === shortcutKeySetting.addKey){
								e.preventDefault()
								setAP((last)=>{
									const v = ReturnNextUpPrice(last)
									e.target.value = v
									return v
								})
							}else if (e.code === shortcutKeySetting.minusKey){
								e.preventDefault()
								setAP((last)=>{
									const v = ReturnNextDownPrice(last)
									e.target.value = v
									return v
								})
							}
						}
					}/>
				</InputGroup>
				<InputGroup style={{flex: 3, marginLeft: 4, fontSize: fontSize}}>
					<InputGroup.Text className={AsStyle.LabelClass} style={AsStyle.LabelStyle}>AT</InputGroup.Text>
					<Form.Control className={AsStyle.InputClass} style={AsStyle.InputStyle} ref={setATInput}
						onBlur={(e)=>{
								if (e.target.value !== ""){
									if (/^[\d]*[.]*(\d)*$/.test(e.target.value)){
										const value = parseInt(parseFloat(e.target.value)*1000)/1000
										if (value > 0 && value < 100){
											e.target.value = value
											setAT(value)
											return
										}
									}
									e.target.value = at
								}else{
									e.target.value = 0
									setAT(0)
								}
						}}
					/>
				</InputGroup>
			</div>
		)
	}, [ap, at, AsColor, AsStyle, AsLight, shortcutKeySetting, disabled])
}

function Quote({callPut, buyColor, sellColor, UBidPrice, UBidVolume, UAskPrice, UAskVolume}){
	const [UBidRatio, UAskRatio] = useMemo(()=>{
		if (UBidVolume === UAskVolume)
			return [50, 50]

		const total = UBidVolume + UAskVolume
		return [Math.round(UBidVolume / total * 100), Math.round(UAskVolume / total * 100)]
	}, [UBidVolume, UAskVolume])

	return useMemo(()=>{
		if (callPut === "Call" || callPut === "Bull")
			return (
				<>
					<div style={{flex: 2, color: sellColor, textAlign: 'center'}}>
						UAsk: {UAskPrice} | {UAskRatio}% | K: {Math.round(UAskVolume/100)/10}
					</div>
					<div style={{flex: 2, color: buyColor, textAlign: 'center'}}>
						UBid: {UBidPrice} | {UBidRatio}% | K: {Math.round(UBidVolume/100)/10}
					</div>
				</>
			)
		else
			return (
				<>
					<div style={{flex: 2, color: buyColor, textAlign: 'center'}}>
						UBid: {UBidPrice} | {UBidRatio}% | K: {Math.round(UBidVolume/100)/10}
					</div>
					<div style={{flex: 2, color: sellColor, textAlign: 'center'}}>
						UAsk: {UAskPrice} | {UAskRatio}% | K: {Math.round(UAskVolume/100)/10}
					</div>
				</>
			)
	}, [callPut, UBidPrice, UAskPrice, UBidVolume, UAskVolume])
}

function parseNumber(num){
    if (num >= 1000){
        return Math.round(num/10) /100 + "K"
    }
    return num
}

function UserHolds({Pts, AvaliablePts, Cost, DBidPrice, fontSize, buyColor, sellColor, AsColor}){
	return useMemo(()=>{
		let FPnL = Math.round((DBidPrice-Cost)*Pts*100)/100
		const PtsColor = Pts>0?AsColor:undefined;
		const PnlColor = FPnL>0?buyColor:FPnL<0?sellColor:undefined;

		return (
			<div style={{display: "flex"}}>
				<InputGroup style={{fontSize: fontSize, width: "fit-content", marginLeft: 4}}>
					<InputGroup.Text style={{padding: "0 4px", fontSize: fontSize}}>Cost</InputGroup.Text>
					<InputGroup.Text style={{padding: "0 4px", fontSize: fontSize}}>{Cost}</InputGroup.Text>
				</InputGroup>
				<InputGroup style={{fontSize: fontSize, width: "fit-content", marginLeft: 4}}>
					<InputGroup.Text style={{padding: "0 4px", fontSize: fontSize}}>Ava.Pts</InputGroup.Text>
					<InputGroup.Text className={AvaliablePts>0?"text-white":""} style={{padding: "0 4px", fontSize: fontSize, backgroundColor: PtsColor}}>{parseNumber(AvaliablePts)}</InputGroup.Text>
				</InputGroup>
				<InputGroup style={{fontSize: fontSize, width: "fit-content", marginLeft: 4}}>
					<InputGroup.Text style={{padding: "0 4px", fontSize: fontSize}}>F.PnL</InputGroup.Text>
					<InputGroup.Text className={Math.abs(FPnL)>0?"text-white":""} style={{padding: "0 4px", fontSize: fontSize, backgroundColor: PnlColor}}>{FPnL}</InputGroup.Text>
				</InputGroup>
			</div>
		)
	}, [Pts, AvaliablePts, Cost, DBidPrice])
}

function TraderW2({context, rowKey, color, volumeSetting, shortcutKeySetting}){
	const MainContext = useContext(context)
    const AllowAddRow = useRef(false)
	const AllowModify = useRef(false)

    const [rowID, setRowID] = useState(null)
	
	const [symbol, setSymbol] = useState("")
	const [selectedSymbol, setSelectedSymbol] = useState(null)

	const [underlying, callPut] = useMemo(()=>{
        let [underlying, callPut] = [selectedSymbol, "Call"]

        if (selectedSymbol && selectedSymbol.length === 5) {
			let data = []
			if (selectedSymbol[0] === "1" || selectedSymbol[0] === "2")
				data = MainContext.warrants.filter((x)=>{
					if (x[1] === selectedSymbol)
						return true
					return false
				})
			else if (selectedSymbol[0] === "5" || selectedSymbol[0] === "6")
				data = MainContext.cbbcs.filter((x)=>{
					if (x[1] === selectedSymbol)
						return true
					return false
				})
			
			if (data.length === 1){
				underlying = data[0][3]
				callPut = data[0][5]
			}
        }

        return [underlying, callPut]
    }, [selectedSymbol, MainContext.warrants, MainContext.cbbcs, MainContext.ws])

	const asset = useMemo(()=>{
		const result = MainContext.assets.filter((x)=>{
			if (x.symbol === selectedSymbol)
				return true
			return false
		})

		if (result.length !== 1)
			return {Pnl: 0, Pts: 0, Cost: 0, Available_Pts: 0}

		return result[0]
	}, [MainContext.assets, selectedSymbol])

	const [UBidPrice, setUBidPrice] = useState(0)
    const [UBidVolume, setUBidVolume] = useState(0)
    const [UAskPrice, setUAskPrice] = useState(0)
    const [UAskVolume, setUAskVolume] = useState(0)
	const [DBidPrice, setDBidPrice] = useState(0)
    const [DBidVolume, setDBidVolume] = useState(0)
    const [DAskPrice, setDAskPrice] = useState(0)
    const [DAskVolume, setDAskVolume] = useState(0)

	const [fontSize] = useMemo(()=>{
		return [12]
	}, [])

	const {buyColor, sellColor, AsColor, callColor, putColor} = useMemo(()=>{
		return color
	}, [color])
	const {maxVolume, defaultVolume} = useMemo(()=>{
		return volumeSetting
	}, [volumeSetting])

	const buyStyle = useMemo(()=>{return (returnStyle(buyColor, fontSize))}, [buyColor, fontSize])
	const sellStyle = useMemo(()=>{return (returnStyle(sellColor, fontSize))}, [sellColor, fontSize])
	const AsStyle = useMemo(()=>{return (returnStyle(AsColor, fontSize))}, [AsColor, fontSize])

	const [buyLight, setBuyLight] = useState(false)
	const [sellLight, setSellLight] = useState(false)
	const [AsLight, setAsLight] = useState(false)
	const [AslLight, setAslLight] = useState(false)
	const [fbuyLight, setFBuyLight] = useState(false)
	const [fsellLight, setFSellLight] = useState(false)

	const [volume, setVolume] = useState(defaultVolume)
	const [tbu, setTBU] = useState(0)
	const [maxB, setMaxB] = useState(0)
	const [br, setBR] = useState(0)
	const [bv, setBV] = useState(0)
	const [tsu, setTSU] = useState(0)
	const [minS, setMinS] = useState(0)
	const [sr, setSR] = useState(0)
	const [sv, setSV] = useState(0)
	const [ap, setAP] = useState(0)
	const [at, setAT] = useState(1)

	const [MaxAllowBuy, MinAllowSell] = useMemo(()=>{
		let maxAllowBuy = null
		let minAllowSell = null

        let maxPendBuy = null
        let minPendSell = null
		MainContext.pending.forEach((x)=>{
			if (x.symbol === selectedSymbol)
				if (x.side === OrderSide.Buy)
                    maxPendBuy = (maxPendBuy===null?x.price:Math.max(maxPendBuy, x.price))
				else if (x.side === OrderSide.Sell)
                    minPendSell = (minPendSell===null?x.price:Math.min(minPendSell, x.price))
		})

		MainContext.pendingRows.forEach((x)=>{
			if (x.symbol === selectedSymbol)
                minPendSell = (minPendSell===null?x.price:Math.min(minPendSell, x.price))
		})

        MainContext.marketTriggers.forEach((x)=>{
			if (x.symbol === selectedSymbol)
				if (x.side === OrderSide.Buy)
                    maxPendBuy = (maxPendBuy===null?x.price:Math.max(maxPendBuy, x.price))
				else if (x.side === OrderSide.Sell)
                    minPendSell = (minPendSell===null?x.price:Math.min(minPendSell, x.price))
		})

        maxAllowBuy = minPendSell===null?99999.0:ReturnNextDownPrice(new Decimal(minPendSell).div(1000).toNumber())
        minAllowSell = maxPendBuy===null?0.01:ReturnNextUpPrice(new Decimal(maxPendBuy).div(1000).toNumber())

		return [maxAllowBuy, minAllowSell]
	}, [selectedSymbol, MainContext.pending, MainContext.pendingRows, MainContext.marketTriggers])
	useEffect(()=>{
		if (MaxAllowBuy < maxB){
			setBuyLight(false)
		}

		if (MinAllowSell > minS || MinAllowSell > ap){
			setSellLight(false)
			setAsLight(false)
		}
	}, [MaxAllowBuy, MinAllowSell, maxB, minS, ap])

	const cleanUP = useCallback(() => {
        const setNull = [setRowID]
        setNull.forEach((e)=>{ e(null) });

        const setFalse = [setBuyLight, setSellLight, setAsLight, setAslLight]
        setFalse.forEach((e)=>{ e(false) });

        const setZero = [
			setTBU, setTSU, setMaxB, setMinS
			, setUBidPrice, setUBidVolume, setUAskPrice, setUAskVolume, setDBidPrice, setDAskPrice, setDBidVolume, setDAskVolume
			, setBR, setBV, setSR, setSV, setAP
		]
        setZero.forEach((e)=>{ e(0) });

		setVolume(defaultVolume)
    }, [])

	useEffect(()=>{
        if (MainContext.session){
			cleanUP()
			setSymbol("")
			setSelectedSymbol(null)

			AllowAddRow.current = false
            MainContext.ws.send(JSON.stringify({e: "trader", subE: "loadRow", rowKey: rowKey, sessionID: MainContext.session}))
        }
    }, [MainContext.session])

	useEffect(()=>{
        if (!MainContext.traderStream)
            return

        const traderStream = MainContext.traderStream
        if (traderStream.subE === "createRow"){
            if (traderStream.rowKey === rowKey && traderStream.status === StatusType.Success){
                setRowID(traderStream.rowID)
			}
			else if(traderStream.rowKey === rowKey && traderStream.status === StatusType.Error)
			{
				AllowAddRow.current = true
				AllowModify.current = true
				cleanUP()
				setSymbol("")
                setSelectedSymbol(null)
			}
        }
        else if (traderStream.subE === "loadRow"){
            if (traderStream.rowKey === rowKey){
                if (traderStream.status === StatusType.Success){
                    AllowAddRow.current = false
					AllowModify.current = false
                    setRowID(traderStream.row.rowID)
                    setSelectedSymbol(traderStream.row.symbol)
                    setSymbol(traderStream.row.symbol)

					setBuyLight(traderStream.row.buyLight)
					setSellLight(traderStream.row.sellLight)

					setVolume(traderStream.row.buyVolume/1000)
					
					setBV(traderStream.row.bv/1000)
					setSV(traderStream.row.sv/1000)
					setBR(traderStream.row.br)
					setSR(traderStream.row.sr)
					setTBU(traderStream.row.tbu/1000)
					setTSU(traderStream.row.tsu/1000)
					setMaxB(traderStream.row.maxB/1000)
					setMinS(traderStream.row.minS/1000)

					setAsLight(traderStream.row.AsLight)
                    setAP(traderStream.row.ap/1000)
					setAT(traderStream.row.at)

                    setTimeout(()=>{
                        AllowAddRow.current = true
						AllowModify.current = true
                    }, 5)
                }else{
					AllowAddRow.current = true
					AllowModify.current = true
                    cleanUP()
                }
            }
        }
		else if (traderStream.subE === "serverRes_Order"){
			const serverRes_Order = traderStream.serverRes_Order
			if (serverRes_Order.RowID === rowID){
				if (serverRes_Order.Status === OrderStatus.Full || serverRes_Order.Status === OrderStatus.Reject){
					AllowModify.current = false
					if (serverRes_Order.OSide === OrderSide.Buy){
						setBuyLight(false)
						if (serverRes_Order.Status === OrderStatus.Full && AslLight){
							const newAP = ReturnNextNUpPrice(serverRes_Order.Cost, at)
							setAP(newAP)
							MainContext.ws.send(JSON.stringify({
                                e: "trader",
                                subE: "modifyRow",
                                sessionID: MainContext.session,
                                rowModifyType: RowModifyType.ap,
                                row: { rowID: rowID, ap: newAP*1000}
                            }))
						}
					}
					else if (serverRes_Order.OSide === OrderSide.Sell){
						setSellLight(false)
						setAsLight(false)
					}
					setTimeout(()=>{
						AllowModify.current = true
					}, 5)
				}
			}
		}
		else if (traderStream.subE === "Quote"){
            if (traderStream.s === underlying && AllowModify.current){
                setUBidPrice(new Decimal(traderStream.quote.bidP).div(1000).toNumber())
                setUAskPrice(new Decimal(traderStream.quote.askP).div(1000).toNumber())
                setUBidVolume(traderStream.quote.bidS)
                setUAskVolume(traderStream.quote.askS)
            }
            if (traderStream.s === selectedSymbol && AllowModify.current){
                setDBidPrice(new Decimal(traderStream.quote.bidP).div(1000).toNumber())
                setDAskPrice(new Decimal(traderStream.quote.askP).div(1000).toNumber())
                setDBidVolume(traderStream.quote.bidS)
                setDAskVolume(traderStream.quote.askS)
            }
        }
    }, [MainContext.traderStream])

	useEffect(()=>{
		if (AllowModify.current === true){
			cleanUP()
		}

        if (AllowAddRow.current === true){
            if (!rowID && selectedSymbol){
                MainContext.ws.send(JSON.stringify({
					e: "trader",
					subE: "createRow",
					sessionID: MainContext.session,
					row: {
						rowKey: rowKey,
						symbol: selectedSymbol,

						buyLight: false,
						sellLight: false,

						buyVolume: defaultVolume * 1000,
						sellVolume: defaultVolume * 1000,

						bv: 0,
						sv: 0,
						br: 0,
						sr: 0,
						tbu: 0,
						tsu: 0,
						maxB: 0,
						minS: 0,

						AsLight: false,
						ap: 0,
						at: at
					}
				}))
            }
			else{
                MainContext.ws.send(JSON.stringify({e: "trader", subE: "delRow", sessionID: MainContext.session, rowKey: rowKey}))
				if (selectedSymbol){
					MainContext.ws.send(JSON.stringify({
						e: "trader",
						subE: "createRow",
						sessionID: MainContext.session,
						row: {
							rowKey: rowKey,
							symbol: selectedSymbol,

							buyLight: false,
							sellLight: false,

							buyVolume: defaultVolume * 1000,
							sellVolume: defaultVolume * 1000,

							bv: 0,
							sv: 0,
							br: 0,
							sr: 0,
							tbu: 0,
							tsu: 0,
							maxB: 0,
							minS: 0,

							AsLight: false,
							ap: 0,
							at: at
						}
					}))
				}
            }
        }
    }, [selectedSymbol])

	//Get Quote When getNewRowID
	useEffect(()=>{
		if (rowID && selectedSymbol){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "subscribeRow",
				sessionID: MainContext.session,
				row: { rowID: rowID }
			}))
		}
	}, [selectedSymbol, rowID])

	// Send Modified value to server
	useEffect(()=>{
		if (rowID && AllowModify.current){
			if (volume >= 0){
				MainContext.ws.send(JSON.stringify({
					e: "trader",
					subE: "modifyRow",
					sessionID: MainContext.session,
					rowModifyType: RowModifyType.buyVolume,
					row: { rowID: rowID, buyVolume: volume*1000 }
				}))
				MainContext.ws.send(JSON.stringify({
					e: "trader",
					subE: "modifyRow",
					sessionID: MainContext.session,
					rowModifyType: RowModifyType.sellVolume,
					row: { rowID: rowID, sellVolume: volume*1000 }
				}))
				if (volume === 0){
					setBuyLight(false)
					setSellLight(false)
					setAsLight(false)
				}
			}
		}
	}, [volume])
	useEffect(()=>{
		if (rowID && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.bv,
				row: { rowID: rowID, bv: bv*1000 }
			}))
		}
	}, [bv])
	useEffect(()=>{
		if (rowID && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.sv,
				row: { rowID: rowID, sv: sv*1000 }
			}))
		}
	}, [sv])
	useEffect(()=>{
		if (rowID && tbu > 0 && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.tbu,
				row: { rowID: rowID, tbu: tbu*1000 }
			}))
		}
	}, [tbu])
	useEffect(()=>{
		if (rowID && tsu > 0 && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.tsu,
				row: { rowID: rowID, tsu: tsu*1000 }
			}))
		}
	}, [tsu])
	useEffect(()=>{
		if (rowID && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.br,
				row: { rowID: rowID, br: br }
			}))
		}
	}, [br, tbu])
	useEffect(()=>{
		if (rowID && AllowModify.current){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.sr,
				row: { rowID: rowID, sr: sr }
			}))
		}
	}, [sr, tsu])
	useEffect(()=>{
		if (rowID){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.maxB,
				row: { rowID: rowID, maxB: maxB*1000 }
			}))
		}
	}, [maxB])
	useEffect(()=>{
		if (rowID){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.minS,
				row: { rowID: rowID, minS: minS*1000 }
			}))
		}
	}, [minS])
	useEffect(()=>{
        if (rowID){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.ap,
				row: { rowID: rowID, ap: ap*1000 }
			}))
		}
    }, [ap])
	useEffect(()=>{
        if (rowID){
			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.at,
				row: { rowID: rowID, at: at }
			}))
		}
    }, [at])
	useEffect(()=>{
		if (rowID && AllowModify.current){
			if (MaxAllowBuy < maxB){
				Sounds.Error()
				MainContext.AddErrorMsg(Date.now(), selectedSymbol, ErrorMsg.NotAllowBuyWhenPending)
				setBuyLight(false)
				return
			}

			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.buyLight,
				row: { rowID: rowID, buyLight: buyLight }
			}))
		}
	}, [buyLight])
	useEffect(()=>{
		if (rowID && AllowModify.current){
			if (MinAllowSell > minS){
				Sounds.Error()
				MainContext.AddErrorMsg(Date.now(), selectedSymbol, ErrorMsg.NotAllowSellWhenPending)
				setSellLight(false)
				return
			}

			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "modifyRow",
				sessionID: MainContext.session,
				rowModifyType: RowModifyType.sellLight,
				row: { rowID: rowID, sellLight: sellLight }
			}))
		}
	}, [sellLight])
	useEffect(()=>{
		if (rowID && AllowModify.current && fbuyLight){
			if (MaxAllowBuy < DAskPrice){
				Sounds.Error()
				MainContext.AddErrorMsg(Date.now(), selectedSymbol, ErrorMsg.NotAllowBuyWhenPending)
				setFBuyLight(false)
				return
			}

			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "forceRow",
				sessionID: MainContext.session,
				row: { rowID: rowID, buyLight: true, sellLight: false}
			}))
		}
	}, [fbuyLight])
	useEffect(()=>{
		if (rowID && AllowModify.current && fsellLight){
			if (MinAllowSell > DBidPrice){
				Sounds.Error()
				MainContext.AddErrorMsg(Date.now(), selectedSymbol, ErrorMsg.NotAllowSellWhenPending)
				setFSellLight(false)
				return
			}

			MainContext.ws.send(JSON.stringify({
				e: "trader",
				subE: "forceRow",
				sessionID: MainContext.session,
				row: { rowID: rowID, buyLight: false, sellLight: true}
			}))
		}
	}, [fsellLight])

	useEffect(()=>{
		if (asset.Available_Pts <= 0 && !sellLight){
			setAsLight(false)
		}
	}, [asset.Available_Pts])
	useEffect(()=>{
		if (AsLight){
			if (MinAllowSell > DBidPrice){
				Sounds.Error()
				MainContext.AddErrorMsg(Date.now(), selectedSymbol, ErrorMsg.NotAllowSellWhenPending)
				setAsLight(false)
				return
			}
		}

		if (AsLight && (asset.Available_Pts > volume || sellLight)){
			if (DBidPrice >= ap){
				MainContext.ws.send(JSON.stringify({
                    e: "trader",
                    subE: "forceRow",
                    sessionID: MainContext.session,
                    row: { rowID: rowID, buyLight: false, sellLight: true, ap: DBidPrice * 1000}
                }))
			}
		}
	}, [ap, AsLight, MinAllowSell, DBidPrice])

	useEffect(()=>{
        if (UBidPrice !== 0 && UAskPrice !== 0 && rowID && AllowModify.current){
			if (tbu === 0)
                if (callPut === "Call" || callPut === "Bull")
                    setTBU(UAskPrice)
                else
                    setTBU(UBidPrice)
            if (tsu === 0)
                if (callPut === "Call" || callPut === "Bull")
                    setTSU(UBidPrice)
                else
                    setTSU(UAskPrice)
        }
    }, [UBidPrice, UAskPrice])
	useEffect(()=>{
		if (DBidPrice > 0 && DAskPrice > 0 && rowID && AllowModify.current){
			if (maxB === 0)
				setMaxB(DAskPrice)
			if (minS === 0)
				setMinS(DBidPrice)
			if (ap === 0)
				setAP(DBidPrice)
		}
	}, [DBidPrice, DAskPrice])

	return useMemo(()=>{
		return (
			<Card tabIndex={5} onKeyPress={(e)=>{
				if (e.code === shortcutKeySetting.triggerBuyKey)
					setBuyLight((last)=>{ return !last})
				else if (e.code === shortcutKeySetting.triggerSellKey)
					setSellLight((last)=>{ return !last})
			}}>
				<Card.Header style={{display:"flex",padding: 4, fontSize: fontSize}}>
					<Symbol symbol={symbol} setSymbol={setSymbol}
						selectedSymbol={selectedSymbol} setSelectedSymbol={setSelectedSymbol}
						volume={volume} setVolume={setVolume} underlying={underlying} callPut={callPut}
						defaultVolume={defaultVolume} maxVolume={maxVolume}
						AsColor={AsColor} AslLight={AslLight} setAslLight={setAslLight}
						buyLight={buyLight} sellLight={sellLight} fbuyLight={fbuyLight} fsellLight={fsellLight} fontSize={fontSize}
					/>
					<Quote callPut={callPut} buyColor={buyColor} sellColor={sellColor} UBidPrice={UBidPrice} UBidVolume={UBidVolume} UAskPrice={UAskPrice} UAskVolume={UAskVolume}/>
					<UserHolds AvaliablePts={asset.Available_Pts} Pts={asset.Pts} Cost={asset.Cost} DBidPrice={DBidPrice} fontSize={fontSize} buyColor={buyColor} sellColor={sellColor} AsColor={AsColor}/>
					<div style={{display: "flex", flexShrink: 0, width: "fit-content"}}>
						<SwitchableButton className="react-draggable-cancel" value={"FB: " + DAskPrice.toFixed(3)} style={{flex: 2, marginLeft: 4, whiteSpace: "nowrap", padding: "0 4px", fontSize: fontSize}} 
							size="sm" color={buyColor} state={fbuyLight} disabled={DAskPrice === 0 || volume <= 0}
							onClick={()=>{
								if (!fbuyLight){
									setFBuyLight(true)
									setTimeout(()=>{
										setFBuyLight(false)
									}, 500)
								}
							}}
						/>
						<SwitchableButton className="react-draggable-cancel" value={"FS: " + DBidPrice.toFixed(3)} style={{flex: 2, marginLeft: 4, whiteSpace: "nowrap", padding: "0 4px", fontSize: fontSize}} 
							size="sm" color={sellColor} state={fsellLight} disabled={DBidPrice === 0 || volume <= 0}
							onClick={()=>{
								if (!fsellLight){
									setFSellLight(true)
									setTimeout(()=>{
										setFSellLight(false)
									}, 500)
								}
							}}
						/>
					</div>
				</Card.Header>
				<Card.Body style={{padding: 0}}>
					<div style={{display: "flex", padding: 0}}>
						<BuyControl buyLight={buyLight} setBuyLight={setBuyLight} UBid={UBidPrice} UAsk={UAskPrice} DBid={DBidPrice} DAsk={DAskPrice} volume={volume}
							tbu={tbu} setTBU={setTBU} maxB={maxB} setMaxB={setMaxB} callPut={callPut}
							br={br} setBR={setBR} bv={bv} setBV={setBV}
							buyColor={buyColor} buyStyle={buyStyle} fontSize={fontSize} shortcutKeySetting={shortcutKeySetting}
						/>
						<SellControl sellLight={sellLight} setSellLight={setSellLight} UBid={UBidPrice} UAsk={UAskPrice} DBid={DBidPrice} DAsk={DAskPrice} volume={volume}
							tsu={tsu} setTSU={setTSU} minS={minS} setMinS={setMinS} callPut={callPut}
							sr={sr} setSR={setSR} sv={sv} setSV={setSV}
							sellColor={sellColor} sellStyle={sellStyle} fontSize={fontSize} shortcutKeySetting={shortcutKeySetting}
						/>
						<AsControl AsLight={AsLight} setAsLight={setAsLight} disabled={(asset.Available_Pts === 0 && !sellLight) || volume <= 0}
							ap={ap} at={at} selectedSymbol={selectedSymbol}
							setAP={setAP} setAT={setAT}
							AsColor={AsColor} AsStyle={AsStyle} fontSize={fontSize} shortcutKeySetting={shortcutKeySetting}
						/>
					</div>
				</Card.Body>
			</Card>
		)
	}, [
		symbol, selectedSymbol, underlying, callPut, asset.Pnl, asset.Cost, asset.Pts,
		UBidPrice, UAskPrice, UBidVolume, UAskVolume, DBidPrice, DAskPrice,
		tbu, maxB, bv, br, buyLight, fbuyLight,
		tsu, minS, sv, sr, sellLight, fsellLight,
		ap, at, AslLight, AsLight,
		volume, defaultVolume, maxVolume,
		buyColor, sellColor, AsColor, buyStyle, sellStyle, AsStyle,
		shortcutKeySetting
	])
}

export default TraderW2