const ErrorMsg = {
	BuySMAError: "Short VWAP can't >= Long VWAP",
	SellSMAError: "Short VWAP can't <= Long VWAP",
    SMASelectionError: "SMA must selected two option",
	BuyVolumeError: "Buy Volume can't <= 0",
	SellVolumeError: "Sell Volume can't <= 0",
	VolumeError: "Sell Volume can't <= 0",
	NoConditionError: "No Condition was selected",
	BuyTOMultiplierError: "Buy TOMultiplier can't <= 0",
	SellTOMultiplierError: "Sell TOMultiplier can't <= 0",
	TOMultiplierError: "TOMultiplier can't <= 0",
    BVError: "BV can't <= 0",
    SVError: "SV can't <= 0",
    NotAllowChangeCondition: "No change during Light on",
    NotAllowBuyWhenPending: "No Buy When Pending Sell",
    NotAllowSellWhenPending: "No Sell When Pending Buy"
}

export default ErrorMsg