import { useState, useEffect, useMemo } from 'react'

import Form from "react-bootstrap/Form"
import InputGroup from 'react-bootstrap/InputGroup'

import SwitchableButton from '../../global_component/SwitchableButton'

function Symbol({
	disableInput, disableSymbolInput, shortcutKeySetting,
	symbol, setSymbol,
	selectedSymbol, setSelectedSymbol,
	volume, setVolume,
	defaultVolume, maxVolume,
	userBuyLight, sellLight, BuyLightBtnOnClick, SellLightBtnOnClick,
	AsLight, setAsLight,
	fontSize, buyColor, sellColor, AsColor
}){
    const [VolumeInput, setVolumeInput] = useState(null)

    useEffect(()=>{
        if (VolumeInput){
            VolumeInput.value = volume
        }
    }, [volume, VolumeInput])

	return useMemo(()=>{
		return (
			<div style={{display: "flex"}}>
				<InputGroup style={{fontSize: fontSize, width: "fit-content"}}>
					<InputGroup.Text style={{padding: "0 4px", fontSize: fontSize}}>Symbol</InputGroup.Text>
					<Form.Control disabled={disableSymbolInput} className="react-draggable-cancel" value={symbol} style={{padding: "0 4px", fontSize: fontSize, width: 50}} onChange={(v)=>{setSymbol(v.currentTarget.value)}}
						onBlur={(e)=>{e.target.value = selectedSymbol}}
						onKeyPress={(e)=>{
							if (e.code === "Enter" || e.code === "NumpadEnter"){
								if (symbol.length > 0){
									setSelectedSymbol(symbol)
								}
							}
						}}
					/>
				</InputGroup>
				<InputGroup style={{marginLeft: 4, fontSize: fontSize, width: "fit-content"}}>
					<InputGroup.Text style={{padding: "0 4px", fontSize: fontSize}}>Vol(K)</InputGroup.Text>
					<Form.Control disabled={disableInput} className="react-draggable-cancel" ref={(r)=>{setVolumeInput(r)}} style={{padding: "0 4px", fontSize: fontSize, width: 40}}
						onBlur={(e) => {
							const value = e.currentTarget.value
							setVolume((last)=>{
								if (value !== ""){
									if (/^[\d]*$/.test(value)){
										let volume = parseInt(value)
										if (volume > maxVolume || volume < 0){
											e.target.value = last
											return last
										}
									return volume
									}
									e.target.value = last
									return last
								}
								e.target.value = defaultVolume
								return defaultVolume
							})
						}}
					/>
				</InputGroup>
				<SwitchableButton value='BUY' disabled={sellLight || selectedSymbol === null}
					color={buyColor} style={{marginLeft: 4, fontSize: fontSize, padding: "0px 4px", minWidth: 100}} state={userBuyLight}
					onClick={BuyLightBtnOnClick}
				/>
				<SwitchableButton value='SELL' disabled={userBuyLight || selectedSymbol === null}
					color={sellColor} style={{marginLeft: 4, fontSize: fontSize, padding: "0px 4px", minWidth: 100}} state={sellLight}
					onClick={SellLightBtnOnClick}
				/>
				<SwitchableButton value="AS" state={AsLight} setState={setAsLight} color={AsColor} style={{marginLeft: 4,fontSize: fontSize, padding: "0px 4px", minWidth: 60}}/>
			</div>
		)
	}, [
		fontSize,
		disableInput, disableSymbolInput, shortcutKeySetting,
		symbol, selectedSymbol,
		volume, maxVolume, defaultVolume,
		userBuyLight, sellLight, BuyLightBtnOnClick, SellLightBtnOnClick, AsLight
	])
}

export default Symbol